import React from "react";

export default function SortingIcons({iconName, classNames, onClick}) {
  if (iconName === 'desc') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className={classNames} viewBox="0 0 20 20" fill="currentColor" onClick={onClick}>
        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
      </svg>
    )
  } else if (iconName === 'asc') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className={classNames} viewBox="0 0 20 20" fill="currentColor" onClick={onClick}>
        <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
      </svg>
    )
  } else {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" className={classNames} viewBox="0 0 20 20" fill="currentColor" onClick={onClick}>
        <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
      </svg>
    )
  }
}