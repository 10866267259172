import React, { Fragment, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { getTooltipMsg, getFisikalTooltipMsg, getClubreadyTooltipMsg } from '../../helpers'
import { assignTempPassword, processSyncUser } from './../../../helpers'
import ReactTooltip from "react-tooltip";
import CustomPopup from './../../assets/customPopup/customPopup'
import DeleteUserComponent from '../deleteUserComponent/deleteUserComponent';
import AlertPopup from './../../assets/alertPopup'
import ChangePasswordComponent from '../changePasswordComponent/changePasswordComponent';
import EmptyTableComponent from '../emptyTableComponent/emptyTableComponent';
import ActivationEmailSingleComponent from '../activationEmailComponent/activationEmailSingleComponent';
import Cookies from 'js-cookie';
import { getSudomain } from '../../../../../helpers'
import ResendVerificationEmailComponent from '../resendVerificationEmailComponent/resendVerificationEmailComponent'
import { disableAutofill, enableEditing, validateEmail } from './../../helpers';

export default function UserTable(props){
  const { business, user, permissions, total, sortType, sortReverse, users, rolesName, setEditHandler, sortHandler, selectedPeople, setTheState, usersIds, pageTab, reloadDataAfterSendActivationEmail } = props
  const [ deleteUser, setDeleteUser ] = useState({})
  const [ confirmationPopupType, setConfirmationPopupType ] = useState('')
  const [ showConfirmationPopup, setShowConfirmationPopup ] = useState(false)
  const [ show, setShow ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ syncingUser, setSyncingUser ] = useState('')

  const sort = (e, sortName, sortRev ) => {
    sortHandler(e, sortName, sortRev)
  }

  const openSendAll = (e, title, data) => {
    setConfirmationPopupType(title)
    setDeleteUser(data)
    setShowConfirmationPopup(true)
    if(title == 'Change User Password'){
      disableAutofill()
    }else{
      enableEditing()
    }
  }

  const handleCreateTempPassword = (e, user) => {
    e.preventDefault();
    assignTempPassword(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), user?.id, user?.can_login_with_temp_password ? false : true).then(
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        let usersData = users
        for (let i = 0; i < usersData?.length; i++){
          if(usersData[i]?.id == result?.user_id){
            usersData[i] = {...usersData[i], can_login_with_temp_password: result?.user_temp_password}
          }
        }
        showAlert(true, 'success', result?.message)
        setTheState('users', usersData)
      }else{
        showAlert(true, 'danger', result.error)
      }
      window.setTimeout(()=>{
        showAlert(false, '', '')
      },3000)
    })
  }

  const setEdit = (e, usr) => {
    setEditHandler(e, usr)
  }

  const handleSelectPeople = (e) => {
    let val = parseInt(e.target.value)
    var __FOUND = selectedPeople.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newUsers = [...selectedPeople, val]
        setTheState('selectedPeople', newUsers)
      }
    }else{
      if(__FOUND){
        let newUsers = selectedPeople.filter((tag, index) => tag !== val)
        setTheState('selectedPeople', newUsers)
      }
    }
  }

  const checkAllPeopleSelect = () => {
    let check = (usersIds.every(v => selectedPeople.includes(v)) && usersIds?.length !== 0)
    return check
  }

  const handleSelectAllPeople = (e) => {
    var result = usersIds
    let selected_people = selectedPeople

    if(e.target.checked){
      for(var i=0; i<result?.length;i++){
        var usr = users.find(elem => elem?.id === result[i])
        if(!selected_people.includes(result[i]) && usr.active_status !== "Active"){
          selected_people.push(result[i])
        }
      }
      setTheState('selectedPeople', selected_people)
    }else{
      let new_peoples = selected_people.filter(item => !result.includes(item))
      setTheState('selectedPeople', new_peoples)
    }
  }

  const handleSyncUser = (e, userId) => {
    e.preventDefault();
    if (syncingUser !== '') {
      return;
    }
    setSyncingUser(userId)
    processSyncUser(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), userId).then(
      response => response.json()
    ).then(result => {
      setSyncingUser('')
      if (result.status === 200) {
        const userIndex = users.findIndex(user => user.id === userId);
        if (userIndex !== -1) {
          users[userIndex] = result.user; // Update the user in the array
        }
        setTheState('users', [...users]);
        showAlert(true, 'success', result.message)
      } else {
        showAlert(true, 'danger', result.error)
      }
      window.setTimeout(() => {
        showAlert(false, '', '')
      }, 3000)
    })
  }

  const closeConfirmationPopup = (data) => {
    enableEditing()
    setShowConfirmationPopup(data)
  }

  const doneDeleteUserProcess = () => {
    enableEditing()
    setShowConfirmationPopup(false)
    window.setTimeout(()=>{
      setTheState('dataLoaded', false)
      reloadDataAfterSendActivationEmail()
    },3000)
  }

  const showAlert = (show, type, message) => {
    setAlertType(type)
    setAlertMessage(message)
    setShow(show)
  }

  const doneSendSingleActivationEmail = () => {
    setShowConfirmationPopup(false)
    window.setTimeout(()=>{
      showAlert(false, '', '')
      reloadDataAfterSendActivationEmail()
    },3000)
  }

  return(
    <Fragment>
      {showConfirmationPopup &&
        <CustomPopup show={showConfirmationPopup} close={closeConfirmationPopup} title={confirmationPopupType}>
          {confirmationPopupType == 'Delete User' &&
            <DeleteUserComponent deleteUser={deleteUser} showAlert={showAlert} doneDeleteUserProcess={doneDeleteUserProcess} closeConfirmationPopup={closeConfirmationPopup}/>
          }
          {confirmationPopupType == 'Change User Password' &&
            <ChangePasswordComponent passwordUser={deleteUser} closeConfirmationPopup={closeConfirmationPopup} showAlert={showAlert} doneDeleteUserProcess={doneDeleteUserProcess} currentUser={user}/>
          }
          {confirmationPopupType == 'Email Confirmation' &&
            <ActivationEmailSingleComponent emailUser={deleteUser} closeConfirmationPopup={closeConfirmationPopup} showAlert={showAlert} doneSendSingleActivationEmail={doneSendSingleActivationEmail}/>
          }
          {confirmationPopupType == 'Resend Verification Email' &&
            <ResendVerificationEmailComponent emailUser={deleteUser} showAlert={showAlert} doneResendEmailProcess={doneSendSingleActivationEmail} closeConfirmationPopup={closeConfirmationPopup}/>
          }
        </CustomPopup>
      }
      {show && <AlertPopup alert_message={alertMessage} alert_type={alertType} closeAlert={setShow} />}
      <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-200'>
          <thead className='bg-gray-50'>
            <tr>
              {pageTab == 'inactive' &&
                <th className="pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
                  <div>
                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={checkAllPeopleSelect() == true ? true : false} value={'all'} onChange={(e) => handleSelectAllPeople(e)}/>
                  </div>
                </th>
              }
              <th className="hidden lg:table-cell pl-4 pr-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div>
                  {['signup', 'signdown'].includes(sortType) ?
                    <React.Fragment>
                      {!sortReverse &&
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-500 min-w-16 cursor-pointer hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'signup', 'desc')}>
                          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                        </svg>
                      }
                      {sortReverse &&
                        <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer min-w-16 text-gray-500 hover:text-gray-400" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'signdown', 'asc')}>
                          <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                        </svg>
                      }
                    </React.Fragment>
                  :
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'signup', 'desc')}>
                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                </div>
              </th>
              <th className={`px-3 py-3 ${pageTab == 'active' ? 'pl-6' : ''} text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                <div className='flex items-center'>
                  <span className='cursor-pointer' onClick={(e) => sort(e, 'full_name', 'desc')}>Name</span> &nbsp; &nbsp;
                  {sortType !== 'full_name' &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'full_name', 'desc')}>
                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                  {(sortType === 'full_name' && sortReverse) &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'full_name', 'asc')}>
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                  {(sortType === 'full_name' && !sortReverse) &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'full_name', 'desc')}>
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                    </svg>
                  }
                </div>
              </th>
              <th className="hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div className='flex items-center'>
                  <span className='cursor-pointer' onClick={(e) => sort(e, 'role_display', 'desc')}>Role</span>&nbsp; &nbsp;
                  {sortType !== 'role_display' &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'role_display', 'desc')}>
                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                  {(sortType === 'role_display' && sortReverse) &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'role_display', 'asc')}>
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                  {(sortType === 'role_display' && !sortReverse) &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'role_display', 'desc')}>
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                    </svg>
                  }
                </div>
              </th>
              <th className="hidden lg:table-cell px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div className='flex items-center'>
                  <span className='cursor-pointer' onClick={(e) => sort(e, 'regions', 'desc')}>Region</span>&nbsp; &nbsp;
                  {sortType !== 'regions' &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'regions', 'desc')}>
                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                  {(sortType === 'regions' && sortReverse) &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'regions', 'asc')}>
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                  {(sortType === 'regions' && !sortReverse) &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'regions', 'desc')}>
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                    </svg>
                  }
                </div>
              </th>
              <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <div className='flex items-center'>
                  <span className='cursor-pointer' onClick={(e) => sort(e, 'date_time', 'desc')}>Status</span>&nbsp; &nbsp;
                  {sortType !== 'date_time' &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'date_time', 'desc')}>
                      <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                  {(sortType === 'date_time' && sortReverse) &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'date_time', 'asc')}>
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h7a1 1 0 100-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                    </svg>
                  }
                  {(sortType === 'date_time' && !sortReverse) &&
                    <svg xmlns="http://www.w3.org/2000/svg" className="hover:text-gray-400 min-w-16 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" onClick={(e) => sort(e, 'date_time', 'desc')}>
                      <path d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z" />
                    </svg>
                  }
                </div>
              </th>
              <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200'>
            {users.map((usr, i) =>
              <tr key={i} className='bg-white'>
                {pageTab == 'inactive' &&
                  <>
                    {usr?.active_status === "Active" ? (
                      <td></td>
                    ) : (
                      <td className='pl-4 pr-3 py-4 text-sm font-medium text-gray-900 text-center'>
                        <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${(!usr?.email || !validateEmail(usr?.email)) ? 'cursor-not-allowed opacity-50' : ''}`} checked={selectedPeople.includes(usr?.id)} value={usr?.id} onChange={(e) => handleSelectPeople(e)} disabled={usr?.active_status === "Active" || !usr?.email || !validateEmail(usr?.email)}/>
                      </td>
                    )}
                  </>
                }
                <td className='hidden lg:table-cell pl-4 pr-3 py-4 text-sm font-medium text-gray-900'>
                  {getTooltipMsg(usr) !== "" &&
                    <div>
                      <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={usr.id.toString()}>
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                      </svg>
                      <ReactTooltip className='max-w-xs' id={usr.id.toString()} place="top" effect="solid">
                        {business?.fisikal_integration ? getFisikalTooltipMsg(usr) : business?.clubready_integration ? getClubreadyTooltipMsg(usr) : getTooltipMsg(usr)}
                      </ReactTooltip>
                    </div>
                  }
                </td>
                <td className={`w-full max-w-0 sm:w-auto sm:max-w-none px-3 py-4 ${pageTab == 'active' ? 'pl-6' : ''} text-sm text-gray-900`}>
                  <div className='inline lg:flex items-center'>
                    <div>
                      <img src={usr?.image} className='h-10 w-10 rounded-full min-wh-40'/>
                    </div>
                    <div className='ml-0 lg:ml-4'>
                      <div className='text-sm text-gray-900 flex items-center font-medium break-words'>
                        <div className='truncate'>{usr?.full_name}</div>
                        <div className='lg:hidden table-cell ml-1'>
                          {getTooltipMsg(usr) !== "" &&
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={usr.id.toString()}>
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                              <ReactTooltip className='w-52' id={usr.id.toString()} place="top" effect="solid">
                                {business?.fisikal_integration ? getFisikalTooltipMsg(usr) : business?.clubready_integration ? getClubreadyTooltipMsg(usr) : getTooltipMsg(usr)}
                              </ReactTooltip>
                            </div>
                          }
                        </div>
                      </div>
                      <dl className='hidden lg:table-cell'>
                        <dd className='mt-1'>
                          <div className="text-sm text-gray-500 truncate items-center gap-3">
                            {usr.email}
                            {(usr.unconfirmed_email !== "" && usr.unconfirmed_email !== null && usr.status === "Yes") &&
                              <React.Fragment >
                                <svg xmlns="http://www.w3.org/2000/svg" className={`text-red-600 h-4 w-4 cursor-pointer`}viewBox="0 0 20 20" fill="currentColor" data-tip data-for={usr.id.toString()+'email'} onClick={(e) => openSendAll(e, 'Resend Verification Email' , usr)}>
                                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                                <ReactTooltip className='max-w-xs' id={usr.id.toString()+'email'} place="top" effect="solid">
                                  Pending Verification
                                </ReactTooltip>
                              </React.Fragment>
                            }
                          </div>
                        </dd>
                      </dl>
                      <div className='flex'>
                        {(['all', 'email'].includes(usr.notifications) && !usr.away) &&
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" data-tip data-for={usr.id.toString()+'notificationType'}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                            {usr?.daily_notifications !== ''&&
                              <ReactTooltip className='max-w-xs whitespace-nowrap' id={usr.id.toString()+'notificationType'} place="top" effect="solid">
                                {usr?.daily_notifications === "daily" ? 'Daily summary' : 'Immediate sub request notifications'}
                              </ReactTooltip>
                            }
                          </div>
                        }
                        {(['all', 'sms'].includes(usr.notifications) && !usr.away) &&
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" data-tip data-for={`text_notification`+usr.id.toString()+'notificationType'}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
                            </svg>
                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`text_notification`+usr.id.toString()+'notificationType'} place="top" effect="solid">
                              Text notifications
                            </ReactTooltip>
                          </div>
                        }
                        {usr.away &&
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" data-tip data-for={`notification`+usr.id.toString()+'notificationOff'}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <ReactTooltip className='max-w-xs whitespace-nowrap' id={`notification`+usr.id.toString()+'notificationOff'} place="top" effect="solid">
                              Notifications Off
                            </ReactTooltip>
                          </div>
                        }
                      </div>
                      <dl className='lg:hidden'>
                        <dd className='mt-1'>
                          <div className="text-sm text-gray-500 truncate items-center gap-3">
                          {usr.email}
                          {(usr.unconfirmed_email !== "" && usr.unconfirmed_email !== null && usr.status === "Yes") &&
                            <React.Fragment >
                              <svg xmlns="http://www.w3.org/2000/svg" className={`text-red-600 h-4 w-4 cursor-pointer`} viewBox="0 0 20 20" fill="currentColor" data-tip data-for={usr.id.toString()+'email'} onClick={(e) => openSendAll(e, 'Resend Verification Email' , usr)}>
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                              <ReactTooltip className='max-w-xs' id={usr.id.toString()+'email'} place="top" effect="solid">
                                Pending Verification
                              </ReactTooltip>
                            </React.Fragment>
                          }
                          </div>

                        </dd>
                      </dl>
                    </div>

                  </div>
                </td>
                <td className='hidden lg:table-cell px-3 py-4'>
                  <div className='text-sm text-gray-500'>{usr?.role_display}</div>
                </td>
                <td className='hidden lg:table-cell px-3 py-4'>
                  <div className='text-sm text-gray-500'>{usr?.regions}</div>
                </td>
                {(permissions.business_users?.edit || user.role_display === "Netgym Employee") &&
                  <td className='px-3 py-4'>
                    <React.Fragment>
                      {(usr.status === 'Yes') ?
                        <React.Fragment>
                          <div className="inline-flex bg-green-100 text-green-800 text-xs px-2.5 py-0.5 rounded-xl font-medium">Active</div>
                          {usr.active_date !== "" &&
                            <div className='flex items-center gap-0.5 mt-1'>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="text-gray-800 w-3 h-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                              </svg>
                              <div className='text-xs text-gray-400 inline-block rounded-xl text-center'>
                                {usr?.active_date}
                              </div>
                            </div>
                          }
                        </React.Fragment>
                      :
                        <React.Fragment>
                          <div className='flex items-center gap-0.5'>
                            <div className={`${usr.activation_sent_at !== "" ? 'bg-yellow-100 text-red-800' : 'red-tag text-gray-400'} text-xs px-2.5 py-0.5 rounded-xl font-medium`}>{usr.activation_sent_at !== "" ? 'Invited' : 'Pending Invite'}</div>
                          </div>

                          <div className='flex items-center gap-0.5 mt-1'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-300 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M7.707 3.293a1 1 0 010 1.414L5.414 7H11a7 7 0 017 7v2a1 1 0 11-2 0v-2a5 5 0 00-5-5H5.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                            <div className='text-xs text-gray-400 inline-block rounded-xl text-center'>
                              {usr?.activation_sent_date !== "" ? usr?.activation_sent_date : usr?.user_create_date}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  </td>
                }
                <td className='px-3 py-4'>
                  <div className='flex gap-x-2'>
                    {business.fisikal_integration &&
                      <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => handleSyncUser(e, usr?.id)} data-tip data-for={`sync_button_` + usr?.id.toString()}>
                        <a className='cursor-pointer'>
                          <FontAwesomeIcon size="md" icon={syncingUser === usr?.id ? faSpinner : faRotate} spin={syncingUser === usr?.id} />
                        </a>
                        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`sync_button_` + usr?.id.toString()} place="top" effect="solid">
                          Sync user
                        </ReactTooltip>
                      </div>
                    }
                    {(user.role_display === "Netgym Employee") &&
                      <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => openSendAll(e, 'Delete User' , usr)} data-tip data-for={`delete_button_`+usr?.id.toString()}>
                        <a className='cursor-pointer'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="text-gray-700 h-5 w-5">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                          </svg>
                        </a>
                        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`delete_button_`+usr?.id.toString()} place="top" effect="solid">
                          Delete user
                        </ReactTooltip>
                      </div>
                    }
                    {(permissions?.business_users?.edit || user.role_display === "Netgym Employee") &&
                      <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => setEdit(e, usr)} data-tip data-for={`edit_button_`+usr?.id.toString()}>
                        <a className='cursor-pointer'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                        </a>
                        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`edit_button_`+usr?.id.toString()} place="top" effect="solid">
                          Edit user
                        </ReactTooltip>
                      </div>
                    }
                    {usr.status === 'Yes' && usr.user_activated && (permissions.business_users?.update_password || user.role_display === "Netgym Employee") &&
                      <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => openSendAll(e, 'Change User Password', usr)} data-tip data-for={`password_${usr.id}`}>
                        <a >
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clipRule="evenodd" />
                          </svg>
                        </a>
                        <ReactTooltip id={`password_${usr.id}`} place="top" effect="solid">
                          Change user password
                        </ReactTooltip>
                      </div>
                    }
                    {(usr.status !== 'Yes' && usr.email) &&
                      <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => openSendAll(e, 'Email Confirmation' , usr)} data-tip data-for={`envelope_${usr.id}`}>
                        <a className='cursor-pointer'>
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 hover:text-gray-400 h-3.5 w-3.5 cursor-pointer" viewBox="0 0 20 20" fill="currentColor" >
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                        </a>
                        {usr.activation_sent_at !== "" &&
                          <ReactTooltip id={`envelope_${usr.id}`} place="top" effect="solid">
                            {usr.activation_sent_at}
                          </ReactTooltip>
                        }
                      </div>
                    }
                    {user?.ng_big_gun &&
                      <div className={`${usr?.can_login_with_temp_password ? 'bg-green-400 hover:bg-green-500' : 'bg-red-400 hover:bg-red-500'} w-8 h-8 rounded flex items-center justify-center cursor-pointer`} onClick={(e) => handleCreateTempPassword(e, usr)} data-tip data-for={`create_temp_password_${usr.id}`}>
                        <a >
                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-700 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clipRule="evenodd" />
                          </svg>
                        </a>
                        <ReactTooltip id={`create_temp_password_${usr.id}`} place="top" effect="solid">
                          {usr?.can_login_with_temp_password ? "Delete temporary password" : "Assign temporary password"}
                        </ReactTooltip>
                      </div>
                    }
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {total === 0 &&
          <EmptyTableComponent />
        }
      </div>
    </Fragment>
  )
}