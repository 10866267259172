import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from "../../../subRequestDetails/components/customPopupContainerComponent";
import { sendActivationEmail } from "../../../settings-v4/users/helpers";
import { getSudomain } from "../../../helpers";
import LoadingIcon from "../../../subRequests/assets/loadingIcon";
import { InfoIcon } from '../../../subRequestDetails/assets'; 

export default function ActivationEmailPopup(props) {
  const { activationUserId, showActivationEmailPopup, setShowActivationEmailPopup, activationPopupMessage, setActivationPopupMessage, showResendInviteBtn, businessName } = props;
  const [ dsblBtn, setDsblBtn ] = useState(false);
  const [ showLoading, setShowLoading ] = useState(false);
  const [ showSuccess, setShowSuccess ] = useState(false);

  const closePopup = () => {
    if(dsblBtn) return;
    setShowActivationEmailPopup(false);
  }

  const handleResendActivationEmail = (e) => {
    if(dsblBtn) return;
    e.preventDefault();
    
    setDsblBtn(true)
    setShowLoading(true)
    sendActivationEmail(JSON.stringify(''), getSudomain(), activationUserId).then(
      response => response.json()
    )
    .then(result => {
      setDsblBtn(false)
      setShowLoading(false)
      setShowSuccess(true)
      if(result.status === 200){
        setActivationPopupMessage(result?.success)
      } else {
        setActivationPopupMessage(result?.error)
      }
    })
  }

  const formatActivationPopupMessage = () => {
    if (!activationPopupMessage.includes('\n')) {
      return <div>{activationPopupMessage}</div>;
    }

    const lines = activationPopupMessage.split('\n');
  
    return (
      <div>
        {lines.map((line, index) => (
          <span key={index}>
            {line}
            {index < lines.length - 1 && <br />}
          </span>
        ))}
      </div>
    );
  }

  return (
    <Fragment>
      <CustomPopupContainerComponent show={showActivationEmailPopup} close={closePopup} title={''} customWidth={'sm:max-w-xl'}>
        <div className="gap-2 px-8 py-4">
          <div className="flex justify-center text-2xl text-black font-bold mb-3">
            NetGym
          </div>
          <div className={`font-medium text-center text-base text-gray-700 mb-6`}>
            {formatActivationPopupMessage()}
            {showResendInviteBtn &&
              <div>
                <p className="mt-2">
                  <InfoIcon classNames={'w-5 h-5 inline mr-1 -mt-1 text-blue-800'} customColour={'#111827'} />
                  <span>The subject line is:</span>
                  <span className="font-medium italic ml-1">
                    {`"Activate your ${businessName} NetGym account!"`}
                  </span>
                  <span className="ml-2 inline">to help you find it in the depths of your inbox.</span>
                </p>
              </div>
            }
          </div>
          <div className="flex justify-center">
            <button className={`relative flex items-center gap-2 ${showLoading ? 'bg-gray-400 cursor-not-allowed': 'bg-gray-900'} text-white text-center text-sm font-semibold rounded-2xl px-7 py-2.5`} onClick={(e) => (!showSuccess && showResendInviteBtn) ? handleResendActivationEmail(e) : closePopup()} disabled={dsblBtn}>
              {showLoading &&
                <div className="absolute top-3 left-1.5">
                  <LoadingIcon classNames={'animate-spin h-4 w-4 text-white'}/>
                </div>
              }
              {(!showSuccess && showResendInviteBtn) ? 'Resend Invite' : 'Close'}
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
} 
