import React, { Fragment, useState } from "react";
import CustomPopupContainerComponent from '../../../subRequestDetails/components/customPopupContainerComponent';
import LoadingIcon from '../../../subRequests/assets/loadingIcon';
import { axiosRequest } from '../../../subRequests/helpers/helpers';

export default function DeleteCategory(props) {
  const { showDeleteCategory, changeCategory, closeDeleteCategory, handleAlert, setPageNumber } = props
  const [ disabledBtn, setDisabledBtn ] = useState(false)

  const processDelete = (e) => {
    e.preventDefault()
    setDisabledBtn(true)
    let url = `/api/v3/certification_categories/${changeCategory.id}`
    axiosRequest(url, 'DELETE', {}, 'data').then(result => {
      if(result.status === 200){
        handleAlert(true, result?.success, 'success')
        setTimeout(() => {
          handleAlert(false, '', 'success')
          setDisabledBtn(false)
          setPageNumber(1)
          closeDeleteCategory(false)
        }, 3000)
      }else{
        handleAlert(true, result?.error, 'danger')
        setTimeout(() => {
          handleAlert(false, '', 'success')
          setDisabledBtn(false)
        }, 3000)
      }
    })
  }

  const handleCloseDeleteCategory = (data) => {
    if(disabledBtn) return
    closeDeleteCategory(data)
  }

  return (
    <Fragment>
      <CustomPopupContainerComponent show={showDeleteCategory} close={handleCloseDeleteCategory} title={'Delete Category'} customWidth={'max-w-lg'}>
        <div className="pt-4 border-t border-gray-200 mx-7 mt-4 mb-2">
          <div className="mb-2">
            <div className='flex gap-4 mb-2 items-center'>
              <p className='text-gray-700 w-36 text-left'>Category Name</p>
              <p className="text-sm text-gray-500">{changeCategory.name}</p>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 flex flex-row-reverse">
            <button
              type="button"
              className={`flex bg-dark-blue hover:bg-gray-800 text-white inline-block px-6 py-2 border shadow rounded-lg relative ${disabledBtn ? 'cursor-not-allowed opacity-70' : 'cursor-pointer'}`}
              onClick={(e) => processDelete(e)}
              disabled={disabledBtn}
            >
              {disabledBtn &&
                <div className="absolute top-0 bottom-0 left-1.5 flex items-center justify-center">
                  <LoadingIcon classNames={'animate-spin h-4 w-4 text-white'}/>
                </div>
              }
              Delete Category
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}