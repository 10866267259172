import React from 'react';
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { SearchIcon, ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/solid'
import { getUsers, getLocationSettings, getSudomain, snakeCase, getName, getSkills, getFilterUsers, getSearchResult, exportSearchResult } from './helpers.js'
import Cookies from 'js-cookie';
import { CSVLink } from "react-csv";
import { TrashOutline } from 'heroicons-react';
import { setTitle } from './../../helpers.js'
import InfinitScroll from 'react-infinite-scroll-component'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class AvailabilitySearch extends React.Component{
  constructor(props){
    super(props);  
    this.state = {
      user: this.props.user,
      all_users: [],
      users: [],
      locationSettings: [],
      selectedAllLocations: [],
      selectedLocations: [],
      selectedAllRegions: [],
      selectedRegions: [],
      all_classes: [],
      classes: [],
      days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      selectedDay: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      selectedInstructor: [],
      selectedClass: "",
      start_time: "",
      end_time: "",
      hour_range: [],
      from_time: "",
      to_hour_range: [],
      to_time: "",
      selectAllInstructor: false,
      help_range: [],
      loaded: "neutral",
      result: [],
      show: false,
      alert_type: "",
      alert_message: "",
      searchValue: "",
      filtered_users: [],
      exportHeader: [],
      exportData: [],
      csvHeader: [],
      csvData: [],
      dataToSave: {},
      dataLoaded: false,
      selectDayAlert: false,
      selectFromTimeAlert: false,
      allLocations: [],
      showFilterOther: null,
      showFilterModal: false,
      loadMore: false,
      loadedPostOnScroll: true,
      activePage: 1,
      pageSize: 30,
      totalResultCount: 0,
      prevSelectedLocations: [],
      prevSelectedDay: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
      prevSelectedClass: "",
      prevFrom_time: "",
      prevTo_time: "",
      prevSelectedInstructor: [],
      loadedUserList: true,
      exportedReport: true,
      userList: [],
      userListPage: 1,
      userListTotalPage: 1
    }  
    
    this.fetchNextResult = this.fetchNextResult.bind(this)
    this.fetchNextUser = this.fetchNextUser.bind(this)
  }
  componentDidMount = () => { 
    this.checkAvailability()           
    // this.props.setInsightsOpen()    
    // this.props.setCurrent('Availabilit', true)
    setTitle('availability')
    if(this.props.pageLoaded){
      this.getAll()
      this.getBusinessHour(this.props.business?.business_hours)
    }
  }

  checkAvailability = () => { 
    const { business, user } = this.props   
    if (Object.keys(business).length != 0) {
      if(business.availability_feature === false){
        let role = snakeCase(user?.role)        
        if(role){
          let url = `/my-requests`
          window.location.href = url
        }        
      }
    }
  }

  // UNSAFE_componentWillReceiveProps(nextProps){
  //   if(nextProps.pageLoaded){
  //     this.getAll()
  //     this.getBusinessHour(this.props.business?.business_hours)
  //   }
  // }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
    clearInterval(this.interval);
  }

  getBusinessHour = (business_hours) => {
    
    if(business_hours && business_hours !== "null"){
      let business_hour_content = this.props.business?.business_hours
      let businessHour = business_hour_content
      this.setState({
        start_time: businessHour?.time_from,
        end_time: businessHour?.time_to
      })
      this.getBusinessRange(businessHour)
    }
  }

  getBusinessRange = (businessHour) => {
    let start_time = businessHour?.time_from
    let end_time = businessHour?.time_to
    let start_time_ampm = start_time.split(" ")
    let end_time_ampm = end_time.split(" ")
    let start_time_hours = start_time_ampm[0]
    let end_time_hours = end_time_ampm[0]
    let large_start_time_hours = ""
    let large_end_time_hours = ""
    if(start_time_ampm[1] === "PM"){
      if(start_time_hours.split(":")[0] !== "12"){
        large_start_time_hours = (Number(start_time_hours.split(":")[0]) + 12).toString()+":"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }
    else{
      if(start_time_hours.split(":")[0] === "12"){
        large_start_time_hours = "0:"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }

    if(end_time_ampm[1] === "PM"){
      if(end_time_hours.split(":")[0] !== "12"){
        large_end_time_hours = (Number(end_time_hours.split(":")[0]) + 12).toString()+":"+end_time_hours.split(":")[1]
      }
      else{
          large_end_time_hours = end_time_hours
      }
    }
    else{
        if(end_time_hours.split(":")[0] === "12"){
          large_end_time_hours = "0:"+end_time_hours.split(":")[1]
        }
        else{
          large_end_time_hours = end_time_hours
        }
      }

    let start_hour_min = large_start_time_hours.split(":")
    let end_hour_min = large_end_time_hours.split(":")
    let start_hour = Number(large_start_time_hours.split(":")[0])
    let end_hour = Number(large_end_time_hours.split(":")[0])
    let row_range = []
    for (;start_hour <= end_hour; start_hour++){
      if(start_hour < 12){
        if(start_hour === 0){
          row_range.push("12:00 AM")
          row_range.push("12:15 AM")
          row_range.push("12:30 AM")
          row_range.push("12:45 AM")
        }
        else{
          row_range.push(start_hour.toString()+":00 AM")
          row_range.push(start_hour.toString()+":15 AM")
          row_range.push(start_hour.toString()+":30 AM")
          row_range.push(start_hour.toString()+":45 AM")
        }
      }
      else{
        if(start_hour > 12){
          row_range.push((start_hour-12).toString()+":00 PM")
          row_range.push((start_hour-12).toString()+":15 PM")
          row_range.push((start_hour-12).toString()+":30 PM")
          row_range.push((start_hour-12).toString()+":45 PM")
        }
        else{
          row_range.push(start_hour.toString()+":00 PM")
          row_range.push(start_hour.toString()+":15 PM")
          row_range.push(start_hour.toString()+":30 PM")
          row_range.push(start_hour.toString()+":45 PM")
        }
      }
    }
    for(let i = 0; i < 4; i++){
      if(start_time !== row_range[0]){
        row_range.shift();
      }else{
        break;
      }
    }
    for(let i = 0; i < 4; i++){
      if(end_time !== row_range[row_range?.length-1]){
        row_range.pop();
      }else{
        break;
      }
    }
    let range = []
    for (let i = 0;i < row_range?.length -1; i++){
      range.push(row_range[i])
    }

    let from_time_index = row_range.indexOf(businessHour?.time_from)
    let check = []
    for (let i = from_time_index +1;i < row_range?.length; i++){
      check.push(row_range[i])
    } 

    this.setState({
      help_range: row_range,
      hour_range: range,
      to_hour_range: check
    });
  }

  getAll(){
    // getUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
    //   response => response.json()
    // )
    // .then(result => {
    //   if(result.status === 200){
    //     this.setState({
    //       users: result.results,
    //       all_users: result.results,
    //       total: result.total,
    //       noSkillUsers: result.users_noskills_no_locations_count,
    //       inactiveUsers: result.inactive_users_count,
    //     })
    //     this.setSelectedUsers(result.results)
    //   }else{
    //     this.setState({
    //       alert_message: result.error,
    //       alert_type: 'danger',
    //       show: true,
    //     })
    //   }
    // })
    this.processFetchSearchResult('undefined', this.state.selectedDay, '', '', '', 'undefined', 1)
    this.processFetchFilteredUserList('undefined', this.state.selectedDay, '', '', '')

    getLocationSettings(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {      
      if(result.status === 200){
        this.setState({
          showFilterOther: result.show_other,
          locationSettings: result.regions_and_locations
        })
        this.setSelectedLocations(result.regions_and_locations)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          loaded: true
        })
      }
    })

    // getClasses(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
    //   response => response.json()
    // )
    // .then(result => {      
    //   if(result.status === 200){        
    //     this.setState({
    //       classes: result.results,
    //       all_classes: result.results
    //     })
    //   }else{
    //     this.setState({
    //       alert_message: result.error,
    //       alert_type: 'danger',
    //       show: true
    //     })
    //   }
    // })

    getSkills(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(      
      response => response.json()
    )
    .then(result => {      
      if(result.status === 200){        
        this.setState({
          classes: result.results,
          all_classes: result.results
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
    })   
  }

  setSelectedUsers = (users) => {
    let selected_instructor = []
    let total_users = []
    for (var i = 0; i < users?.length; i++){
      if(users[i].availability?.length > 0){
        selected_instructor.push(users[i]['id'])
        total_users.push(users[i])
      }
    }
    this.setState({
      selectedInstructor: selected_instructor,
      users: total_users,
      filtered_users: total_users,
      dataLoaded: true
    })
    this.searchResult() 
  }

  setSelectedLocations = (regions) => {
    let all_locations = []
    if(this.props.user?.role !== "Business Admin"){
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<regions.length;i++){
        selected_regions.push(regions[i]['region']['name'])
        let locationIds = regions[i]?.locations.map((loc) => loc.id)
        selected_locations = [...selected_locations, ...locationIds]
        let locHash = regions[i]?.locations.map((loc) => {return {'id': loc.id, 'name': loc.name}})
        all_locations = [...all_locations, ...locHash]
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions,
        allLocations: all_locations,
      })
    }else{
      for(var i=0; i<regions.length;i++){      
        let locHash = regions[i]?.locations.map((loc) => {return {'id': loc.id, 'name': loc.name}})
        all_locations = [...all_locations, ...locHash]
      }
      this.setState({
        allLocations: all_locations,
      })
    }
  }

  selectAll_Days = () => {
    return (this.state.days.every(v => this.state.selectedDay.includes(v)))
  }

  selectAllDays = (e) => {
    if(e.target.checked){
      let all = this.state.days
      let selected_day = []
      for(var i=0; i<all.length;i++){ 
          selected_day.push(all[i])    
      }
      this.setState({
        selectedDay: selected_day,
        selectDayAlert: false
      })
      this.updateInstructor(this.state.selectedLocations, selected_day, this.state.selectedClass, this.state.from_time, this.state.to_time)
    }else{
      this.setState({
        selectedDay: []
      })
      this.updateInstructor(this.state.selectedLocations, [], this.state.selectedClass, this.state.from_time, this.state.to_time)

    }
  }

  shortDaysList = (days) => {
    let all_days = this.state.days
    let selected = all_days.filter((tag, index) => days.includes(tag))
    return selected
  }

  handleChange = (e) => {
    
    let val = e.target.value
    var __FOUND = this.state.selectedDay.includes(val)
    if(e.target.checked){
      let newDay = []
      if(!__FOUND){
        newDay = this.shortDaysList([...this.state.selectedDay, val])
        this.setState(state => ({ selectedDay: newDay , selectDayAlert: false}));
      }
      this.updateInstructor(this.state.selectedLocations, newDay, this.state.selectedClass, this.state.from_time, this.state.to_time)
    }else{
      if(__FOUND){
        const { selectedDay } = this.state;
        let newDay = this.shortDaysList(selectedDay.filter((tag, index) => tag !== val))  
        this.setState({
          selectedDay: newDay        
        }); 
        this.updateInstructor(this.state.selectedLocations, newDay, this.state.selectedClass, this.state.from_time, this.state.to_time) 
      }      
    }
  }

  selectedAllRegionsLocations = (e) => {
    if(e.target.checked){
      let all = this.state.locationSettings
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['name'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions
      })
      this.updateInstructor(selected_locations, this.state.selectedDay, this.state.selectedClass, this.state.from_time, this.state.to_time)
    }else{
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
      this.updateInstructor([], this.state.selectedDay, this.state.selectedClass, this.state.from_time, this.state.to_time)

    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  checkStartEndTime = (start_time, end_time) => {
    let help_range = this.state.help_range
    let start_time_index = help_range.indexOf(start_time)
    let end_time_index = help_range.indexOf(end_time)
    let check = []
    for (;start_time_index <= end_time_index; start_time_index++){
      check.push(help_range[start_time_index])
    }
    return check;
  }

  processFetchFilteredUserList = (locations, days, clss, start_time, end_time) => {
    if(days?.length === 0 || (start_time === '' && end_time !== '') || (start_time !== '' && end_time === '')){
      return
    }
    let params = {
      location_ids: locations,
      days: days,
      class: clss,
      start_time: start_time,
      end_time: end_time
    }
    this.setState({loadedUserList: false})
    getFilterUsers(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        let pages = Math.floor(result?.users?.length / 30)
        let rem = result?.users?.length % 30
        this.setState({
          users: result?.users,
          userList: result?.users?.length == 0 ? [] : result?.users?.length > 30 ? result?.users.slice(0, 30) : result?.users,
          userListPage: 1,
          userListTotalPage: rem === 0 ? pages : pages + 1,
          selectedInstructor: result?.users?.length > 0 ? result?.users.map((usr) => usr?.id) : [],
          loadedUserList: true
        })
      }else{
        this.setState({
          loadedUserList: true,
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
        })
      }
    })
  }

  updateInstructor = (locations, days, clss, start_time, end_time) =>{
    this.processFetchFilteredUserList(locations, days, clss, start_time, end_time)
  
    // let hold_users = this.state.filtered_users
    // let users = this.state.all_users
    // if(locations?.length !== 0){
    //   hold_users = []
    //   let all_Locations = locations
    //   for(var i = 0; i < users?.length; i++){
    //     for(var j = 0; j < users[i].business_location_ids?.length; j++){
    //       if(all_Locations.includes(users[i].business_location_ids[j])){
    //         if(!hold_users.includes(users[i])){
    //           hold_users.push(users[i])
    //         }
    //       }
    //     }
    //   }
    // }
    // if(days?.length !== 0){
      
    //   let for_days_hold_user = hold_users
    //   hold_users = []
    //   for(var i = 0; i < for_days_hold_user?.length; i++){
    //     if(for_days_hold_user[i].availability?.length > 0){
    //       for(var j = 0; j < for_days_hold_user[i].availability?.length; j++){
    //         if(for_days_hold_user[i].availability[j]?.available === true && days.includes(this.capitalizeFirstLetter(for_days_hold_user[i].availability[j].day))){
    //           if(!hold_users.includes(for_days_hold_user[i])){
    //             hold_users.push(for_days_hold_user[i])
    //             break;
    //           }
    //         }
    //         else{
    //           if(hold_users.includes(for_days_hold_user[i])){
    //             hold_users = for_days_hold_user.filter(All => All != for_days_hold_user[i]);
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // if(clss?.length !== 0){
    //   let for_cls_hold_user = hold_users
    //   hold_users = []
    //   for(var i = 0; i < for_cls_hold_user?.length; i++){
    //     if(for_cls_hold_user[i].business_skill_ids?.length > 0){
    //       for(var j = 0; j < for_cls_hold_user[i].business_skill_ids?.length; j++){
    //         // if(this.state.classes){
    //         //   let classes = this.state.classes
    //         //   for(var k = 0; k < classes?.length; k++){
    //         //     if(classes[k]?.id.toString() === clss){
    //         //       if(for_cls_hold_user[i].business_skill_ids.includes(classes[k].business_category?.id)){
    //         //         if(!hold_users.includes(for_cls_hold_user[i])){
    //         //           hold_users.push(for_cls_hold_user[i])
    //         //           break;
    //         //         }
    //         //       }
    //         //     }
    //         //   }
    //         // }
            
    //         if(for_cls_hold_user[i].business_skill_ids.includes(parseInt(clss))){
    //           if(!hold_users.includes(for_cls_hold_user[i])){
    //             hold_users.push(for_cls_hold_user[i])
    //             break;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    // if(start_time?.length !== 0 && end_time?.length !== 0){
    //   let check_start_end_time = this.checkStartEndTime(start_time, end_time)
    //   let for_time_hold_user = hold_users
    //   hold_users = []
    //   for (var i = 0; i < for_time_hold_user?.length; i++){
    //     if(for_time_hold_user[i].availability?.length > 0){
    //       for (var j = 0; j < for_time_hold_user[i].availability?.length; j++){
    //         if(days.includes(this.capitalizeFirstLetter(for_time_hold_user[i].availability[j].day)) && for_time_hold_user[i].availability[j].available === true){
    //             for(var k = 0; k < for_time_hold_user[i].availability[j].availability_slots_attributes?.length; k++){

    //                 //Range Me slots check yha ho rhe he
    //                 if(check_start_end_time.includes(for_time_hold_user[i].availability[j].availability_slots_attributes[k].time_from) && check_start_end_time.includes(for_time_hold_user[i].availability[j].availability_slots_attributes[k].time_to)){
    //                   if(!hold_users.includes(for_time_hold_user[i])){
    //                     hold_users.push(for_time_hold_user[i])
    //                     break;
    //                   }
    //                 }
    //                 //slots me range yha check ho rhe he
    //                 else if(this.checkStartEndTime(for_time_hold_user[i].availability[j].availability_slots_attributes[k].time_from, for_time_hold_user[i].availability[j].availability_slots_attributes[k].time_to).includes(start_time) && this.checkStartEndTime(for_time_hold_user[i].availability[j].availability_slots_attributes[k].time_from, for_time_hold_user[i].availability[j].availability_slots_attributes[k].time_to).includes(end_time)){
    //                   if(!hold_users.includes(for_time_hold_user[i])){
    //                     hold_users.push(for_time_hold_user[i])
    //                   }
    //                 }
    //             }
    //         }
    //       }
    //     }
    //   }
    // }

    // let selected_instructor = []

    // for (var i = 0; i < hold_users?.length; i++){
    //   selected_instructor.push(hold_users[i].id)
    // }

    // this.setState({
    //   users: hold_users,
    //   selectedInstructor: selected_instructor
    // })
  }

  selectedAllRegLoc = () => {
    return (this.state.allLocations.every(v => this.state.selectedLocations.includes(v.id)))
  }

  selectRegions = (e, region) => {
    let val = e.target.value
    let regions = this.state.selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        selected_locations = Array.prototype.push.apply(selected_locations, final_result)
      }
      this.updateInstructor(this.state.selectedLocations, this.state.selectedDay, this.state.selectedClass, this.state.from_time, this.state.to_time)
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          selectedLocations: selected_locations
        })
        this.updateInstructor(selected_locations, this.state.selectedDay, this.state.selectedClass, this.state.from_time, this.state.to_time)
      }
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.selectedLocations, val]
        this.setState(state => ({ selectedLocations: newlocs }));
        this.checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { selectedLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          selectedLocations: newLocs        
        });
        this.checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }))
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
    this.updateInstructor(locations, this.state.selectedDay, this.state.selectedClass, this.state.from_time, this.state.to_time)
  }

  searchClasses = (e) => {
    let searchTerm = e.target.value.trim()
    let classes = this.state.all_classes
    this.setState({searchValue: e.target.value})
    if(searchTerm === ''){
      this.setState({ classes: this.state.all_classes })
    }else{
      var foundClasses = classes.filter(function (entry) {
        return (entry.name.toLowerCase().includes(searchTerm.toLowerCase()));
      });
      this.setState({
        classes: foundClasses
      })
    }
  }

  handleChange3 = (e) => {
    if(e.target.checked){
      this.setState({selectedClass: e.target.value})
      this.updateInstructor(this.state.selectedLocations, this.state.selectedDay, e.target.value, this.state.from_time, this.state.to_time)
    }
    else{
      this.setState({selectedClass: ""})
      this.updateInstructor(this.state.selectedLocations, this.state.selectedDay, "", this.state.from_time, this.state.to_time)
    }
  }

  selectAllInst = () => {
    return (this.state.users.every(v => this.state.selectedInstructor.includes(v.id)))
  }

  selectAllInstructor = (e) => {
    if(e.target.checked){
      let all = this.state.users
      let selected_instructor = []
      for(var i=0; i<all.length;i++){ 
          selected_instructor.push(all[i]['id'])    
      }
      this.setState({
        selectedInstructor: selected_instructor,
      })
    }else{
      this.setState({
        selectedInstructor: []
      })
    }
  }

  handleChange4 = (e, id) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedInstructor.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newInstructor = [...this.state.selectedInstructor, val]
        this.setState(state => ({ selectedInstructor: newInstructor }));
      }
    }else{
      if(__FOUND){
        const { selectedInstructor } = this.state;
        let newInstructor = selectedInstructor.filter((tag, index) => tag !== val)  
        this.setState({
          selectedInstructor: newInstructor        
        });  
      }      
    }
  }

  handleStart = (e) => {
    // e.preventDefault()
    if(e.target.checked){
      this.setState({
        from_time: e.target.value,
        selectFromTimeAlert: false
      })
      let from_time_index = this.state.help_range.indexOf(e.target.value)
      let check = []
      for (let i = from_time_index +1;i < this.state.help_range?.length; i++){
        check.push(this.state.help_range[i])
      } 
      
      if(!check.includes(this.state.to_time)){
        this.setState({
        to_time: check[0]
        })
        this.updateInstructor(this.state.selectedLocations, this.state.selectedDay, this.state.selectedClass, e.target.value, check[0])
      }
      else{
        this.updateInstructor(this.state.selectedLocations, this.state.selectedDay, this.state.selectedClass, e.target.value, this.state.to_time)
      }

      this.setState({
        to_hour_range: check
      });
    }
    else{
      let check = []
      for (let i = 1;i < this.state.help_range?.length; i++){
        check.push(this.state.help_range[i])
      } 
      this.setState({
        from_time: '',
        to_time: '',
        to_hour_range: check
      })

      this.updateInstructor(this.state.selectedLocations, this.state.selectedDay, this.state.selectedClass, '', '')
    }
    
  }

  handleEnd = (e) => {
    // e.preventDefault()
    if(e.target.checked){
      this.setState({
        to_time: e.target.value
      })
      this.updateInstructor(this.state.selectedLocations, this.state.selectedDay, this.state.selectedClass, this.state.from_time, e.target.value)
    }
    else{
      let check = []
      for (let i = 1;i < this.state.help_range?.length; i++){
        check.push(this.state.help_range[i])
      }
      this.setState({
        from_time: '',
        to_time: '',
        to_hour_range: check
      })

      this.updateInstructor(this.state.selectedLocations, this.state.selectedDay, this.state.selectedClass, '', '')
    }
  }

  processFetchSearchResult = (locations, days, clss, start_time, end_time, userIds, page) => {
    if(days?.length === 0 || (start_time === '' && end_time !== '') || (start_time !== '' && end_time === '')){
      return
    }
    let params = {
      location_ids: locations,
      days: days,
      class: clss,
      start_time: start_time,
      end_time: end_time,
      users_id: userIds,
      page: page,
      page_size: this.state.pageSize
    }
    getSearchResult(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          result: result?.results,
          activePage: result?.page,
          loadMore: result?.results?.length === result?.total ? false : true,
          totalResultCount: result?.total,
          prevSelectedLocations: locations,
          prevSelectedDay: days,
          prevSelectedClass: clss,
          prevFrom_time: start_time,
          prevTo_time: end_time,
          prevSelectedInstructor: userIds,
          loaded: true,
          dataLoaded: true
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
        })
      }
    })
  }

  searchResult = (e) => {
    // e.preventDefault()
    this.setState({loaded: false, showFilterModal: false})
    let result = []
    let all_users = this.state.all_users
    let selected_instructor = this.state.selectedInstructor
    if(this.state.to_time && !this.state.from_time){
      this.setState({
          alert_message: "Start Time Missing",
          alert_type: 'danger',
          show: true,
          loaded: "neutral",
          selectFromTimeAlert: true
        })
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
      return;
    }
    let selectedDay = this.state.selectedDay
    if(!selectedDay?.length){
      this.setState({
          alert_message: "Please select a day",
          alert_type: 'danger',
          show: true,
          loaded: "neutral",
          selectDayAlert: true
        })
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
      return;
    }

    const { selectedLocations, selectedClass, from_time, to_time, selectedInstructor } = this.state

    this.processFetchSearchResult(selectedLocations, selectedDay, selectedClass, from_time, to_time, selectedInstructor, 1 )
    
    // let locationSettings = this.state.locationSettings
    // for(var i = 0; i < all_users?.length; i++){
    //   for(var j = 0; j < selected_instructor?.length; j++){
    //     if(all_users[i].id === selected_instructor[j]){
    //       let location = []
    //       for(var k = 0; k < locationSettings?.length; k++){
    //         for(var l = 0; l < locationSettings[k].locations?.length; l++){
    //           if(this.state.selectedLocations?.length !== 0){
    //             if(this.state.selectedLocations.includes(locationSettings[k].locations[l].id) && all_users[i].business_location_ids.includes(locationSettings[k].locations[l].id)){
    //             location.push(locationSettings[k].locations[l].name)
    //             }
    //           }
    //           else{
    //             if(all_users[i].business_location_ids.includes(locationSettings[k].locations[l].id)){
    //               location.push(locationSettings[k].locations[l].name)
    //             }
    //           }
    //         }
    //       }
    //       let availability_slots = []
    //       let available_days = []
    //       for(var k = 0; k < all_users[i].availability?.length; k++){
    //         if(this.state.selectedDay.includes(this.capitalizeFirstLetter(all_users[i].availability[k].day))){
    //           available_days.push(this.capitalizeFirstLetter(all_users[i].availability[k].day))

    //           if(all_users[i].availability[k].available === true){
    //             let slots = []

    //             for(var l = 0; l < all_users[i].availability[k].availability_slots_attributes?.length; l++){

    //               if(this.checkStartEndTime(all_users[i].availability[k].availability_slots_attributes[l].time_from, all_users[i].availability[k].availability_slots_attributes[l].time_to).includes(this.state.from_time || this.state.start_time) && this.checkStartEndTime(all_users[i].availability[k].availability_slots_attributes[l].time_from, all_users[i].availability[k].availability_slots_attributes[l].time_to).includes( this.state.to_time || this.state.end_time)){

    //                     slots.push(all_users[i].availability[k].availability_slots_attributes[l])

    //               }
    //               else if(this.checkStartEndTime(this.state.from_time || this.state.start_time, this.state.to_time || this.state.end_time).includes(all_users[i].availability[k].availability_slots_attributes[l].time_from) && this.checkStartEndTime(this.state.from_time || this.state.start_time, this.state.to_time || this.state.end_time).includes(all_users[i].availability[k].availability_slots_attributes[l].time_to)){
                    
    //                   slots.push(all_users[i].availability[k].availability_slots_attributes[l])

    //               }
    //             }
                
    //             availability_slots.push(slots)
    //           }
    //           else{
    //             availability_slots.push([])
    //           }
    //         }
    //       }

    //       result.push({"name": all_users[i].full_name, "user_tz": all_users[i].user_tz, 'last_modified': all_users[i].last_modified, 'current_usr_tz': all_users[i].current_user_abbr, "status": all_users[i].active_status, "class": this.checkClassName(this.state.selectedClass), "day": available_days, "available_slots": availability_slots, "locations": location, "preferred_classes": all_users[i].preferred_classes, "note": all_users[i].note})
    //     }
    //   }
    // }
    // result.sort((a, b) => a.name.localeCompare(b.name, 'es', {sensitivity: 'base'}))

    // let header = []
    // if(result?.length !== 0){
    //   header.push("Name", "User_tz", "Last_modified_date", "Status")
    //   if(result[0].class?.length !== 0){
    //     header.push("Skill/Class Type")
    //   } 
    //   header.push("Locations")
    //   for(let i = 0; i < result[0].day?.length; i++){
    //     header.push(result[0].day[i])
    //   }
    //   header.push("Preferred # Classes")
    //   header.push("Note")
    // }
    // let data = []
    // if(result?.length !== 0){
    //   for(let i = 0; i < result?.length; i++){
          
    //     let obj = [result[i].name, result[i].user_tz, result[i].last_modified, result[i].status] 
    //     if(result[0].class?.length !== 0){
    //       obj.push(result[0].class)
    //     }  
    //     obj.push(result[i].locations)
    //     for(let j = 0; j < result[i].available_slots?.length; j++){
    //       let slots = []
    //       if(result[i].available_slots[j]?.length > 0){
    //         for(let k = 0; k < result[i].available_slots[j]?.length; k++){
    //           let stringTime = result[i].available_slots[j][k].time_from + " - " + result[i].available_slots[j][k].time_to
              
    //           slots.push(stringTime)
    //         }
    //       }
    //       else{
    //         slots.push("Not available")
    //       }
    //       obj.push(slots)
    //     }
    //     obj.push(result[i].preferred_classes)
    //     obj.push(result[i].note)
    //     data.push(obj)
    //   }
    // }
    // let dataToSave = {locations: this.state.selectedLocations, days: this.state.selectedDay, class: this.checkClassName(this.state.selectedClass), start_time: this.state.from_time, end_time: this.state.to_time, instructors: this.state.selectedInstructor}

    // this.setState({
    //   result: result,
    //   loaded: true,
    //   exportHeader: header,
    //   exportData: data,
    //   dataToSave: dataToSave
    // })
  }

  processExport = (locations, days, clss, start_time, end_time, userIds) => {
    if(days?.length === 0 || (start_time === '' && end_time !== '') || (start_time !== '' && end_time === '')){
      return
    }
    let params = {
      location_ids: locations,
      days: days,
      class: clss,
      start_time: start_time,
      end_time: end_time,
      users_id: userIds,
    }
    const { business } = this.props
    let date = new Date()
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate()
    let fileName = business?.name+`-${day}-${month}-${year}`+'.csv'
    exportSearchResult(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
      response => response.blob()
      .then(result => { 
        const url = window.URL.createObjectURL(result)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)        
        this.setState({exportedReport: true})
      })
    )
  }

  export = () => {
    const { prevSelectedLocations, prevSelectedDay, prevSelectedClass, prevFrom_time, prevTo_time, prevSelectedInstructor } = this.state
    this.setState({exportedReport: false})
    this.processExport(prevSelectedLocations, prevSelectedDay, prevSelectedClass, prevFrom_time, prevTo_time, prevSelectedInstructor)
    // let csvData = [] 
    // let csvHeader = []
    // let exportHeader = this.state.exportHeader
    // let exportData = this.state.exportData
    // for (let i = 0; i < exportHeader?.length; i++){
    //   csvHeader.push({label: exportHeader[i], keys: exportHeader[i]})
    // }

    // for(let i = 0; i < exportData?.length; i++){
    //   let obj = {}
    //   exportHeader.forEach(function(value, index) {
    //     obj[value] = exportData[i][index];
    //   });
    //   csvData.push(obj)
    // }
    // this.setState({
    //   csvHeader: csvHeader,
    //   csvData: csvData
    // })
  }

  chekLocationNameCount = (name) => {
		let shortName = []
		for(let i = 0; i < name?.length; i++){
			if(i < 20){
				shortName.push(name[i])
			}else{
				shortName.push('...')
				break
			}
		}
	
		return shortName.join('')
	}

  usersName = () => {
    let users = this.state.users
    let selected_instructor = this.state.selectedInstructor
    let usersName = []
    let ctr = 0
    for(let i = 0; i < users?.length; i++){
      if(selected_instructor.includes(users[i].id)){
        
        if(ctr < 2){
          usersName.push(users[i].full_name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return this.chekLocationNameCount(usersName.join(", "))
  }

  locationName = () => {
    let locations = this.state.allLocations
    let selected_locations = this.state.selectedLocations
    let locationName = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationName.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return this.chekLocationNameCount(locationName.join(", "))
  }

  checkClassName = (clss) => {
    let classes =  this.state.classes;
    for (let i = 0; i < classes?.length; i++){
      if(classes[i]?.id.toString() === clss){
        return classes[i]?.name;
      }
    }
  }

  closeFilterModal = (e) => {
    this.setState({
      showFilterModal: false
    })
  }

  fetchNextResult = () => {
    if(this.state.loadMore){
      let activePage = this.state.activePage + 1
      this.setState({activePage: activePage, loadedPostOnScroll: false})
      this.processPaginationSearchResult(this.state.prevSelectedLocations, this.state.prevSelectedDay, this.state.prevSelectedClass, this.state.prevFrom_time, this.state.prevTo_time, this.state.prevSelectedInstructor, activePage)
    }
  }

  processPaginationSearchResult = (locations, days, clss, start_time, end_time, userIds, page) => {
    if(days?.length === 0 || (start_time === '' && end_time !== '') || (start_time !== '' && end_time === '')){
      return
    }
    let params = {
      location_ids: locations,
      days: days,
      class: clss,
      start_time: start_time,
      end_time: end_time,
      users_id: userIds,
      page: page,
      page_size: this.state.pageSize
    }
    getSearchResult(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), params).then(      
      response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        let searchResults = this.state.result.concat(result?.results)
        this.setState({
          result: searchResults,
          activePage: result?.page,
          loadMore: searchResults?.length === result?.total ? false : true,
          totalResultCount: result?.total,
          loaded: true,
          dataLoaded: true,
          loadedPostOnScroll: true
        })
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
        })
      }
    })
  }

  fetchNextUser = () => {
    const { users, userList, userListPage, userListTotalPage } = this.state
    if(userList?.length < users?.length){
      let newPage = userListPage + 1
      let splitFrom = userListPage*30
      let splitTo = newPage*30 > users?.length ? users?.length : newPage*30
      let newUsers = users.slice(splitFrom, splitTo)
      let newUserList = [...userList, ...newUsers]
      this.setState({
        userList: newUserList,
        userListPage: newPage
      })
    }
  }

  handleOpenFilterModal = (e) => {
    const { users } = this.state
    let userList = users?.length === 0 ? [] : users?.length > 30 ? users?.slice(0, 30) : users
    
    this.setState({
      userList: userList,
      userListPage: 1,
      showFilterModal: true,
    })
  }

  render(){
    const { all_users, users, locationSettings, selectedAllLocations, selectedAllRegions, selectedRegions, all_classes, classes, days, selectedDay, selectedInstructor, selectedLocations, selectedClass, start_time, end_time, hour_range, from_time, to_hour_range, to_time, help_range, user, loaded, result, alert_message, alert_type, show, searchValue, filtered_users, exportHeader, exportData, csvHeader, csvData, dataToSave, dataLoaded, selectDayAlert, selectFromTimeAlert, allLocations, showFilterOther, showFilterModal, totalResultCount, loadedPostOnScroll, loadMore, loadedUserList, exportedReport, userList, userListTotalPage, userListPage } = this.state
    const { business, loadingMessage } = this.props
    let date = new Date()
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate()
    let fileName = business?.name+`-${day}-${month}-${year}`+'.csv'
    const csvReport = {
      filename: fileName,
      header: this.state.csvHeader,
      data: this.state.csvData
    }
    
    return (
      <React.Fragment>
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        }
        {!dataLoaded &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {dataLoaded &&
          <div className='flex-1 flex flex-col overflow-hidden bg-gray-50 pt-3'>
            <div className="w-full bg-gray-50">
              <div className="pb-5">
                <header>
                  <div className="mx-auto">
                    <h1 className="text-2xl font-semibold leading-tight text-gray-900">
                      Availability Permanent Classes
                    </h1>
                  </div>
                </header>
                <main>
                  <div className="mx-auto">
                    <div className="px-4 pt-3 sm:px-0">
                      {loaded === true &&
                        <div className="flex items-center mb-3">
                          <div className="text-gray-900">Results {totalResultCount}</div>
                        </div>
                      }
                      <div className="flex items-center gap-2 mb-5">
                        {dataLoaded &&
                          <React.Fragment>
                            <button className={`flex text-sm justify-center text-white rounded-md py-2.5 px-4 ${loaded == false ? 'button-disabled bg-gray-700' : 'bg-dark-blue'}`} onClick = {(e) => this.handleOpenFilterModal(e)} disabled={loaded == false}>
                              {loaded == false &&
                                <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                              }
                              Select Filters
                            </button>
                            
                          </React.Fragment>
                        }
                        {!dataLoaded &&
                          <button className="flex text-sm w-28 justify-center bg-gray-700 text-white rounded-md py-2.5 px-4" disabled onClick = {(e) => this.searchResult(e)}>
                            <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                              Select Filters
                          </button>
                        }
                        <div className="text-sm font-medium text-gray-400">
                          {result?.length > 0 && loaded === true &&
                            // <CSVLink className="flex bg-dark-blue text-white text-xs rounded-md px-3 py-2.5 ml-auto font-medium items-center" onClick = {() => this.export()} {...csvReport}>
                            // <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2.5" viewBox="0 0 20 20" fill="currentColor">
                            //     <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z" clipRule="evenodd" />
                            //   </svg>
                            // Export .xls</CSVLink>
                            <button className={`flex text-white text-sm w-36 justify-center rounded-md px-3 py-2.5 ml-auto font-medium items-center ${(!exportedReport) ? 'button-disabled bg-gray-700' : 'bg-dark-blue'}`} onClick = {(e) => this.export(e)} disabled={!exportedReport}>
                              {(!exportedReport) &&
                                <svg className="animate-spin -ml-4 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                              }
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm2 10a1 1 0 10-2 0v3a1 1 0 102 0v-3zm2-3a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zm4-1a1 1 0 10-2 0v7a1 1 0 102 0V8z" clipRule="evenodd" />
                              </svg>
                              Export .xls
                            </button>
                          }
                        </div>
                      </div>
                      {loaded === true &&
                        <div className="flex flex-col">
                          {/* <div className="flex items-center mt-4 mb-5">
                            <div className="text-gray-900">Results {result?.length}</div>
                          </div> */}
                          <div className="-my-2 border-t shadow overflow-x-auto insights-avaibility-table" id="scrollableDiv" style={{ overflowX: "auto" }}>
                            <InfinitScroll
                              className="rounded-lg overflow-inherit"
                              dataLength = {result?.length}
                              next = {this.fetchNextResult}
                              scrollableTarget="scrollableDiv"
                              hasMore = {true}
                            >
                            <div className="align-middle inline-block min-w-full">
                              
                              <div className="border border-t-0 border-b border-gray-200 sm:rounded-lg">
                                {result?.length > 0 &&
                                  <table className="min-w-full divide-y divide-gray-200 availability-classes-table table-fixed">
                                    <thead className="bg-gray-50">
                                      <tr className="bg-gray-50">
                                        <th
                                          scope="col"
                                          className="sticky -top-1 left-0 z-21 px-6 py-3 text-left text-xs border-r font-medium text-gray-500 bg-gray-50 border-b uppercase tracking-wider"
                                        >
                                          instructor
                                        </th>
                                        <th
                                          scope="col"
                                          className="sticky -top-1 z-20 px-6 py-3 text-left text-xs font-medium text-gray-500 bg-gray-50 border-b uppercase tracking-wider"
                                        >
                                          last modified date
                                        </th>
                                        {result[0]?.class_name !== '' &&
                                          <th
                                            scope="col"
                                            className="sticky -top-1 z-20 px-6 py-3 text-left text-xs font-medium text-gray-500 bg-gray-50 border-b uppercase tracking-wider"
                                          >
                                            Skill/Class Type
                                          </th>
                                        }
                                        <th
                                          scope="col"
                                          className="sticky -top-1 z-20 px-6 py-3 text-left text-xs font-medium text-gray-500 bg-gray-50 border-b uppercase tracking-wider"
                                        >
                                          Locations
                                        </th>
                                        {result[0].days.map((day, i) =>
                                          <th
                                            scope="col"
                                            className="sticky -top-1 z-20 px-6 py-3 text-left text-xs font-medium text-gray-500 bg-gray-50 border-b uppercase tracking-wider" key={i}
                                          >
                                            {day}
                                          </th>
                                        )}
                                        <th
                                          scope="col"
                                          className="sticky -top-1 z-20 px-6 py-3 text-left text-xs font-medium text-gray-500 bg-gray-50 border-b uppercase tracking-wider"
                                        >
                                          Preferred # Classes
                                        </th>
                                        <th
                                          scope="col"
                                          className="sticky -top-1 z-20 px-6 py-3 text-left text-xs font-medium text-gray-500 bg-gray-50 border-b uppercase tracking-wider"
                                        >
                                          Notes
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                      {result.map((data, i) =>
                                        <tr key = {i}>
                                          <td className="px-6 py-4 sticky left-0 border-r border-b z-20 bg-white">

                                            <div className="flex text-sm font-medium w-40">
                                              <div className="text-gray-900">{data.full_name}</div>
                                              <div className="text-gray-600 ml-1">({data.user_tz})</div>
                                            </div>
                                            <div className = "text-sm text-gray-400">{data.active_status !=="Active" && <p className = "text-gray-400">({data.active_status}) </p>}
                                            </div>
                                          </td>
                                          <td className="px-6 py-4 whitespace-nowrap sticky border-b left-0 z-10 ">
                                            <div className="flex text-sm font-medium">
                                              <div className="text-gray-500">{data.last_modified === '' ? '' : `${data.last_modified} [${data.current_user_abbr}]`}</div>
                                            </div>
                                          </td>
                                          {data?.class_name !== '' &&  
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 sticky border-b left-0 z-10">{data?.class_name}</td>
                                          }
                                          <td className="px-6 py-4 whitespace-nowrap border-b text-sm text-gray-500 sticky left-0 z-10">
                                            {data.locations.map((location, i) => 
                                              <p key = {i}>{location}</p>
                                            )}
                                          </td>
                                          {data.availability.map((slots, i) =>
                                            <td className="px-6 py-4 whitespace-nowrap border-b text-sm text-gray-500 sticky left-0 z-10" key = {i} >
                                              {slots?.availability_slots_attributes.map((slot, j) =>
                                                <p className = "font-sm" key = {j}>{slot.time_from} - {slot.time_to}</p>
                                              )}
                                              {slots?.availability_slots_attributes?.length === 0 && <p> Not available </p>}
                                            </td>
                                          )}
                                          <td className="px-6 py-4 text-sm text-gray-500 border-b sticky left-0 z-10">{data.preferred_classes}</td>
                                          <td className="px-6 py-4 text-sm border-b text-gray-500"><div className = "w-48 break-word">{data.note? data.note: "N/A"}</div></td>
                                        </tr>
                                        
                                      )}
                                      {!loadedPostOnScroll &&
                                        <tr>
                                          <td colSpan="12">
                                            <div className="rounded-md p-4 w-full">
                                              <div className="animate-pulse flex space-x-4">
                                                <div className="flex-1 space-y-6 py-1">
                                                  <div className="space-y-3">
                                                    <div className="grid grid-cols-3 gap-4">
                                                      <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                                                      <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="flex-1 space-y-6 py-1">
                                                  <div className="space-y-3">
                                                    <div className="grid grid-cols-3 gap-4">
                                                      <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                                                      <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      }
                                      {/* {(result?.length === totalResultCount && totalResultCount > 30) &&
                                        <tr>
                                          <td colSpan="12">
                                            <p className='text-sm text-gray-500 text-center pb-3'>That's all folks</p>
                                          </td>
                                        </tr>
                                      } */}
                                    </tbody>
                                  </table>
                                }
                              </div>
                            </div>
                            </InfinitScroll>
                          </div>
                        </div>
                      }

                      {loaded === "neutral" &&
                        <div>
                        </div>
                      }

                      {loaded === false &&
                        <div className="rounded-md mx-auto mt-7">
                          <div className="flex">
                            <div className="w-full animate-pulse flex items-center justify-center mb-4">
                              <div className="w-20 bg-gray-100 h-4 rounded-lg">
                              </div>
                              <div className="w-20 bg-gray-100 h-7 rounded-lg ml-auto">
                              </div>
                            </div>
                          </div>
                          <div className="animate-pulse flex-row items-center h-full justify-center">
                              <div className="w-full bg-gray-100 h-10 rounded-lg mb-2">
                              </div>
                              <div className="w-full bg-gray-100 h-32 rounded-lg mb-2">
                              </div>
                              <div className="w-full bg-gray-100 h-32 rounded-lg mb-2">
                              </div>
                              <div className="w-full bg-gray-100 h-32 rounded-lg">
                              </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        }
        <Transition.Root show={showFilterModal} as={Fragment}>
					<Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" onClose={(e) => this.closeFilterModal(e)}>
						<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</Transition.Child>
		
							{/* This element is to trick the browser into centering the modal contents. */}
							<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
								&#8203;
							</span>
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div className="inline-block align-bottom  bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl w-full sm:p-6">
									<div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button
											type="button"
											className="bg-white rounded-md text-gray-400 hover:text-gray-500"
											onClick={(e) => this.closeFilterModal(e)}
										>
											<span className="sr-only">Close</span>
											<XIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
									<div className="sm:flex sm:items-start">
										{/* <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
										</div> */}
										<div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
											<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 border-gray-200 border-b mb-4 pb-4">
                        Permanent Classes
											</Dialog.Title>
											<div className="mt-2">
                        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 mb-4 gap-x-4 gap-y-4">
                          <div className="relative filters_box">
                            <Menu as="div" className="relative block text-left">

                              <div>
                                <Menu.Button className="inline-flex justify-center w-full rounded border border-gray-200 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                                  {selectedLocations?.length === allLocations?.length && <p className = "text-gray-900">All Locations Selected</p>
                                  }
                                  {selectedLocations?.length < allLocations?.length && 
                                    <React.Fragment>
                                      {(selectedLocations?.length < 2 && selectedLocations?.length !== 0) && 
                                        <p className = "text-gray-900">{this.locationName()}</p>
                                      }
                                      {(selectedLocations?.length > 1 && selectedLocations?.length < allLocations?.length) && 
                                        <p className = "text-gray-900 flex">{this.locationName()} {(selectedLocations?.length - this.locationName()?.split(',')?.length) > 0 && <p>+{selectedLocations?.length - this.locationName()?.split(',')?.length}</p>}</p>
                                      }
                                    </React.Fragment>
                                  }
                                  {(selectedLocations?.length === 0  && selectedLocations?.length !== allLocations?.length) && <p>Select location</p>} 
                                  <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                show={true}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                                  <div className="flex items-center p-2 border-b">
                                {/*<div className="relative">
                                  <div className="bg-gray-50 rounded-lg h-8">
                                    <input type="text" className="bg-gray-50 px-2 py-1.5 w-full focus:outline-none text-sm text-gray-900 placeholder-gray-500"  placeholder="Search" />
                                    <button className="absolute top-1.5 right-1.5">
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                      </svg>
                                    </button>
                                  </div>
                                </div>*/}
                                <div className=" ml-auto">
                                  <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                    Select All
                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectedAllRegLoc()} onChange = {(e) => this.selectedAllRegionsLocations(e)}/>
                                      
                                    </label>
                                </div>
                              </div>
                                  <div className="p-2 max-h-60 overflow-y-auto">
                                    {locationSettings.map((region, i) =>
                                      <div className="py-1" key = {i}>
                                        {showFilterOther &&
                                          <Menu.Item>
                                            {({ active }) => (
                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                                {region.region.name}
                                              </label>
                                                
                                            )}
                                          </Menu.Item>
                                        }
                                        <div className="ml-5">
                                          {region.locations.map((location, j) =>
                                          <Menu.Item key = {j}>
                                            {({ active }) => (
                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                {location.name}
                                              </label>
                                                
                                            )}
                                          </Menu.Item>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          <div className="relative filters_box">
                            <Menu as="div" className="relative block text-left">
                              <div>
                                <Menu.Button className={classNames('flex justify-center w-full rounded border shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 days_items_button', selectDayAlert? 'focus:ring-red-500 border-red-200' : 'focus:ring-indigo-500 border-gray-200')}>
                                  {selectedDay?.length === days?.length && <p className = "text-gray-900">All Days Selected</p>}
                                  {(selectedDay?.length < days?.length && selectedDay?.length > 1) && <p className = "text-gray-900">{selectedDay?.length} Days Selected</p>
                                  }
                                  {selectedDay?.length === 1 && <p className = "text-gray-900">{selectedDay?.length} Day Selected</p>}
                                  {selectedDay?.length === 0 && <p>Select Day *</p>}
                                  <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                show={true}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden days_items filter_items">
                                  <div className="flex items-center p-2 border-b">
                                    <div className="ml-auto">
                                      <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                        Select All
                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectAll_Days()} onChange = {(e) => this.selectAllDays(e)}/>
                                          
                                        </label>
                                    </div>
                                  </div>
                                  <div className="p-2 max-h-60 overflow-y-auto">
                                    <div className="py-1">
                                      <div>
                                        {days.map((day, i) =>
                                          <Menu.Item key = {i}>
                                            {({ active }) => (
                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {selectedDay.includes(day)} value={day} onChange = {(e) => this.handleChange(e)}/>
                                                  {day}
                                              </label>  
                                            )}
                                          </Menu.Item>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          
                          <div className="relative filters_box">
                            <Menu as="div" className="relative block text-left">
                              <div>
                                <Menu.Button className={classNames('flex justify-center w-full rounded border shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 start_time_button', selectFromTimeAlert ? 'border-red-200 focus:ring-red-500' : 'border-gray-200 focus:ring-indigo-500' )}>
                                  {from_time && <p className = "text-gray-900">{from_time}</p>}
                                  {!from_time && <p>Start Time</p>}
                                  <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                show={true}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none start_time_items hidden filter_items">
                                  <div className="p-2 max-h-60 overflow-y-auto">
                                    <div className="py-1">
                                      <div className = "dropdown-menu">
                                        {hour_range.map((hour, i) => 
                                          <Menu.Item key = {i}>
                                            {({ active }) => (
                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {from_time === hour} value = {hour} onChange = {(e) => this.handleStart(e)}/>
                                                  {hour}
                                              </label>   
                                            )}
                                          </Menu.Item>
                                        )}
                                      </div>
                                    </div>
                                    
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          <div className="relative filters_box">
                            <Menu as="div" className="relative block text-left">
                              <div>
                                <Menu.Button className="flex justify-center w-full rounded border border-gray-200 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 end_time_button">
                                  {to_time && <p className = "text-gray-900">{to_time}</p>}
                                  {!to_time && <p>End Time</p>}
                                  <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                show={true}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none end_time_items hidden filter_items">
                                  <div className="p-2 max-h-60 overflow-y-auto">
                                    <div className="py-1">
                                      <div>
                                        {to_hour_range.map((hour, i) => 
                                          <Menu.Item key = {i}>
                                            {({ active }) => (
                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {to_time === hour} value = {hour} onChange = {(e) => this.handleEnd(e)}/>
                                                  {hour}
                                              </label>   
                                            )}
                                          </Menu.Item>
                                        )}
                                      </div>
                                    </div>
                                    
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          <div className="relative filters_box">
                            <Menu as="div" className="relative block text-left">
                              <div>
                                <Menu.Button className="flex justify-center w-full rounded border border-gray-200 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 class_items_button">
                                  {selectedClass && <p className = "text-gray-900">{this.checkClassName(selectedClass)}</p>}
                                  {!selectedClass && <p>Skill/Class Type</p>}
                                  <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                show={true}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none class_items hidden filter_items">
                                  <div className="flex items-center p-2 border-b">
                                    <div className="relative w-60">
                                      <div className="bg-gray-50 rounded-lg h-8">
                                        <input type="text" className="bg-gray-50 pl-2 pr-8 py-1.5 w-full rounded-lg focus:outline-none text-sm text-gray-900 placeholder-gray-500"  placeholder="Search" value = {searchValue} onChange = {(e) => this.searchClasses(e)}/>
                                        <button className="absolute top-1.5 right-1.5">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p-2 max-h-60 overflow-y-auto">
                                    <div className="py-1">
                                      <div>
                                        {classes.map((cls, i) =>
                                          <Menu.Item key = {i}>
                                            {({ active }) => (
                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedClass === cls.id.toString()} value = {cls.id} onChange={(e)=>this.handleChange3(e)}/>
                                                  {cls.name}
                                              </label>
                                                
                                            )}
                                          </Menu.Item>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          <div className="relative filters_box">
                            <Menu as="div" className="relative block text-left">
                              <div>
                                {users?.length === 0 &&
                                  <Menu.Button className="flex justify-center w-full rounded border border-gray-200 shadow-sm pl-4 pr-2 py-2 bg-gray-100 cursor-not-allowed  text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                    {selectedInstructor?.length === users?.length && <p className = "text-gray-900">No User Found</p>
                                    }
                                    <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                }
                                {users?.length !== 0 &&
                                  <Menu.Button className="flex justify-center w-full rounded border border-gray-200 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 instructor_items_button">
                                    {(selectedInstructor?.length === users?.length && users?.length > 2) && <p className = "text-gray-900">All Users Selected</p>
                                    }
                                    {(selectedInstructor?.length === users?.length && users?.length <= 2) && <p className = "text-gray-900">{this.usersName()}</p>
                                    }
                                    {selectedInstructor?.length < users?.length && 
                                      <React.Fragment>
                                        {(selectedInstructor?.length < 2 && selectedInstructor?.length !== 0) && 
                                          <p className = "text-gray-900">{this.usersName()}</p>
                                        }
                                        {(selectedInstructor?.length > 1 && selectedInstructor?.length < users?.length) && 
                                          <p className = "text-gray-900 flex">{this.usersName()} 
                                          {/* +{selectedInstructor?.length-2}  */}
                                          {(selectedInstructor?.length - this.usersName()?.split(',')?.length) > 0 && <p>+{selectedInstructor?.length - this.usersName()?.split(',')?.length}</p>}</p>
                                        }
                                      </React.Fragment>
                                    }
                                    {(selectedInstructor?.length === 0 && selectedInstructor?.length !== users?.length) && <p>Instructor</p>}
                                    <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                }
                              </div>

                              <Transition
                                as={Fragment}
                                show={true}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none instructor_items hidden filter_items">
                                  <div className="flex items-center p-2 border-b">
                                    <div className="ml-auto">
                                      <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                        Select All
                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectAllInst()} onChange = {(e) => this.selectAllInstructor(e)}/>
                                          
                                        </label>
                                    </div>
                                  </div>
                                  <div className="p-2">
                                    <div className="py-1">
                                      <div>
                                        <InfinitScroll
                                          className=""
                                          dataLength = {userList?.length}
                                          next = {this.fetchNextUser}
                                          height={240}
                                          hasMore = {true}
                                        >
                                          {userList.map((user, i) => 
                                            <div key={i}>
                                              <label className="flex items-center text-gray-900 text-sm mb-2">
                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked = {selectedInstructor.includes(user?.id)} value={user.id} onChange = {(e) => this.handleChange4(e, user.id)}/>
                                                  {user.full_name} {user.active_status !=="Active" && <div className = "ml-2 text-gray-400">({user.active_status}) </div>}
                                              </label>
                                            </div>
                                          )}
                                        </InfinitScroll>
                                      </div>
                                    </div>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse justify-center">
                    <button className={`flex text-sm w-28 justify-center text-white rounded-md py-2.5 px-4 ${(!loadedUserList || loaded == false) ? 'button-disabled bg-gray-700' : 'bg-dark-blue'}`} onClick = {(e) => this.searchResult(e)} disabled={(!loadedUserList || loaded == false)}>
                      {(!loadedUserList || loaded == false) &&
                        <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      }
                      Search
                    </button>
									</div>
								</div>
							</Transition.Child>
						</div>
					</Dialog>
				</Transition.Root>
      </React.Fragment>
    )
  }  
}
