import React, { Fragment, useState } from "react";
import InstructorDetailComponent from './../../cardViewComponents/cardComponent/instructorDetailComponent';

export default function ApprovedUserComponent (props) {
  const { event, handleAlert, defaultDisabled = false } = props
  const [ showInstructorDetail, setShowInstructorDetail ] = useState(false)

  const handleShowInstructorDetails = () => {
    setShowInstructorDetail(true)
  }

  const handleCloseInstructorDetails = () => {
    setShowInstructorDetail(false)
  }

  return(
    <Fragment>
      {showInstructorDetail &&
        <InstructorDetailComponent showInstructorDetail={showInstructorDetail} detailInstructor={event?.approved_user} close={handleCloseInstructorDetails} handleAlert={handleAlert}/>
      }
      <button className={`relative z-20 text-xs custom-blue-200 font-medium ${(defaultDisabled) ? 'cursor-not-allowed' : ''}`} onClick={() => handleShowInstructorDetails()} disabled={defaultDisabled}>
        {event?.approved_user?.full_name}
      </button>
    </Fragment>
  )
}