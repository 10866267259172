import React, { Fragment, useEffect, useState } from "react";
import ClassSelectorComponent from './classSelectorComponent';
import LocationSelectorComponent from './locationSelectorComponent';
import DateTimeSelectorComponent from './dateTimeSelectorComponent';
import { getCurrentEndTime, formatClassesForSchedule, getBusinessMinDate, getBusinessMaxDate, getMinDate, getMaxDate, getSortedData } from '../../../helpers';
import { axiosGetRequest } from './../../../../subRequests/helpers/helpers';
import { PlusIcon } from './../../../assets';
import TimeSelectorComponent from './timeSelectorComponent';

export default function BasicBusinessSelectClassComponents(props) {
  const { user, newSubRequest, setNewSubRequest } = props
  const [ blockBeforeDaysMinDate, setBlockBeforeDaysMinDate ] = useState(getBusinessMinDate(user))
  const [ bizMinDate, setBizMinDate ] = useState(blockBeforeDaysMinDate)
  const [ bizMaxDate, setBizMaxDate ] = useState(getBusinessMaxDate(user))
  const [ minDate, setMinDate ] = useState(getMinDate(user, bizMinDate))
  const [ maxDate, setMaxDate ] = useState(getMaxDate(user, bizMaxDate))
  const [ startTimeMins, setStartTimeMins ] = useState(new Date())
  const [ endTimeMins, setEndTimeMins ] = useState(new Date())
  const [ eventDate, setEventDate ] = useState(blockBeforeDaysMinDate)
  const blackOutDates = user?.blackout_dates_data?.map((date) => new Date(date))
  const [ firstTime, setFirstTime ] = useState(true)
  const [ instructorClasses, setInstructorClasses ] = useState([])
  const [ instructorLocations, setInstructorLocations ] = useState([])
  const businessDateFormat = user?.date_format

  const handleFillSchedules = (schedules, minDate, maxDate, block_before_hours, blackOutDates) => {
    let newClass = []
    if(schedules?.length > 0){
      for(let i = 0; i < schedules?.length; i++){
        newClass.push({
          selectedClass: {id: schedules[i]?.business_class_id, name: schedules[i]?.business_class_name, business_category_id: schedules[i]?.business_category_id},
          selectedLocation: {id: schedules[i]?.location_id, name: schedules[i]?.location},
          selectedDateTime: {eventDate: new Date(schedules[i]?.start_time.substring(0, 19)), startTime: new Date(schedules[i]?.start_time.substring(0, 19)), endTime: new Date(schedules[i]?.end_time.substring(0, 19)), minDate: minDate, maxDate: maxDate, blockBeforeHours: block_before_hours},
          blackOutDates: blackOutDates
        })  
      }
    }
    return newClass
  }

  const [ newClasses, setNewClasses ] = useState(newSubRequest?.schedules?.length > 0 ? handleFillSchedules(newSubRequest?.schedules, minDate, maxDate, user.block_before_hours, blackOutDates) : [
    {
      selectedClass: {id: '', name: '', business_category_id: ''},
      selectedLocation: {id: '', name: ''},
      selectedDateTime: {eventDate: eventDate, startTime: startTimeMins, endTime: endTimeMins, minDate: minDate, maxDate: maxDate, blockBeforeHours: user.block_before_hours},
      blackOutDates: blackOutDates
    }
  ])

  const setDataWithBlockBeforeHours = () => {
    let newTimeOffset = bizMinDate.getTimezoneOffset();
    let startDateTime = new Date((bizMinDate.getTime()/1000 + user?.time_zone_offset +  (newTimeOffset*60))*1000)
    let set_hours = new Date()
    let newMinDate = ''
    let eventDateData = new Date(eventDate)
    if(user.block_before_hours > 0){
      startDateTime.setHours(startDateTime.getHours() + user?.block_before_hours)
      let newDateTime = new Date((bizMinDate.getTime()/1000 + user?.time_zone_offset +  (newTimeOffset*60))*1000)
      newDateTime.setHours(newDateTime.getHours() + user?.block_before_hours)
      newMinDate = new Date(newDateTime)
      setMinDate(new Date(newDateTime))
      setEventDate(new Date(newDateTime))
      eventDateData = new Date(newDateTime)
      setBizMinDate(new Date(newDateTime))
      set_hours = startDateTime.setHours(startDateTime.getHours() + 1)
    }
    else{
      set_hours = startDateTime.setHours('18')
      newMinDate = minDate
    }
    let newStartTime = new Date(set_hours)
    newStartTime.setFullYear(eventDateData.getFullYear())
    newStartTime.setMonth(eventDateData.getMonth())
    newStartTime.setDate(eventDateData.getDate())
    let finalStartTime = new Date(newStartTime.setMinutes('00'))
    let finalEndTime = new Date(newStartTime.setMinutes('45'))
    setStartTimeMins(finalStartTime)
    setEndTimeMins(finalEndTime)
    if(newSubRequest?.schedules?.length > 0){
      let newClassList = []
      for(let i = 0; i < newClasses?.length; i++){
        newClassList.push({...newClasses[i], selectedDateTime: {...newClasses[i]?.selectedDateTime, minDate: newMinDate, maxDate: maxDate, blockBeforeHours: user.block_before_hours}})
      }
      setNewClasses(newClassList)
    }else{
      setNewClasses([{...newClasses[0], selectedDateTime: {eventDate: eventDateData, startTime: finalStartTime, endTime: finalEndTime, minDate: newMinDate, maxDate: maxDate, blockBeforeHours: user.block_before_hours}}])
    }
  }
  
  useEffect(() => {
    if(firstTime){
      setFirstTime(false)
      setDataWithBlockBeforeHours()
    }
  }, [user])
  
  useEffect(() => {
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      schedules: formatClassesForSchedule(newClasses),
      secondSkillCheck: []
    }))
  }, [newClasses])

  const nextClassStartTime = (prevClassStarttime) => {
    let newDate = new Date(prevClassStarttime)
    return new Date(newDate.setHours(newDate.getHours() + 1))
  }

  const nextClassEndTime = (prevClassEndtime) => {
    let newDate = new Date(prevClassEndtime)
    newDate.setHours(newDate.getHours() + 1)
    return new Date(newDate.setMinutes(newDate.getMinutes() + 45))
  }

  const handeleAddMoreSchedule = () => {
    setNewClasses(prevNewClasses => ([
      ...prevNewClasses,
      {
        selectedClass: {id: prevNewClasses[0]?.selectedClass?.id || '', name: prevNewClasses[0]?.selectedClass?.name || '', business_category_id: prevNewClasses[0]?.selectedClass?.business_category_id || ''},
        selectedLocation: {id: prevNewClasses[0]?.selectedLocation?.id || '', name: prevNewClasses[0]?.selectedLocation?.name || ''},
        selectedDateTime: {eventDate:  prevNewClasses[0]?.selectedDateTime?.eventDate || eventDate, startTime: prevNewClasses[0]?.selectedDateTime?.startTime || startTimeMins, endTime: prevNewClasses[0]?.selectedDateTime?.endTime || endTimeMins, minDate: minDate, maxDate: maxDate, blockBeforeHours: user.block_before_hours},
        blackOutDates: blackOutDates
      }
    ]))
  }

  const handleRemoveSchedule = (i) => {
    setNewClasses(prevNewClasses => (prevNewClasses.filter((item, index) => index !== i)))
  }

  useEffect(() => {
    fetchInstructorClasses();
  }, []);

  const fetchInstructorClasses = () => {
    axiosGetRequest('/api/v2/classes').then(result => {
      if(result?.status == 200){
        setInstructorClasses(getSortedData(result?.results))
      }
    })
  }

  const handleSaveClass = (classId, className, skillId, i) => {
    setNewClasses([...newClasses.slice(0, i), {...newClasses[i], selectedClass: {id: classId, name: className, business_category_id: skillId}}, ...newClasses.slice(i + 1)])
  }

  useEffect(() => {
    fetchInstructorLocations()
  }, [newSubRequest?.requestingInstructor])

  const fetchInstructorLocations = () => {
    if(newSubRequest?.requestingInstructor?.id == '') return
    axiosGetRequest(`/api/v2/instructor_locations?requesting_instructor_id=${newSubRequest?.requestingInstructor?.id}`).then(result => {
      if(result?.status == 200){
        setInstructorLocations(getSortedData(result?.results))
        if(result?.results?.length == 1){
          handleSaveLocation(result?.results[0].id, result?.results[0].name, 0)
        }
        else{
          let locationsIds = result?.results.map((item) => item.id)
          for(let i = 0; i < newClasses?.length; i++){
            if(!locationsIds.includes(newClasses[i]?.selectedLocation?.id)){
              setNewClasses(prevNewClasses => ([
                ...prevNewClasses.slice(0, i), 
                {...prevNewClasses[i], selectedLocation: {id: '', name: ''}}, 
                ...prevNewClasses.slice(i + 1)
              ]))
            }
          }
        }
      }
    })
  }

  const handleSaveLocation = (locationId, locationName, i) => {
    setNewClasses([...newClasses.slice(0, i), {...newClasses[i], selectedLocation: {id: locationId, name: locationName}}, ...newClasses.slice(i + 1)])
  }

  const handleSaveDateTime = (eventDate, startTime, endTime, i) => {
    setNewClasses([...newClasses.slice(0, i), {...newClasses[i], selectedDateTime: {...newClasses[i].selectedDateTime, eventDate: eventDate, startTime: startTime, endTime: endTime}}, ...newClasses.slice(i + 1)])
  }

  return(
    <Fragment>
      <div className="my-6 ">
        {newClasses.map((newClass, i) => 
          <div key={i} className={`grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2 lg:grid-cols-4 mb-4 ${i > 0 ? 'border-t lg:border-t-0 border-gray-200 pt-2 lg:pt-0' : ''}`}>
            <ClassSelectorComponent
              i={i}
              newClass={newClass}
              instructorClasses={instructorClasses}
              handleSaveClass={handleSaveClass}
            />
            <LocationSelectorComponent
              i={i}
              newClass={newClass}
              instructorLocations={instructorLocations}
              handleSaveLocation={handleSaveLocation}
            />
            <DateTimeSelectorComponent
              handleRemoveSchedule={handleRemoveSchedule}
              i={i}
              newClass={newClass}
              handleSaveDateTime={handleSaveDateTime}
              businessDateFormat={businessDateFormat}
            />
            <TimeSelectorComponent 
              handleRemoveSchedule={handleRemoveSchedule}
              i={i}
              newClass={newClass}
              handleSaveDateTime={handleSaveDateTime}
            />
          </div>
        )}
        {!newSubRequest?.editSubRequest &&
          <div className="flex items-center justify-end">
            <button className="flex items-center gap-2" onClick={() => handeleAddMoreSchedule()}>
              <PlusIcon classNames={'w-6 h-6'}/>
              <div className="text-sm font-medium text-gray-900">Add More Classes</div>
            </button>
          </div>
        }
      </div>
    </Fragment>
  )
}