import React, { Component, Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { propTypes } from 'react-bootstrap/esm/Image';
import { WithContext as ReactTags, top } from "react-tag-input";
import { Switch } from '@headlessui/react'
import { defaultApprovalStyles } from '../../helpers'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class LocationForm extends Component {
	constructor(props){
    super(props);
    this.state = {
      showModal: props.showModal,
      states: [],
      time_zones: [],
      tags: [],
      submitted: false,
      fitnessManagers: this.props.fitnessManagers,
      regions: [],
      location: {},
      invalidFields: [],
      formType: '',
      business: {},
      currentUser: "",
			manegers: [],
			searchValue: '',
			dataReady: false,
      firstTime: true
    }
    this.handleDelete = this.handleDelete.bind(this);    
    this.handleAddition = this.handleAddition.bind(this);    
  }

	handleDelete(tagId) {
    const { tags } = this.state;
    let deleted_tags = tags.filter((tag, index) => tag.id !== tagId)
    this.setState({
     tags: tags.filter((tag, index) => tag.id !== tagId),
    });
    this.handleFM(deleted_tags)
  }

  handleAddition(tag) {
    var __FOUND = this.state.fitnessManagers.find(function(post, index) {
      if(post.text == tag.text)
        return true;
    });

    if(__FOUND == undefined){
      return;
    }

    let tags = this.state.tags
    tags =  [...tags, tag]

    this.setState(state => ({ tags: [...state.tags, tag] }));
		this.setState({
			manegers: [],
			searchValue: ''
		})
    this.handleFM(tags)
  }

	closeModal = (e) => {
    this.setState({
      showModal: false
    })
    this.props.setShowModal()
  }

	componentDidMount(){
		document.body.addEventListener('click', this.handleClosepeopleList)
	}

	handleClosepeopleList = (e) => {
    const target = $(e.target) // $(this).parents().children()
    const manegers = this.state.manegers

    if(!target.parents('div#targetShowpeopleList1').length && !target.parents('div#showPeopleListInput').length && manegers?.length > 0){
      this.setState({manegers: []})
    }
  }

	componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
    document.body.removeEventListener('click', this.handleClosepeopleList)
  }

	UNSAFE_componentWillReceiveProps(nextProps){
		if(nextProps.dataReady){
      if(this.state.firstTime){
				this.setState({
					showModal: nextProps.showModal,
					states: nextProps.states,
					time_zones: nextProps.time_zones,
					tags: nextProps.tags,
					fitnessManagers: nextProps.fitnessManagers,
					regions: nextProps.regions,
					location: nextProps.location,
					formType: nextProps.formType,
					submitted: false,
					business: nextProps.business,
					currentUser: nextProps.currentUser,
					dataReady: nextProps.dataReady
				})
				if(nextProps.formType === 'edit'){
					this.setTags(nextProps.location)
				}  
				this.setState({firstTime: false})
			}
		}  
  }

	handleChange = e => {
    let { name, value } = e.target;
    const { location } = this.state;
    this.setState({
      location: {
        ...location,
        [name]: value
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name, address, city, state, zipcode, time_zone, club_type, business_region_id, mindbody_id, mbo_site_id, external_id } = this.state.location;
    const { marianatek_integration, abc_integration, mbo_integration, crunch_integration, fisikal_integration, clubready_integration } = this.state.business;
		const { currentUser } = this.state
    let business =  this.state.business
    this.setState({
      submitted: true
    });

    if ((name && name !== "") && (address && address !== "") && (city && city !== "") && (state && state !== "") && (zipcode && zipcode !== "") && (time_zone && time_zone !== "") && !marianatek_integration && !abc_integration && !mbo_integration && !crunch_integration && !fisikal_integration && !clubready_integration ) {
      if(this.state.invalidFields.length == 0){
        this.submitForm()
      }
      else {
        console.log("Error creating location");
      }
    } 
    else if ((name && name !== "") && (address && address !== "") && (city && city !== "") && (state && state !== "") && (zipcode && zipcode !== "") && (time_zone && time_zone !== "") && mbo_integration && !clubready_integration && mindbody_id !== "" && currentUser?.assigned_single_site_id){
      if(this.state.invalidFields.length == 0){
        this.submitForm()
      }
      else {
        console.log("Error creating location");
      }
    }else if ((name && name !== "") && (address && address !== "") && (city && city !== "") && (state && state !== "") && (zipcode && zipcode !== "") && (time_zone && time_zone !== "") && !marianatek_integration && !abc_integration && !crunch_integration && mbo_integration && mindbody_id !== "" && mbo_site_id !== "" && !currentUser?.assigned_single_site_id && !fisikal_integration && !clubready_integration){
      if(this.state.invalidFields.length == 0){
        this.submitForm()
      }
      else {
        console.log("Error creating location");
      }
    }
    else if ((name && name !== "") && (address && address !== "") && (city && city !== "") && (state && state !== "") && (zipcode && zipcode !== "") && (time_zone && time_zone !== "") && marianatek_integration && external_id !== "" && !abc_integration && !mbo_integration && !crunch_integration && !fisikal_integration && !clubready_integration){
      if(this.state.invalidFields.length == 0){
        this.submitForm()
      }
      else {
        console.log("Error creating location");
      }
    }
    else if ((name && name !== "") && (address && address !== "") && (city && city !== "") && (state && state !== "") && (zipcode && zipcode !== "") && (time_zone && time_zone !== "") && !marianatek_integration && external_id !== "" && abc_integration && !mbo_integration && !crunch_integration && !fisikal_integration && !clubready_integration){
      if(this.state.invalidFields.length == 0){
        this.submitForm()
      }
      else {
        console.log("Error creating location");
      }
    }
    else if ((name && name !== "") && (address && address !== "") && (city && city !== "") && (state && state !== "") && (zipcode && zipcode !== "") && (time_zone && time_zone !== "") && external_id !== "" && !marianatek_integration && crunch_integration && !abc_integration && !mbo_integration && !fisikal_integration && !clubready_integration){
      if(this.state.invalidFields.length == 0){
        this.submitForm()
      }
      else {
        console.log("Error creating location");
      }
    }
		else if ((name && name !== "") && (address && address !== "") && (city && city !== "") && (state && state !== "") && (zipcode && zipcode !== "") && (time_zone && time_zone !== "") && (external_id && external_id !== "") && !marianatek_integration && !crunch_integration && !abc_integration && !mbo_integration && fisikal_integration && !clubready_integration){
			if(this.state.invalidFields.length == 0){
				this.submitForm()
			}
			else {
			console.log("Error creating location");
			}
		}
		else if ((name && name !== "") && (address && address !== "") && (city && city !== "") && (state && state !== "") && (zipcode && zipcode !== "") && (time_zone && time_zone !== "") && (external_id && external_id !== "") && !marianatek_integration && !crunch_integration && !abc_integration && !mbo_integration && !fisikal_integration && clubready_integration){
      if(this.state.invalidFields.length == 0){
        this.submitForm()
      }
      else {
        console.log("Error creating location");
      }
    }
    else {
      console.log("Error creating location");
    }
  }

  submitForm = () => {
    if(this.state.formType === 'edit'){
      this.props.editLocation(this.state.location)
    }else{
      this.props.addLocation(this.state.location)
    }
    return;
  }

  handleFM = (tags) => {
    let tagIds = []
    tagIds = tags.map(a => a.id).join(",")
    this.setState({
      location: {
        ...this.state.location,
        fitness_managers: tagIds
      }
    })
  }

  setTags = (location) => {
    let business = this.state.business

    if(location?.fitness_manager_displays?.length > 0){
      let fms = location.fitness_manager_displays
      let tags = []
      for(var i=0;i<fms.length;i++){
        let a = {id: fms[i].id.toString(), text: fms[i].name}
        tags.push(a)
      }
      this.setState({ tags })
    }

    if(business.mbo_integration){
      let site_name = location.site_name
      let site = business.mindbody_details.find((elem) => elem.site_name === site_name)
      this.setState({
        location: {
          ...location,
          mbo_site_id: site?.site_id?.toString()
        }
      })
    }

    if(location?.business_region !== null && Object.keys(location.business_region).length === 0 && location.business_region.constructor === Object){      
      this.setState({
        location: {
          ...location,
          business_region_id: ''
        }
      })
    }else if(location.business_region === null){
      this.setState({
        location: {
          ...location,
          business_region_id: ''
        }
      })
    }
    else{
      this.setState({
        location: {
          ...location,
          business_region_id: location.business_region.id
        }
      })
    }
  }

	searchUsers = (e) => {
    let searchTerm = e.target.value.trim()
    let classes = this.state.fitnessManagers
		let tags = this.state.tags
    if(searchTerm === ''){
      this.setState({ manegers: []})
    }else{
      var foundClasses = classes.filter(function (entry) {
        return (entry.text.toLowerCase().includes(searchTerm.toLowerCase()) && !tags.find(function(post, index) {
					return (post.text === entry.text)
				}));
      });
      this.setState({
        manegers: foundClasses
      })
    }
    this.setState({searchValue: e.target.value})
  }

	handleOnBlur = (e) => {
    this.setState({manegers: []})
  }

	isDisabledIntegratedIdField = () => {
    if(this.state.business?.fisikal_integration || this.state.business?.clubready_integration){
      if(this.state.formType === 'edit'){
        if(this.state.currentUser?.role_display === "Netgym Employee"){
        return false
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }else{
      return false
    }
  }

	disableFisikalLocationName = () => {
		return (this.state.business?.fisikal_integration && this.state.formType === 'edit' && this.state.currentUser?.role_display !== "Netgym Employee")
	}

  render() {
		const { showModal, states, time_zones, tags, fitnessManagers, location, submitted, regions, formType, business, currentUser, manegers, searchValue, dataReady } = this.state 
		const { updatingLocations } = this.props
		
    return (
			<Transition.Root show={showModal} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
					<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
	
						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-4xl w-full sm:p-6 min-h-96 mt-20">
								<div className="absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={(e) => this.closeModal(e)}
									>
										<span className="sr-only">Close</span>
										<XIcon className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
										<Dialog.Title as="h4" className="text-lg leading-6 font-medium text-gray-900 border-b pb-3">
											{formType === 'add' ? 'New' : 'Edit'} Location Form
										</Dialog.Title>
										<div className="big-modal-scroll">
											{!dataReady &&
                        <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                          <img className = "w-9" src="/assets/business/loading.gif" />
                        </div>
                      }
											{dataReady &&
											<Fragment>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start  py-4'>
												<label htmlFor="location-name" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Location Name*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<input type="text" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10 ${this.disableFisikalLocationName() ? 'cursor-not-allowed' : ''}`} defaultValue={location.name} name="name" onChange={(e)=>this.handleChange(e)} placeholder="Enter Location Name" disabled={this.disableFisikalLocationName()}/>
													{submitted && !location.name && (
														<span className="error text-xs">*Location Name is required</span>
													)}
												</div>
											</div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="street-address" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Street Address*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" name="address" defaultValue={location.address} onChange={(e)=>this.handleChange(e)} placeholder="Enter Location Address"/>
													{submitted && !location.address && (
														<span className="error text-xs">*Address is required</span>
													)}
												</div>
											</div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="city" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">City*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<input type="text" className="lg:w-80 text-sm h-10 border rounded-md px-2 py-1.5 w-full shadow-sm" name="city" defaultValue={location.city} onChange={(e)=>this.handleChange(e)} placeholder="Enter Location City"/>
													{submitted && !location.city && (
														<span className="error text-xs">*City is required</span>
													)}
													{/* {submitted && location.city && !location.city.match("^[a-zA-Z][a-zA-Z\\s]*$") && (
														<span className="error text-xs">*City should only contain alphabets and spaces.</span>
													)} */}
												</div>
											</div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="state" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">State*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<select className='form-select lg:w-80 bg-white mt-1 block h-10 w-full pl-2 pr-10 py-2 border border-gray-300 shadow-sm text-sm rounded-md focus:outline-none focus:ring focus:ring-gray-300' value={location.state} name="state" onChange={(e)=>this.handleChange(e)} >
														<option value="">Select a State</option>
														{states.map((state,i) =>
															<option key={i} value={state.value}>{state.label}</option>
														)}
													</select>
													{submitted && !location.state && (
														<span className="error text-xs">*State is required</span>
													)}
												</div>
											</div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="zipcode" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Zipcode*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" defaultValue={location.zipcode} name="zipcode" onChange={(e)=>this.handleChange(e)} placeholder="Enter Location Zipcode"/>
													{submitted && !location.zipcode && (
														<span className="error text-xs">*Zipcode is required</span>
													)}
												</div>
											</div>
											{business.marianatek_integration &&
												<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
													<label htmlFor="marianatek-id" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Marianatek ID*</label>
													<div className='mt-1 sm:mt-0 sm:col-span-1'>
														<input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" name="external_id" defaultValue={location.external_id} onChange={(e)=>this.handleChange(e)} placeholder="Enter Marianatek ID"/>
														{submitted && !location.external_id && (
															<span className="error text-xs">*ID is required</span>
														)}
													</div>
												</div>
											}
											{business.abc_integration &&
												<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
													<label htmlFor="abc-id" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">ABC ID*</label>
													<div className='mt-1 sm:mt-0 sm:col-span-1'>
														<input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm numeric text-sm h-10" name="external_id" defaultValue={location.external_id} onChange={(e)=>this.handleChange(e)} placeholder="Enter ABC ID"/>
														{submitted && !location.external_id && (
															<span className="error text-xs">*ID is required</span>
														)}
													</div>
												</div>
											}
											{business.mbo_integration  &&
												<React.Fragment>
													<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
														<label htmlFor="mindbody-id" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Mindbody ID*</label>
														<div className='mt-1 sm:mt-0 sm:col-span-1'>
															<input type="text" className="lg:w-80 text-sm h-10 border rounded-md px-2 py-1.5 w-full shadow-sm numeric" defaultValue={location.mindbody_id} name="mindbody_id" onChange={(e)=>this.handleChange(e)} placeholder="Enter Mindbody ID" maxLength="6"/>
															{submitted && !location.mindbody_id && (
																<span className="error text-xs">*Mindbody ID is required</span>
															)}
														</div>
													</div>
													{(business.mbo_integration && !currentUser?.assigned_single_site_id) &&
														<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
															<label htmlFor="mbo-site" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">MBO Site*</label>
															<div className='mt-1 sm:mt-0 sm:col-span-1'>
																<select className='form-select lg:w-80 bg-white mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-sm rounded-md' defaultValue={location.mbo_site_id} name="mbo_site_id" onChange={(e)=>this.handleChange(e)}>
																	<option value="">Select MBO Site</option>
																	{currentUser?.assigned_mbo_sites.map((site,i) =>
																		<option key={i} value={site.site_id}>{site.site_name}</option>
																	)}
																</select>
																{submitted && !location.mbo_site_id && (
																	<span className="error text-xs">*Site ID is required</span>
																)}
															</div>
														</div>
													}
												</React.Fragment>
											}
											{business.crunch_integration &&
												<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
													<label htmlFor="crunch-id" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Crunch ID*</label>
													<div className='mt-1 sm:mt-0 sm:col-span-1'>
														<input type="text" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm numeric" name="external_id" defaultValue={location.external_id} onChange={(e)=>this.handleChange(e)} placeholder="Enter Crunch ID" disabled={formType === 'edit' && currentUser.role_display !== "Netgym Employee"}/>
														{submitted && !location.external_id && (
															<span className="error text-xs">*Crunch ID is required</span>
														)}
													</div>
												</div>
											}
											{business?.fisikal_integration &&
												<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
													<label htmlFor="fisikal-id" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Fisikal ID*</label>
													<div className='mt-1 sm:mt-0 sm:col-span-1'>
														<input type="text" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm numeric h-10 ${this.isDisabledIntegratedIdField() ? 'cursor-not-allowed' : ''}`} name="external_id" defaultValue={location.external_id} onChange={(e)=>this.handleChange(e)} placeholder="Enter Fisikal ID" disabled={this.isDisabledIntegratedIdField()}/>
														{submitted && !location.external_id && (
															<span className="error text-xs">*Fisikal ID is required</span>
															)}
													</div>
												</div>
											}
											{business.clubready_integration &&
												<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
													<label htmlFor="clubready-club-id" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Club ID*</label>
													<div className='mt-1 sm:mt-0 sm:col-span-1'>
														<input type="text" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm numeric h-10 ${this.isDisabledIntegratedIdField() ? 'cursor-not-allowed' : ''}`} name="external_id" defaultValue={location.external_id} onChange={(e)=>this.handleChange(e)} placeholder="Enter Club ID" disabled={this.isDisabledIntegratedIdField()}/>
														{submitted && !location.external_id && (
															<span className="error text-xs">*Club ID is required</span>
														)}
													</div>
												</div>
											}
											{regions.length > 0 &&
												<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
													<label htmlFor="region" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Region</label>
													<div className='mt-1 sm:mt-0 sm:col-span-2'>
														<select className='form-select lg:w-80 bg-white mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-sm rounded-md' value={location?.business_region_id} name="business_region_id" onChange={(e)=>this.handleChange(e)}>
															<option value="">Please select a Region</option>
															{regions.map((region,i) =>
																<option key={i} value={region.id}>{region.name}</option>
															)}
														</select>
													</div>
												</div>
											}
											{business.crunch_integration && currentUser.role_display === "Netgym Employee" &&
												<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
													<label htmlFor="club-type" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Club Type</label>
													<div className='mt-1 sm:mt-0 sm:col-span-2'>
														<select className='form-select lg:w-80 bg-white mt-1 block w-full pl-2 pr-10 py-2 border border-gray-300 text-sm rounded-md' defaultValue={location.club_type} name="club_type" onChange={(e)=>this.handleChange(e)}>
															<option value="">Select a Club Type</option>
															<option value="base">Base</option>
															<option value="select">Select</option>
															<option value="signature">Signature</option>
														</select>
													</div>
												</div>
											}
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="time-zone" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Time Zone*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<select className='form-select lg:w-80 bg-white mt-1 block w-full pl-2 pr-5 py-2 border border-gray-300  text-sm rounded-md' value={location.time_zone} name="time_zone" onChange={(e)=>this.handleChange(e)}>
														<option value="">Select Time Zone</option>
														{time_zones.map((zone,i) =>
															<option key={i} value={zone.key}>{zone.value}</option>
														)}
													</select>
													{submitted && !location.time_zone && (
														<span className="error text-xs">*Time Zone is required</span>
													)}
												</div>
											</div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="fitness-manager" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Fitness Manager</label>
												<div className='mt-1 sm:mt-0 sm:col-span-2'>
													<div id='showPeopleListInput' className='w-full lg:w-80 bg-white border border-gray-300 shadow-sm rounded-md h-10 flex items-center'>
														<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4 ml-3.5" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
														</svg>
														<input type="text" className='text-sm w-full border-0 h-10 ml-1.5 focus:outline-none bg-transparent' placeholder='Search for fitness manager' name="searchValue" value={searchValue} onChange={(e) => this.searchUsers(e)} onBlur={(e) => (e)} onClick={(e) => this.searchUsers(e)}/>
													</div>
													{manegers?.length > 0 &&
														<div id='targetShowpeopleList1' className="relative block text-left">
															<div className="origin-top-right z-10 absolute left-0 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-1">
																<div className="max-h-48 overflow-y-auto">
																	<div>
																		{manegers.map((user, i) => 
																			<div key = {i}>
																				<a className="flex items-center text-gray-900 text-sm  cursor-pointer hover:bg-blue-50 py-1.5 -mb-1" onClick={(e) => this.handleAddition(user)}>
																					<div className='pl-1'>{user?.text}</div>
																				</a>
																			</div>
																		)}
																	</div>
																</div>
															</div>
														</div>
													}
													{tags?.length > 0 &&
														<div className='flex items-center mt-2 gap-2 flex-wrap'>
															{tags.map((tag, i) => 
																<div key={i} className='bg-gray-100 rounded-xl flex items-center px-2.5 h-6'>
																	<div className='text-sm text-gray-800 mr-1.5 font-medium'>{tag.text}</div>
																	<a className='cursor-pointer' onClick={(e) => this.handleDelete(tag.id)}>
																		<svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
																			<path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
																		</svg>
																	</a>
																</div>
															)}
														</div>
													}
												</div>
											</div>
											{regions.length > 0 && !business.crunch_integration &&
												<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
													<label htmlFor="add_User_Check" className="block text-left text-sm font-medium text-gray-500 sm:mt-px">Add location to all users’ profiles who teach in this region</label>
													<div className='mt-1 sm:mt-0 sm:col-span-2'>
														<div className='flex items-center'>
															<Switch
																id = "add_User_Check"
																checked={location.added_in_users}
																name="setReminder"
																onChange={(e) => this.setState({location: {...location, added_in_users: !location.added_in_users}})}
																className={classNames(
																	location.added_in_users ? 'bg-gray-900' : 'bg-gray-200',
																	'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none', location?.business_region_id === "" ? 'cursor-not-allowed' : '' 
																)} disabled = {location?.business_region_id === ""}
															>
																<span className="sr-only">Use setting</span>
																<span
																	aria-hidden="true"
																	className={classNames(
																		location.added_in_users ? 'translate-x-5' : 'translate-x-0',
																		'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
																	)}
																/>
															</Switch>
															<label className={`ml-2 text-sm ${location?.business_region_id === "" ? 'text-gray-400' : ''}`} htmlFor="defaultCheck1">
																{location.added_in_users ? 'Yes' : 'No'} &nbsp; &nbsp;
															</label>
														</div>
													</div>
												</div>
											}
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="require-manager-approval" className="block text-left text-sm font-medium text-gray-500 sm:mt-px">Sub Approval Setting</label>
												<div className='mt-1 sm:mt-0 sm:col-span-2'>
													<select defaultValue={location?.approval_style || business?.default_approval_style} name='approval_style' onChange={(e) => this.handleChange(e)} className='form-select lg:w-80 bg-white block w-full pl-3 pr-10 py-2 h-10 text-base border border-gray-300  sm:text-sm rounded-md shadow-sm'>
														{defaultApprovalStyles().map((style,i) =>
															<option key={i} value={style.value}>{style.label}</option>
														)}
													</select>
												</div>
											</div>
											<div className='flex mt-4 gap-3 justify-end'>
												<a className='bg-white hover:bg-gray-100 text-xs sm:text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3' onClick={(e) => this.closeModal(e)}>Cancel</a>
												<button className={`flex text-xs sm:text-base hover:bg-gray-800 font-medium rounded-md text-white bg-dark-blue px-6 py-3 gap-2 ${updatingLocations ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={(e) => this.handleSubmit(e)} disabled={updatingLocations}>{formType === 'add' ? 'Add New' : 'Update'} Location</button>
											</div>
											</Fragment>
											}
										</div>
									</div>
								</div>
								
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
    )
  }
}
 
