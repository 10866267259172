import React, { useEffect, useState } from "react";
import { useChatContext, useChannelStateContext, ChannelHeaderProps } from "stream-chat-react";
import Avatar from './avatar/avatar'
import ChannelMoreActionComponent from './channelMoreActionComponent';
import HerderTabsComponent from './pinnedMessagesComponent/herderTabsComponent';

export default function CustomChannelHeader (props) {
	const { title, handleSetShowInfo, loadingInProgress, channelDataLoaded, showEditButton, showDeleteButton, setShowInfoComponent, setShowNotificationList, handleShowDeleteComfirmation, processHideChannel, notification, notificationDuration, setShowMessages, showMessages, pinnedMessages } = props;
  const { channel } = useChannelStateContext();
  const { client } = useChatContext();

  const handlefetchChannelData = () => {
    if(loadingInProgress || channelDataLoaded) return
    handleSetShowInfo()
  }

  const renderDmName = (members, channel) => {
    const defaultName = client.user.name;

    if (!members.length || members.length === 1) {
      return(<span>{members[0]?.user.name || members[0]?.user.id || defaultName}</span>)
    }

    let names = members.map((usr) => {return usr.user.name})
    let len = 2
    let shortName = []
    for(let i = 0; i < names?.length; i++){
      if(i < len){
        shortName.push(names[i])
      }else{
        // shortName.push(`${names?.length - len}${names?.length > 99 ? '+' : ''} ${(names?.length - len) > 1 ?'others' : 'other'}`)
        shortName.push(`${channel.data?.member_count - 3} ${(channel.data?.member_count - 3) > 1 ?'others' : 'other'}`)
        break
      }
    }
    let groupName = shortName.join(', ') 
    
    return(<span>{groupName}</span>)
  }

  const DmHeaderPreview = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
    const defaultName = "Johnny Blaze";

    return (
      <React.Fragment>
        {/* <Avatar
          image={members[0]?.user.image || undefined}
          name={members[0]?.user.name || members[0]?.user.id}
          size={40}
          shape="rounded"
        /> */}
        <div className="str-chat__header-livestream-left str-chat__channel-header-end">
          <p className="str-chat__header-livestream-left--title str-chat__channel-header-title">
            {renderDmName(members, channel)}
          </p>
          <p className='str-chat__header-livestream-left--members str-chat__channel-header-info'>
            {channel.data?.member_count > 0 && `${channel.data?.member_count} members, ${channel.state?.watcher_count} online`} 
          </p>
        </div>
      </React.Fragment> 
    )
  }

  const ChannelHeaderPreview = () =>{
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
    const defaultName = "Johnny Blaze";

    return(
      <React.Fragment>
        {/* <Avatar
          image={channel?.data.image || undefined}
          name={channel?.data.name || channel?.data.id}
          size={50}
          shape="rounded"
        /> */}
        <div className="str-chat__header-livestream-left str-chat__channel-header-end">
          <p className="str-chat__header-livestream-left--title str-chat__channel-header-title text-base">
            {channel.data?.name || 'Channel'}
          </p>
          <p className='str-chat__header-livestream-left--members str-chat__channel-header-info'>
            {channel.data?.member_count > 0 && `${channel.data?.member_count} members, ${channel.state?.watcher_count} online`} 
          </p>
        </div>
      </React.Fragment>
    )
  }

  const checkDmInfoPageVisible = () => {
    if(channel?.type === 'team'){
      return true
    }else{
      const members = Object.values(channel.state.members).filter(
        ({ user }) => user.id !== client.userID
      );
      return (members && members?.length > 0)
    }
  }

  const DmImageAvtar = () => {
    const members = Object.values(channel.state.members).filter(
      ({ user }) => user.id !== client.userID
    );
    members.sort((a, b) => a.user.name.localeCompare(b.user.name, 'es', {sensitivity: 'base'}))
    const defaultName = "Johnny Blaze";

    if (!members.length || members.length === 1) {
      return(
        <React.Fragment>
          <Avatar
            image={members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={40}
            // shape="rounded"
          />
        </React.Fragment>
      ) 
    }
    
    return(
      <React.Fragment>
        {channel?.data.image && channel?.data.image !== '' ? 
          <Avatar
            image={channel?.data.image || members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={40}
            // shape="rounded"
          />
        :
          <Avatar
            image={members[0]?.user.image || undefined}
            name={members[0]?.user.name || members[0]?.user.id}
            size={40}
            // shape="rounded"
          />
        }
      </React.Fragment>
    ) 
  }

	return (
		<div className="str-chat__header-livestream str-chat__channel-header border-b p-5 pb-10">
      {channel?.type === 'team' ? <ChannelHeaderPreview /> : <DmHeaderPreview />}
      <HerderTabsComponent showMessages={showMessages} setShowMessages={setShowMessages} pinnedMessages={pinnedMessages}/>
      <div className="str-chat__header-livestream-right str-chat__channel-header-end items-center gap-2">
        <ChannelMoreActionComponent
          channel={channel}
          loadingInProgress={loadingInProgress}
          setShowInfoComponent={setShowInfoComponent}
          handlefetchChannelData={handlefetchChannelData}
          showEditButton={showEditButton}
          showDeleteButton={showDeleteButton}
          setShowNotificationList={setShowNotificationList}
          handleShowDeleteComfirmation={handleShowDeleteComfirmation}
          processHideChannel={processHideChannel}
          notification={notification}
          notificationDuration={notificationDuration}
        />
      </div>
		</div>
	)
}