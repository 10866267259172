import React, { Fragment, useState } from "react";
import DownloadIcon from "../../../../subRequests/assets/downloadIcon";
import { axiosGetDownloadRequest } from '../../../../subRequests/helpers/helpers';
import { apiRoutes } from '../../../../constants';
import { LoadingIcon } from "../../../../subRequests/assets";

export default function ExportButton(props) {
  const { selectedInstructors, selectedStatus, selectedCategories, selectedLocations } = props
  const [ disabledBtn, setDisabledBtn ] = useState(false)

  const handleExport = () => {
    setDisabledBtn(true)
    let selectedInstructorIds = selectedInstructors.map(function(a) {return a?.id});
    let url = `${apiRoutes.getCertificationsExport}?instructor_ids=${selectedInstructorIds}&category_ids=${selectedCategories}&statuses=${selectedStatus}&location_ids=${selectedLocations}`
    axiosGetDownloadRequest(url).then((result) => {
      setDisabledBtn(false)
      const url = window.URL.createObjectURL(result)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Instructor_certifications.csv')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  return(
    <Fragment>
      <button className={`w-11 h-11 rounded-full flex items-center justify-center bg-white border-2 border-gray-200 relative ${disabledBtn ? 'cursor-not-allowed opacity-50' : 'cursor-pointer opacity-75'}`} onClick={() => handleExport()} disabled={disabledBtn}>
        {disabledBtn &&
          <div className="flex items-center justify-center absolute">
            <LoadingIcon classNames={'animate-spin h-5 w-5 text-black'}/>
          </div>
        }
        {!disabledBtn &&
          <DownloadIcon classNames={`w-6 h-6 hover:text-black text-gray-700`}/>
        }
      </button>
    </Fragment>
  )
}