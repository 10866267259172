import React from 'react'
import Cookies from 'js-cookie';
import { getSudomain } from '../../helpers'

function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj    
  }  
}

function userToken(){
  return `${Cookies.get(`currentUser.${getSudomain()}`)}`
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const updateUserDetails = async (user_token, subdomain, params) => {
  return await fetch(`/api/v3/my_profile/update_user`,{
    method: 'POST',
    body: params,
    headers: {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${user_token}`),
      'subdomain': localStorage.getItem("businessID")
    }
  });
}

export const axiosRequest = async(url, method, params, headerType) => {
  return await fetch(url, {
    method: method,
    body: headerType == 'content' ? params : JSON.stringify({user_data: params}),
    headers: headerType == 'content' ? {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${userToken()}`),
      'subdomain': localStorage.getItem("businessID")
    } : getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const axiosGetRequest = async(url) => {
  return await fetch(url, {
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function checkReloadUserAvailable() {
  let params = new URLSearchParams(window.location.search)
  let type = (params.has('reload_user') && params.get('reload_user') !== '') ? params.get('reload_user') : ''
  return type
}

export function removeReloaduser() {
  let params = new URLSearchParams(window.location.search);
  params.delete('reload_user');
  const newUrl = window.location.pathname;
  window.history.replaceState(null, null, newUrl);
}

export function getCountriesAbbr() {
  return ['US', 'AU', 'PR', 'NL', 'GB', 'TH', 'FI', 'CA', 'NZ', 'IE'];
}

export function getPhoneNumberValue(directory_phone_number) {
  return (directory_phone_number == '' ? undefined : (directory_phone_number?.startsWith('+') ? directory_phone_number : '+'+directory_phone_number))
}

export const showEditButton = (user) => {
  return (!user?.clubready_feature && !user?.fisikal_feature && user?.integration_name !== 'Crunch')
}