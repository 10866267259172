import React, { Fragment } from "react";
import clsx from 'clsx';
import { MessageText, messageHasAttachments, Avatar as DefaultAvatar, MML, useComponentContext } from 'stream-chat-react';
import { MessageTimestamp as DefaultMessageTimestamp } from '../customMessage/customMessageTimeStamp/customMessageTimestamp'
import { CUSTOM_MESSAGE_TYPE } from 'stream-chat-react/dist/constants/messageTypes';

export default function PinnedSingleMessageComponent(props) {
  const { message, dataFormat } = props
  const { Attachment, Avatar = DefaultAvatar, MessageTimestamp = DefaultMessageTimestamp } = useComponentContext('MessageSimple');
  const hasAttachment = messageHasAttachments(message);
  const allowRetry = message.status === 'failed' && message.errorStatusCode !== 403;
  if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
    return null;
  }
  const rootClassName = clsx(
    'str-chat__message str-chat__message-simple',
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    'str-chat__message--other',
    message.text ? 'str-chat__message--has-text' : 'has-no-text',
    {
      'pinned-message': message.pinned,
      'str-chat__message--has-attachment': hasAttachment,
      'str-chat__message-send-can-be-retried':
        message?.status === 'failed' && message?.errorStatusCode !== 403,
    },
  );
  message.created_at = new Date(message.created_at)
  const messageCreatedAtString = message.created_at.toDateString()
  const todaysDateTimeStamp = new Date()
  const todaysDateString = todaysDateTimeStamp.toDateString()
  const yesterdayDate = new Date(todaysDateString)
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const yseterdayDateString = yesterdayDate.toDateString()

  return(
    <Fragment>
      <div className={rootClassName} key={message.id}>
        {message.user && (
          <Avatar
            image={message.user.image}
            name={message.user.name || message.user.id}
            onClick={() => {}}
            onMouseOver={() => {}}
            user={message.user}
          />
        )}
        <div
          className={clsx('str-chat__message-inner', {
            'str-chat__simple-message--error-failed': allowRetry,
          })}
          data-testid='message-inner'
          onClick={allowRetry ? () => handleRetry(message) : undefined}
          onKeyUp={allowRetry ? () => handleRetry(message) : undefined}
        >
          <div className='str-chat__message-bubble'>
            {message.attachments?.length && !message.quoted_message ? (
              <Attachment actionHandler={() => {}} attachments={message.attachments} />
            ) : null}
            <MessageText message={message} renderText={undefined} />
            {message.mml && (
              <MML
                actionHandler={() => {}}
                align={'left'}
                source={message.mml}
              />
            )}
          </div>
          <div className='str-chat__message-data str-chat__message-simple-data'>
            {message.user ? (
              <span className='str-chat__message-simple-name'>
                {message.user.name || message.user.id}
              </span>
            ) : null}
            <MessageTimestamp calendar={(messageCreatedAtString === todaysDateString || messageCreatedAtString ===  yseterdayDateString)} format={`${dataFormat} h:mm A`} customClass='str-chat__message-simple-timestamp' message={message}/>
          </div>
        </div>
      </div>
    </Fragment>
  )
}