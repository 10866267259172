import React, { Fragment } from "react";
import EditIcon from "../../../assets/editIcon";

export default function EditButtonComponent(props) {
  const { editSelected, setEditSelected } = props;

  return(
    <Fragment>
      <button className={`w-10 h-10 rounded-full flex items-center justify-center ${editSelected ? 'bg-dark-blue hover:bg-gray-700' : 'bg-gray-custom-50 hover:bg-gray-100'}`} onClick={() => setEditSelected(!editSelected)}>
        <EditIcon classNames={`w-5 h-5 ${editSelected ? 'text-white' : 'text-gray-500'}`} />
      </button>
    </Fragment>
  )
}