import React from "react";
import SortingIcons from "../../assets/sortingIcons";

export default function TableSortingHeader({sortType, sortReverse, sort, sortName, title}) {
  return (
    <div className='flex items-center'>
      <span className='cursor-pointer' onClick={(e) => sort(e, sortName, (sortType !== sortName ? false : !sortReverse))}>{title}</span>&nbsp; &nbsp;
      {sortType !== sortName &&
        <SortingIcons iconName={''} classNames={'hover:text-gray-400 min-w-16 cursor-pointer'} onClick={(e) => sort(e, sortName, false)}/>
      }
      {(sortType === sortName && sortReverse) &&
        <SortingIcons iconName={'desc'} classNames={'hover:text-gray-400 min-w-16 cursor-pointer'} onClick={(e) => sort(e, sortName, false)}/>
      }
      {(sortType === sortName && !sortReverse) &&
        <SortingIcons iconName={'asc'} classNames={'hover:text-gray-400 min-w-16 cursor-pointer'} onClick={(e) => sort(e, sortName, true)}/>
      }
    </div>
  )
}