import React, { Fragment } from "react";
import UserIcon from '../../../../../subRequestCreate/assets/userIcon';

export default function AssignMassSubComponent({setShowAssignPopup, btnDisabled}) {
  return (
    <Fragment>
      <button className={`flex rounded-2xl items-center border-2 border-gray-300 px-5 py-1.5 gap-1 text-sm text-black font-semibold ${btnDisabled ? 'cursor-not-allowed bg-gray-200 opacity-50' : 'cursor-pointer hover:bg-gray-100'}`} onClick={() => setShowAssignPopup(true)} disabled={btnDisabled}>
        <UserIcon classNames={"w-4 h-4"} customColour={'black'}/>
        Assign Sub
      </button>
    </Fragment>
  )
}