import React, { Fragment } from "react";
import { CalendarIcon } from '../../../subRequests/assets';

export default function AddCalendarButton({href}) {

  return(
    <Fragment>
      <a href={href} target="_blank" className={`flex cursor-pointer items-center justify-center border rounded-md shadow-sm text-sm text-black gap-1 px-3 h-8 m-button`}>
        <CalendarIcon classNames={'w-4 h-4 shrink-0'}/>
        Add to Cal
      </a>
    </Fragment>
  )
}