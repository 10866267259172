import React, { Fragment } from "react";
import PinnedSingleMessageComponent from './pinnedSingleMessageComponent';

export default function PinnedMessageList(props) {
  const { pinnedMessagesLoading, pinnedMessages, dataFormat, handleScrollToMessageId } = props
  const handleMessageOnClick = (event, messageId) => {
    const clickedElement = event.target;
    if(['video', 'a', 'img', 'svg', 'polyline', 'path'].includes(clickedElement?.tagName?.toLowerCase()) || clickedElement.className?.includes('str-chat__modal--open')){
      return
    }
    handleScrollToMessageId(messageId)
  }
  
  return (
    <Fragment>
      <div className="str-chat__list pinned-message-list">
        <div className="str-chat__reverse-infinite-scroll  str-chat__message-list-scroll">
          <ul className="str-chat__ul">
            {pinnedMessages?.map((message, index) => 
              <li 
                className="border border-gray-200 px-4 rounded-xl shadow-md mb-4 pb-4 cursor-pointer" 
                data-message-id={message?.id} 
                key={index} 
                data-testid={`str-chat__li str-chat__li--single`}
                onClick={(e) => handleMessageOnClick(e, message.id)}
                onKeyUp={(e) => handleMessageOnClick(e, message.id)}
              >
                <div className="str-chat__li str-chat__li--single">
                  <PinnedSingleMessageComponent message={message} dataFormat={dataFormat}/>
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </Fragment>
  );
}