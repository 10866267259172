import React, { Fragment, useState } from "react";
import { axiosRequest, integratedBusiness } from './../../../../subRequests/helpers/helpers';
import AlertPopup from '../../../../messenger/pages/chatComponents/assets/alertPopup'
import { classesDataForIntegratedBusinesses, classesDataForBasicBusinesses, removeSubRequestFromLocalStorage, formattedErrorMessage, setUrlByPageNo, getClassTitles } from './../../../helpers';
import MultipleClassesMessageComponent from './../../secondStepComponents/multipleClassesMessageComponent';
import { Redirect } from 'react-router-dom';

export default function ConfirmSubRequestButton(props) {
  const { newSubRequest, user, setStep, business } = props
  const [ disabledButton, setDisabledButton ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertType, setAlertType ] = useState('')
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ showErrorPopup, setShowErrorPopup ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ redirect, setRedirect ] = useState(false)
  const [ errorVideoLink, setErrorVideoLink ] = useState('')

  const createSubRequest = () => {
    setDisabledButton(true)
    let url = `/api/v3/business_events`
    let params = {
      "sub_request": {
        "invited_instructors": (newSubRequest?.invitedInstructors?.length > 0 ? newSubRequest?.invitedInstructors?.map((inst) => inst?.id) : (newSubRequest?.approvedUser?.id !== '' ? [newSubRequest?.approvedUser?.id] : [])), 
        "reason": newSubRequest?.reason?.reason, 
        "approved_instructor_id": newSubRequest?.approvedUser?.id,
        "requesting_instructor_id": newSubRequest?.requestingInstructor?.id,
        "classes": integratedBusiness(business) ? classesDataForIntegratedBusinesses(newSubRequest?.schedules) : classesDataForBasicBusinesses(newSubRequest?.schedules),
        "description": newSubRequest?.comment, 
        "other_reason": newSubRequest?.reason?.otherReason, 
        "second_skill_check": newSubRequest?.secondSkillCheck?.map((item) => {return {"business_category_id": item?.business_category_id, "class_id": item?.class_id, "date": item?.date, "location_id": item?.business_location_id, "second_skill": item?.second_skill, "second_skill_id": item?.second_skill_id, "start_time": item?.formatted_start_time?.trim(), "urgent_class": item?.urgent_class}}),
        "time_frame": newSubRequest?.previousWeek ? 'previous_week_and_4_months' : newSubRequest?.timeFrame,
        class_titles: getClassTitles(newSubRequest?.schedules)
      },
      "location_id": newSubRequest?.locationId,
      "mbo_site_id": newSubRequest?.mboSiteId?.site_id
    } 
    axiosRequest(url, 'POST', params, 'data').then(result => {
      removeSubRequestFromLocalStorage()
      if(result?.status == 200){
        if(result?.success.includes('BOOM')){
          setAlertType('success')
          setAlertMessage(result?.success)
          setShowAlert(true)
          window.setTimeout(() => {
            setShowAlert(false)
            setAlertType('')
            setAlertMessage('')
            setRedirect(true)
          }, 5000)
        }else{
          setErrorMessage(formattedErrorMessage(result?.success))
          setErrorVideoLink(result?.video_link || '')
          setShowErrorPopup(true)
        }
      }else{
        if(newSubRequest?.approvedUser?.id == ''){
          setAlertType('danger')
          setAlertMessage(result?.error)
          setShowAlert(true)
          window.setTimeout(() => {
            setShowAlert(false)
            setAlertType('')
            setAlertMessage('')
            setStep(1)
            setUrlByPageNo(1)
          }, 5000)
        }else{
          setErrorMessage(formattedErrorMessage(result?.error))
          setErrorVideoLink(result?.video_link || '')
          setShowErrorPopup(true)
        }
      }
    })
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertType('')
    setAlertMessage('')
  }

  const closeErrorPopup = (data) => {
    setShowErrorPopup(data)
    setErrorMessage('')
    setErrorVideoLink('')
    setRedirect(true)
  }

  return(
    <Fragment>
      {redirect &&
        <Redirect to={`/my-requests`} />
      }
      {showErrorPopup &&
        <MultipleClassesMessageComponent showMultipleClassPopup={showErrorPopup} closeMultipleClassPopup={closeErrorPopup} message={''} showPopupWithError={showErrorPopup} showErrorMessage={errorMessage} errorVideoLink={errorVideoLink}/>
      }
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      <div className="flex justify-center items-center">
        <button className={`flex justify-center items-center rounded-2xl px-4 py-3 h-11 w-48 bg-black text-sm text-white font-semibold mt-2 ${disabledButton ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => createSubRequest()} disabled={disabledButton}>
          Confirm
        </button>
      </div>
    </Fragment>
  )
}