import React from 'react'
import Cookies from 'js-cookie';
import { getSudomain } from '../../helpers'

function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj    
  }  
}

function userToken(){
  return `${Cookies.get(`currentUser.${getSudomain()}`)}`
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const axiosRequest = async(url, method, params, headerType) => {
  return await fetch(url, {
    method: method,
    body: headerType == 'content' ? params : JSON.stringify({user_data: params}),
    headers: headerType == 'content' ? {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${userToken()}`),
      'subdomain': localStorage.getItem("businessID")
    } : getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const axiosGetRequest = async(url) => {
  return await fetch(url, {
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const getInstructorsHistory = async (user_token, subdomain, user_id, startDate, endDate, type, page, pageSize, sortType, sortReverse) => {  
  return await fetch(`/api/v3/analytics/history?id=${user_id}&start_date=${startDate}&end_date=${endDate}&type=${type}&page=${page}&page_size=${pageSize}&sort_type=${sortType}&sort_reverse=${sortReverse}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export function hideItems(cls){
  $('.filter_items').each(function(i, obj) {
    if($(obj).hasClass(`${cls}_items`)){
      $(`.${cls}_items`).toggleClass("hidden")
    }else{
      $(obj).addClass("hidden")
    }
  });
}

export const getBehaviourUsers = async (user_token, subdomain, region_ids, location_ids, startDate, endDate, text, page, pageSize, sortType, sortReverse) => {  
  return await fetch(`/api/v3/analytics/index?region_ids=${region_ids}&location_ids=${location_ids}&start_date=${startDate}&end_date=${endDate}&text=${text}&page=${page}&page_size=${pageSize}&sort_type=${sortType}&sort_reverse=${sortReverse}`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const axiosGetExportRequest = async(url) => {
  return await fetch(url, {
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.blob()
  )
}

export const updateAnalyticsLocation = async(user_token, subdomain, location_ids, region_ids, check) => {
  return await fetch(`/api/v3/analytics/update_analytics_location_filter`,{
    method: "PATCH",
    body: JSON.stringify({region_ids, location_ids, check}),
    headers: getHeaders(user_token, subdomain)
  });
}

export const updateBehaviorColumns = async(user_token, subdomain, type, behaviorColumns) => {
  return await fetch(`/api/v3/analytics/update_behavior_columns`,{
    method: "PATCH",
    body: JSON.stringify({type: type, behavior_columns: behaviorColumns}),
    headers: getHeaders(user_token, subdomain)
  });
}

export const getRoleName = (role) => {
  let name = ""
  switch(role){
    case 'business_admin':
    case 'Business Admin':
    case 'account_owner':
    case 'Account Owner':
      name = "admin"
      break;
    case 'regional_admin':
    case 'Regional Admin':
      name = 'regional_admin'
      break;
    case 'fitness_manager':
    case 'Fitness Manager':
      name = 'fitness_manager'
      break;
    case 'instructor':
    case 'Fitness Instructor':
      name = 'instructor'
      break;
    case 'ops':
    case 'Ops':
      name = 'ops'
      break;
  }
  return name
}

export const checkLocationsAndRegions = (regionsAndLocations, locationIds) => {
  let regionIdsArray = []
  let locationIdsArray = []
  for (let i = 0; i < regionsAndLocations.length; i++) {
    regionIdsArray.push(regionsAndLocations[i]?.region?.id)
    let locs = regionsAndLocations[i]?.locations?.map((loc) => loc.id)
    locationIdsArray = [...locationIdsArray, ...locs]
  }

  if(locationIds.length == 0){
    return [locationIdsArray, regionIdsArray]
  }else{
    let newLocations = []
    let newRegion = []
    newLocations = locationIds.filter((id) => locationIdsArray.includes(id))
    if(newLocations.length == 0){
      newLocations = locationIdsArray
      newRegion = regionIdsArray
    }else{
      newRegion = checkSelctedRegions(regionsAndLocations, newLocations)
    }
    return [newLocations, newRegion]
  }
}

const checkSelctedRegions = (regionLocations, locations) => {
  let selectedRegions = []
  for (let i = 0; i < regionLocations?.length; i++) {
    selectedRegions = checkRegionsByLocation(regionLocations[i], locations, selectedRegions)
  }
  
  return selectedRegions
}

const checkRegionsByLocation = (region, locations, selectedRegions) =>{
  let location_ids = region.locations.map(function(a) {return a.id;});
  let checker = (arr, target) => target.every(v => arr.includes(v));
  let equal = checker(locations, location_ids)
  let val = region.region?.id
  let regions = selectedRegions
  var __FOUND = regions.indexOf(val) > -1;
  if(equal){
    if(!__FOUND){
      return ([...selectedRegions, val])
    }else{
      return selectedRegions
    }
  }else{
    if(__FOUND){
      return(selectedRegions.filter((tag, index) => tag !== val))
    }else{
      return selectedRegions
    }
  }
}