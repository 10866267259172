import React, { useEffect, useContext } from "react";
import { useIntercom } from 'react-use-intercom';
import UserContext from '../../userComponents/userContext';

const IntercomManager = ({ isLoggedIn, user }) => {
  const { boot, shutdown } = useIntercom();
  const { setUserLoggedIn } = useContext(UserContext);
  
  useEffect(() => {
    if (isLoggedIn) {
      let params = {
        email: user?.email,
        createdAt: user?.created_at_unix,
        userId: user?.id,
      }
      boot(params);
      setUserLoggedIn(true);
    } else {
      shutdown();
      setUserLoggedIn(false);
    }
  }, [isLoggedIn, user, boot, shutdown]);

  return null;
};

export default IntercomManager;