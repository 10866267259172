import React, { Component, Fragment } from 'react';
import TopButtonComponent from '../components/topButtonComponent';
import CreatePageSectionComponent from '../components/createPageSectionComponent';
import LoaderComponent from './../../subRequests/pages/components/loaderComponent/loaderComponent';
import { setTitle } from './../../helpers'

export default class SubRequestCreateLayout extends Component {
  constructor(props){
    super(props);
    this.state = {
      loaded: false,
    }
  }

  componentDidMount() {
    this.props.setActive('Sub Board')
    setTitle('create_sub_request')
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    this.setState({
      loaded: nextProps.pageLoaded
    })
  }

  render() {
    const { loaded } = this.state
    const { user, business, loadingMessage, path, permissions } = this.props
    return (
      <Fragment>
        <div className={`w-full mt-20 md:mt-8 min-h-screen font-open-sans bg-gray-custom-50 px-8 pb-8`}>
          {!loaded &&
            <LoaderComponent loadingMessage={loadingMessage}/>
          }
          {loaded &&
            <Fragment>
              <CreatePageSectionComponent user={user} business={business} path={path} permissions={permissions}/>
            </Fragment>
          }
        </div>
      </Fragment>
    );
  }
}