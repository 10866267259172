import React, { Fragment, useEffect } from "react";
import RequestingInstructorComponent from './requestingInstructorComponent';
import AssignSubComponent from './assignSubComponent';
import LocationComponent from './locationComponent';
import MboSiteIdComponent from "./mboSiteIdComponent";

export default function FiltersComponents(props) {
  const { user, business, integratedBusiness, newSubRequest, setNewSubRequest } = props

  useEffect(() => {
    function removeColumn() {
      var row = document.getElementById('filter-row');
      var columns = row.querySelectorAll('.filter-column');
      if (columns.length > 0) {
        var remainingColumns = row.querySelectorAll('.filter-column');
        // remainingColumns.forEach(function(column) {
        //   column.style.width = (100 / remainingColumns.length) + '%';
        // });
        var textSections = row.querySelectorAll('.filter-text-field');
        var divWidth = $('.filter-column').width()
        textSections.forEach(function(textp) {
          // textp.style.width = (divWidth-60)+'px'
        })
      }
    }

    removeColumn()
  }, [])

  const checkBusinessIntegrations = () => {
    return (business?.clubready_integration || business?.crunch_integration || business?.abc_integration || (business?.mbo_integration && business?.multi_site_multi_staff_feature))
  }

  return (
    <Fragment>
      <div id="filter-row" className={`gap-6 grid grid-cols-1 ${user?.role_display !== 'Fitness Instructor' ? `${(checkBusinessIntegrations()) ? 'sm:grid-cols-2 xl:grid-cols-3' : 'lg:grid-cols-2'}` : `${(checkBusinessIntegrations()) ? 'lg:grid-cols-2' : ''}`}`}>
        {user?.role_display !== 'Fitness Instructor' &&
          <Fragment>
            <RequestingInstructorComponent 
              newSubRequest={newSubRequest}
              setNewSubRequest={setNewSubRequest}
            />
          </Fragment>
        }
        {(business?.mbo_integration && business?.multi_site_multi_staff_feature) &&
          <Fragment>
            <MboSiteIdComponent
              newSubRequest={newSubRequest}
              setNewSubRequest={setNewSubRequest}
            />
          </Fragment>
        }
        {(business?.clubready_integration || business?.crunch_integration || business?.abc_integration) &&
          <LocationComponent
            newSubRequest={newSubRequest}
            setNewSubRequest={setNewSubRequest}
          />
        }
        {user?.role_display !== 'Fitness Instructor' &&
          <AssignSubComponent 
            integratedBusiness={integratedBusiness}
            newSubRequest={newSubRequest}
            setNewSubRequest={setNewSubRequest}
            business={business}
          />
        }
      </div>
    </Fragment>
  )
}