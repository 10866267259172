import React, { Component, Fragment } from 'react';
import { setTitle } from '../../helpers';
import LoaderComponent from './../../subRequests/pages/components/loaderComponent/loaderComponent';
import AttendanceListComponent from '../components/attendanceListComponent';
import { axiosGetRequest } from "../../subRequests/helpers/helpers";
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup';


export default class MyClasses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      loaded: false,
      paginatonLoader: false,
      classes: [],
      total: 0,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      firstTime: true
    };
  }

  componentDidMount = () => {
    setTitle('attendance')
    if (this.props.pageLoaded && this.state.firstTime) {
      this.getAttendance(1)
      this.setState({ firstTime: false })
    }
  }

  getAttendance = (page) => {
    let { pageSize } = this.state;
    let url = `/api/v2/list_attendance_data?page=${page}&pageSize=${pageSize}`

    axiosGetRequest(url).then((response) => {
      if (response?.status === 200) {
        this.setState({
          classes: response?.results,
          total: response?.total,
          page: parseInt(response?.page),
          loaded: true,
          paginatonLoader: true
        })
      } else {
        this.setState({
          showAlert: true,
          alertType: 'danger',
          alertMessage: (response?.error || 'There is an issue in fetching attendance.')
        })
        window.setTimeout(() => {
          this.setState({ showAlert: false })
        }, 3000)
      }
    })
  }

  closeAlert = (data) => {
    this.setState({ showAlert: data })
  }

  handleAlert = (showAlert, alertType, alertMessage) => {
    this.setState({
      showAlert: showAlert,
      alertType: alertType,
      alertMessage: alertMessage
    });
  }

  handlePageChange = (page) => {
    this.setLoaded(false)
    this.getAttendance(page)
  }

  setLoaded = (data) => {
    this.setState({ paginatonLoader: data })
  }

  render() {
    const { page, pageSize, loaded, classes, total, showAlert, alertType, alertMessage, paginatonLoader } = this.state
    const { loadingMessage } = this.props

    return (
      <Fragment>
        {showAlert &&
          <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={this.closeAlert} />
        }
        <div className={`w-full min-h-screen font-open-sans bg-gray-custom-50`}>
          <div>
            <header>
              <h1 className="text-2xl font-semibold mb-5 leading-tight text-gray-900">Class Attendance</h1>
            </header>
          </div>
          {!loaded &&
            <LoaderComponent loadingMessage={loadingMessage} />
          }
          {loaded && (
            <Fragment>
              <AttendanceListComponent abcClasses={classes} handleAlert={this.handleAlert} page={page} pageSize={pageSize} total={total} handlePageChange={this.handlePageChange} getAttendance={this.getAttendance} paginatonLoader={paginatonLoader} loadingMessage={loadingMessage} setLoaded={this.setLoaded} />
            </Fragment>
          )}
        </div>
      </Fragment>
    )
  }
}
