import React, { Fragment, useState } from "react";
import { checkEmailAPI, setUserEmailInCookies, setBusinessSubdomainInUrl, logoutUser } from '../../helpers';
import { axiosGetRequest } from '../../../subRequests/helpers/helpers';

export default function EmailComponent(props) {
  const { loginUser, emailRegex, setBusinesses, setLoginUser, setAlert, setPagePath, subdomain } = props;
  const [ email, setEmail ] = useState('');
  const [ submited, setSubmited ] = useState(false);
  const [ disableSubmit, setDisableSubmit ] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const validateEmail = (email) => {
    return emailRegex.test(email);
  };

  const handleBlur = () => {
    setIsTouched(true);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmited(true);
    if (validateEmail(email)) {
      setDisableSubmit(true);
      axiosGetRequest(`${checkEmailAPI}?email=${encodeURIComponent(email)}`)
      .then(result => {
        if(result.status == 200){
          setLoginUser({
            ...loginUser,
            email: result?.email
          })
          setBusinesses(result?.businesses)
          setUserEmailInCookies(subdomain, result?.email)
          if(!result?.select){
            let subdomain =  result?.businesses[0]?.subdomain
            logoutUser(subdomain)
            setBusinessSubdomainInUrl(subdomain)
          }else{
            setPagePath('business_select')
          }
        }else{
          setAlert(true, 'danger', result.error)
          window.setTimeout(()=>{
            setAlert(false, '', '')
          }, result?.status === 404 ? 10000 : 5000)
        }
        setDisableSubmit(false);
      })
    }
  };

  return (
    <Fragment>
      <div className="flex flex-col gap-4 max-w-lg w-full">
        <h1 className="text-2xl text-start">Sign In</h1>
        <div className="flex flex-col gap-1">
          <input
            type="email"
            className="block w-full rounded-xl border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none sm:text-sm sm:leading-6 px-4"
            id="email"
            name="email"
            placeholder="Enter Email"
            value={email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {(submited || isTouched) && email =='' &&
            <p className="text-xs text-red-500">Please enter an email.</p>
          }
          {(submited || isTouched) && email !=='' && !validateEmail(email) &&
            <p className="text-xs text-red-500">Invalid Email.</p>
          }
        </div>
        <button
          className={`bg-gray-900 rounded-xl text-white flex items-center justify-center px-8 py-2 text-sm w-full md:w-1/2 ${(disableSubmit || (email =='' || (email !=='' && !validateEmail(email)))) ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={(e) => handleSubmit(e)}
          disabled={(disableSubmit || (email =='' || (email !=='' && !validateEmail(email))))}
        >
          Submit
        </button>
      </div>
    </Fragment>
  );
}