import React, { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { setCurrentUserToken } from '../../../signIn/helpers';
import { checkTheSignUpPagePath } from '../../helpers';
import IntercomManager from '../../../intercom/intercomManager';

export default function ConfirmUserComponent(props) {
  const { handleUpdateUser, updateUserDataAndRedirect, handleReIntializeAllApiCalls, setLoading, pagePath, setAlert } = props
  const [ redirect, setRedirect ] = useState(false);
  const [ redirectToUrl, setRedirectToUrl ] = useState('');
  const [ currentUser, setCurrentUser ] = useState({});
  const [ loggedIn, setLoggedIn ] = useState(false);

  const checkUserToken = () => {
    let searchParams = new URLSearchParams(window.location.search)
    let confirmationToken = (searchParams.has('confirmation_token') && searchParams.get('confirmation_token') !== '') ? searchParams.get('confirmation_token') : ''
    if(confirmationToken == ''){
      setRedirectToUrl('/signin')
      setRedirect(true)
      setTimeout(()=>{
        handleReIntializeAllApiCalls()
      }, 500)
      return
    }
    let params = {
      confirmation_token: confirmationToken
    }
    let url = '/api/v3/confirm_user'
    axiosRequest(url, 'POST', params, 'data').then(result => {
      if(result.status == 200){
        setCurrentUserToken(result?.user?.api_token)
        if(pagePath == 'email-confirmation'){
          setAlert(true, 'success', 'Email Id Updated')
          setTimeout(()=>{
            setAlert(false, '', '')
            updateUserDataAndRedirect(result?.user, '/my-requests')
          }, 3000);
        }else{
          if(result.user?.active){
            updateUserDataAndRedirect(result?.user, '/my-requests')
            setLoading(false)
          } else {
            setCurrentUser(result?.user)
            setLoggedIn(true)
            setTimeout(()=>{
              handleUpdateUser(result?.user, checkTheSignUpPagePath(result?.user))
            }, 200)
          }
        }
      }else{
        setRedirectToUrl('/signin')
        setRedirect(true)
        setTimeout(()=>{
          handleReIntializeAllApiCalls()
        }, 500)
      }
    })
  }

  useEffect(() => {
    checkUserToken()
  }, [])

  return(
    <Fragment>
      {loggedIn && <IntercomManager isLoggedIn={loggedIn} user={currentUser} />}
      {redirect && <Redirect to={redirectToUrl} />}
    </Fragment>
  )
}