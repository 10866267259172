import React, { Fragment, useEffect, useState } from "react";

export default function ScheduleNotFoundMessageComponent(props) {
  const { fetching, resultError, emptyLocationError, emptyMboSiteError } = props
  const [ subRequestFlowSlogans, setSubRequestFlowSlogans ] = useState(window.getSubRequestFlowSlogans())

  useEffect(() => {
    if(fetching){
      setSubRequestFlowSlogans(window.getSubRequestFlowSlogans())
    }
  }, [fetching])

  return (
    <Fragment>
      <div className={`h-c-365 ${(!fetching && resultError !== '' && !emptyMboSiteError) ? 'border-red-500' : 'border-gray-300'} border flex items-center justify-center rounded-2xl p-5`}>
        {fetching ?
          <p className="text-center text-lg font-medium">
            {subRequestFlowSlogans}
          </p>
        :
          (emptyLocationError ?
            <p className="text-center text-lg font-medium">
              NetGym will display your upcoming classes after you select the location of your request.
            </p>
          : emptyMboSiteError ?
            <p className="text-center text-lg font-medium">
              NetGym will display your upcoming classes after you select the mbo site of your request.
            </p>
          :
            <p className="text-center text-lg font-medium text-red-500">
              {resultError}
            </p>
          )
        }
      </div>
    </Fragment>
  )
}