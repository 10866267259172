import React, { Fragment } from "react";
import { useComponentContext } from 'stream-chat-react';

export default function AttachmentCard({item, i, type}) {
  const { Attachment } = useComponentContext('MessageSimple')
  return (
    <Fragment>
      <div key={i+type} className={`rounded-md border border-gray-200 ${type == 'links' ? '' : 'bg-gray-200'} shadow-md ${type}-attachment-container`}>
        <Attachment actionHandler={() => {}} attachments={[item]} />
      </div>
    </Fragment>
  )
}