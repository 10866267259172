import React from 'react';
import { Link } from 'react-router-dom';

const ConfigureNavbar = ({ showReports, path }) => {
  return (
    <div className='bg-white h-11 shadow rounded-lg mb-5 inline-flex'>
      <Link to={`/attendance/my-classes`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${path === 'my-classes' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>
        My Classes
      </Link>
      {showReports && (
        <Link to={`/attendance/reports`} className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-8 px-2 ${path === 'reports' ? 'text-gray-900 border-gray-900' : 'text-gray-300 border-transparent'}`}>
          Reports
        </Link>
      )}
    </div>
  );
};

export default ConfigureNavbar;