import React, { Fragment, useEffect, useState } from "react";
import SelectAllComponent from './selectAllComponent';
import CancelActionComponent from './cancelActionComponent';
import AssignMassSubComponent from './assignMassSubComponent';
import CancelMassSubComponent from './cancelMassSubComponent';
import CancelMassActionPopupComponent from './cancelMassActionPopupComponent';
import AlertPopup from '../../../../../messenger/pages/chatComponents/assets/alertPopup';
import AssignPopupContentComponent from '../../assignPopupContentComponent';

export default function MassActionOptionsComponent(props) {
  const { setEditSelected, selectedEvents, setSelectedEvents, events, selectEventLocations, disabledEventIds, setSelectEventLocations, handleUpdateListData } = props
  const [ showCancelOptionsPopup, setShowCancelOptionsPopup ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ showAssignPopup, setShowAssignPopup ] = useState(false)
  const [ btnDisabled, setBtnDisabled ] = useState(selectedEvents?.length == 0 || false)

  useEffect(() => {
    setBtnDisabled(selectedEvents?.length == 0 || false)
  }, [selectedEvents])

  const handleCancelMassAction = () => {
    setEditSelected(false);
  }

  const closeCancelOptionsPopup = (data) => {
    setShowCancelOptionsPopup(false)
  }

  const closeAlert = () => {
    setShowAlert(false)
    setAlertMessage('')
    setAlertType('')
  }

  const handleSetAlert = (show, message, type) => {
    setShowAlert(show)
    setAlertMessage(message)
    setAlertType(type)
  }

  const handleUpdateEvents = (data) => {
    handleUpdateListData()
  }

  const closeAssignPopup = (data) => {
    setShowAssignPopup(false)
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {showAssignPopup &&
        <AssignPopupContentComponent show={showAssignPopup} eventId={selectedEvents} close={closeAssignPopup} assignPopupType={'mass-assign-action'} handleSetAlert={handleSetAlert} approvedUserId={selectEventLocations[0] || ''} processFetchEventData={handleUpdateEvents} type={'mass-assign-action'} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/>
      }
      {showCancelOptionsPopup &&
        <CancelMassActionPopupComponent showCancelOptionsPopup={showCancelOptionsPopup} closeCancelOptionsPopup={closeCancelOptionsPopup} handleSetAlert={handleSetAlert} handleUpdateEvents={handleUpdateEvents} eventIds={selectedEvents}/>
      }
      <div className={`mb-5 h-12`}>
        <div className={`flex justify-between flex-wrap gap-3`}>
          <SelectAllComponent events={events} disabledEventIds={disabledEventIds} selectedEvents={selectedEvents} setSelectedEvents={setSelectedEvents} setSelectEventLocations={setSelectEventLocations} selectEventLocations={selectEventLocations}/>
          <div className="flex items-center gap-3 flex-wrap">
            <AssignMassSubComponent setShowAssignPopup={setShowAssignPopup} btnDisabled={btnDisabled}/>
            <CancelMassSubComponent setShowCancelActionsPopup={setShowCancelOptionsPopup} btnDisabled={btnDisabled}/>
          </div>
          <CancelActionComponent handleCancelMassAction={handleCancelMassAction}/>
        </div>
      </div>
    </Fragment>
  )
}