import React, { useEffect, useRef, useState } from "react";
import { useChatContext, useChannelStateContext } from 'stream-chat-react'
import { fetchFilteredMessages } from '../channelAttachmentComponents/heplers'

const getPinnedMessagesComponent = () => {
  const { channel, client } = useChatContext();
  const queryInProgress = useRef(false)
  const [ pinnedMessagesLoading, setPinnedMessagesLoading ] = useState(true);
  const [ pinnedMessages, setPinnedMessages ] = useState([]);

  useEffect(() => {
    if(channel) {
      fetchingPinnedMessages()
    }
  }, [channel])

  useEffect(() => {
    const handlePinnedEvent = (event) => {
      if (event.type === 'message.updated' && event.message.pinned) {
        setTimeout(()=>{
          fetchingPinnedMessages()
        }, 800)
      }
      if (event.type === 'message.updated' && !event.message.pinned) {
        setTimeout(()=>{
          fetchingPinnedMessages()
        }, 800)
      }
      if(event.type === 'message.deleted' && event.message.pinned) {
        setTimeout(()=>{
          fetchingPinnedMessages()
        }, 800)
      }
    };
    if(channel){
      channel.on('message.updated', handlePinnedEvent);
      channel.on('message.deleted', handlePinnedEvent);
    }
    return () => {
      if(channel){
        channel.off('message.updated', handlePinnedEvent);
        channel.off('message.deleted', handlePinnedEvent);
      }
    };
  }, [channel]);

  const fetchingPinnedMessages = async () => {
    if (queryInProgress.current) return;
    setPinnedMessagesLoading(true)
    try {
      queryInProgress.current = true
      const messages = await fetchFilteredMessages(client, channel.cid, 'pinnedMessage');
      const sortedMessages = messages.sort((a, b) => new Date(b.pinned_at) - new Date(a.pinned_at));
      setPinnedMessages(sortedMessages)
    } catch (error) {
      console.error('Error fetching pinned messages:', error)
    }
    queryInProgress.current = false
    setPinnedMessagesLoading(false)
  };

  const loadAgain = () => {
    fetchingPinnedMessages()
  }

  return {
    pinnedMessagesLoading,
    pinnedMessages,
    loadAgain
  }
};

export default getPinnedMessagesComponent