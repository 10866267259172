import React, { Fragment } from "react";
import DeclineButtonIcon from '../../../../assets/declineButtonIcon';

export default function CancelMassSubComponent({setShowCancelActionsPopup, btnDisabled}) {
  return (
    <Fragment>
      <button className={`flex rounded-2xl items-center border-2 border-gray-300 px-5 py-1.5 gap-1 text-sm text-black font-semibold ${btnDisabled ? 'cursor-not-allowed bg-gray-200 opacity-50' : 'cursor-pointer hover:bg-gray-100'}`} onClick={() => setShowCancelActionsPopup(true)} disabled={btnDisabled}>
        <DeclineButtonIcon classNames={"w-5 h-5 text-black"}/>
        Cancel Request
      </button>
    </Fragment>
  )
}