import React, { Fragment, useState, useEffect } from "react";
import { useParams, Redirect } from 'react-router-dom';
import DropZone from './dropZone';
import CertificationCard from './certificationCard'
import AlertPopup from '../../../messenger/pages/chatComponents/assets/alertPopup';
import { LoadingSpinner } from '../../helpers';
import { axiosGetRequest } from '../../../subRequests/helpers/helpers';

export default function SubmitCertification() {
  const { id } = useParams();
  const [ certification, setCertification ] = useState({
    id: id,
    name: '',
    expiration_date: '',
    business_user_id: '',
    certification_category_id: '',
    key_url: '',
    category: {name: '', expiration_date_required: false},
    status: '',
    submitted_at: '',
    submitted_by: ''
  })
  const [ loaded, setLoaded ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ redirectUrl, setRedirectUrl ] = useState('')
  const [ redirect, setRedirect ] = useState(false)

  useEffect(() => {
    fetchCertDetails()
  }, []);

  const fetchCertDetails = () => {
    let url = `/api/v3/certifications/${id}`
    axiosGetRequest(url).then(result => {
      if(result.status === 200){
        let expiration_date = result?.results?.expiration_date
        setCertification({
          ...result?.results,
          expiration_date: ((expiration_date && expiration_date !== '') ? new Date(expiration_date) : '')
          })
        setLoaded(true)
      }else{
        setAlertMessage(result.error)
        setAlertType('danger')
        setShowAlert(true)
        setTimeout(()=>{
          setShowAlert(false)
          handleRedirect()
        },3000)
      }
    })
  }

  const handleShowAlert = (show, message, type) => {
    setAlertMessage(message)
    setAlertType(type)
    setShowAlert(show)
  }

  const handleRedirect = () => {
    setRedirectUrl(location?.pathname?.split('/submit-certification')[0])
    setRedirect(true)
  }

  return(
    <Fragment>
      {redirect &&
        <Redirect to={redirectUrl}/>
      }
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={(data) => handleShowAlert(data, '', '')} />
      }
      {!loaded &&
        <LoadingSpinner />
      }
      {loaded &&
        <div className="flex lg:flex-row flex-col gap-5">
          <div className="w-full lg:w-3/5">
            <DropZone
              certification={certification}
              setCertification={setCertification}
              handleShowAlert={handleShowAlert}
            />
          </div>
          <div className="w-full lg:w-2/5">
            <CertificationCard
              certification={certification}
              setCertification={setCertification}
              handleShowAlert={handleShowAlert}
              handleRedirect={handleRedirect}
            />
          </div>
        </div>
      }
    </Fragment>
  )
}