import React, { Fragment, useEffect, useState } from "react";
import EmptyTableComponent from './../emptyTableComponent';
import { dateTimeString, eventDetailUrl, savePageViewInLocalStorage } from './../../../../helpers/helpers';
import AcceptedUserComponent from './../acceptedUserComponent';
import AlertPopup from './../../../../../messenger/pages/chatComponents/assets/alertPopup';
import MgrButtonsComponent from './../mgrButtonsComponent';
import CommentsComponent from './../commentsComponent';
import AssignButtonComponent from './../assignButtonComponent';
import ReOpenButtonComponent from './../reOpenButtonComponent';
import CommentButtonComponent from './../commentButtonComponent';
import CancelButtonComponent from './../cancelButtonComponent';
import ResendButtonComponent from './../resendButtonComponent';
import Pagination from "react-js-pagination";
import LoaderComponent from './../../loaderComponent/loaderComponent';
import RequestingInstructorComponent from './../requestingInstructorComponent';
import ApprovedUserComponent from './../approvedUserComponent';
import UndoCancelButtonComponent from '../undoCancelButtonComponent';
import UndoNoShowButtonComponent from '../undoNoShowButtonComponent';
import AcceptDeclineButtonsComponent from '../acceptDeclineButtonsComponent';
import AddToCalendarButtonComponent from '../addToCalendarButtonComponent';
import { Link } from 'react-router-dom';

export default function ListViewTableComponent(props) {
  const { user, events, handleReloadData, business, tableDateLoaded, handleUpdateEvents, handleChangePage, activePage, totalEventsCount, pageSize, path, myRequestsTab, handleDeleteEvent, handleClickOnEvent, myRequestsPage, myRequestsPageTotalCount, handleMyRequestsPageChange, editSelected, selectedEvents, setSelectedEvents, setSelectEventLocations, selectEventLocations, disabledEventIds } = props
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ alertType, setAlertType ] = useState('')
  const [ tableLoaded, setTableLoaded ] = useState(false)
  const sentBucket = (['my-requests'].includes(path) && myRequestsTab == 'sent') ? myRequestsTab : ''
  const [ paginationLoader, setPaginationLoader ] = useState(false)

  useEffect(() => {
    setTableLoaded(tableDateLoaded)
    if(tableDateLoaded) setPaginationLoader(false)
  }, [tableDateLoaded])

  const handleAlert = (show, message, type) => {
    setShowAlert(show)
    setAlertMessage(message)
    setAlertType(type)
  }

  const closeAlert = (data) => {
    setShowAlert(data)
    setAlertMessage('')
    setAlertType('')
  }

  const handlePageChange = (page) => {
    handleChangePage(page)
  }
  
  const handleCloseAndReloadData = () => {
    handleReloadData(true)
  }

  const handleGetDataWithoutLoading = () => {
    handleReloadData(false)
  }

  const handleListRecordClick = () => {
    savePageViewInLocalStorage('list', path)
    handleClickOnEvent()
  }

  const handlePageChangeMyRequests = (page) => {
    setPaginationLoader(true)
    handleMyRequestsPageChange(page)
  }

  const checkDeclinedEvent = (event) => {
    return (['my-requests'].includes(path) && myRequestsTab == 'available' && event?.user_status == 'Declined')
  } 

  const handleSelectEvents = (e, event) => {
    let eventId = event?.id
    let locationId = event?.location_id
    if(e.target.checked) {
      setSelectedEvents(prevSelectedEvents => {
        if(prevSelectedEvents?.length == 0 && selectEventLocations?.length == 0) setSelectEventLocations([locationId]);
        return([...prevSelectedEvents, eventId])
      })
    }else{
      setSelectedEvents(prevSelectedEvents => {
        let newResult = prevSelectedEvents.filter((evntId) => evntId !== eventId)
        if(newResult?.length == 0 && selectEventLocations?.length > 0) setSelectEventLocations([]);
        return(newResult)
      })
    }
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {!tableLoaded &&
        <div className={`absolute z-30 bg-gray-400 bg-opacity-10 flex w-full h-screen list-loader-screen ${['all-requests'].includes(path) && totalEventsCount > pageSize ? 'list-loader-screen-height-with-pagination' : 'list-loader-screen-height'} ${paginationLoader ? 'justify-start flex-col flex-col-reverse' : 'justify-center'}`}>
          <div className={`msg-loading flex flex-col items-center w-full mt-20 h-screen ${paginationLoader ? 'justify-end mb-72' : ''}`}>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        </div>
      }
      <Fragment>
        <div className="flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 special-container-class">
              <div className='shadow overflow border border-gray-200 rounded-2xl'>
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50 rounded-t-2xl'>
                    <tr>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider rounded-tl-2xl`}>
                        <div className='flex items-center'>
                          <span className=''>Status</span> &nbsp; &nbsp;
                        </div>
                      </th>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                        <div className='flex items-center'>
                          <span className=''>Date & Time</span> &nbsp; &nbsp;
                        </div>
                      </th>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                        <div className='flex items-center'>
                          <span className=''>Location</span> &nbsp; &nbsp;
                        </div>
                      </th>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                        <div className='flex items-center'>
                          <span className=''>Class Name</span> &nbsp; &nbsp;
                        </div>
                      </th>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                        <div className='flex items-center'>
                          <span className=''>Requesting Instructor</span> &nbsp; &nbsp;
                        </div>
                      </th>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                        <div className='flex items-center'>
                          <span className=''>Approved Sub</span> &nbsp; &nbsp;
                        </div>
                      </th>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                        <div className='flex items-center'>
                          <span className=''>Reason</span> &nbsp; &nbsp;
                        </div>
                      </th>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}>
                        <div className='flex items-center'>
                          <span className=''>Comments</span> &nbsp; &nbsp;
                        </div>
                      </th>
                      <th className={`px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider rounded-tr-2xl`}>
                        <div className='flex items-center'>
                          <span className=''>Action</span> &nbsp; &nbsp;
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200'>
                    {events.map((event, i) => 
                      <tr key={i} className='bg-white relative'>
                        <td className='px-3 py-3'>
                          <Link to={eventDetailUrl(event, path)} className="absolute w-full z-10 cursor-pointer -mt-4 h-14 left-0 safari-padding custom-padding-apple" onClick={() => handleListRecordClick()}></Link>
                          <div className="flex items-center">
                            {editSelected && <input type="checkbox" className={`w-5 h-5 z-20 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 ${disabledEventIds?.includes(event?.id) ? 'opacity-50 bg-gray-300 cursor-not-allowed' : 'cursor-pointer'}`} onChange={(e) => handleSelectEvents(e, event)} checked={selectedEvents?.includes(event?.id)} disabled={disabledEventIds?.includes(event?.id)}/>}
                            <div className="flex justify-center items-center text-center rounded-2xl px-2 py-1" style={{backgroundColor: `${((checkDeclinedEvent(event)) ? '#FEE2E2' : event?.background)}`}}>
                              <div className='text-xs font-medium' style={{color: `${((checkDeclinedEvent(event)) ? '#DC2626' : event?.border)}`}}>
                                {(['my-requests'].includes(path) && myRequestsTab == 'available' ) ?
                                  (event?.user_status == 'Pending' ?
                                    (event?.status == 'Approved' ? 'Approved' : 'Open')
                                  :
                                    event?.user_status
                                  )
                                :
                                  (event?.status == 'Accepted' ? 
                                    'Pending' 
                                  : 
                                    (['Open', 'Approved'].includes(event?.status) && event?.show_manger_request_buttons) ? 
                                    'MGR' 
                                  : 
                                    event?.status
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className='px-3 py-3'>
                          <div className='text-xs text-gray-500 font-medium whitespace-nowrap'>
                            {`${event?.event_date_time} ${user?.show_tz ? event?.tz_display : ''}`}
                          </div>
                        </td>
                        <td className='px-3 py-3'>
                          <a 
                            href={event?.location_map_url} 
                            target="_blank"
                            className="relative z-20 text-xs custom-blue-200 font-medium cursor-pointer"
                          >
                            {event?.location_name}
                          </a>
                        </td>
                        <td className='px-3 py-3'>
                          <div className='text-xs text-gray-500 font-medium'>{event?.class_name}</div>
                        </td>
                        <td className='px-3 py-3'>
                          <RequestingInstructorComponent event={event} handleAlert={handleAlert} defaultDisabled={editSelected || false}/>
                        </td>
                        <td className='px-3 py-3'>
                          {(['my-requests'].includes(path) && myRequestsTab == 'available' ) ?
                            ((event?.user_status == 'Accepted' && event?.show_invites_section) ?
                              <AcceptedUserComponent event={event} handleAlert={handleAlert} handleCloseAndReloadData={handleUpdateEvents} eventsCount={events?.length} index={i} sentBucket={sentBucket}/>
                            :
                              (event?.status == 'Approved' ?
                                <ApprovedUserComponent event={event} handleAlert={handleAlert} />
                              :
                                <div className='text-xs font-medium'>-</div>
                              )
                            )
                          :
                            (event?.status == 'Approved' ?
                              <ApprovedUserComponent event={event} handleAlert={handleAlert} defaultDisabled={editSelected || false}/>
                            :
                              event?.status == 'Accepted' ?
                              <AcceptedUserComponent event={event} handleAlert={handleAlert} handleCloseAndReloadData={handleUpdateEvents} eventsCount={events?.length} index={i} sentBucket={sentBucket} defaultDisabled={editSelected || false}/>
                            :
                              <div className='text-xs font-medium'>-</div>
                            )
                          }
                        </td>
                        <td className='px-3 py-3'>
                          <div className='text-xs text-gray-500 font-medium'>{event?.reason}</div>
                        </td>
                        <td className='px-3 py-3'>
                          <div className="flex items-center">
                            {event?.comments?.length > 0 ? 
                              <CommentsComponent comments={event?.comments} eventsCount={events?.length} index={i}/>
                              :
                              <div className='text-xs font-medium'>-</div>
                            }
                          </div>
                        </td>
                        <td className='px-3 py-3'>
                          <div className='flex gap-x-2'>
                            {(['my-requests'].includes(path) && myRequestsTab == 'approved') &&
                              <AddToCalendarButtonComponent 
                                event={event}
                                type={'list'}
                              />
                            }
                            {event?.show_manger_request_buttons &&
                              <MgrButtonsComponent user={user} event={event} handleSetAlert={handleAlert} handleCloseAndReloadData={handleUpdateEvents} defaultDisabled={editSelected || false}/>
                            }
                            {(['my-requests'].includes(path) && myRequestsTab == 'available') &&
                              <Fragment>
                                {event?.show_accept_decline_button &&
                                  <AcceptDeclineButtonsComponent 
                                    event={event}
                                    business={business}
                                    handleAlert={handleAlert} 
                                    handleUpdateEvent={handleUpdateEvents}
                                    type={'list'}
                                    calendarEvent={false} 
                                    handleUpdateCalendarEvent={() => {}}
                                  />
                                }
                              </Fragment>
                            }
                            {!(['my-requests'].includes(path) && myRequestsTab == 'available') &&
                              <Fragment>
                                {!(['my-requests'].includes(path) && myRequestsTab == 'sent') &&
                                  <Fragment>
                                    {event?.show_assign_button &&
                                      <AssignButtonComponent event={event} handleAlert={handleAlert} handleCloseAndReloadData={handleUpdateEvents} defaultDisabled={editSelected || false}/>
                                    }
                                  </Fragment>
                                }
                                {event?.show_resend_invite_button &&
                                  <ResendButtonComponent event={event} handleAlert={handleAlert} handleReloadData={handleUpdateEvents} sentBucket={sentBucket} defaultDisabled={editSelected || false}/>
                                }
                                {event?.show_reopen_button &&
                                  <ReOpenButtonComponent event={event} handleAlert={handleAlert} handleCloseAndReloadData={handleUpdateEvents} user={user} sentBucket={sentBucket} defaultDisabled={editSelected || false}/>
                                }
                              </Fragment>
                            }
                            {business?.comments_feature &&
                              <CommentButtonComponent event={event} user={user} handleSetAlert={handleAlert} processFetchEventData={handleUpdateEvents} sentBucket={sentBucket} defaultDisabled={editSelected || false}/>
                            }
                            {!(['my-requests'].includes(path) && myRequestsTab == 'sent') && event?.cancelled &&
                              <UndoCancelButtonComponent 
                                event={event}
                                handleSetAlert={handleAlert}
                                handleUpdateEvent={handleUpdateEvents}
                                defaultDisabled={editSelected || false}
                              />
                            }
                            {(!(['my-requests'].includes(path) && myRequestsTab == 'sent') && event?.no_show) &&
                              <UndoNoShowButtonComponent 
                                event={event}
                                handleSetAlert={handleAlert} 
                                handleUpdateEvent={handleUpdateEvents}
                                defaultDisabled={editSelected || false}
                              />
                            }
                            {(!(['my-requests'].includes(path) && myRequestsTab == 'available') && (((['my-requests'].includes(path) && myRequestsTab == 'sent') && event?.show_cancel_button) || (!(['my-requests'].includes(path) && myRequestsTab == 'sent') && (event?.show_cancel_button || event?.cancelled || event?.no_show)))) &&
                              <CancelButtonComponent 
                                event={event} 
                                handleSetAlert={handleAlert} 
                                handleUpdateEvent={handleUpdateEvents} 
                                handleReloadData={handleGetDataWithoutLoading}
                                sentBucket={sentBucket}
                                handleDeleteEvent={handleDeleteEvent}
                                defaultDisabled={editSelected || false}
                              />
                            }
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {events?.length === 0 &&
                  <EmptyTableComponent />
                }
              </div>
            </div>
          </div>
        </div>
        {['all-requests'].includes(path) && totalEventsCount > pageSize &&
          <div className="flex justify-center w-full flex-wrap mt-4 pb-2">
            <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7 list-view-pagination">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={pageSize}
                totalItemsCount={totalEventsCount}
                pageRangeDisplayed={9}
                onChange={handlePageChange}
                itemClassPrev='previous'
                itemClassNext='next'
                itemClassFirst='first'
                itemClassLast='last'
                hideDisabled={true}
                activeClass={'pagination-active-tab'}
              />
            </div>
          </div>
        }
        {['my-requests'].includes(path) && myRequestsPageTotalCount > pageSize &&
          <div className="flex justify-center w-full flex-wrap mt-4 pb-2">
            <div className="custom-pagination order-first sm:order-last mb-3 sm:mb-0 mr-7 list-view-pagination">
              <Pagination
                activePage={myRequestsPage}
                itemsCountPerPage={pageSize}
                totalItemsCount={myRequestsPageTotalCount}
                pageRangeDisplayed={9}
                onChange={handlePageChangeMyRequests}
                itemClassPrev='previous'
                itemClassNext='next'
                itemClassFirst='first'
                itemClassLast='last'
                hideDisabled={true}
                activeClass={'pagination-active-tab'}
              />
            </div>
          </div>
        }
      </Fragment>
    </Fragment>
  )
}