import React, { Fragment } from "react";
import PinTabIcon from '../assets/pinTabIcon';

export default function PinnedMessageIndicator({pinnedMessageText, isMyMessage}) {

  return(
    <Fragment>
      <div className={`absolute top-1 ${isMyMessage ? 'right' : 'left'}-10 flex items-center gap-1`}>
        <PinTabIcon classNames={'w-3.5 h-3.5 pinned-message-icon-color'} isActive={true}/>
        <div className="text-gray-600 text-xs flex gap-1 items-center">Pinned by <div className="font-semibold">{pinnedMessageText}</div></div>
      </div>
    </Fragment>
  )
}