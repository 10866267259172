import React, { Fragment, useState, useEffect } from "react";
import { checkAndShowMassActionSelectAll } from "../../../../helpers/supportHelpers";

export default function SelectAllComponent(props) {
  const { events, disabledEventIds, selectedEvents, setSelectedEvents, setSelectEventLocations, selectEventLocations } = props
  const [ allEventIds, setAllEventIds ] = useState(events?.filter((event) => !disabledEventIds?.includes(event?.id))?.map((event) => event?.id) || []);
  const [ showSelectAll, setShowSelectAll ] = useState(checkAndShowMassActionSelectAll(events) || false)

  useEffect(() => {
    setShowSelectAll(checkAndShowMassActionSelectAll(events) || false);
  }, [events]);

  useEffect(() => {
    setAllEventIds(events?.filter((event) => !disabledEventIds?.includes(event?.id))?.map((event) => event?.id) || []);
  }, [events, disabledEventIds]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      if(selectEventLocations?.length == 0){
        let eventId = allEventIds[0]
        let event = events.find((event) => event.id == eventId)
        setSelectEventLocations([event.location_id])
      }
      setSelectedEvents(allEventIds)
    } else {
      setSelectedEvents([])
      setSelectEventLocations([])
    }
  }

  const checkAllSelected = () => {
    return (allEventIds.every(v => selectedEvents.includes(v)) && allEventIds.length > 0)
  }

  return (
    <Fragment>
      {showSelectAll ? 
        <div className="flex items-center relative">
          <label className="flex text-gray-900 text-sm items-center">
            <input type="checkbox" className="w-5 h-5 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-2 cursor-pointer" onChange={(e) => handleSelectAll(e)} checked={checkAllSelected()}/>
            <div className='flex items-center gap-2 cursor-pointer text-base font-medium'>Select All</div>
          </label>
          {checkAllSelected() &&
            <div className="font-semibold text-xs text-gray-600 absolute top-9 truncate">
              All Requests Selected
            </div>
          }
        </div>
      :
        <div></div>
      }
    </Fragment>
  )
}