import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { getReports, downloadReport } from './helpers';
import { DownloadIcon } from '../../subRequests/assets';
import { getSudomain, setTitle } from '../../helpers';
import AlertPopup from '../../messenger/pages/chatComponents/assets/alertPopup';

const ReportsComponent = (props) => {
  const { loadingMessage } = props;
  const [reports, setReports] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [show, setShow] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [downloading, setDownloading] = useState('');

  const getAll = () => {
    getReports(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(
      response => response.json()
    )
      .then(result => {
        if (result.status === 200) {
          setReports(result.reports);
          setLoaded(true);
          setShow(false);
          setAlertType('');
          setAlertMessage('');
        } else {
          setAlertMessage(result.error);
          setAlertType('danger');
          setShow(true);
          setLoaded(true);
        }
      });
  };

  useEffect(() => {
    const { showReports, pageLoaded } = props;

    if (!showReports) {
      location.href = `/attendance/my-classes`;
    } else {
      setTitle('reports');
      if (pageLoaded) {
        getAll();
      }
    }
  }, [props]);

  const handleDownload = (e, name) => {
    e.preventDefault();
    setDownloading(name);
    downloadReport(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), name).then(
      response => response.blob()
    ).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      setDownloading('');
    });
  };

  return (
    <React.Fragment>
      {show &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={() => setShow(false)} />
      }
      <div className='w-full min-h-screen bg-gray-50'>
        <div className='h-full'>
          {!loaded &&
            <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
              <p className="mb-1 text-center px-5">{loadingMessage}</p>
              <img className="w-9" src="/assets/business/loading.gif" />
            </div>
          }
          {loaded &&
            <div className='shadow border overflow-hidden border-b border-gray-200 sm:rounded-lg mb-4'>
              {reports.length > 0 &&
                <table className='min-w-full divide-y divide-gray-200'>
                  <thead className='bg-gray-50'>
                    <tr>
                      <th scope="col" className="hidden lg:table-cell px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10">#</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created Date</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-10"></th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-200'>
                    {reports.map((report, i) =>
                      <tr key={i} className='bg-white'>
                        <td className='hidden lg:table-cell px-6 py-4 text-sm font-medium text-gray-900'>
                          <div className='text-sm text-gray-500'>{i + 1}</div>
                        </td>
                        <td className='px-6 py-4'>
                          <div className='text-sm font-medium text-gray-900'>{report.name}</div>
                        </td>
                        <td className='px-6 py-4'>
                          <div className='text-sm font-medium text-gray-900'>{report.created_at}</div>
                        </td>
                        <td className='px-6 py-4'>
                          <div className="justify-end">
                            <div className=' flex gap-3'>
                              <div className='bg-gray-100 hover:bg-gray-200 w-8 h-8 rounded flex items-center justify-center cursor-pointer' onClick={(e) => handleDownload(e, report.name)} data-tip data-for={`download_button_` + report?.name}>
                                <a className='cursor-pointer'>
                                  {downloading === report?.name
                                    ? <img className='w-5 h-5' src='/assets/business/loading.gif' />
                                    : <DownloadIcon classNames={`h-5 w-5 ${'text-black'}`} />}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              }
            </div>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportsComponent;