import React from 'react';
import UserContext from '../userContext';
import Cookies from 'js-cookie';
import { getSudomain, getUserDeatails, clearApiTokenAndRedirectToSignIn } from '../../helpers'

class ContextProvider extends React.Component {
  getShowPopupSessionStorage = () => {
    let storedValue = sessionStorage.getItem('showMobileWebBanner')
    return storedValue !== null ? storedValue === 'true' : true;
  }
  state = {
    user: {},
    fetchedAccounts: false,
    accounts: [],
    notIncludePaths: ['/reset-password', '/confirm-user', '/email-confirmation'],
    showMobileWebBanner: this.getShowPopupSessionStorage(),
    firstTime: true,
    userLoggedIn: false
  };

  updateUserData = (newUser) => {
    this.setState({ user: newUser });
  }

  componentDidMount() {
    this.fetchData();
    this.interval = setInterval(this.fetchData, 600000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchData = async () => {
    let subdomain = getSudomain();
    if(subdomain && subdomain !== null && subdomain !== '' && subdomain !== 'home'){
      let currentUserToken = Cookies.get(`currentUser.${subdomain}`)
      if (!currentUserToken || subdomain == 'home') {
        return
      }
      if(this.state.notIncludePaths.includes(location.pathname)) return
      getUserDeatails(Cookies.get(`currentUser.${getSudomain()}`), getSudomain())
      .then(response => response.json())
      .then(result => {
        if(this.state.firstTime) this.setState({firstTime: false})
        if(result.status == 200){
          this.setState({ user: result.user });
        }else{
          if(!this.state.firstTime) clearApiTokenAndRedirectToSignIn()
        }
      })
    }
  };

  callUserAPI = () => {
    this.fetchData();
  }

  setFetchedAccounts = (data) => {
    this.setState({
      fetchedAccounts: data
    })
  }

  setAccounts = (data) => {
    this.setState({
      accounts: data
    })
  }

  setShowMobileWebBanner = (data) => {
    sessionStorage.setItem('showMobileWebBanner', data);
    this.setState({
      showMobileWebBanner: data
    })
  }

  setUserLoggedIn = (data) => {
    this.setState({
      userLoggedIn: data
    })
  }

  render() {
    return (
      <UserContext.Provider value={
        {
          user: this.state.user,
          updateUserData: this.updateUserData,
          callUserAPI: this.callUserAPI,
          fetchedAccounts: this.state.fetchedAccounts,
          setFetchedAccounts: this.setFetchedAccounts,
          accounts: this.state.accounts,
          setAccounts: this.setAccounts,
          showMobileWebBanner: this.state.showMobileWebBanner,
          setShowMobileWebBanner: this.setShowMobileWebBanner,
          userLoggedIn: this.state.userLoggedIn,
          setUserLoggedIn: this.setUserLoggedIn
        }
      }>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default ContextProvider;