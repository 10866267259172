import React, { useContext } from 'react';
import { useIntercom } from 'react-use-intercom';
import UserContext from '../userComponents/userContext';

export const useAnalytics = () => {
  const { trackEvent } = useIntercom();
  const { userLoggedIn } = useContext(UserContext);

  const track = (eventTitle, metadata = {}) => {
    if (!userLoggedIn) {
      console.warn('User is not logged into Intercom. Event not tracked.');
      return;
    }
    try {
      trackEvent(eventTitle, metadata);
    } catch (error) {
      console.error('Failed to track event:', error);
    }
  };

  return { track };
};