import React, { Fragment } from "react";
import MessageTabIcon from "../assets/messageTabIcon";
import PinTabIcon from "../assets/pinTabIcon";

export default function HerderTabsComponent({showMessages, setShowMessages, pinnedMessages}) {
  
  return(
    <Fragment>
      <div className="flex items-center gap-2 absolute left-0 bottom-0 mx-5">
        <button className={`flex items-center gap-1.5 p-2 rounded-t-md hover:bg-gray-100 border-b-2 border-transparent ${showMessages ? 'border-gray-900' : ''}`} onClick={() => setShowMessages(true)}>
          <MessageTabIcon classNames={'w-4 h-4'} isActive={showMessages}/>
          <div className="text-black text-xs font-medium">Messages</div>
        </button>
        {pinnedMessages?.length > 0 &&
          <button className={`flex items-center gap-1.5 p-2 rounded-t-md hover:bg-gray-100 border-b-2 border-transparent ${!showMessages ? 'border-gray-900' : ''}`} onClick={() => setShowMessages(false)}>
            <PinTabIcon classNames={'w-4 h-4'} isActive={!showMessages}/>
            <div className="text-black text-xs font-medium">Pins</div>
          </button>
        }
      </div>
    </Fragment>
  )
}