import React, { Fragment, useEffect, useState } from "react";
import CustomPopupContainerComponent from '../../../subRequestDetails/components/customPopupContainerComponent';
import LoadingIcon from '../../../subRequests/assets/loadingIcon';
import InfoIcon from '../../assets/infoIcon';
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { useAnalytics } from '../../../utils/analytics';
import { Switch } from '@headlessui/react'

export default function CreateCategoryForm(props) {
  const { categoryDate, showCreateCategory, closeCreateCategory, handleAlert, refetchTheCategories, user } = props
  const { track } = useAnalytics();
  const [ category, setCategory ] = useState(categoryDate || {})
  const [ formType, setFormType ] = useState(categoryDate?.id ? 'edit' : 'create')
  const [ btnDisabled, setBtnDisabled ] = useState(false);
  const [ addInNewDisabled, setAddInNewDisabled ] = useState(false);

  useEffect(() => {
    if (!['business_admin', 'account_owner'].includes(user?.role_name)){
      setAddInNewDisabled(true)
    }
  }, [ user?.role_name ])

  const handleCreateOrEditCategory = (e) => {
    e.preventDefault()
    setBtnDisabled(true)
    let url = formType === 'edit' ? `/api/v3/certification_categories/${category.id}` : '/api/v3/certification_categories'
    axiosRequest(url, formType === 'edit' ? 'PUT' : 'POST', category, 'data').then(result => {
      if(result.status === 200){
        handleAlert(true, result?.success, 'success')
        track('certification-category-created');
        setTimeout(() => {
          handleAlert(false, '', 'success')
          setBtnDisabled(false)
          refetchTheCategories(formType === 'create' ? 1 : null)
          closeCreateCategory(false)
        }, 3000)
      }else{
        handleAlert(true, result?.error, 'danger')
        setTimeout(() => {
          handleAlert(false, '', 'success')
          setBtnDisabled(false)
        }, 3000)
      }
    })
  }

  const handleChange = (e) => {
    let name = e.target.name
    if(name == 'expiration_date_required') {
      let checked = e.target.checked
      setCategory({
        ...category,
        [name]: checked
      })
    } else {
      let val = e.target.value
      setCategory({
        ...category,
        [name]: val
      })
    }
  }

  const handleCloseDeleteCategory = (data) => {
    if(btnDisabled) return
    closeCreateCategory(data)
  }

  const checkRequiredFields = () => {
    return((!category?.name || category?.name === ''))
  }

  const handleSwitch = (e, name) => {
    setCategory({
      ...category,
      [name]: !category?.[name]
    })
  };

  return(
    <Fragment>
      <CustomPopupContainerComponent show={showCreateCategory} close={handleCloseDeleteCategory} title={`${formType === 'edit' ? 'Edit' : 'Create'} Certification Category`} customWidth={'sm:max-w-3xl'}>
        <div className="text-left text-base font-medium text-gray-500 px-7 mt-3">
          Categories help define the types of certifications employees must submit
        </div>
        <div className="mx-7 mt-2">
          <div className='flex flex-col py-4'>
            <label htmlFor="Category-name" className="block text-left text-base font-medium text-gray-900">Category Name*</label>
            <div className='mt-2 sm:col-span-1'>
              <input type="text" className="border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-12" defaultValue={category?.name} onChange={(e) => handleChange(e)} name="name" placeholder="Enter a name for this category"/>
            </div>
          </div>
          <div className="text-left text-base font-medium text-gray-900 pt-4 border-t border-gray-300 pb-4">Notifications</div>
          <div className="flex flex-col gap-4 mb-4">
            <div className='flex items-start rounded-lg border border-gray-200 py-4 px-4 gap-4 shadow-sm'>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={'expiration_date_required'}
                  checked={category?.expiration_date_required || false}
                  name="expiration_date_required"
                  onChange={(e) => handleSwitch(e, 'expiration_date_required')}
                  className={`${category?.expiration_date_required ? 'bg-gray-900' : 'bg-gray-200'} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${category?.expiration_date_required ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </div>
              <label className="block text-base font-semibold text-gray-900 sm:mt-px text-left">
                Expiration Date Required
              </label>
            </div>
            <div className='flex sm:items-center items-start rounded-lg border border-gray-200 py-2.5 px-4 shadow-sm gap-4'>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={'certification_reminder'}
                  checked={false}
                  name='certification_reminder'
                  onChange={(e) => {}}
                  className={`${false ? 'bg-gray-900' : 'bg-gray-200'} cursor-not-allowed opacity-50 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${false ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </div>
              <div className='flex text-base font-semibold text-gray-900 sm:mt-px mr-1 text-left '>
                <div className='text-base inline mr-3 text-left'>Send a reminder to submit this certification every
                <input
                  type="number"
                  className={`inline mx-3 shadow-sm border focus:ring-indigo-500 focus:border-indigo-500 block w-9 h-9 pl-3 sm:text-sm border-gray-300 rounded-md text-center cursor-not-allowed opacity-50`}
                  name='additionalMonths'
                  value={1}
                  min={0}
                  max={5}
                  onChange={(e) => {}}
                  onKeyDown={(e) => {}}
                  onBlur={(e) => {}}
                  disabled={true}
                />
                <div className='text-base inline mr-1'>days</div>
                <InfoIcon classNames={'inline shrink-0 w-5 h-5'}/>
                </div>
              </div>
            </div>
            <div className='flex items-start rounded-lg border border-gray-200 py-4 px-4 shadow-sm'>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={'expiration_reminder'}
                  checked={false}
                  name='expiration_reminder'
                  onChange={(e) => {}}
                  className={`${false ? 'bg-gray-900' : 'bg-gray-200'} cursor-not-allowed opacity-50 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${false ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </div>
              <div className="flex">
                <div className="inline text-base font-semibold text-gray-900 sm:mt-px ml-4 mr-1 text-left">
                  Send expiration reminders
                  <InfoIcon classNames={'inline shrink-0 w-5 h-5 ml-1'}/>
                </div>
                
              </div>
            </div>
          </div>
          <div className="text-left text-base font-medium text-gray-900 pt-4 border-t border-gray-300 pb-4">Assigning to Users</div>
          <div className="flex flex-col gap-4 mb-4">
            <div className='flex items-start rounded-lg border border-gray-200 py-4 px-4 gap-4 shadow-sm'>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={'add_in_existing_users'}
                  checked={category?.add_in_existing_users}
                  name='add_in_existing_users'
                  onChange={(e) => {}}
                  className={`${category?.add_in_existing_users ? 'bg-gray-900' : 'bg-gray-200'} cursor-not-allowed opacity-50 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${category?.add_in_existing_users ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </div>
              <label className="block text-base font-semibold text-gray-900 sm:mt-px text-left">
                Add this category to existing active users
              </label>
            </div>
            <div className='flex items-start rounded-lg border border-gray-200 py-4 px-4 gap-4 shadow-sm'>
              <div className='mt-1 sm:mt-0 sm:col-span-2 flex gap-2 items-center'>
                <Switch
                  id={'add_in_new_users'}
                  checked={category?.add_in_new_users}
                  name='add_in_new_users'
                  onChange={(e) => handleSwitch(e, 'add_in_new_users')}
                  className={`${category?.add_in_new_users ? 'bg-gray-900' : 'bg-gray-200'} ${addInNewDisabled ? 'cursor-not-allowed opacity-50' : ''} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none`}
                  disabled={addInNewDisabled}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${category?.add_in_new_users ? 'translate-x-5' : 'translate-x-0'} pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
                  />
                </Switch>
              </div>
              <label className="block text-base font-semibold text-gray-900 sm:mt-px text-left">
                Automatically add this category to new users
              </label>
            </div>
          </div>
          <div className='flex mt-4 gap-3 justify-end pt-6 pb-4 border-t border-gray-300'>
            <div className="flex gap-3 items-center flex-wrap">
              <button className={`bg-white hover:bg-gray-100 sm:text-base text-sm items-center rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-2 cursor-pointer ${(btnDisabled) ? 'cursor-not-allowed opacity-50' : ''}`} onClick={() => handleCloseDeleteCategory(false)} disabled={btnDisabled}>Cancel</button>
              <button className={`flex bg-dark-blue hover:bg-gray-800 sm:text-base text-sm text-white inline-block px-6 py-2 border shadow rounded-lg relative  ${(checkRequiredFields() || btnDisabled) ? 'cursor-not-allowed opacity-50' : ''}`} onClick={(e) => handleCreateOrEditCategory(e)} disabled={checkRequiredFields() || btnDisabled}>
                {btnDisabled &&
                  <div className="absolute top-0 bottom-0 left-1.5 flex items-center justify-center">
                    <LoadingIcon classNames={'animate-spin h-4 w-4 text-white'}/>
                  </div>
                }
                Submit
              </button>
            </div>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}