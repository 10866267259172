import React, { Fragment, useState } from "react";
import SendIcon from './../../../../assets/sendIcon';
import ReactTooltip from "react-tooltip";
import { axiosGetRequest } from './../../../../helpers/helpers'

export default function ResendButtonComponent(props) {
  const { event, handleAlert, handleReloadData, sentBucket, defaultDisabled = false } = props
  const [ disableResendButton, setDisableResendButton ] = useState(false)

  const handleResendInvite = () => {
    setDisableResendButton(true)
    let url = `/api/v2/resend_invites?id=${event?.id}&inst_id=&gql_event_details=true&bucket_type=${sentBucket}`
    axiosGetRequest(url).then((result) => {
      if(Object.keys(result).includes('success')){
        handleAlert(true, result?.success, 'success');
      }else{
        handleAlert(true, result?.error, 'danger');
      }
      setDisableResendButton(false);
      setTimeout(() => {
        handleAlert(false, '', ''); 
        handleReloadData(result?.event);
      }, 3000)
    })
  }

  return(
    <Fragment>
      <button className={`flex relative z-20 justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8 ${(disableResendButton || defaultDisabled) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleResendInvite()} data-tip data-for={`resend_button_`+event?.id.toString()} disabled={disableResendButton || defaultDisabled}>
        <SendIcon classNames={'wc-14 hc-14 transform -rotate-45 mb-0.5 ml-0.5'}/>
        <ReactTooltip className='max-w-xs whitespace-nowrap' id={`resend_button_`+event?.id.toString()} place="top" effect="solid">
          Resend
        </ReactTooltip>
      </button>
    </Fragment>
  )
}