import React, { Fragment, useState } from "react";
import { Menu, Transition } from '@headlessui/react'
import { AngleIcon } from './../../../../assets';
import { axiosRequest, axiosGetRequest, handleAddExtraPadding, handleRemoveExtraPadding } from './../../../../helpers/helpers';
import InstructorDetailComponent from './../../cardViewComponents/cardComponent/instructorDetailComponent';
import MultipleClassesMessageComponent from '../../../../../subRequestCreate/components/secondStepComponents/multipleClassesMessageComponent';
import { formattedErrorMessage } from '../../../../../subRequestCreate/helpers';

export default function AcceptedUserComponent(props) {
  const { event, handleAlert, handleCloseAndReloadData, eventsCount, index, sentBucket, defaultDisabled = false } = props
  const [ disableRemoveButton, setDisableRemoveButton ] = useState(false)
  const [ showInstructorDetail, setShowInstructorDetail ] = useState(false)
  const [ userToShowDetails, setUserToShowDetails ] = useState({})
  const [ isOpen, setIsOpen ] = useState(false);
  const [ acceptedUsers, setAcceptedUsers] = useState([])
  const [ loader, setLoader ] = useState(false)
  const [ firstApiCall, setFirstApiCall ] = useState(true)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorVideoLink, setErrorVideoLink] = useState('')

  const handleShowInstructorDetails = (user) => {
    setUserToShowDetails(user)
    setShowInstructorDetail(true)
  }

  const handleCloseInstructorDetails = () => {
    setShowInstructorDetail(false)
    setUserToShowDetails({})
  }

  const handleApprove = (acceptedUserId) => {
    setDisableRemoveButton(true)
    let url = `/api/v2/sub_requests/update_status`
    let params = {
      status: 'confirm',
      event_id: event?.id,
      inst_id: acceptedUserId,
      gql_event_details: true,
      bucket_type: sentBucket
    }
    axiosRequest(url, 'PATCH', params, 'data').then((res) => {
      if(Object.keys(res).includes('status') && res?.status === 200){
        handleAlert(true, 'Status Updated', 'success');
        setDisableRemoveButton(false)
        setTimeout(() => {
          handleAlert(false, '', ''); 
          handleCloseAndReloadData(res?.event);
        }, 3000)
      }else{
        if(res?.video_link && res?.video_link !== '') {
          setShowErrorPopup(true)
          setErrorMessage(formattedErrorMessage(res?.error))
          setErrorVideoLink(res?.video_link)
          if(res?.event) handleCloseAndReloadData(res?.event);
        }else{
          handleAlert(true, res?.error, 'danger');
          setDisableRemoveButton(false)
          setTimeout(() => {
            handleAlert(false, '', ''); 
            if(res?.event) handleCloseAndReloadData(res?.event);
          }, 3000)
        }
      }
    })
  }

  const setRef = node => {
    if (node) {
      setIsOpen(true)
      handleAddExtraPadding(index, eventsCount, event?.accepted_users)
    }else if (node === null) {
      setIsOpen(false)
      handleRemoveExtraPadding()
    }
  };

  const fetchAcceptedUsers = (event) => {
    if(!firstApiCall || loader) return;

    let url = `/api/v3/business_events/${event?.id}/accepted_users`
    setLoader(true)

    axiosGetRequest(url).then((result) => {
      setFirstApiCall(false)
      setLoader(false)
      if(result?.status == 200){
        setAcceptedUsers(result?.users)
      }else{
        handleAlert(true, result?.error, 'danger');
      }
      setTimeout(() => {
        handleAlert(false, '', ''); 
      }, 3000)
    })
  }

  const closeErrorPopup = (data) => {
    setShowErrorPopup(data)
    setErrorMessage('')
    setErrorVideoLink('')
    setDisableRemoveButton(false)
  }

  return(
    <Fragment>
      {showInstructorDetail &&
        <InstructorDetailComponent showInstructorDetail={showInstructorDetail} detailInstructor={userToShowDetails} close={handleCloseInstructorDetails} handleAlert={handleAlert}/>
      }
      {showErrorPopup &&
        <MultipleClassesMessageComponent showMultipleClassPopup={showErrorPopup} closeMultipleClassPopup={closeErrorPopup} message={''} showPopupWithError={showErrorPopup} showErrorMessage={errorMessage} errorVideoLink={errorVideoLink}/>
      }
      <Menu as="div" className="relative block text-left">
        <Menu.Button className={`flex relative z-20 justify-start items-center w-full text-xs text-gray-500 font-medium gap-1 custom-blue-200 whitespace-nowrap ${(defaultDisabled) ? 'cursor-not-allowed' : ''}`} onClick={() => fetchAcceptedUsers(event)} disabled={defaultDisabled}>
          See who accepted
          <AngleIcon classNames={`ml-1 text-gray-800 hc-14 wc-14 transform ${isOpen ? 'rotate-180' : ''}`}/>
        </Menu.Button>
        <Transition
          as={Fragment}
          // show={true}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 rounded-xl bg-white focus:outline-none mt-1 w-56 shadow-md border-t border-gray-100 absolute">
            {loader &&
              <div className="flex items-center right-0 justify-center p-2">
                <svg className="animate-spin h-4 w-4 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </div>
            }
            {!loader &&
              <div className="p-2 max-h-40 overflow-y-auto">
                <div className="flex text-gray-900 mb-2 gap-2">
                  <div className='bg-pending-event w-2 h-4 rounded-sm'></div>
                  <div className='text-xs font-semibold'>{`Accepted (${acceptedUsers?.length})`}</div>
                </div>
                {acceptedUsers?.map((usr, i) => (
                  <div key={i} className="flex justify-between items-center mb-2">
                    <div>
                      <button className='flex gap-2 items-center' onClick={() => handleShowInstructorDetails(usr)}>
                        <img src={usr?.image} className='w-4 h-4 rounded-full bg-gray-200' />
                        <div className='tc-10 font-semibold custom-blue-200'>{usr?.full_name}</div>
                      </button>
                      <div className="tc-10 font-medium text-neutral-600">{usr?.accepted_at}</div>
                    </div>
                    {event?.canApprove &&
                      <button className={`tc-11 flex items-center justify-center border-green-custom rounded-2xl h-7 gap-0.5 px-5 text-green-c ${(disableRemoveButton) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => handleApprove(usr?.sub_request_id)} disabled={disableRemoveButton}>
                        Approve
                      </button>
                    }
                  </div>
                ))}
              </div>
            }
          </Menu.Items>
        </Transition>
      </Menu>
    </Fragment>
  )
}