import React from 'react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { Alert } from 'react-bootstrap'
import Cookies from 'js-cookie';
//import { getSudomain, setTitle, timeZones } from './../../../helpers'
import { getDaysIndex, getSudomain, saveAvailabilityDays, saveAvailabilityNote, savePermanentAdditionalInfo, getPreferredLocations, snakeCase, getName } from './helpers.js'
import TimePicker from 'react-bootstrap-time-picker';
import {
  Link, 
  Redirect
} from "react-router-dom";
import { setTitle } from './../../helpers.js'
import { SearchIcon, ChevronDownIcon } from '@heroicons/react/solid'
import Switch from "react-switch";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class AvailabilityEdit extends React.Component  {
  constructor(props){
    super(props);
    this.state = {
      day_index: "",
      note: "",
      alert_message: "",
      alert_type: "",
      show: false,
      time_from: "",
      time_to: "",
      start_time: "00:00",
      end_time: "23:59",
      start_to_time: "00:00",
      editNote: false,
      check_time: "",
      disable: false,
      hour_range: [],
      to_hour_range: [],
      loaded: false,
      extra_note: "",
      showAvailability: 'subbing',
      editPermanentAdditionInfo: false,
      permanentPreferredLocations: [],
      permanentPreferredWeeklyClasses: 0,
      permanentNote: '',
      permanentAddiInfoData: {},
      redirectToAvailability: false,
      preferredResionsLocations: {},
      allLocations: [],
      selectedLocations: [],
      selectedRegions: [],
      showFilterOther: true,
      selectedRegLocToShow: [],
      showOthers: true,
      permanentPreferredWeeklyClassesArray: Array.apply(null, {length: 26}).map(Number.call, Number),
      disabledSaveAvailableBtn: false,
      disabledSaveAdditionalInfo: false
    }
  }

  componentDidMount = () => {
    this.checkAvailability()
    if(this.props.pageLoaded){
      this.getBusinessHour(this.props.business?.business_hours)
      this.getRegionLocations()
      // this.getDays()
    }
    setTitle('edit_availability')
    this.props.setCurrent('Availability')
  }

  checkAvailability = () => { 
    const { business, user } = this.props   
    if (Object.keys(business).length != 0) {
      if(business.availability_feature === false){
        let role = snakeCase(user?.role)        
        if(role){
          let url = `/my-requests`
          window.location.href = url
        }        
      }
    }
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    };
  }

  sortFromRange = (prevToTime, currentToTime) => {
    let prevToTime_index = this.state.hour_range.indexOf(prevToTime)
    let currentToTime_index = this.state.hour_range.indexOf(currentToTime)
    let check = []
    for (let i = prevToTime_index;i < this.state.hour_range?.length; i++){
      if(i < currentToTime_index){  
        check.push(this.state.hour_range[i])
      }
    }
    return check
  }

  sortToRange = (currentFromTime, nextFromTime) => {
    let currentFromTime_index = this.state.hour_range.indexOf(currentFromTime)
    let nextFromTime_index = this.state.hour_range.indexOf(nextFromTime)
    let check = []
    for (let i = currentFromTime_index +1;i < this.state.hour_range?.length; i++){
      if(i <= nextFromTime_index){
        check.push(this.state.hour_range[i])
      }
    }
    return check
  }

  getDays = () => {
    let files = location.pathname?.split('/')
    let file = files.pop()
    this.setState({showAvailability: file})
    getDaysIndex(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        let content = file === 'permanent' ? result.permanent_data : result.data
        for(let i = 0; i < content?.length; i++){
          for(let j = 0; j < content[i].availability_slots_attributes?.length; j++){
            if(j === 0){
              if(j === content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[j] = Object.assign({
                  id: content[i].availability_slots_attributes[j]?.id,
                  accepted: content[i].availability_slots_attributes[j]?.accepted,
                  time_from: content[i].availability_slots_attributes[j].time_from,
                  time_to: content[i].availability_slots_attributes[j].time_to,
                  from_range: this.sortFromRange(this.state.time_from, content[i].availability_slots_attributes[j].time_to),
                  to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.time_to)
                })
              }
              else{
                content[i].availability_slots_attributes[j] = Object.assign({
                  id: content[i].availability_slots_attributes[j]?.id,
                  accepted: content[i].availability_slots_attributes[j]?.accepted,
                  time_from: content[i].availability_slots_attributes[j].time_from,
                  time_to: content[i].availability_slots_attributes[j].time_to,
                  from_range: this.sortFromRange(this.state.time_from, content[i].availability_slots_attributes[j].time_to),
                  to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, content[i].availability_slots_attributes[j+1].time_from)
                })
              }
            }
            else{
              if(j === content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[j] = Object.assign({
                  id: content[i].availability_slots_attributes[j]?.id,
                  accepted: content[i].availability_slots_attributes[j]?.accepted,
                  time_from: content[i].availability_slots_attributes[j].time_from,
                  time_to: content[i].availability_slots_attributes[j].time_to,
                  from_range: this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to),
                  to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.time_to)
                })
              }
              else{
                content[i].availability_slots_attributes[j] = Object.assign({
                  id: content[i].availability_slots_attributes[j]?.id,
                  accepted: content[i].availability_slots_attributes[j]?.accepted,
                  time_from: content[i].availability_slots_attributes[j].time_from,
                  time_to: content[i].availability_slots_attributes[j].time_to,
                  from_range: this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to),
                  to_range: this.sortToRange(content[i].availability_slots_attributes[j].time_from, content[i].availability_slots_attributes[j+1].time_from)
                })
              }
            }
          }
          let help = this.sortToRange(this.state.time_from, this.state.time_to)

          if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
            content[i] = Object.assign({ id: content[i].id, day: content[i].day, available: content[i].available, availability_slots_attributes: content[i].availability_slots_attributes, disable : true
              })
          }
          else{
            content[i] = Object.assign({ id: content[i].id, day: content[i].day, available: content[i].available, availability_slots_attributes: content[i].availability_slots_attributes, disable : false
              })
          }

          let fromHelp = this.sortFromRange(this.state.time_from, this.state.time_to)
          
          if(content[i].availability_slots_attributes[0].time_from === this.state.time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
            content[i] = Object.assign({ id: content[i].id, day: content[i].day, available: content[i].available, availability_slots_attributes: content[i].availability_slots_attributes, disable : content[i].disable, prev_disable: true
              })
          }
          else{
            content[i] = Object.assign({ id: content[i].id, day: content[i].day, available: content[i].available, availability_slots_attributes: content[i].availability_slots_attributes, disable : content[i].disable, prev_disable: false
              })
          }
        }
        this.setState({
          day_index: content,
          extra_note: result.note,
          note: result.note,
          permanentPreferredLocations: result.permanent_notes.preferred_locations,
          permanentPreferredWeeklyClasses: result.permanent_notes.preferred_classes,
          permanentNote: result.permanent_notes.permanent_notes,
          permanentAddiInfoData: result.permanent_notes,
          selectedLocations: result.permanent_notes.preferred_locations,
          loaded: true
        })
        this.showRegionLocation(result.permanent_notes.preferred_locations)
        localStorage.setItem("addiInfoData", JSON.stringify(result.permanent_notes));
        this.setSelectedLocations(this.state.preferredResionsLocations, result.permanent_notes.preferred_locations)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger'
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },3000)
      });
    })

  }

  getRegionLocations =() => {
    getPreferredLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(response => response.json()
    ).then(result => {
      if(result.status === 200){
        this.setState({
          preferredResionsLocations: result.regions_and_locations,
          showFilterOther: result.show_others
        })
        this.getDays()
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.getDays()
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })
  }

  dayName = (str) => {
   var splitStr = str.toLowerCase().split(' ');
   for (var i = 0; i < splitStr.length; i++) {
     splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
   }
   return splitStr.join(' '); 
  }

  handleChange = (e, day) => {
    let content = this.state.day_index;
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        content[i].available = e.target.checked
        if (e.target.checked){
          if ((this.state.showAvailability === 'permanent' && this.props.business?.block_permanent_availability) || (this.state.showAvailability === 'subbing' && this.props.business?.block_subbing_availability)){
            for (let j = 0; j < content[i].availability_slots_attributes?.length; j++){
              content[i].availability_slots_attributes[j].accepted = e.target.checked
            }
          }
        }
      }
    }
    this.setState({
      day_index: content
    })
  }

  getBusinessHour = (business_hours) => {
    if(business_hours && business_hours !== "null"){
      let business_hour_content = this.props.business?.business_hours
      let businessHour = business_hour_content//JSON.parse(business_hour_content)
      //console.log(businessHour)
      let time_from = businessHour?.time_from.split(" ")
      let time_to = businessHour?.time_to.split(" ")

      let start_time = ""
      let end_time = ""
      if(time_from[1] === "AM"){
        let time_from_split_hour_min = time_from[0].split(":")
        let time_from_hour = time_from_split_hour_min[0]
        if(Number(time_from_hour) == 12){
          start_time = "00:"+time_from_split_hour_min[1]
        }
        else{
          start_time = time_from[0]
        }
      }
      else if(time_from[1] === "PM"){
        let time_from_full_time = time_from[0].split(":")
        if(time_from_full_time[0] < 12){
          let time_from_large_hour = Number(time_from_full_time[0]) + 12
          start_time = time_from_large_hour.toString()+":"+time_from_full_time[1]
        }
      }
      if(time_to[1] === "AM"){
        end_time = time_to[0]
      }
      else if(time_to[1] === "PM"){
        let time_to_full_time = time_to[0].split(":")
        if(time_to_full_time[0] < 12){
          let time_to_large_hour = Number(time_to_full_time[0]) + 12
          end_time = time_to_large_hour.toString()+":"+time_to_full_time[1]
        }
      }
      this.setState({
        time_from: businessHour?.time_from,
        time_to: businessHour?.time_to,
        start_time: start_time,
        end_time: end_time,
        check_time: end_time,
      })
      this.getBusinessRange(businessHour)
    }
  }

  getBusinessRange = (businessHour) => {
    let start_time = businessHour?.time_from
    let end_time = businessHour?.time_to
    let start_time_ampm = start_time.split(" ")
    let end_time_ampm = end_time.split(" ")
    let start_time_hours = start_time_ampm[0]
    let end_time_hours = end_time_ampm[0]
    let large_start_time_hours = ""
    let large_end_time_hours = ""
    if(start_time_ampm[1] === "PM"){
      if(start_time_hours.split(":")[0] !== "12"){
        large_start_time_hours = (Number(start_time_hours.split(":")[0]) + 12).toString()+":"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }
    else{
      if(start_time_hours.split(":")[0] === "12"){
        large_start_time_hours = "0:"+start_time_hours.split(":")[1]
      }
      else{
        large_start_time_hours = start_time_hours
      }
    }

    if(end_time_ampm[1] === "PM"){
      if(end_time_hours.split(":")[0] !== "12"){
        large_end_time_hours = (Number(end_time_hours.split(":")[0]) + 12).toString()+":"+end_time_hours.split(":")[1]
      }
      else{
          large_end_time_hours = end_time_hours
      }
    }
    else{
        if(end_time_hours.split(":")[0] === "12"){
          large_end_time_hours = "0:"+end_time_hours.split(":")[1]
        }
        else{
          large_end_time_hours = end_time_hours
        }
      }

    let start_hour_min = large_start_time_hours.split(":")
    let end_hour_min = large_end_time_hours.split(":")
    let start_hour = Number(large_start_time_hours.split(":")[0])
    let end_hour = Number(large_end_time_hours.split(":")[0])
    let row_range = []
    for (;start_hour <= end_hour; start_hour++){
      if(start_hour < 12){
        if(start_hour === 0){
          row_range.push("12:00 AM")
          row_range.push("12:15 AM")
          row_range.push("12:30 AM")
          row_range.push("12:45 AM")
        }
        else{
          row_range.push(start_hour.toString()+":00 AM")
          row_range.push(start_hour.toString()+":15 AM")
          row_range.push(start_hour.toString()+":30 AM")
          row_range.push(start_hour.toString()+":45 AM")
        }
      }
      else{
        if(start_hour > 12){
          row_range.push((start_hour-12).toString()+":00 PM")
          row_range.push((start_hour-12).toString()+":15 PM")
          row_range.push((start_hour-12).toString()+":30 PM")
          row_range.push((start_hour-12).toString()+":45 PM")
        }
        else{
          row_range.push(start_hour.toString()+":00 PM")
          row_range.push(start_hour.toString()+":15 PM")
          row_range.push(start_hour.toString()+":30 PM")
          row_range.push(start_hour.toString()+":45 PM")
        }
      }
    }
    for(let i = 0; i < 4; i++){
      if(start_time !== row_range[0]){
        row_range.shift();
      }else{
        break;
      }
    }
    for(let i = 0; i < 4; i++){
      if(end_time !== row_range[row_range?.length-1]){
        row_range.pop();
      }else{
        break;
      }
    }
    
    this.setState({
      hour_range: row_range,
      to_hour_range: row_range
    });
  }

  getTimeFrom = (time) =>{
    let changeTime = time//JSON.parse(time);
    return changeTime.time_from
  }

  getTimeTo = (time) => {
    let changeTime = time//JSON.parse(time);
    return changeTime.time_to
  }

  handleAdd = (e, day) => {
    let content = this.state.day_index;
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        let help = this.sortToRange(this.state.time_from, this.state.time_to)

        if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to !== this.state.time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].time_to !== help[help?.length-2]){

          content[i].availability_slots_attributes.push({
            time_from: this.sortFromRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to, this.state.time_to)[1], 
            time_to: this.state.time_to, 
            from_range: this.sortFromRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to, this.state.time_to),
            to_range: this.sortToRange(this.sortFromRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to, this.state.time_to)[1], this.state.time_to)
          })
          if(content[i].availability_slots_attributes?.length === 2){
            content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].to_range = this.sortToRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].time_from, content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_from)

          }
          else {
            content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].from_range = this.sortFromRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-3].time_to, content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].time_to)

            content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].to_range = this.sortToRange(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-2].time_from, content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_from)
          }
          

          if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
            content[i].disable = true
          }
          else{
            content[i].disable = false
          }
        }
        else{
            content[i].disable = true
          }
      }
    }
    this.setState({
      day_index: content
    })
  }

  handleAddPrevSlot = (e, day) => {
    let content = this.state.day_index;
    let holdSlot = []
    let actualData = []
    let help = this.sortToRange(this.state.time_from, this.state.time_to)
    let fromHelp = this.sortFromRange(this.state.time_from, this.state.time_to)
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){

        if(content[i].availability_slots_attributes[0].time_from !== this.state.time_from || content[i].availability_slots_attributes[0].time_from !== fromHelp[1]){

          holdSlot.push({
            time_from: this.state.time_from, 
            time_to: this.sortToRange(this.state.time_from, content[i].availability_slots_attributes[0].time_from)[this.sortToRange(this.state.time_from, content[i].availability_slots_attributes[0].time_from)?.length-2], 
            from_range: this.sortFromRange(this.state.time_from, this.sortToRange(this.state.time_from, content[i].availability_slots_attributes[0].time_from)[this.sortToRange(this.state.time_from, content[i].availability_slots_attributes[0].time_from)?.length-2]),
            to_range: this.sortToRange(this.state.time_from, content[i].availability_slots_attributes[0].time_from)
          })
          content[i].availability_slots_attributes[0].from_range = this.sortFromRange(holdSlot[0].time_to, content[i].availability_slots_attributes[0].time_to)
          actualData = [...holdSlot, ...content[i].availability_slots_attributes]
          content[i].availability_slots_attributes = actualData

          if(content[i].availability_slots_attributes[0].time_from === this.state.time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
            content[i].prev_disable = true
          }
          else{
            content[i].prev_disable = false
          }
        }
        else{
            content[i].prev_disable = true
          }
      }
    }
    this.setState({
      day_index: content
    })
  }

  deleteSlot = (e,key, day) => {
    let content = this.state.day_index;
    let new_hours = {time_from: this.state.new_time_from, time_to: this.state.new_time_to}
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        if(content[i].availability_slots_attributes?.length > 1){
          //console.log(content[i].availability_slots_attributes?.length)
          
           if(key < content[i].availability_slots_attributes?.length-1){
            if(key === 0){
              content[i].availability_slots_attributes[key+1].from_range = this.sortFromRange(this.state.time_from, content[i].availability_slots_attributes[key+1].time_to)
            }
            else{
              if(key === content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[key-1].to_range = this.sortToRange(content[i].availability_slots_attributes[key-1].time_from, this.state.time_to)
              }
              else{
                content[i].availability_slots_attributes[key-1].to_range = this.sortToRange(content[i].availability_slots_attributes[key-1].time_from, content[i].availability_slots_attributes[key+1].time_from)
                content[i].availability_slots_attributes[key+1].from_range = this.sortFromRange(content[i].availability_slots_attributes[key-1].time_to, content[i].availability_slots_attributes[key+1].time_to)
              }
            }
          }
          else{
            content[i].availability_slots_attributes[key-1].to_range = this.sortToRange(content[i].availability_slots_attributes[key-1].time_from, this.state.time_to)
          }
          content[i].availability_slots_attributes.splice(key, 1);
          
          let help = this.sortToRange(this.state.time_from, this.state.time_to)
          
          if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
            content[i].disable = true
          }
          else{
            content[i].disable = false
          }

          let fromHelp = this.sortFromRange(this.state.time_from, this.state.time_to)
          
          if(content[i].availability_slots_attributes[0].time_from === this.state.time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
            content[i].prev_disable = true
          }
          else{
            content[i].prev_disable = false
          }
        }
        else if(content[i].availability_slots_attributes?.length === 1){
          //console.log(content[i].availability_slots_attributes?.length)
          content[i].available = false
        }
      }
    }
    this.setState({
      day_index: content
    })
  }

  saveSlots = (e, type) => {
    this.setState({disabledSaveAvailableBtn: true})
    saveAvailabilityDays(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.day_index, type).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        localStorage.setItem("startingURL", this.state.showAvailability);
        this.setState({
          alert_message: 'Successfully Edited',
          alert_type: 'success',
          show: true,
          redirectToAvailability: true
        })
        // window.location.href = "/availability"
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
      this.setState({disabledSaveAvailableBtn: false})
    })
  }

  handleFrom = (e, day, key) => {
    let content = this.state.day_index;
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        for (let j = 0; j < content[i].availability_slots_attributes?.length; j++){
          if(key === j){
            if(j === 0){
              if(j === content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[j].time_from = e.target.value
                content[i].availability_slots_attributes[j].to_range = this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.time_to)
              }
              else{
                content[i].availability_slots_attributes[j].time_from = e.target.value
                content[i].availability_slots_attributes[j].to_range = this.sortToRange(content[i].availability_slots_attributes[j].time_from, content[i].availability_slots_attributes[j+1].time_from)
              }
            }
            else{
              if(j < content[i].availability_slots_attributes?.length-1 ){
                content[i].availability_slots_attributes[j].time_from = e.target.value

                //content[i].availability_slots_attributes[j].time_to = this.sortToRange(content[i].availability_slots_attributes[j].time_from, tcontent[i].availability_slots_attributes[j+1].time_from)[0]

                content[i].availability_slots_attributes[j].to_range = this.sortToRange(content[i].availability_slots_attributes[j].time_from, content[i].availability_slots_attributes[j+1].time_from)
                content[i].availability_slots_attributes[j-1].to_range = this.sortToRange(content[i].availability_slots_attributes[j-1].time_from, content[i].availability_slots_attributes[j].time_from)
              }
              else{
                content[i].availability_slots_attributes[j].time_from = e.target.value
                let check_to_time = this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.time_to)
                if(!check_to_time.includes(content[i].availability_slots_attributes[j].time_to)){
                  
                  content[i].availability_slots_attributes[j].time_to = this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.time_to)[0]
                }
                content[i].availability_slots_attributes[j-1].to_range = this.sortToRange(content[i].availability_slots_attributes[j-1].time_from, content[i].availability_slots_attributes[j].time_from)
                content[i].availability_slots_attributes[j].to_range = this.sortToRange(content[i].availability_slots_attributes[j].time_from, this.state.time_to)

              }
            }
          }
        }
        let help = this.sortToRange(this.state.time_from, this.state.time_to)

        if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
          content[i].disable = true
        }
         else{
           content[i].disable = false
         }
        
        let fromHelp = this.sortFromRange(this.state.time_from, this.state.time_to)
          
        if(content[i].availability_slots_attributes[0].time_from === this.state.time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
          content[i].prev_disable = true
        }
        else{
          content[i].prev_disable = false
        }
      }
    }
    this.setState({
      day_index: content
    })

  }

  handleTo = (e, day, key) => {
    let content = this.state.day_index;
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        for (let j = 0; j < content[i].availability_slots_attributes?.length; j++){
          if(key === j){
            if(j === 0){
              if(j === content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[j].time_to = e.target.value
                content[i].availability_slots_attributes[j].from_range = this.sortFromRange(this.state.time_from, content[i].availability_slots_attributes[j].time_to)
              }
              else{
                content[i].availability_slots_attributes[j].time_to = e.target.value
                content[i].availability_slots_attributes[j].from_range = this.sortFromRange(this.state.time_from, content[i].availability_slots_attributes[j].time_to)
                content[i].availability_slots_attributes[j+1].from_range = this.sortFromRange(content[i].availability_slots_attributes[j].time_to, content[i].availability_slots_attributes[j+1].time_to)
              }
            }
            else{
              if(j < content[i].availability_slots_attributes?.length-1){
                content[i].availability_slots_attributes[j].time_to = e.target.value
                content[i].availability_slots_attributes[j].from_range = this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to)
                content[i].availability_slots_attributes[j+1].from_range = this.sortFromRange(content[i].availability_slots_attributes[j].time_to, content[i].availability_slots_attributes[j+1].time_to)
              }
              else{
                content[i].availability_slots_attributes[j].time_to = e.target.value
                content[i].availability_slots_attributes[j].from_range = this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to)
              }
            }
          }
          else if(j > key){
            content[i].availability_slots_attributes[j].from_range = this.sortFromRange(content[i].availability_slots_attributes[j-1].time_to, content[i].availability_slots_attributes[j].time_to)          }
        }
        let help = this.sortToRange(this.state.time_from, this.state.time_to)

        if(content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === this.state.time_to || content[i].availability_slots_attributes[content[i].availability_slots_attributes?.length-1].time_to === help[help?.length-2]){
          content[i].disable = true
        }
        else{
          content[i].disable = false
        }

        let fromHelp = this.sortFromRange(this.state.time_from, this.state.time_to)
          
        if(content[i].availability_slots_attributes[0].time_from === this.state.time_from || content[i].availability_slots_attributes[0].time_from === fromHelp[1]){
          content[i].prev_disable = true
        }
        else{
          content[i].prev_disable = false
        }
      }
    }
    this.setState({
      day_index: content
    })
  }

  handleEditNotes = (e) => {
    this.setState({editNote: true})
  }

  handleCancelNotes = (e) => {
    this.setState({
      editNote: false,
      note: this.state.extra_note
    })
  }

  handleChangeText = (e) => {
    this.setState({note: e.target.value})
  }

  saveNotes = (e) => {
    saveAvailabilityNote(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.note).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Successfully Edited',
          alert_type: 'success',
          editNote: false,
          show: true,

        })
        this.getDays()
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })
  }

  handlePermanentAdditionInfo = () => {
    this.setState({editPermanentAdditionInfo: true})
  }

  handleCancelPermanentAdditionInfo = () => {
    let permanent_notes = JSON.parse(localStorage.getItem("addiInfoData"));// this.state.permanentAddiInfoData
    this.setState({
      editPermanentAdditionInfo: false,
      permanentPreferredLocations: permanent_notes.preferred_locations,
      permanentPreferredWeeklyClasses: permanent_notes.preferred_classes,
      permanentNote: permanent_notes.permanent_notes,
      selectedLocations: permanent_notes.preferred_locations
    })
    this.setSelectedLocations(this.state.preferredResionsLocations, permanent_notes.preferred_locations)
  }

  handleChangePermanentAdditionalInfo = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({
      [name]: value
    })
  }

  handleKeyDownPermanentPreferredWeeklyClasses = (e) => {
    e.preventDefault();
  }

  handleCancelSlotsEditing = (e) => {
    localStorage.setItem("startingURL", this.state.showAvailability);
    localStorage.removeItem("addiInfoData");
    this.setState({
      redirectToAvailability: true
    })
  }

  performSavePermanentAdditionalInfo = (e) => {
    this.setState({disabledSaveAdditionalInfo: true})
    let data = {
      preferred_classes: this.state.permanentPreferredWeeklyClasses,
      preferred_locations: this.state.selectedLocations,
      permanent_class_notes: this.state.permanentNote
    }

    savePermanentAdditionalInfo(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), data).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Successfully Edited',
          alert_type: 'success',
          show: true,
          loaded: false,
          editPermanentAdditionInfo: false,
          disabledSaveAdditionalInfo: false
        })
        this.getDays()
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          disabledSaveAdditionalInfo: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })

  }

  setSelectedLocations = (regions, locations) => {
    let selectLocations = locations
    let all_locations = []
    for(var i=0; i<regions.length;i++){     
      let selected_locations = [] 
      for(var j=0; j<regions[i]['locations'].length; j++){
        //if(regions[i]['locations'][j]['checked'] === true){
          all_locations.push({id: regions[i]['locations'][j]['id'], name: regions[i]['locations'][j]['name']})
        //}
        if(selectLocations.includes(regions[i].locations[j].id)){
          selected_locations.push(regions[i].locations[j].id)
        }
      }
      // if(selected_locations?.length > 0){
        this.checkRegionVisibility(regions[i], selected_locations)
      // }
    }
    this.setState({
      allLocations: all_locations,
    })
  }

  locationName = () => {
    let locations = this.state.allLocations
    let selected_locations = this.state.selectedLocations
    let locationName = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationName.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return locationName.join(", ")
  }

  selectRegions = (e, region) => {
    let val = e.target.value
    let regions = this.state.selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        selected_locations = Array.prototype.push.apply(selected_locations, final_result)
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          selectedLocations: selected_locations
        })
      }
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.selectedLocations, val]
        this.setState(state => ({ selectedLocations: newlocs }));
        this.checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { selectedLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          selectedLocations: newLocs        
        });
        this.checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }))
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
  }

  selectedAllRegLoc = () => {
    let check = this.state.selectedRegions?.length === this.state.preferredResionsLocations?.length
    return check
  }

  selectedAllRegionsLocations = (e) => {
    if(e.target.checked){
      let all = this.state.preferredResionsLocations
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['name'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions
      })
    }else{
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
    }
  }

  showRegionLocation = (locations) => {
    let all_preferredResionsLocations = this.state.preferredResionsLocations
    let selectedLocations = locations
    let finalArr = []
    if(locations?.length > 0){
      let selected_regions = []
      
      for(let i = 0; i < all_preferredResionsLocations?.length; i++){
        let string = ''
        let select_reg = ''
        let selected_locations = []
        let region = all_preferredResionsLocations[i]
        let location_ids = region.locations.map(function(a) {return a.id;});    
        const found = location_ids.some(r=> selectedLocations.includes(r))
        if(found){
          select_reg = region.region.name
        }
        region.locations.map(function(a) {
          if(selectedLocations.includes(a.id)){
            selected_locations.push(a.name)
          }
        });

        string = select_reg+': '+selected_locations.join(' ')
        selected_regions.push(string)
        if(selected_locations?.length > 0){
          finalArr.push({region: select_reg, locations: selected_locations})
        }
      }
    }
    let show_others = true
    if(finalArr?.length === 1 && finalArr[0].region === "Others"){
      show_others = false
    }else{
      show_others = true
    }
    this.setState({
      selectedRegLocToShow: finalArr,
      showOthers: show_others
    })
  }

  handleSwitch = (val, index, day) => {
    let content = this.state.day_index;
    let new_hours = {time_from: this.state.new_time_from, time_to: this.state.new_time_to}
    for (let i = 0; i < content?.length; i++){
      if(content[i].day === day){
        content[i].availability_slots_attributes[index] = Object.assign({
          id: content[i].availability_slots_attributes[index]?.id,
          accepted: val,
          time_from: content[i].availability_slots_attributes[index].time_from,
          time_to: content[i].availability_slots_attributes[index].time_to,
          from_range: content[i].availability_slots_attributes[index].from_range,
          to_range: content[i].availability_slots_attributes[index].to_range,
        })
        let chk = content[i].availability_slots_attributes.every(slt => slt.accepted == false)
        if(chk){
          content[i].available = false
        }
      }
    }
    this.setState({
      day_index: content
    })
  }

  render() {
    const { day_index, time_from, time_to, start_time, end_time, show, alert_type, alert_message, start_to_time, editNote, note, disable, hour_range, to_hour_range, loaded, extra_note, showAvailability, editPermanentAdditionInfo, permanentPreferredLocations, permanentPreferredWeeklyClasses, permanentNote, redirectToAvailability, preferredResionsLocations, selectedLocations, selectedRegions, allLocations, showFilterOther, selectedRegLocToShow, showOthers, permanentPreferredWeeklyClassesArray, disabledSaveAvailableBtn, disabledSaveAdditionalInfo } = this.state
    const { business, user, loadingMessage } = this.props
    
    return (
      <React.Fragment>
        {redirectToAvailability &&
          <Redirect to={`/availability`} />
        }
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        } 
        {!loaded &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <div className="w-full min-h-screen bg-gray-50">
            <div className="pt-20 md:pt-8 pb-8">
              <header>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <h1 className="text-2xl font-semibold leading-tight text-gray-900">Availability</h1>
                </div>
              </header>
              <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                  <div className="px-4 py-6 sm:px-0">
                    <div className="bg-white shadow-md rounded-lg md:p-6 py-6 px-4">
                      <div className="sm:inline-flex md:flex items-center mb-4 pb-4 border-b border-gray-200 w-full">
                        <div className="text-lg font-medium flex-auto">{showAvailability === 'permanent' ? 'Permanent Classes Weekly Hours' : 'Subbing Weekly Hours'}</div>
                        <div className="text-gray-500 text-sm ml-auto">
                          Hours: [{time_from}-{time_to}]
                        </div>
                      </div>
                      {day_index &&
                        <React.Fragment>
                          {day_index.map((day, i) => 
                            <div key = {i} className="flex flex-wrap py-4 border-b border-gray-200 relative">
                              <div className="w-1/6 sm:1/6 md:w-1/6 lg:mr-0 md:mr-8 text-sm text-gray-900 font-medium">
                                <label className="inline-flex items-center">
                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 cursor-pointer" checked = {day.available} onChange = {(e) => this.handleChange(e, day.day)}/>
                                    <span className="ml-2">{this.dayName(day.day)}</span>
                                </label>
                              </div>
                              {day.available &&
                                <React.Fragment>
                                  {(showAvailability === 'permanent' && !business?.block_permanent_availability) || (showAvailability === 'subbing' && !business?.block_subbing_availability) ?
                                    <React.Fragment>
                                      <div className="w-full md:w-3/6 md:mt-0 mt-2">
                                        {day.availability_slots_attributes.map((hours, j) => 
                                          <div key = {j} className="flex mb-2">
                                            <div className="w-4/12 lg:w-4/12 sm:w-5/12 md:w-1/2">
                                              <div className="flex">
                                                <div className="text-xs text-gray-700 pb-1">From</div>
                                              </div>
                                              <div className="flex -mx-1 mb-2">
                                                <div className="w-full px-1 md:mb-0">
                                                  <div className="relative">
                                                    <select 
                                                      className = "form-select text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none"
                                                      name = "time_from"
                                                      onChange={(e) => this.handleFrom(e, day.day, j)} 
                                                      value={hours?.time_from}
                                                    >
                                                    {hours.from_range.map((start_time, i) =>
                                                      <option key = {i} value = {start_time}>{start_time}</option>
                                                    )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-4/12 lg:w-4/12 sm:w-5/12 md:w-1/2 md:ml-3.5 ml-2.5">
                                              <div className="flex">
                                                <div className="text-xs text-gray-700 pb-1">To</div>
                                              </div>
                                              <div className="flex -mx-1 mb-2">
                                                <div className="w-full px-1 md:mb-0">
                                                  <div className="relative">
                                                    <select 
                                                      className = "form-select text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:no-focus"
                                                      name = "time_to"
                                                      onChange={(e) => this.handleTo(e, day.day, j)} 
                                                      value={hours?.time_to}
                                                    >
                                                    {hours.to_range.map((end_time, i) =>
                                                      <option key = {i} value = {end_time}>{end_time}</option>
                                                    )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-1/12 flex items-center mt-3 ml-2.5">
                                              <a className="cursor-pointer" onClick = {(e) => this.deleteSlot(e,j, day.day)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                </svg>
                                              </a>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="md:w-1/4 text-right absolute right-0">
                                        {!day.disable ?
                                          <button className="md:mt-5 font-medium inline-flex bg-dark-blue text-xs text-white py-2 px-3 rounded-md h-8 gap-2" onClick = {(e) => this.handleAdd(e, day.day)} disabled = {day.disable}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                            Add slot
                                          </button>
                                        :
                                          <React.Fragment>
                                            {day.disable && day.prev_disable &&
                                              <button className="md:mt-5 font-medium inline-flex  text-xs text-white py-2 px-3 rounded-md h-8 gap-2 cursor-default bg-gray-300" onClick = {(e) => this.handleAdd(e, day.day)} disabled = {day.disable}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                                Add slot
                                              </button>
                                            }
                                            {day.disable && !day.prev_disable &&
                                              <button className="md:mt-5 font-medium inline-flex bg-dark-blue text-xs text-white py-2 px-3 rounded-md h-8 gap-2" onClick = {(e) => this.handleAddPrevSlot(e, day.day)} disabled = {day.prev_disable}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                                Add slot
                                              </button>
                                            }
                                          </React.Fragment>
                                        }
                                        {!day?.prev_disable && day.disable}
                                          
                                      </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                      <div className="w-full md:w-3/6 md:mt-0 mt-2">
                                        {day.availability_slots_attributes.map((hours, j) => 
                                          <div key = {j} className="flex mb-2">
                                            <div className="w-4/12 lg:w-4/12 sm:w-5/12 md:w-1/2">
                                              <div className="flex">
                                                <div className="text-xs text-gray-700 pb-1">From</div>
                                              </div>
                                              <div className="flex -mx-1 mb-2">
                                                <div className="w-full px-1 md:mb-0">
                                                  <div className="relative">
                                                    <select 
                                                      className = {`form-select bg-none text-sm p-2 rounded-md border shadow-sm w-full focus:outline-none ${hours?.accepted ? 'bg-white text-gray-700' : 'bg-gray-100 text-gray-400' }`}
                                                      name = "time_from"
                                                      onChange={(e) => this.handleFrom(e, day.day, j)} 
                                                      value={hours?.time_from}
                                                      disabled={true}
                                                    >
                                                    {hours.from_range.map((start_time, i) =>
                                                      <option key = {i} value = {start_time}>{start_time}</option>
                                                    )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-4/12 lg:w-4/12 sm:w-5/12 md:w-1/2 md:ml-3.5 ml-2.5">
                                              <div className="flex">
                                                <div className="text-xs text-gray-700 pb-1">To</div>
                                              </div>
                                              <div className="flex -mx-1 mb-2">
                                                <div className="w-full px-1 md:mb-0">
                                                  <div className="relative">
                                                    <select 
                                                      className = {`form-select bg-none text-sm p-2 ${hours?.accepted ? 'bg-white text-gray-700' : 'bg-gray-100 text-gray-400' } rounded-md border shadow-sm w-full focus:no-focus`}
                                                      name = "time_to"
                                                      onChange={(e) => this.handleTo(e, day.day, j)} 
                                                      value={hours?.time_to}
                                                      disabled={true}
                                                    >
                                                    {hours.to_range.map((end_time, i) =>
                                                      <option key = {i} value = {end_time}>{end_time}</option>
                                                    )}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="w-1/12 flex items-center mt-3 ml-2.5">
                                              <React.Fragment>
                                                <Switch 
                                                  checked={hours?.accepted}
                                                  height={23}
                                                  width={47}
                                                  uncheckedIcon={false}
                                                  checkedIcon={false}
                                                  handleDiameter={15}
                                                  offColor={'#ff0000'}
                                                  boxShadow="0px 0px 0px rgba(0, 0, 0, 0.6)"
                                                  onChange={(val) => this.handleSwitch(val, j, day.day)}
                                                />
                                              </React.Fragment>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  }
                                </React.Fragment>
                              }
                              {!day.available &&
                                <React.Fragment>
                                  <div className="md:w-4/6 ml-auto">
                                    <div className="md:w-1/2 text-base text-gray-400">
                                      Not available
                                    </div>
                                    <div className="md:w-1/4"></div>
                                  </div>
                                  <div className="md:w-1/6"></div>
                                </React.Fragment>
                              }
                            </div>
                          )}
                        </React.Fragment>
                      }

                      <div className="flex mt-4 gap-3 md:justify-end">
                        <button className="text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" onClick={(e) => this.handleCancelSlotsEditing(e)}>Cancel
                        </button>
                        <button className={`flex text-base font-medium rounded-md text-white px-4 py-3 gap-2 ${disabledSaveAvailableBtn ? 'button-disabled bg-gray-500' : 'bg-dark-blue'}`} onClick = {(e) => this.saveSlots(e, showAvailability)} disabled={disabledSaveAvailableBtn}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                          </svg>
                          Save
                        </button>
                      </div>
                    </div>
                    {/* {showAvailability === 'subbing' &&
                      <div className="bg-white shadow-md rounded-lg p-6 mt-4">
                        <div className="flex items-center border-b pb-4">
                          <div className="text-lg font-medium flex-auto">
                            Additional Information
                          </div>
                          {!editNote &&
                            <button className="flex text-sm bg-dark-blue text-white py-2 px-4 rounded-md h-10" onClick = {(e) => this.handleEditNotes(e)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                                Edit
                            </button>
                          }
                        </div>
                        
                        {!editNote &&
                          <div className="flex flex-wrap pt-5 pb-6">
                            <div className="w-1/4 text-sm text-gray-500 mb-1 md:mb-0 font-medium">Note</div>
                            <div className="w-full md:w-3/4 text-sm text-gray-900">
                              <p className="break-all">{note}</p>
                            </div>
                          </div>
                        }
                        {editNote &&
                          <React.Fragment>
                          <div className="flex flex-wrap pt-5">
                          <div className="w-1/4 text-sm text-gray-500 mb-1 md:mb-0">Note</div>
                            <div className="w-full md:w-3/4 text-sm text-gray-900">
                              <textarea className="w-full text-sm border focus:outline-none rounded-md text-gray-500 shadow-sm resize-none py-2 px-3.5" rows="3" defaultValue = {note} placeholder = "Enter note" onChange = {(e) => this.handleChangeText(e)}>
                              </textarea>
                            </div>
                            </div>
                            <div className="flex mt-4 gap-3 md:justify-end">
                              <button href = "/availability" className="text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" onClick = {(e) => this.handleCancelNotes(e)}>Cancel
                              </button>
                              <button className="flex text-base font-medium rounded-md text-white bg-dark-blue px-4 py-3 gap-2" onClick = {(e) => this.saveNotes(e)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                                Save
                              </button>
                            </div>
                          </React.Fragment>
                        } 
                      </div>
                    } */}
                    {showAvailability === 'permanent' &&
                      <div className="bg-white shadow-md rounded-lg p-6 mt-4">
                        <div className='flex items-center border-b border-gray-200 pb-4'>
                          <div className='text-lg font-medium flex-auto'>
                            Additional information
                          </div>
                          {!editPermanentAdditionInfo &&
                            <button className="flex text-sm bg-dark-blue text-white py-2 px-4 rounded-md h-10" onClick = {(e) => this.handlePermanentAdditionInfo(e)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                                Edit
                            </button>
                          }
                        </div>
                        {/* Location where wants to teach */}
                        <div className="flex flex-wrap pt-5 md:pb-0 pb-5 md:border-b-0 border-b border-gray-200">
                          <div className='md:w-1/4 text-sm text-gray-900 mb-1 md:mb-0'>
                            Preferred Locations
                          </div>
                          {!editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                {selectedRegLocToShow?.length > 0 &&
                                  <React.Fragment>
                                    {selectedRegLocToShow.map((reg, i) =>
                                      <div className='flex' key = {i}> 
                                        {showOthers && <div>{reg.region}:</div>} <div className={`${showOthers ? 'ml-2' : ''}`}>{reg.locations.join(', ')}</div>
                                      </div>
                                    )}
                                  </React.Fragment>
                                }
                              </div>
                            </div>
                          }
                          {editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              {/* <div className="relative">
                                <select className='form-select text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none'>
                                    <option>Select location(s)</option>
                                </select>
                                <p className='text-gray-500 text-sm mt-1'>Where do you want to teach?</p>
                              </div> */}
                              <div className="relative filters_box">
                                <Menu as="div" className="relative block text-left">

                                  <div>
                                    <Menu.Button className="inline-flex justify-center w-full rounded border border-gray-200 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                                      {selectedLocations?.length === allLocations?.length && <p className = "text-gray-900">All Locations Selected</p>
                                      }
                                      {selectedLocations?.length < allLocations?.length && 
                                        <React.Fragment>
                                          {(selectedLocations?.length <= 2 && selectedLocations?.length !== 0) && 
                                            <p className = "text-gray-900">{this.locationName()}</p>
                                          }
                                          {(selectedLocations?.length > 2 && selectedLocations?.length < allLocations?.length) && 
                                            <p className = "text-gray-900">{this.locationName()} +{selectedLocations?.length-2}</p>
                                          }
                                        </React.Fragment>
                                      }
                                      {(selectedLocations?.length === 0  && selectedLocations?.length !== allLocations?.length) && <p>Select location</p>} 
                                      <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    show={true}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                                      <div className="flex items-center p-2 border-b">
                                    {/*<div className="relative">
                                      <div className="bg-gray-50 rounded-lg h-8">
                                        <input type="text" className="bg-gray-50 px-2 py-1.5 w-full focus:outline-none text-sm text-gray-900 placeholder-gray-500"  placeholder="Search" />
                                        <button className="absolute top-1.5 right-1.5">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>*/}
                                    <div className=" ml-auto">
                                      <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                        Select All
                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectedAllRegLoc()} onChange = {(e) => this.selectedAllRegionsLocations(e)}/>
                                          
                                        </label>
                                    </div>
                                  </div>
                                      <div className="p-2 max-h-60 overflow-y-auto">
                                        {preferredResionsLocations.map((region, i) =>
                                          <div className="py-1" key = {i}>
                                            {showFilterOther &&
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                                    {region.region.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                            }
                                            <div className="ml-5">
                                              {region.locations.map((location, j) =>
                                              <Menu.Item key = {j}>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                    {location.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          }
                        </div>
                        {/* No of classes to teach */}
                        <div className="flex flex-wrap pt-5 md:pb-0 pb-5 md:border-b-0 border-b border-gray-200">
                          <div className='md:w-1/4 text-sm text-gray-900 mb-1 md:mb-0'>
                            Preferred # Weekly Classes
                          </div>
                          {!editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                <p>{permanentPreferredWeeklyClasses} classes per week</p>
                              </div>
                            </div>
                          }
                          {editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                {/* <input 
                                  id="permanent-PreferredWeeklyClasses"
                                  name="permanentPreferredWeeklyClasses"
                                  type='number'
                                  min="0"
                                  max="20" 
                                  onKeyDown={(e) => this.handleKeyDownPermanentPreferredWeeklyClasses(e)}
                                  className='text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none' 
                                  value={permanentPreferredWeeklyClasses}
                                  onChange={(e) => this.handleChangePermanentAdditionalInfo(e)}
                                  /> */}
                                  <select 
                                    id="permanent-PreferredWeeklyClasses" name="permanentPreferredWeeklyClasses" className='form-select text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none' value={permanentPreferredWeeklyClasses}
                                    onChange={(e) => this.handleChangePermanentAdditionalInfo(e)}>
                                      {permanentPreferredWeeklyClassesArray.map((num, i) => 
                                        <option key={i} value={num}>{num}</option>
                                      )}
                                  </select>
                                <p className='text-gray-500 text-sm mt-1'>How many classes per week do you want to teach? Pick between 1 - 25 classes</p>
                              </div>
                            </div>
                          }
                        </div>
                        {/* Note */}
                        <div className="flex flex-wrap pt-5">
                          <div className='md:w-1/4 text-sm text-gray-900 mb-1 md:mb-0'>
                            Notes
                          </div>
                          {!editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                <p>{permanentNote}</p>
                              </div>
                            </div>
                          }
                          {editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                <textarea 
                                  id= "permanent-Note"
                                  name="permanentNote"
                                  value={permanentNote}
                                  className='text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none' 
                                  onChange={(e) => this.handleChangePermanentAdditionalInfo(e)}
                                >
                                </textarea>
                              </div>
                            </div>
                          }
                        </div>
                        {editPermanentAdditionInfo &&
                          <div className="flex mt-4 gap-3 md:justify-end">
                            <a className="cursor-pointer text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" onClick={() => this.handleCancelPermanentAdditionInfo()}>Cancel</a>
                            <button className={`flex text-base font-medium rounded-md text-white px-4 py-3 gap-2 ${disabledSaveAdditionalInfo ? 'button-disabled bg-gray-500' : 'bg-dark-blue'}`} onClick={(e) => this.performSavePermanentAdditionalInfo(e)} disabled={disabledSaveAdditionalInfo}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>Save</button>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </main>
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}