import React, { Fragment } from "react";
import { InfoIcon } from '../../../../subRequestDetails/assets';
import ReactTooltip from "react-tooltip";

export default function HeadingComponent(props) {
  const { step, path } = props

  return(
    <Fragment>
      <div className="flex justify-center w-full items-center h-8 gap-2 mb-6">
        <h1 className="text-center text-xl md:text-2xl font-bold text-black">
          {
            step === 1 ? 
              `${path == 'edit-sub-request' ? 'Edit' : 'Enter'} Sub Request Details` 
            :
            step === 2 ? 
              `Invite Instructor(s) to Sub` 
            :
              `Almost there! Final review`
          }
        </h1>
      </div>
    </Fragment>
  )
}