import React, { Fragment, useState, useEffect } from "react";
import CancelConfirmationPopupComponent from './../../cancelConfirmationPopupComponent';
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
library.add( faUndo )

export default function UndoCancelButtonComponent(props) {
  const { event, handleSetAlert, handleUpdateEvent, defaultDisabled = false } = props
  const [ undoCancelOption, setUndoCancelOption ] = useState(event?.more_action_detail?.filter(item => item?.key == 'undo_cancel'))
  const [ showUndoCancelPopup, setShowUndoCancelPopup ] = useState(false)

  useEffect(() => {
    if(event){
      setUndoCancelOption(event?.more_action_detail?.filter(item => item?.key == 'undo_cancel'))
    }
  }, [event])

  return(
    <Fragment>
      {showUndoCancelPopup &&
        <CancelConfirmationPopupComponent eventId={event?.id} showCancelPopup={showUndoCancelPopup} closeCancelPopup={setShowUndoCancelPopup} handleSetAlert={handleSetAlert} handleCloseAndReloadData={handleUpdateEvent} type={'preview'} eventData={event} calendarEvent={false} handleUpdateCalendarEvent={() => {}}/> 
      }
      {undoCancelOption.length > 0 ?
        <button className={`flex relative z-20 justify-center items-center border border-gray-300 rounded-full min-h-8 min-w-8 ${(defaultDisabled) ? 'opacity-50 cursor-not-allowed' : ''}`} onClick={() => setShowUndoCancelPopup(true)} data-tip data-for={`undo_cancel_button_`+event?.id.toString()} disabled={defaultDisabled}>
          <FontAwesomeIcon className='w-3.5 h-3.5 shrink-0' icon="fa-solid fa-undo" />
          <ReactTooltip className='max-w-xs whitespace-nowrap' id={`undo_cancel_button_`+event?.id.toString()} place="top" effect="solid">
            {undoCancelOption[0]?.value}
          </ReactTooltip>
        </button>
      :
        null
      }
    </Fragment>
  )
}