import React, { Fragment } from "react";

export default function PdfView({docUrl}) {

  const addHttps = (url) => {
    if(!url.includes('https')){
      return url.replace('http', 'https')
    }else{
      return url
    }   
  }

  return(
    <Fragment>
      <iframe className='hidden md:block' src={`${addHttps(docUrl)}`+"#toolbar=0"} width='100%' height='550px' frameBorder='0'> </iframe> 
      <embed className='block md:hidden' src={`${addHttps(docUrl)}`} type="application/pdf" style={{width:"100%", height:"100%"}}></embed>
      <div className="block md:hidden mt-2">
        <a href={`${addHttps(docUrl)}`} target="_blank" className="bg-gray-900 inline-flex items-center px-4 py-2 border shadow rounded-md">
          <div className="text-white text-sm ml-1.5">View</div>
        </a>
      </div>
    </Fragment>
  )
}