import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";

export default function TopButtons(props) {
  const [ redirect, setRedirect ] = useState(false)
  const [ backPath, setBackPath ] = useState('')
  const goBack = () => {
    let backPagePath = location?.pathname?.split('/submit-certification')[0]
    setBackPath(backPagePath)
    setRedirect(true)
  }
  return(
    <Fragment>
      {redirect &&
        <Redirect to={`${backPath}`} />
      }
      <div className="flex justify-start mb-5">
        <button className="flex justify-center items-center rounded-lg px-3 py-2 shadow hover:bg-gray-100 bg-white text-sm text-gray-900 border border-gray-300 font-semibold gap-2" onClick={() => goBack()}>
          Back
        </button>
      </div>
    </Fragment>
  )
}