import React, { Fragment, useState, useEffect, useRef } from "react";
import { axiosGetRequest } from './../../../../../subRequests/helpers/helpers';
import { LocationPinIcon } from '../../../../../subRequests/assets';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

export default function MboSiteIdComponent(props) {
  const { newSubRequest, setNewSubRequest } = props;
  const menuButtonRef = useRef(null);
  const [ selectedMboSite, setSelectedMboSite ] = useState(newSubRequest?.mboSiteId || {})
  const [ mboSiteIdOptions, setMboSiteIdOptions ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ requestingInstId, setRequestingInstId ] = useState('');
  const [ selectedSiteId, setSelectedSiteId ] = useState(newSubRequest?.mboSiteId?.site_id || '')

  useEffect(() => {
    if (newSubRequest?.requestingInstructor?.id !== '' && parseInt(newSubRequest?.requestingInstructor?.id) !== parseInt(requestingInstId)) {
      setRequestingInstId(parseInt(newSubRequest?.requestingInstructor?.id))
      fetchMboSiteIds()
    }
  }, [newSubRequest?.requestingInstructor])

  useEffect(() => {
    setSelectedMboSite(newSubRequest?.mboSiteId || {})
    setSelectedSiteId(newSubRequest?.mboSiteId?.site_id || '')
  }, [newSubRequest?.mboSiteId])

  const fetchMboSiteIds = () => {
    setLoading(true);
    axiosGetRequest(`/api/v3/fetch_mbo_site_ids?requesting_instructor_id=${newSubRequest?.requestingInstructor?.id}`).then(result => {
      setLoading(false);
      if (result?.status === 200) {
        const mboSites = result?.results;
        setMboSiteIdOptions(mboSites);
      
        const hasSelectedSite = selectedMboSite?.site_name === '' && selectedMboSite?.site_id !== '';
        const singleSite = mboSites?.length === 1;
      
        let selectedSite = mboSites.find(site => site.site_id === selectedMboSite?.site_id);
      
        if (hasSelectedSite) {
          if (selectedSite) {
            setSelectedSiteId(selectedSite?.site_id);
          }
        } else if (singleSite) {
          const site = mboSites[0];
          saveMboSiteId(site.site_id, site.site_name);
          setSelectedSiteId(site.site_id);
        } else {
          if (selectedSite) {
            setSelectedSiteId(selectedSite?.site_id);
          } else {
            saveMboSiteId('', '');
            setSelectedSiteId('');
          }
        }
      }      
    })
  }

  const handleSelectedOption = (e, site) => {
    saveMboSiteId(site?.site_id, site?.site_name)
    setSelectedMboSite(site)
    setSelectedSiteId(site?.site_id)
  }

  const saveMboSiteId = (siteId, siteName) => {
    let selectedMboSiteHash = {site_id: siteId, site_name: siteName}
    setSelectedMboSite(selectedMboSiteHash)
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      mboSiteId: selectedMboSiteHash
    }))
  }

  const setRef = node => {
    if (node) {
      $(`.mbo-site-need-border`).addClass('filter-dynamic-border')
    }else if (node === null) {
      $(`.mbo-site-need-border`).removeClass('filter-dynamic-border')
    }
  };

  const instructorSiteName = (siteId) => {
    return (mboSiteIdOptions.find((i) => i.site_id == siteId)?.site_name || '' )
  }

  return(
    <Fragment>
      <div className="flex flex-col gap-2 filter-column justify-end">
        <div className="flex text-base md:text-lg text-black font-semibold">
          Mbo Site
        </div>
        <div className="relative filters_box"> 
          <Menu as="div" className="relative block text-left">
            <div>
              <Menu.Button className={`flex justify-center w-full rounded-2xl pl-4 pr-2 py-2.5 bg-gray-custom-50 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 h-11 gap-1 items-center border-2 border-transparent mbo-site-need-border`} ref={menuButtonRef}>
                <LocationPinIcon classNames={'w-5 h-5 text-neutral-500'}/>
                <p className="truncate filter-text-field text-left">
                  {selectedSiteId === '' ?
                    'Select location'
                  :
                  instructorSiteName(selectedSiteId)
                  }
                </p>
                <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
              {loading &&
                <div className="custom-dots-loader absolute top-5 right-9 z-10"></div>
              }
            </div>
            <Transition
              as={Fragment}
              // show={true}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items ref={setRef} className="origin-top-right z-30 right-0 w-full rounded-2xl bg-white shadow-md border bordar-gray-200 focus:outline-none mt-2 absolute">
                <div className="my-4 px-4 max-h-60 overflow-y-auto">
                  {mboSiteIdOptions?.map((mboSite, i) => 
                    <label key={i} className="flex text-gray-900 text-sm mb-2 justify-between cursor-pointer">
                      <div className="flex text-left font-medium text-base text-gray-900">
                        {mboSite?.site_name}
                      </div>
                      <div className="assign-checkbox flex mt-1">
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer"
                          value={mboSite?.site_id}
                          checked={selectedSiteId == mboSite?.site_id}
                          onChange={(e) => handleSelectedOption(e, mboSite)}
                        />
                      </div>
                    </label>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </Fragment>
  )
}