import React, { Fragment, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import { AngleIcon, BellIcon, PhoneIcon, EnvelopeIcon, ChatIcon, TextIcon, UserIcon, SendIcon } from './../../../subRequests/assets';
import AlertPopup from './../../../messenger/pages/chatComponents/assets/alertPopup';
import { Redirect } from "react-router-dom";
import UsersListCardComponent from './usersListCardComponent';
import MgrEventUsersListComponent from './mgrEventUsersListComponent';
import ApproveButtonComponent from './approveButtonComponent';
import ResendInviteAllButtonComponent from './resendInviteAllButtonComponent';
import ResendInviteButtonComponent from './resendInviteButtonComponent';
import SendInviteButtonComponent from './sendInviteButtonComponent';
import SendInviteAllButtonComponent from './sendInviteAllButtonComponent';
import UserCardComponent from './userCardComponent';

export default function UsersListSectionComponent(props){
  const { eventData, handleReloadData, handleUpdateEvent, business } = props
  const [ redirectAfterCreateGroup, setRedirectAfterCreateGroup ] = useState(false)
  const [ redirectAtGroupPage, setRedirectAtGroupPage ] = useState('')
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertType, setAlertType ] = useState('')
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ openAccordionKey, setOpenAccordionKey ] = useState(['0', '2', '6'])
  const [ approvedUsers, setApprovedUsers ] = useState([])

  useEffect(() => {
    if(eventData){
      let newOpenAccordionKey = openAccordionKey
      if(eventData?.accepted_subs?.length == 0){
        newOpenAccordionKey = newOpenAccordionKey.filter(item => item !== '0')
      }
      if(eventData?.pending_subs?.length == 0){
        newOpenAccordionKey = newOpenAccordionKey.filter(item => item !== '2')
      }
      if(eventData?.approved_subs?.length == 0){
        newOpenAccordionKey = newOpenAccordionKey.filter(item => item !== '6')
      }
      setOpenAccordionKey(newOpenAccordionKey)
    }
  }, [eventData])

  const redirectToUrl = (url, redirect) => {
    setRedirectAtGroupPage(url)
    setRedirectAfterCreateGroup(redirect)
  }

  const closeAlert = (data) => {
    handleAlert(data, '', '')
  }

  const handleAlert = (show, message, type) => {
    setAlertType(type)
    setAlertMessage(message)
    setShowAlert(show)
  }

  const handleAccordionClick= (accordKey) => {
    if(openAccordionKey.includes(accordKey)){
      setOpenAccordionKey(openAccordionKey.filter(item => item !== accordKey))
    }else{
      setOpenAccordionKey([...openAccordionKey, accordKey])
    }
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {redirectAfterCreateGroup &&
        <Redirect push to={`${redirectAtGroupPage}`} />
      }
      <div className="lg:ml-2 h-full">
        <div className="lg:rounded-2xl lg:border-2 lg:border-gray-300 h-full w-full bg-white p-4">
          {(eventData?.manager_gate_event && !eventData?.mrg_reopened) ?
            <Fragment>
              <MgrEventUsersListComponent eventData={eventData} redirectToUrl={redirectToUrl} handleAlert={handleAlert} handleUpdateEvent={handleUpdateEvent}/>
            </Fragment>
            :
            <Fragment>
              <div className="text-xl font-semibold mb-2">Invites</div>
              <Fragment>
                {eventData?.approved_subs?.length > 0 &&
                  <Accordion className="bg-white" defaultActiveKey={'6'}>
                    <Card className="border-b border-gray-300">
                      <Accordion.Toggle eventKey="6" className="bg-white flex items-center w-full gap-2 lg:justify-between lg:px-5 py-2 h-10 lg:h-16" disabled={eventData?.approved_subs?.length == 0} onClick={() => handleAccordionClick('6')}>
                        <div className="flex items-center gap-2">
                          <div className='bg-green-custom w-2 h-4 rounded-sm'></div>
                          <div className='text-xs lg:text-base font-semibold lg:font-medium'>Approved</div>
                          <div className="tc-10 rounded-full bg-gray-900 text-white hidden lg:flex items-center justify-center w-4 h-4 font-medium">{eventData?.approved_subs?.length}</div>
                          {eventData?.approved_subs?.length > 0 && 
                            <div className="flex lg:hidden text-xs font-semibold">{`(${eventData?.approved_subs?.length})`}</div>
                          }
                        </div>
                        <div>
                          <AngleIcon classNames={`w-4 h-4 transform ${openAccordionKey.includes('6') ? 'rotate-180' : ''}`}/>
                        </div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body className="p-0">
                          <div className="mt-2">
                            {eventData?.approved_subs?.map((sub, i) =>   
                              <div key={i}>
                                <UserCardComponent sub={sub} i={i} eventData={eventData} redirectToUrl={redirectToUrl} handleUpdateEvent={handleUpdateEvent} handleAlert={handleAlert} cardType={'Approved'}/>
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                }
                <Accordion className="bg-white" defaultActiveKey={eventData?.accepted_subs?.length == 0 ? '' : '0'}>
                  <Card className="border-b border-gray-300">
                    <Accordion.Toggle eventKey="0" className="bg-white flex items-center w-full gap-2 lg:justify-between lg:px-5 py-2 h-10 lg:h-16" disabled={eventData?.accepted_subs?.length == 0} onClick={() => handleAccordionClick('0')}>
                      <div className="flex items-center gap-2">
                        <div className='bg-pending-event w-2 h-4 rounded-sm'></div>
                        <div className='text-xs lg:text-base font-semibold lg:font-medium'>Accepted</div>
                        <div className="tc-10 rounded-full bg-gray-900 text-white hidden lg:flex items-center justify-center w-4 h-4 font-medium">{eventData?.accepted_subs?.length}</div>
                        {eventData?.accepted_subs?.length > 0 && 
                          <div className="flex lg:hidden text-xs font-semibold">{`(${eventData?.accepted_subs?.length})`}</div>
                        }
                      </div>
                      <div>
                        <AngleIcon classNames={`w-4 h-4 transform ${openAccordionKey.includes('0') ? 'rotate-180' : ''}`}/>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="p-0">
                        <div className="mt-2">
                          {eventData?.accepted_subs?.map((sub, i) =>   
                            <div key={i}>
                              <UserCardComponent sub={sub} i={i} eventData={eventData} redirectToUrl={redirectToUrl} handleUpdateEvent={handleUpdateEvent} handleAlert={handleAlert} cardType={'Accepted'}/>
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion className="bg-white hidden">
                  <Card className="border-b border-gray-300 hidden">
                    <Accordion.Toggle eventKey="1" className="bg-white flex items-center w-full justify-between px-5 py-2 h-16">
                      <div className="flex items-center gap-2">
                        <div className='bg-purple-700 w-2 h-4 rounded-sm'></div>
                        <div className='text-base font-medium'>Maybe</div>
                        <div className="tc-10 rounded-full bg-gray-900 text-white flex items-center justify-center w-4 h-4 font-medium">2</div>
                      </div>
                      <div>
                        <AngleIcon classNames={'w-4 h-4'}/>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="p-0">
                        <div className="mt-2">
                          <div className="px-4 py-3 border-2 border-gray-50 rounded-2xl shadow mb-3 flex justify-between">
                            <div className="flex gap-2">
                              <div className="wc-74 mt-1">
                                <img
                                  src={'/assets/default-profile.jpg'}
                                  className="hc-74 wc-74 rounded-full"
                                />
                              </div>
                              <div className="">
                                <div className="flex items-center gap-1 mb-1">
                                  <div className="text-lg font-semibold">Lily Graham</div>
                                  <div className="text-sm font-medium text-neutral-600">
                                    5 Nov, 2023, 8:00 AM
                                  </div>
                                </div>
                                <div className="flex bg-custom-gray2 px-3 rounded-2xl items-center gap-2 px-4 py-2 mb-2">
                                  <BellIcon classNames={'h-4 w-4'}/>
                                  <div className="text-xs font-semibold text-black">Notification preferences:</div>
                                  <div className="text-xs font-semibold text-neutral-500">
                                    Daily Summary
                                  </div>
                                </div>
                                <div className="flex items-center gap-2">
                                  <button className="flex items-center gap-2 border boarder-gray-200 rounded-2xl px-3 py-2 text-xs font-semibold hover:bg-gray-100">
                                    <div className="flex items-center">
                                      <TextIcon classNames={'h-4 w-4'} />
                                    </div>
                                    Text
                                  </button>
                                  <button className="flex items-center gap-2 border boarder-gray-200 rounded-2xl px-3 py-2 text-xs font-semibold hover:bg-gray-100">
                                    <div className="flex items-center">
                                      <PhoneIcon classNames={'h-4 w-4'} />
                                    </div>
                                    Call
                                  </button>
                                  <button className="flex items-center gap-2 border boarder-gray-200 rounded-2xl px-3 py-2 text-xs font-semibold hover:bg-gray-100">
                                    <div className="flex items-center">
                                      <EnvelopeIcon classNames={'h-4 w-4'} />
                                    </div>
                                    Mail
                                  </button>
                                  <button className="flex items-center gap-2 border boarder-gray-200 rounded-2xl px-3 py-2 text-xs font-semibold hover:bg-gray-100" >
                                    <div className="flex items-center">
                                      <ChatIcon classNames={'h-4 w-4'} />
                                    </div>
                                    Chat
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="flex items-center px-3 py-2 rounded-2xl text-sm font-semibold h-9 border-green-custom text-green-c">
                              Approve
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                <Accordion className="bg-white" defaultActiveKey={eventData?.pending_subs?.length == 0 ? '' : '2'}>
                  <Card className="border-b border-gray-300 relative">
                    <Accordion.Toggle eventKey="2" className="bg-white flex items-center w-full gap-2 lg:justify-between lg:px-5 py-2 h-10 lg:h-16" disabled={eventData?.pending_subs?.length == 0} onClick={() => handleAccordionClick('2')}>
                      <div className="flex items-center gap-2">
                        <div className='bg-yellow-500 w-2 h-4 rounded-sm'></div>
                        <div className='text-xs lg:text-base font-semibold lg:font-medium'>No Responses</div>
                        <div className="tc-10 rounded-full bg-gray-900 text-white hidden lg:flex items-center justify-center w-4 h-4 font-medium">{eventData?.pending_subs?.length}</div>
                        {eventData?.pending_subs?.length > 0 && 
                          <div className="flex lg:hidden text-xs font-semibold">{`(${eventData?.pending_subs?.length})`}</div>
                        }
                      </div>
                      <div className="flex items-center gap-4">
                        <AngleIcon classNames={`w-4 h-4 transform ${openAccordionKey.includes('2') ? 'rotate-180' : ''}`}/>
                      </div>
                    </Accordion.Toggle>
                    {eventData?.show_resend_invite_button && eventData?.pending_subs?.length > 0 &&
                      <ResendInviteAllButtonComponent eventData={eventData} updateEventData={handleUpdateEvent} handleAlert={handleAlert} eventPreview={false} classNames={''}/>
                    }
                    <Accordion.Collapse eventKey="2">
                      <Card.Body className="p-0">
                        <div className="mt-2">
                          {eventData?.pending_subs?.map((sub, i) =>
                            <div key={i}> 
                              <UserCardComponent sub={sub} i={i} eventData={eventData} redirectToUrl={redirectToUrl} handleUpdateEvent={handleUpdateEvent} handleAlert={handleAlert} cardType={'No Responses'}/>
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                {business?.availability_feature &&
                  <Accordion className="bg-white">
                    <Card className="border-b border-gray-300 relative">
                      <Accordion.Toggle eventKey="3" className="bg-white flex items-center w-full gap-2 lg:justify-between lg:px-5 py-2 h-10 lg:h-16" disabled={eventData?.unavailable_subs?.length == 0} onClick={() => handleAccordionClick('3')}>
                        <div className="flex items-center gap-2">
                          <div className='bg-neutral-400 w-2 h-4 rounded-sm'></div>
                          <div className='text-xs lg:text-base font-semibold lg:font-medium'>Unavailable</div>
                          <div className="tc-10 rounded-full bg-gray-900 text-white hidden lg:flex items-center justify-center w-4 h-4 font-medium">{eventData?.unavailable_subs?.length}</div>
                          {eventData?.unavailable_subs?.length > 0 && 
                            <div className="flex lg:hidden text-xs font-semibold">{`(${eventData?.unavailable_subs?.length})`}</div>
                          }
                        </div>
                        <div className="flex items-center gap-4">
                          <AngleIcon classNames={`w-4 h-4 transform ${openAccordionKey.includes('3') ? 'rotate-180' : ''}`}/>
                        </div>
                      </Accordion.Toggle>
                      {eventData?.unavailable_subs?.length > 0 && eventData?.status !== 'Approved' && !eventData?.cancelled &&
                        <SendInviteAllButtonComponent eventData={eventData} handleAlert={handleAlert} handleUpdateEvent={handleUpdateEvent} type={'unavilable'} allUsers={eventData?.unavailable_subs}/>
                      }
                      <Accordion.Collapse eventKey="3">
                        <Card.Body className="p-0">
                          <div className="mt-2">
                            {eventData?.unavailable_subs?.map((sub, i) =>
                              <div key={i}> 
                                <UserCardComponent sub={sub} i={i} eventData={eventData} redirectToUrl={redirectToUrl} handleUpdateEvent={handleUpdateEvent} handleAlert={handleAlert} cardType={'unavilable'}/>
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                }
                <Accordion className="bg-white">
                  <Card className="border-b border-gray-300">
                    <Accordion.Toggle eventKey="4" className="bg-white flex items-center w-full gap-2 lg:justify-between lg:px-5 py-2 h-10 lg:h-16 relative" disabled={eventData?.declined_subs?.length == 0} onClick={() => handleAccordionClick('4')}>
                      <div className="flex items-center gap-2">
                        <div className='bg-cancelled-event w-2 h-4 rounded-sm'></div>
                        <div className='text-xs lg:text-base font-semibold lg:font-medium'>Declined</div>
                        <div className="tc-10 rounded-full bg-gray-900 text-white hidden lg:flex items-center justify-center w-4 h-4 font-medium">{eventData?.declined_subs?.length}</div>
                        {eventData?.declined_subs?.length > 0 && 
                          <div className="flex lg:hidden text-xs font-semibold">{`(${eventData?.declined_subs?.length})`}</div>
                        }
                      </div>
                      <div>
                        <AngleIcon classNames={`w-4 h-4 transform ${openAccordionKey.includes('4') ? 'rotate-180' : ''}`}/>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body className="p-0">
                        <div className="mt-2">
                          {eventData?.declined_subs?.map((sub, i) =>
                            <div key={i}> 
                              <UserCardComponent sub={sub} i={i} eventData={eventData} redirectToUrl={redirectToUrl} handleUpdateEvent={handleUpdateEvent} handleAlert={handleAlert} cardType={'Declined'}/>
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                {(eventData?.show_not_invited_bucket) &&
                  <Accordion className="bg-white">
                    <Card className="border-0 relative">
                      <Accordion.Toggle eventKey="5" className="bg-white flex items-center w-full gap-2 lg:justify-between lg:px-5 py-2 h-10 lg:h-16" disabled={eventData?.not_invited_users?.length == 0} onClick={() => handleAccordionClick('5')}>
                        <div className="flex items-center gap-2">
                          <div className='bg-mgr-event w-2 h-4 rounded-sm'></div>
                          <div className='text-xs lg:text-base font-semibold lg:font-medium'>Not Invited Yet</div>
                          <div className="tc-10 rounded-full bg-gray-900 text-white hidden lg:flex items-center justify-center w-4 h-4 font-medium">{eventData?.not_invited_users?.length}</div>
                          {eventData?.not_invited_users?.length > 0 && 
                            <div className="flex lg:hidden text-xs font-semibold">{`(${eventData?.not_invited_users?.length})`}</div>
                          }
                        </div>
                        <div className="flex items-center gap-4">
                          <AngleIcon classNames={`w-4 h-4 transform ${openAccordionKey.includes('5') ? 'rotate-180' : ''}`}/>
                        </div>
                      </Accordion.Toggle>
                      {eventData?.show_send_invite_all_button &&
                        <SendInviteAllButtonComponent eventData={eventData} handleAlert={handleAlert} handleUpdateEvent={handleUpdateEvent} type={'not_invited_users'} allUsers={eventData?.not_invited_users}/>
                      }
                      <Accordion.Collapse eventKey="5">
                        <Card.Body className="p-0">
                          <div className="mt-2">
                            {eventData?.not_invited_users?.map((sub, i) =>
                              <div key={i}> 
                                <UserCardComponent sub={sub} i={i} eventData={eventData} redirectToUrl={redirectToUrl} handleUpdateEvent={handleUpdateEvent} handleAlert={handleAlert} cardType={'not_invited_users'}/>
                              </div>
                            )}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                }
              </Fragment>
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  )
}