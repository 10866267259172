import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

export default function MyProfileNavBar(props){
  const { myProfilePath, handleChangePage, user } = props

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return(
    <Fragment>
      <div>
        <div className="relative filters_box  w-full md:hidden my-5">
          <Menu as="div" className="relative block text-left">
            <div className='mb-3'>
              <Menu.Button className="inline-flex items-center justify-center w-full rounded-lg border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 hover:bg-gray-50 focus:ring-offset-gray-100 focus:ring-indigo-500">
                <p className='text-gray-900'>{capitalizeFirstLetter(myProfilePath.replace('my-', ''))}</p>
                <ChevronDownIcon className="ml-auto text-gray-900 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-2 max-h-60 overflow-y-auto">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`text-sm flex rounded-bl items-center font-medium px-8 py-1 w-full ${myProfilePath === 'information' ? ' bg-gray-200' : ''}  text-gray-900`} onClick={() => handleChangePage('information')}>Information</button>
                      )}
                    </Menu.Item>
                  </div>
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button className={`text-sm flex items-center font-medium px-8 py-1 w-full ${myProfilePath === 'notifications' ? ' bg-gray-200' : ''}  text-gray-900`} onClick={() => handleChangePage('notifications')}>Notifications</button>
                      )}
                    </Menu.Item>
                  </div>
                  {user?.certifications_feature &&
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button className={`text-sm flex items-center font-medium px-8 py-1 w-full ${myProfilePath === 'my-certifications' ? ' bg-gray-200' : ''}  text-gray-900`} onClick={() => handleChangePage('my-certifications')}>Certifications</button>
                        )}
                      </Menu.Item>
                    </div>
                  }
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      
        <div className={`hidden md:inline-flex bg-white h-11 whitespace-nowrap overflow-x-auto justify-between shadow-md rounded-lg my-5`}>
          <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-14 px-8 ${myProfilePath === 'information' ? 'text-gray-900 border-gray-900 rounded-bl' : 'text-gray-300 hover:text-gray-400 border-transparent'}`} onClick={() => handleChangePage('information')}>Information</button>
          
          <button className={`sm:text-sm text-xs flex items-center border-b-2 font-medium sm:px-14 px-8 ${myProfilePath === 'notifications' ? 'text-gray-900 border-gray-900' : 'text-gray-300 hover:text-gray-400 border-transparent'}`} onClick={() => handleChangePage('notifications')}>Notifications</button>

          {user?.certifications_feature &&
            <button className={`text-sm flex items-center border-b-2 font-medium sm:px-14 px-8 ${myProfilePath === 'my-certifications' ? 'text-gray-900 border-gray-900 rounded-br' : 'text-gray-300 hover:text-gray-400 border-transparent'}`} onClick={() => handleChangePage('my-certifications')}>Certifications</button>
          }
        </div>
      </div>
    </Fragment>
  )
}