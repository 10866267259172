import React from "react";

export default function AddCategory(props) {
  const { setAddNewCategory } = props

  return (
    <div className="flex flex-wrap mb-5 h-auto w-full justify-center items-center">
      <div className=''>
        <a className='bg-dark-blue text-white hover:bg-gray-800 text-sm rounded-md py-2 px-4 font-medium h-10 block cursor-pointer flex items-center' onClick={(e) => setAddNewCategory(e)}>Add New Category</a>
      </div>
    </div>
  )
}