import React from 'react';
import clsx from 'clsx';

import { MessageErrorIcon, MessageDeleted as DefaultMessageDeleted, MessageRepliesCountButton as DefaultMessageRepliesCountButton, MessageStatus as DefaultMessageStatus, MessageText, MessageTimestamp , areMessageUIPropsEqual, messageHasAttachments, messageHasReactions, Avatar as DefaultAvatar, EditMessageForm as DefaultEditMessageForm, MessageInput, MML, Modal, ReactionsList as DefaultReactionList, ReactionSelector as DefaultReactionSelector, useChatContext, useComponentContext, MessageContextValue, useMessageContext, MessageUIComponentProps, DefaultStreamChatGenerics } from 'stream-chat-react';
import { CUSTOM_MESSAGE_TYPE } from 'stream-chat-react/dist/constants/messageTypes';
import { MessageOptions as DefaultMessageOptions } from './messageOptions/messageOptions'
import CustomMessageInput from '../customMessageInput/customMessageInput';
import { MessageTimestamp as DefaultMessageTimestamp } from './customMessageTimeStamp/customMessageTimestamp'
import PinnedMessageIndicator from '../pinnedMessagesComponent/pinnedMessageIndicator'

export const MessageSimpleWithContext = (props) => {
  const { additionalMessageInputProps, clearEditingState, editing, endOfGroup, firstOfGroup, groupedByUser, handleAction, handleOpenThread, handleRetry, highlighted, isMyMessage, isReactionEnabled, message, onUserClick, onUserHover, reactionSelectorRef, renderText, showDetailedReactions, threadList, dataFormat } = props;

  const { Attachment, Avatar = DefaultAvatar, EditMessageInput = DefaultEditMessageForm, MessageDeleted = DefaultMessageDeleted, MessageOptions = DefaultMessageOptions, MessageRepliesCountButton = DefaultMessageRepliesCountButton, MessageStatus = DefaultMessageStatus, MessageTimestamp = DefaultMessageTimestamp, ReactionSelector = DefaultReactionSelector, ReactionsList = DefaultReactionList, } = useComponentContext('MessageSimple');

  const { themeVersion, client } = useChatContext('MessageSimple');
  const pinnedMessageText = ((message?.pinned && Object.keys(message?.pinned_by)?.length > 0) ? (client?.userID == message?.pinned_by?.id ? 'you' : message?.pinned_by?.name) : '')

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);

  if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
    return null;
  }

  if (message.deleted_at || message.type === 'deleted') {
    return <MessageDeleted message={message} />;
  }

  const showMetadata = !groupedByUser || endOfGroup;
  const showReplyCountButton = !threadList && !!message.reply_count;
  const allowRetry = message.status === 'failed' && message.errorStatusCode !== 403;

  const rootClassName = clsx(
    'str-chat__message str-chat__message-simple',
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage()
      ? 'str-chat__message--me str-chat__message-simple--me'
      : 'str-chat__message--other',
    message.text ? 'str-chat__message--has-text' : 'has-no-text',
    {
      'pinned-message': message.pinned,
      'str-chat__message--has-attachment': hasAttachment,
      'str-chat__message--highlighted': highlighted,
      'str-chat__message--with-reactions str-chat__message-with-thread-link':
        hasReactions && isReactionEnabled,
      'str-chat__message-send-can-be-retried':
        message?.status === 'failed' && message?.errorStatusCode !== 403,
      'str-chat__virtual-message__wrapper--end': endOfGroup,
      'str-chat__virtual-message__wrapper--first': firstOfGroup,
      'str-chat__virtual-message__wrapper--group': groupedByUser,
    },
  );

  const messageCreatedAtString = message.created_at.toDateString()
  const todaysDateTimeStamp = new Date()
  const todaysDateString = todaysDateTimeStamp.toDateString()
  const yesterdayDate = new Date(todaysDateString)
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  const yseterdayDateString = yesterdayDate.toDateString()

  return (
    <React.Fragment>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            // Input={EditMessageInput}
            Input={CustomMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <div className={rootClassName} key={message.id}>
          {themeVersion === '1' && <MessageStatus />}
          {message.pinned && <PinnedMessageIndicator pinnedMessageText={pinnedMessageText} isMyMessage={isMyMessage()}/>}
          {message.user && (
            <Avatar
              image={message.user.image}
              name={message.user.name || message.user.id}
              onClick={onUserClick}
              onMouseOver={onUserHover}
              user={message.user}
            />
          )}
          <div
            className={clsx('str-chat__message-inner', {
              'str-chat__simple-message--error-failed': allowRetry,
            })}
            data-testid='message-inner'
            onClick={allowRetry ? () => handleRetry(message) : undefined}
            onKeyUp={allowRetry ? () => handleRetry(message) : undefined}
          >
            <MessageOptions />
            <div className='str-chat__message-reactions-host'>
              {hasReactions && isReactionEnabled && <ReactionsList reverse />}
              {showDetailedReactions && isReactionEnabled && (
                <ReactionSelector ref={reactionSelectorRef} />
              )}
            </div>
            <div className='str-chat__message-bubble'>
              {message.attachments?.length && !message.quoted_message ? (
                <Attachment actionHandler={handleAction} attachments={message.attachments} />
              ) : null}
              <MessageText message={message} renderText={renderText} />
              {message.mml && (
                <MML
                  actionHandler={handleAction}
                  align={isMyMessage() ? 'right' : 'left'}
                  source={message.mml}
                />
              )}
              {themeVersion === '2' && <MessageErrorIcon />}
            </div>
            {showReplyCountButton && themeVersion === '1' && (
              <MessageRepliesCountButton
                onClick={handleOpenThread}
                reply_count={message.reply_count}
              />
            )}
            {showMetadata && themeVersion === '1' && (
              <div className='str-chat__message-data str-chat__message-simple-data'>
                {!isMyMessage() && message.user ? (
                  <span className='str-chat__message-simple-name'>
                    {message.user.name || message.user.id}
                  </span>
                ) : null}
                <MessageTimestamp calendar={(messageCreatedAtString === todaysDateString || messageCreatedAtString ===  yseterdayDateString)} format={`${dataFormat} h:mm A`} customClass='str-chat__message-simple-timestamp' />
              </div>
            )}
          </div>
          {showReplyCountButton && themeVersion === '2' && (
            <MessageRepliesCountButton
              onClick={handleOpenThread}
              reply_count={message.reply_count}
            />
          )}
          {showMetadata && themeVersion === '2' && (
            <div className='str-chat__message-data str-chat__message-simple-data str-chat__message-metadata'>
              <MessageStatus />
              {!isMyMessage() && !!message.user && (
                <span className='str-chat__message-simple-name'>
                  {message.user.name || message.user.id}
                </span>
              )}
              <MessageTimestamp calendar customClass='str-chat__message-simple-timestamp' />
            </div>
          )}
        </div>
      }
    </React.Fragment>
  );

}