import React, { Fragment, useState } from "react";
import CustomPopupComponent from '../../../../../subRequests/pages/components/customPopupComponent/customPopupComponent';
import { getName } from '../../../../../subRequests/helpers/helpers';

import InviteMoreUserListComponent from './inviteMoreUserListComponent';

export default function InviteMoreComponent(props){
  const { user, eventId, confirmationMessage, closeInviteMorePopup, showInviteMorePopup, handleUpdateEvent, handleSetAlert, type, sentBucket, reopenEventMRG } = props
  const [ showInviteMoreListPopup, setShowInviteMoreListPopup ] = useState(false)

  const handleInviteMoreNow = () => {
    setShowInviteMoreListPopup(!showInviteMoreListPopup)
  }

  return(
    <Fragment>
      {!showInviteMoreListPopup &&
        <CustomPopupComponent show={showInviteMorePopup} close={closeInviteMorePopup} title={'Invite More'}>
          <div className="px-4 pt-3 font-open-sans">
            <div className='text-sm px-2 font-semibold text-gray-700 mt-3 text-center'>
              {confirmationMessage }
            </div>
            <div className="mt-5 flex gap-3 flex-row-reverse">
              <button 
                className={`bg-gray-900 rounded-xl text-white flex items-center justify-center w-40 py-2 text-sm font-semibold`}
                onClick={() => {!reopenEventMRG ? handleInviteMoreNow() : closeInviteMorePopup(false)}}
              >
                {!reopenEventMRG ? 'Invite More Now!' : 'Okay'}
              </button>
              {!reopenEventMRG &&
                <button 
                  className={`bg-white border rounded-xl text-gray-800 flex items-center justify-center px-4 py-2 text-sm hover:bg-gray-100 font-semibold shadow`}
                  onClick={() => closeInviteMorePopup(false)}
                >
                  Reopen and Invite Later
                </button>
              }
            </div>
          </div>
        </CustomPopupComponent>
      }
      {showInviteMoreListPopup && 
        <InviteMoreUserListComponent eventId={eventId} showInviteMorePopup={showInviteMorePopup} closeInviteMorePopup={closeInviteMorePopup} handleUpdateEvent={handleUpdateEvent} handleSetAlert={handleSetAlert} type={type} sentBucket={sentBucket}/>
      }
    </Fragment>
  )
}