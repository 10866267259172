import React, { Fragment, useEffect, useState } from "react";
import TableComponent from "./tableComponent";
import { axiosGetRequest } from './../../../../subRequests/helpers/helpers';
import ScheduleNotFoundMessageComponent from './scheduleNotFoundMessageComponent';
import { helpingChecksForAPICall, getBusinessMinDate, getBusinessMaxDate, getMinDate, getMaxDate, checkInstructorDaysInAdvanceCheckDate, handleEmptyLocationError, handleEmptyMboSiteError } from './../../../helpers';

export default function IntegratedBusinessClassesTableComponent(props) {
  const { user, business, newSubRequest, setNewSubRequest } = props
  const [ apiCall, setApiCall ] = useState(0)
  const [ requestingInstId, setRequestingInstId ] = useState(parseInt(newSubRequest?.requestingInstructor?.id))
  const [ timeFrame, setTimeFrame ] = useState(newSubRequest?.timeFrame)
  const [ locationId, setLocationId ] = useState(newSubRequest?.locationId)
  const [ schedules, setSchedules ] = useState([])
  const [ resultError, setResultError ] = useState('')
  const [ fetching, setFetching ] = useState(false)
  const [ selectedSchedules, setSelectedSchedules ] = useState([])
  const [ netgymSchedules, setNetgymSchedules ] = useState([])
  const [ newSchedules, setNewSchedules ] = useState([])
  const [ netgymSchedulesEventIds, setNetgymSchedulesEventIds ] = useState([])
  const [ bizMinDate, setBizMinDate ] = useState(getBusinessMinDate(user))
  const [ bizMaxDate, setBizMaxDate ] = useState(getBusinessMaxDate(user))
  const [ minDate, setMinDate ] = useState(getMinDate(user, bizMinDate))
  const [ maxDate, setMaxDate ] = useState(getMaxDate(user, bizMaxDate))
  const [ instructorDaysInAdvanceCheckDate, setInstructorDaysInAdvanceCheckDate ] = useState(checkInstructorDaysInAdvanceCheckDate(user, maxDate))
  const [ emptyLocationError, setEmptyLocationError ] = useState(handleEmptyLocationError(business, newSubRequest) || false)
  const [ emptyMboSiteError, setEmptyMboSiteError ] = useState(handleEmptyMboSiteError(business, newSubRequest) || false)
  const [ fetchingPastSchedules, setFetchingPastSchedules ] = useState(false)
  const [ mboSiteId, setMboSiteId ] = useState(newSubRequest?.mboSiteId || {})

  const fetchSchedules = () => {
    setResultError('')
    setFetching(true)
    setApiCall(apiCall + 1)
    setNewSubRequest(prevNewSubRequest => ({
      ...prevNewSubRequest,
      schedules: [],
      previousWeek: false
    }))
    let url = `/api/v3/business_events/fetch_schedules?time_frame=${newSubRequest?.timeFrame}&requesting_instructor_id=${newSubRequest?.requestingInstructor?.id}&location_id=${newSubRequest?.locationId}&mbo_site_id=${newSubRequest?.mboSiteId?.site_id}`
    axiosGetRequest(url).then((result) => {
      setFetching(false)
      if(result?.status == 200){
        setSchedules(result?.results)
        setNetgymSchedules(result?.netgym_schedules)
        setNewSchedules(result?.new_schedules)
        setNetgymSchedulesEventIds(result?.netgym_schedules_event_ids)
        setResultError('')
        setSelectedSchedules([])
      }else{
        setSchedules([])
        setNetgymSchedules([])
        setNewSchedules([])
        setNetgymSchedulesEventIds([])
        setResultError(result?.error)
        setSelectedSchedules([])
      }
    })
  }

  useState(() => {
    if(helpingChecksForAPICall(newSubRequest, business)){
      fetchSchedules()
    }else{
      setApiCall(apiCall + 1)
    }
  }, [newSubRequest?.requestingInstructor?.id, newSubRequest?.locationId, newSubRequest?.timeFrame])
  
  useEffect(() => {
    const hasValidMboSiteId = newSubRequest?.mboSiteId?.site_id && newSubRequest?.mboSiteId?.site_id !== '';
    const multiSiteFeature = business?.multi_site_multi_staff_feature;

    if(((newSubRequest?.requestingInstructor?.id !== '' && parseInt(newSubRequest?.requestingInstructor?.id) !== parseInt(requestingInstId)) || (newSubRequest?.timeFrame !== '' && (timeFrame !== newSubRequest?.timeFrame)) || (newSubRequest?.locationId !== '' && (parseInt(locationId) !== parseInt(newSubRequest?.locationId))) || (business?.mbo_integration && multiSiteFeature && newSubRequest?.mboSiteId?.site_id !== '' && (parseInt(mboSiteId?.site_id) !== parseInt(newSubRequest?.mboSiteId?.site_id)))) && ((business?.mbo_integration && multiSiteFeature && hasValidMboSiteId) || !business?.mbo_integration || !multiSiteFeature)){
      setRequestingInstId(parseInt(newSubRequest?.requestingInstructor?.id))
      setTimeFrame(newSubRequest?.timeFrame)
      setLocationId(newSubRequest?.locationId)
      setMboSiteId(newSubRequest?.mboSiteId)
      if(apiCall > 0 && helpingChecksForAPICall(newSubRequest, business)){
        fetchSchedules()
      }
    }
    setEmptyLocationError(handleEmptyLocationError(business, newSubRequest))
    setEmptyMboSiteError(handleEmptyMboSiteError(business, newSubRequest))
  }, [newSubRequest])

  const getPastSchedules = () => {
    setFetchingPastSchedules(true)
    let url = `/api/v3/business_events/fetch_schedules?time_frame=previous_week&requesting_instructor_id=${newSubRequest?.requestingInstructor?.id}&location_id=${newSubRequest?.locationId}&mbo_site_id=${newSubRequest?.mboSiteId?.site_id}`
    axiosGetRequest(url).then((result) => {
      setFetchingPastSchedules(false)
      setNewSubRequest(prevNewSubRequest => ({
        ...prevNewSubRequest,
        previousWeek: true
      }))
      if(result?.status == 200){
        setSchedules([...result?.results, ...schedules])
        setSchedules(mergeUniqueArrays(result?.results, schedules))
        setNetgymSchedules([...result?.netgym_schedules, ...netgymSchedules])
        setNewSchedules({...result?.new_schedules, ...newSchedules})
        setNetgymSchedulesEventIds({...result?.netgym_schedules_event_ids, ...netgymSchedulesEventIds})
      }
    })
  }

  function mergeUniqueArrays(arr1, arr2) {
    const map = new Map();
    arr1.forEach(item => {
      map.set(item.class_id, item);
    });
    arr2.forEach(item => {
      map.set(item.class_id, item);
    });
    return Array.from(map.values());
  }

  return(
    <Fragment>
      <div className="flex flex-col gap-2 w-full my-6">
        <div className="flex justify-between items-center">
          <div className="text-base md:text-lg text-black font-semibold">
            Select Classes
          </div>
          <button className="text-sm md:text-base text-black font-semibold need-help-button">
            Need Help?
          </button>
        </div>
        {(fetching || schedules.length == 0) &&
          <ScheduleNotFoundMessageComponent 
            emptyLocationError={emptyLocationError}
            fetching={fetching}
            resultError={resultError}
            emptyMboSiteError={emptyMboSiteError}
          />
        }
        {(!fetching && schedules.length > 0) &&
          <TableComponent 
            schedules={schedules}
            selectedSchedules={selectedSchedules}
            setSelectedSchedules={setSelectedSchedules}
            netgymSchedules={netgymSchedules}
            newSchedules={newSchedules}
            newSubRequest={newSubRequest}
            netgymSchedulesEventIds={netgymSchedulesEventIds}
            user={user}
            fetchSchedules={fetchSchedules}
            instructorDaysInAdvanceCheckDate={instructorDaysInAdvanceCheckDate}
            setNewSubRequest={setNewSubRequest}
            business={business}
            getPastSchedules={getPastSchedules}
            fetchingPastSchedules={fetchingPastSchedules}
          />
        }
      </div>
    </Fragment>
  )
}