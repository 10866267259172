import React, { Fragment, useState } from "react";
import SendIcon from '../../assets/sendIcon';
import LoadingIcon from '../../../subRequests/assets/loadingIcon';
import DatePicker from "react-datepicker";
import { axiosRequest } from '../../../subRequests/helpers/helpers';
import { formatTimestampToDate } from '../../helpers';

export default function CertificationCard(props) {
  const { certification, setCertification, handleShowAlert, handleRedirect } = props
  const [ submitBtnDisabled, setSubmitBtnDisabled ] = useState(false)

  const handleCertChange = (name, val) => {
    setCertification( prevertification => ({
      ...prevertification,
      [name]: val
    }))
  }

  const checkDisabledCondition = () => {
    return(submitBtnDisabled || certification?.key_url == '' || certification?.name === '' || (certification?.category?.expiration_date_required && certification?.expiration_date == ''))
  }

  const handleSubmitCert = (e) => {
    e.preventDefault();
    let url = `/api/v3/certifications/update_status`
    let params = {
      id: certification?.id,
      name: certification?.name,
      expiration_date: certification?.expiration_date == '' ? '' : formatTimestampToDate(certification?.expiration_date),
    }
    setSubmitBtnDisabled(true)
    axiosRequest(url, 'POST', params, 'data').then((response) => {
      if(response.status === 200){
        handleShowAlert(true, response?.message, 'success')
        setTimeout(() => {
          handleShowAlert(false, '', '')
          handleRedirect()
        }, 3000)
      }else{
        handleShowAlert(true, response?.error, 'danger')
        setSubmitBtnDisabled(false)
        setTimeout(() => {
          handleShowAlert(false, '', '')
        }, 3000)
      }
    })
    .catch((error) => {
      handleShowAlert(true, error, 'danger')
      setSubmitBtnDisabled(false)
      setTimeout(() => {
        handleShowAlert(false, '', '')
      }, 3000)
    })
  }

  return(
    <Fragment>
      <div className="w-full p-4 bg-white shadow border border-gray-100 rounded-lg">
        <p className="text-base font-bold break-all border-b border-gray-100">
          {certification?.category?.name}
        </p>
        <div className="flex items-center flex-wrap pt-4 pb-2 w-full lg:gap-4">
          <label htmlFor="Cert-name" className="block text-left text-sm font-medium text-gray-500 w-full lg:w-1/5">Name*</label>
          <input type="text" className="border rounded-md px-2 py-1.5 shadow-sm text-sm h-10 w-full lg:w-3/5 mt-2 lg:mt-0 focus:outline-none" defaultValue={certification?.name} onChange={(e) => handleCertChange('name', e?.target?.value)} name="name" placeholder="Enter Cartification Name"/>
        </div>
        <div className="flex items-center flex-wrap py-4 w-full lg:gap-4">
          <label htmlFor="Cert-expireDate" className="block text-left text-sm font-medium text-gray-500 w-full lg:w-1/5">Expiration Date{certification?.category?.expiration_date_required ? '*' : ''}</label>
          <div className="w-full lg:w-3/5 mt-2 lg:mt-0 relative">
            <DatePicker
              selected={certification?.expiration_date}
              className="h-10 w-full rounded-md border shadow-sm px-2 py-1.5 text-sm text-gray-900 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 cursor-pointer relative z-10 bg-transparent"
              autoFocus={false}
              minDate={new Date()}
              dateFormat="M/d/yyyy"
              onChange={(e) => handleCertChange('expiration_date', e ? e : '')} 
              calendarType="US"
            />
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 absolute top-2 right-2.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
            </svg>
          </div>
        </div>
        <div className='flex mt-4 gap-3 justify-end'>
          <button className={`flex bg-dark-blue hover:bg-gray-800 sm:text-base text-sm text-white inline-block px-5 py-2 border shadow rounded-lg relative gap-2 items-center ${(checkDisabledCondition()) ? 'cursor-not-allowed opacity-50' : ''}`} onClick={(e) => handleSubmitCert(e)} disabled={checkDisabledCondition()}>
            {submitBtnDisabled &&
              <div className="absolute top-0 bottom-0 left-1.5 flex items-center justify-center">
                <LoadingIcon classNames={'animate-spin h-4 w-4 text-white'}/>
              </div>
            }
            <SendIcon classNames={'text-white h-4 w-4'}/>
            <div className='text-white text-base'>Submit</div>
          </button>
        </div>
        {certification?.status == 'submitted' &&
          <div className="w-full mt-4">
            <div className="flex items-center flex-wrap pb-2 w-full flex-wrap gap-2">
              <label htmlFor="Cert-name" className="block text-left text-sm font-semibold text-gray-900 w-26">Submitted By:</label>
              <label htmlFor="Cert-name" className="block text-left text-sm font-semibold text-gray-500">{certification?.submitted_by}</label>
            </div>
            <div className="flex items-center flex-wrap pb-2 w-full flex-wrap gap-2">
              <label htmlFor="Cert-name" className="block text-left text-sm font-semibold text-gray-900 w-26">Submitted On:</label>
              <label htmlFor="Cert-name" className="block text-left text-sm font-semibold text-gray-500">{certification?.submitted_at}</label>
            </div>
          </div>
        }
      </div>
    </Fragment>
  )
}