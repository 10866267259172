import React, { Fragment } from "react";

export default function EmptyCategories({type}) {
  return(
    <Fragment>
      <div className='bg-white shadow-md empty-screen-table-custom-height border border-gray-300 px-6 pt-7 flex justify-center rounded-b-lg items-center'>
        <div className='flex flex-col items-center w-full justify-center h-56'>
          <div className='flex items-center'>
            <div className='flex flex-col'>
              <div className='w-28 bg-gray-300 rounded p-1 relative'>
                <div className='bg-white border border-black rounded px-2.5 py-3'>
                  <div className='flex items-center gap-2'>
                    <div className='w-3 h-3 bg-dark-blue rounded-full'></div>
                    <div className='flex flex-col gap-1.5'>
                      <div className='bg-gray-300 w-8 h-1 rounded-full'></div>
                      <div className='bg-gray-300 w-12 h-1 rounded-full'></div>
                    </div>
                  </div>
                  <div className='flex justify-end mt-2.5 gap-0.5'>
                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                  </div>
                </div>
                <div className='absolute -bottom-2 left-2'>
                  <img src='/assets/bottom-angle.png' />
                </div>
              </div>
              <div className='w-28 bg-gray-300 rounded p-1 relative mt-3 ml-12'>
                <div className='bg-white border border-black rounded px-2.5 py-3'>
                  <div className='flex items-center gap-2'>
                    <div className='w-3 h-3 bg-dark-blue rounded-full'></div>
                    <div className='flex flex-col gap-1.5'>
                      <div className='bg-gray-300 w-8 h-1 rounded-full'></div>
                      <div className='bg-gray-300 w-12 h-1 rounded-full'></div>
                    </div>
                  </div>
                  <div className='flex justify-end mt-2.5 gap-0.5'>
                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                    <div className='bg-gray-200 w-1 h-1 rounded-full'></div>
                  </div>
                </div>
                <div className='absolute -bottom-2 right-2 transform rotate-90'>
                  <img src='/assets/bottom-angle.png' />
                </div>
              </div>
            </div>
            <div className='ml-9'>
              <div className='text-gray-500 text-sm mb-3'>{`No ${type ? type : 'categories'} found`}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}