import React, { Fragment } from "react";

export default function MessageTabIcon({classNames, isActive}) {

  return(
    <Fragment>
      {isActive ?
        <svg aria-hidden="true" viewBox="0 0 20 20" className={classNames}><path fill="currentColor" fillRule="evenodd" d="M10 1.5a8.5 8.5 0 1 0 3.859 16.075l3.714.904a.75.75 0 0 0 .906-.906l-.904-3.714A8.5 8.5 0 0 0 10 1.5" clipRule="evenodd"></path></svg>
      :
        <svg aria-hidden="true" viewBox="0 0 20 20" className={classNames}><path fill="currentColor" fillRule="evenodd" d="M10 3a7 7 0 1 0 3.394 13.124.75.75 0 0 1 .542-.074l2.794.68-.68-2.794a.75.75 0 0 1 .073-.542A7 7 0 0 0 10 3m-8.5 7a8.5 8.5 0 1 1 16.075 3.859l.904 3.714a.75.75 0 0 1-.906.906l-3.714-.904A8.5 8.5 0 0 1 1.5 10" clipRule="evenodd"></path></svg>
      }
    </Fragment>
  )
}