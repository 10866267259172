import React, { Fragment } from "react";

export default function EmptyMyCertifications() {

  return(
    <Fragment>
      <div className="mt-5">
        <div className="bg-white shadow-md items-center justify-between rounded-lg px-3 py-7 mb-2 text-center text-lg text-gray-800">
          You're all set! There are no certifications that you're required to submit.
        </div>
      </div>
    </Fragment>
  )
}