function getHeaders(token, subdomain){
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID")
    }
    return obj
  }
}

export const getReports = async (user_token, subdomain) => {
  return await fetch(`/api/v3/abc/reports`,{
    headers: getHeaders(user_token, subdomain)
  });
}

export const downloadReport = async (user_token, subdomain, name) => {
  return await fetch(`/api/v3/abc/reports/download?file_name=${name}`,{
    headers: getHeaders(user_token, subdomain)
  });
}