import React, { useState, useCallback } from "react";
import { axiosGetRequest, axiosRequest } from '../../../../../subRequests/helpers/helpers';
import { debounce } from "lodash";
import LoadingIcon from '../../../../../subRequests/assets/loadingIcon';

export default function MergeMindbodyProfilesComponent(props) {
  const { userId, closeAndReload, showAlert } = props;
  const [ searchValue, setSearchValue ] = useState('');
  const [ selectedUser, setSelectedUser ] = useState('');
  const [ usersList, setUsersList ] = useState([]);
  const [ showLoading, setShowLoading ] = useState(false);

  const handleChange = (e) => {
    let val = e.target.value
    setSearchValue(val);
    if(val == '') {
      setSelectedUser('')
      return
    }
    searchInstructors(val);
  };

  const searchInstructors = useCallback(
    debounce((val) => {
      fetchInstructors(val.trim())
    }, 800),
    []
  );

  const fetchInstructors = (val) => {
    if(val == '') return
    setShowLoading(true)
    axiosGetRequest(`/api/v3/get_all_users?user_id=${userId}&text=${val}`).then(result => {
      setShowLoading(false)
      if(result?.status == 200){
        setUsersList(result?.users)
      }
    })
  }

  const handleSelectEmail = (e, usr) => {
    e.preventDefault();
    setSelectedUser(usr);
    setSearchValue(usr?.email);
    setUsersList([]);
  }

  const checkDisabled = () => {
    if(selectedUser == '') return true
    return false
  }

  const handleMerge = (e) => {
    e.preventDefault();
    if(checkDisabled()) return

    let url = '/api/v3/mindbody/merge_profiles'
    let params = {
      user_id: selectedUser?.id,
      duplicate_user_id: userId
    }

    setSelectedUser('');
    axiosRequest(url, 'PATCH', params, 'data').then(result => {
      if(result?.status == 200){
        showAlert(true, 'success', result?.success)

        processDelete()
      } else {
        showAlert(true, 'danger', result?.error)
      }
      setTimeout(()=>{
        showAlert(false, '', '')
      }, 3000)
    })
  }

  const processDelete = () => {
    let deleteUrl = `/api/v3/users/${userId}`
    axiosRequest(deleteUrl, 'DELETE', {}, 'data').then(result => {
      if(result?.status == 200){
        closeAndReload()
      }
    })
  }
  
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start pb-4">
      <label className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">&nbsp;</label>
      <div className="mt-1 sm:mt-0 sm:col-span-1">
        <div className="lg:w-80 w-full text-center font-semibold mb-2.5 text-sm">Merge Mindbody Profiles</div>
        <div id='showAnalyticsInstructorslistInput' className="relative w-full">
          <div className='lg:w-80 w-full bg-white border border-gray-300 shadow-sm rounded-md h-9 flex items-center'>
            <input type="text" className='text-sm lg:w-80 w-full border-0 h-9 ml-1.5 focus:outline-none bg-transparent' placeholder='' value={searchValue} onChange={(e) => handleChange(e)} />
            {showLoading &&
              <LoadingIcon classNames={'animate-spin h-4 w-4 text-black mr-3'}/>
            }
          </div>
          {(usersList?.length > 0) &&
            <div id='targetshowAnalyticsInstructorslist1' className="relative block text-left">
              <div className="origin-top-right z-20 absolute left-0 right-0 lg:w-80 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none py-2">
                <div className="py-2 max-h-60 overflow-y-auto">
                  <div>
                    {usersList.map((user, i) => 
                      <a key = {i} className="flex items-center text-gray-900 text-sm px-3 cursor-pointer hover:bg-gray-100 py-2.5" onClick= {(e) => {handleSelectEmail(e, user)}}>
                        <div className='pl-1'>{user?.email}</div>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          }
          <button className={`lg:w-80 w-full flex justify-center items-center text-xs sm:text-base font-medium rounded-md text-white px-6 py-3 gap-2 mt-4 ${checkDisabled() ? 'bg-gray-400 cursor-not-allowed' : 'bg-dark-blue'}`} onClick={(e) => {handleMerge(e)}} disabled={checkDisabled()}>Merge</button>
          <div className="lg:w-80 w-full text-red-600 text-sm text-center mt-2.5">
            Merging this profile into another one will move all of the above mbo profiles to the other user and delete this user.
          </div>
        </div>
      </div>
    </div>
  )
}