import React, { Fragment, useEffect, useState } from "react";
import { InfoIcon } from '../../../subRequestDetails/assets';
import SearchComponent from './searchComponent';
import InstructorTableComponent from './instructorTableComponent';
import SecondStepNextButtonComponent from './secondStepNextButtonComponent';
import MultipleClassesMessageComponent from './multipleClassesMessageComponent';
import { axiosRequest } from './../../../subRequests/helpers/helpers';
import AlertPopup from './../../../messenger/pages/chatComponents/assets/alertPopup'
import { getSchedulesData, setUrlByPageNo, formattedErrorMessage } from '../../helpers';
import ReactTooltip from "react-tooltip";

export default function SecondStepComponents(props) {
  const { newSubRequest, setStep, setNewSubRequest, user, business } = props
  const [ showMultipleClassPopup, setShowMultipleClassPopup ] = useState(false)
  const [ instructors, setInstructors ] = useState([])
  const [ instructorsList, setInstructorsList ] = useState([])
  const [ instructorMessage, setInstructorMessage ] = useState('')
  const [ loaded, setLoaded ] = useState(false)
  const [ showAlert, setShowAlert ] = useState(false)
  const [ alertType, setAlertType ] = useState('')
  const [ alertMessage, setAlertMessage ] = useState('')
  const [ showPopupWithError, setShowPopupWithError ] = useState(false)
  const [ showErrorMessage, setShowErrorMessage ] = useState('')
  const [ showDisableInstructors, setShowDisableInstructors ] = useState(false)

  const closeAlert = (data) => {
    handleAlert(data, '', '')
  }

  useEffect(() => {
    fetchInstructors()
  }, [])

  const fetchInstructors = () => {
    if (newSubRequest?.schedules?.length == 0){
      setAlertType('danger')
      setAlertMessage('Please select atleast one class.')
      setShowAlert(true)
      window.setTimeout(() => {
        setShowAlert(false)
        setAlertType('')
        setAlertMessage('')
      }, 5000)
      setStep(1)
      setUrlByPageNo(1)
      return
    }
    let url = '/api/v3/business_events/get_instructors'
    let paramsData = getSchedulesData(newSubRequest?.schedules)
    let params = {
      ...paramsData, 
      requesting_instructor_id: newSubRequest?.requestingInstructor?.id,
      text: '',
      mbo_site_id: newSubRequest?.mboSiteId?.site_id
    }
    axiosRequest(url, 'POST', params, 'data').then(result => {
      if(result?.status == 200){
        setShowDisableInstructors(result?.show_instructors_disabled)
        if(result?.instructor_message !== ''){
          setInstructorMessage(result?.instructor_message)
          setShowMultipleClassPopup(true)
        }else if(result?.intructor_default_message !== ''){
          setInstructorMessage(result?.intructor_default_message)
          setShowMultipleClassPopup(true)
        }
        setInstructors(result?.results)
        setInstructorsList(result?.results)    
        setNewSubRequest(prevNewSubRequest => ({
          ...prevNewSubRequest,
          secondSkillCheck: result?.second_skill_used
        }))
        setLoaded(true)
      }else{
        setShowErrorMessage(formattedErrorMessage(result?.error))
        setShowPopupWithError(true)
        setShowMultipleClassPopup(true)
        setShowDisableInstructors(false)
        setNewSubRequest(prevNewSubRequest => ({
          ...prevNewSubRequest,
          secondSkillCheck: []
        }))
      }
    })
  }


  const closePopup = (data) => {
    setShowMultipleClassPopup(data)
    if(showPopupWithError){
      setShowPopupWithError(false)
      setStep(1)
      setUrlByPageNo(1)
    }
  }

  return(
    <Fragment>
      {showAlert &&
        <AlertPopup alert_type={alertType} alert_message={alertMessage} closeAlert={closeAlert} />
      }
      {showMultipleClassPopup &&
        <MultipleClassesMessageComponent showMultipleClassPopup={showMultipleClassPopup} closeMultipleClassPopup={closePopup} message={instructorMessage} showPopupWithError={showPopupWithError} showErrorMessage={showErrorMessage} errorVideoLink={''}/>
      }
      {!loaded &&
        <div className="msg-loading flex flex-col items-center w-full justify-center h-96">
          <img className = "w-9" src="/assets/business/loading.gif" />
        </div>
      }
      {loaded &&
        <div className="flex flex-col gap-2 w-full">
          <div className="flex items-center gap-2">
            <div className="text-base md:text-lg text-black font-semibold">
              Invite Instructor(s) to Sub
            </div>
            <div className="flex" data-tip data-for={'invite_instructor_page_heading_info_icon'}>
              <InfoIcon classNames={'w-4 h-4'} customColour={'#a3a3a3'}/>
              <ReactTooltip className='max-w-xs shadow-md font-semibold text-xs' id={'invite_instructor_page_heading_info_icon'} place="top" effect="solid">
                These are the instructors who will be invited to sub based on skill, locations, and availability of their profile for this sub request
              </ReactTooltip>
            </div>
          </div>
          <SearchComponent
            instructorsList={instructorsList}
            setInstructors={setInstructors}
          />
          <InstructorTableComponent
            instructors={instructors}
            instructorsList={instructorsList}
            instructorMessage={instructorMessage}
            setNewSubRequest={setNewSubRequest}
            showDisableInstructors={showDisableInstructors}
          />
          <SecondStepNextButtonComponent 
            newSubRequest={newSubRequest}
            setStep={setStep}
            user={user} 
            business={business}
          />
        </div>
      }
    </Fragment>
  )
}