import React, { Component, Fragment } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import ReactTooltip from "react-tooltip";
import DatePicker from 'react-date-picker';
import { integration_enabled, checkUnsyncedSkills, checkAllUnsyncedSkillsSelected } from './helpers'
import { getCountriesAbbr, getPhoneNumberValue } from '../../myProfile/helpers';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber, getCountryCallingCode } from "react-phone-number-input";
import MergeMindbodyProfilesComponent from './usersPageIntegrationComponents/components/mergeMindbodyProfilesComponent';

export default class UserForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      showModal: false,      
      formType: '',
      business: {},
      roles: [],
      user: {},
      regionWithLocations: [],
      time_zones:[],
      selectedRegions: [],
      selectedLocations: [],
      skills: [],
      selectedSkills: [],
      invalidFields: [],
      managedRegions: [],
      managedLocations: [],
      business_skill_ids: [],
      business_location_ids: [],
      business_region_ids: [],
      manager_location_ids: [],
      manager_region_ids: [],
      admin_region_ids: [],
      add_user_in_classes: false,
      hire_date: new Date(),
      show_date: new Date(),
      rolesName: [],
      propsSkills: [],
      propsLocations: [],
      checkSkills: [],
      checkLocations: [],
      showFormOther: null,
      teachingRegionWithLocations: [],
      teachingRegionLocationsWithSiteIds: [],
      teachingLocationForCrunch: [],
      currentUser: {},
      currentUserPermissions: {},
      locationsList: [],
      userPrevRole: '',
      userPrevManLoc: [],
      userPrevManReg: [],
      sendActivationEmail: false,
      userSyncSkills: [],
      dataReady: false,
      firstTime: true,
      manager_locations_ids: [],
      manager_regions_ids: [],
      countryList: getCountriesAbbr(),
      phoneNumber: undefined,
      defaultCountry: 'US',
      validPhoneNumber: true,
      countryCodes: this.allCountryCodes(),
      unSynckedSkills: [],
      selectedCertificationIds: [],
      certificationCategories: [],
      certificationCategoriesIds: [],
    }    
  }

	closeModal = (e) => {
    this.setState({
      showModal: false
    })
    this.props.setShowModal()
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.dataReady){
      if(this.state.firstTime){
        let certificationCategoriesIds = nextProps?.certificationCategories?.map(item => item.id) || [];
        this.setState({
          user: nextProps.user,
          userPrevRole: nextProps?.user?.role,
          showModal: nextProps.showModal,
          formType: nextProps.formType,
          submitted: false,
          business: nextProps.business,
          regionWithLocations: nextProps.regionWithLocations,
          locationsList: this.getLocationsList(nextProps.regionWithLocations),//nextProps.regionWithLocations.map((item)=> {return item.locations.map((loc)=> {return loc})}),
          teachingRegionWithLocations: nextProps.teachingRegionWithLocations,
          roles: nextProps.roles,
          time_zones: nextProps.time_zones,
          skills: nextProps.skills,
          // selectedLocations: nextProps.user?.business_location_ids || [],
          selectedLocations: nextProps.user?.show_locs || [],
          selectedSkills: nextProps.user?.business_skill_ids || [],
          propsSkills: nextProps.user?.business_skill_ids || [],
          propsLocations: nextProps.user?.business_location_ids || [],
          manager_locations_ids: nextProps.user?.manager_location_ids || [],
          manager_regions_ids: (nextProps.user?.manager_region_ids || nextProps.user?.admin_region_ids)|| [],
          add_user_in_classes: nextProps.user?.add_user_in_classes,
          show_date: this.setDate(nextProps.user?.hire_date) || new Date(),
          marianatek_profile_id: nextProps.user?.marianatek_profile_id,
          external_id: nextProps.user?.external_id,
          mindbody_id: nextProps.user?.mindbody_id,
          mbo_site_id: nextProps.user?.mbo_site_id,
          showFormOther: nextProps.showFormOther,
          showFormOtherTeaching: nextProps.showFormOtherTeaching,
          currentUser: nextProps.currentUser,
          currentUserPermissions: nextProps.currentUserPermissions,
          userSyncSkills: nextProps.userSyncSkills,
          dataReady: nextProps.dataReady,
          phoneNumber: getPhoneNumberValue(nextProps.user?.directory_phone_number),
          validPhoneNumber: this.validatePhoneNumber(nextProps.user?.directory_phone_number),
          unSynckedSkills: checkUnsyncedSkills(nextProps.skills, nextProps.userSyncSkills, nextProps.user?.business_skill_ids || []),
          certificationCategories: nextProps.certificationCategories,
          certificationCategoriesIds: certificationCategoriesIds,
          selectedCertificationIds: nextProps.user?.certification_categories
        })
        this.checkSelectedRegions(nextProps.user?.show_locs || [], nextProps.regionWithLocations)
        if(nextProps.user.role === 'regional_admin'){
          this.checkManagedLocations(nextProps.user?.manager_location_ids || [], nextProps.regionWithLocations, nextProps.user?.admin_region_ids, nextProps.user.role) 
        }else{
          this.checkManagedLocations(nextProps.user?.manager_location_ids || [], nextProps.regionWithLocations, (nextProps.user?.manager_region_ids || nextProps.user?.admin_region_ids)|| [], nextProps.user.role) 
        }
        // if((this.state.regionWithLocations?.length < 2 && this.state.regionWithLocations[0]?.region?.name === "Others") || this.state.regionWithLocations?.length === 0){
        //   const filtered = nextProps.roles.filter(role => role.value !== "regional_admin");
        //   this.setState({roles: filtered})
        // }
        if(nextProps.roles){
          let roles = nextProps.roles
          let rolesName = []
          for (let i = 0; i < roles?.length; i++){
            rolesName.push(roles[i].value)
          }
          this.setState({
            rolesName: rolesName
          })
        }

        if(nextProps.business?.mbo_integration){
          let user_mbo_site_ids = (nextProps.business?.multi_site_multi_staff_feature) ? nextProps.user?.mindbody_profile_site_ids : [nextProps.user?.mbo_site_id]
          this.showTeachingRegionLocationsWithSiteIds(user_mbo_site_ids, nextProps.teachingRegionWithLocations)
        }
        if(nextProps.business?.crunch_integration){
          this.showTeachingRegionLocationsWithCrunchTeachingLocations(nextProps.user, nextProps.teachingRegionWithLocations)
        }
        this.setState({firstTime: false})
      }
    }
  }

  getLocationsList = (regionWithLocations) => {
    let all_locations = []
    for (let i = 0; i<regionWithLocations?.length; i++){
      all_locations = [...all_locations, ...regionWithLocations[i]?.locations.map((loc)=> {return loc})]
    }
    return all_locations
  }

  showTeachingRegionLocationsWithCrunchTeachingLocations = (user, all_teachingLocations) => {
    let trachingLocations = user?.teaching_locations
    let teachingLocationForCrunch = []
    if(trachingLocations && trachingLocations?.length > 0){
      for( let i = 0; i < all_teachingLocations?.length; i++){
        let locations = []
        let region = {}
        for(let j = 0; j < all_teachingLocations[i].locations?.length; j++){
          if(trachingLocations?.includes(all_teachingLocations[i].locations[j].id)){
            locations.push(all_teachingLocations[i].locations[j])
          }
        }
        if(locations?.length > 0){
          region = all_teachingLocations[i].region
          teachingLocationForCrunch.push({'locations': locations, 'region': region})
        }
      }
      if(teachingLocationForCrunch?.length === 1 && (teachingLocationForCrunch[0]?.region?.name === 'other' || teachingLocationForCrunch[0]?.region?.name === 'Other' || teachingLocationForCrunch[0]?.region?.name === 'others' || teachingLocationForCrunch[0]?.region?.name === 'Others')){
        this.setState({showFormOtherTeaching: false})
      }else{
        this.setState({showFormOtherTeaching: true})
      }
      let selectedLocation = this.state.selectedLocations
      for(let i = 0; i < teachingLocationForCrunch?.length; i++){
        let region = teachingLocationForCrunch[i]
        if(region){
          this.checkRegionVisibilityForMBOSiteRegions(region, selectedLocation)
        }
      }
    } else {
      if(this.state.formType === 'add'){
        teachingLocationForCrunch = all_teachingLocations
      }
      else{
        teachingLocationForCrunch = []
      }
    }
    this.setState({teachingLocationForCrunch: teachingLocationForCrunch})
  }

  showTeachingRegionLocationsWithSiteIds = (mbo_site_ids, teachingRegionWithLocations) => {
    if(mbo_site_ids){
      let site_ids = mbo_site_ids?.map(site_id => parseInt(site_id)) || [];
      let allTeachingRegionLocations = teachingRegionWithLocations
      let selectedLocationsWithSiteId = []
      for(let i = 0; i < allTeachingRegionLocations?.length; i++){
        let locations = []
        let region = {}
        for(let j = 0; j < allTeachingRegionLocations[i].locations?.length; j++){
          if(site_ids?.includes(allTeachingRegionLocations[i].locations[j].mbo_site_id)){
            locations.push(allTeachingRegionLocations[i].locations[j])
          }
        }
        if(locations?.length > 0){
          region = allTeachingRegionLocations[i].region
          selectedLocationsWithSiteId.push({'locations': locations, 'region': region})
        }
      }
      if(selectedLocationsWithSiteId?.length === 1 && (selectedLocationsWithSiteId[0]?.region?.name === 'other' || selectedLocationsWithSiteId[0]?.region?.name === 'Other' || selectedLocationsWithSiteId[0]?.region?.name === 'others' || selectedLocationsWithSiteId[0]?.region?.name === 'Others')){
        this.setState({showFormOtherTeaching: false})
      }else{
        this.setState({showFormOtherTeaching: true})
      }
      let selectedLocation = this.state.selectedLocations
      for(let i = 0; i < selectedLocationsWithSiteId?.length; i++){
        let region = selectedLocationsWithSiteId[i]
			  if(region){
          this.checkRegionVisibilityForMBOSiteRegions(region, selectedLocation)
        }
      }
      this.setState({teachingRegionLocationsWithSiteIds: selectedLocationsWithSiteId})
    }
    else{
      this.setState({teachingRegionLocationsWithSiteIds: []})
      // this.setState({teachingRegionLocationsWithSiteIds: teachingRegionWithLocations})
    }
  }

  checkRegionVisibilityForMBOSiteRegions = (region, locations) =>{
    let location_ids = region?.locations?.map(function(a) {return a.id;});
    let checker = (arr, target) => target.some(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name
    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        let sl = this.state.selectedRegions
        sl.push(val)

        this.setState(state => ({ selectedRegions: sl }))
      }
    }else{
      if(__FOUND){
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
  }

  setDate = (date) => {
    if(this.state.business?.abc_integration && this.state.formType === "edit"){
      if(date === ""){
        var d = new Date()
      }else{
        let arr = date?.split("/")
        let day = arr[1]
        let month = arr[0]
        let year = arr[2]  
        var d = new Date(parseInt(year),parseInt(month)-1,parseInt(day));
      }
      return d
    }
  }

  checkManagedLocations(manager_locations_ids, regionWithLocations, manager_regions_ids, user_role){
    if(user_role === 'regional_admin'){
      for (var i = 0; i < regionWithLocations.length; i++){
        if(manager_regions_ids?.includes(parseInt(regionWithLocations[i].region?.id))){
          let val = regionWithLocations[i].region?.name
          var __FOUND = this.state.managedRegions.find(function(region, index) {
            if(region == val)
              return true;
            });
          if(!__FOUND){
            this.setState(state => ({ 
              managedRegions: [...state.managedRegions, val],
              userPrevManReg: [...state.managedRegions, val]
            }));
          } 
        } 
      }
    }
    else{  
      for (var i = 0; i < regionWithLocations.length; i++){
        for (var j = 0; j < regionWithLocations[i].locations.length; j++){
          if(manager_locations_ids?.includes(parseInt(regionWithLocations[i].locations[j].id))){
            let val = regionWithLocations[i].region?.name
            let val2  = regionWithLocations[i].locations[j]?.id
            var __FOUND = this.state.managedRegions.find(function(region, index) {
                if(region == val)
                  return true;
              });
            if(!__FOUND){
              this.setState(state => ({ 
                managedRegions: [...state.managedRegions, val],
                managedLocations: [...state.managedLocations, val2],
                userPrevManReg: [...state.managedRegions, val],
                userPrevManLoc: [...state.managedLocations, val2]
                }));
            }
          }
        }
      }
    }
  }

  checkSelectedRegions = (selectLoc, allregionWithLocations)=>{
    let selectedloc = selectLoc;
    let allRegionWithLocations = allregionWithLocations;
    for (var i = 0; i < allRegionWithLocations.length; i++){
      for (var j = 0; j < allRegionWithLocations[i].locations.length; j++){
        if(selectedloc?.includes(parseInt(allRegionWithLocations[i].locations[j].id))){
          
          let val = allRegionWithLocations[i].region?.name
          var __FOUND = this.state.selectedRegions.find(function(region, index) {
              if(region == val)
                return true;
            });
            if(!__FOUND){
              this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
            }

        }
      }
    
    }
  }

  selectRegions = (e, region) => {
    let val = e.target.value

    var __FOUND = this.state.selectedRegions.find(function(region, index) {
      if(region == val)
        return true;
    });

    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.selectedLocations;
    let checkLocations = this.state.checkLocations
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        selected_locations = Array.prototype.push.apply(selected_locations, result)
        for(let i = 0; i < result?.length; i++){
          if(!checkLocations?.includes(result[i])){
            //if(!this.state.propsLocations?.includes(result[i])){
              checkLocations.push(result[i])
            //}
          }
        }
        this.checkAddUserInClass(this.state.checkSkills, checkLocations)
      }
    }else{
      if(__FOUND){
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        let newcheckLoc = checkLocations.filter((item) => !result.includes(item))
        this.setState({
          selectedLocations: selected_locations,
          checkLocations: newcheckLoc
        })
        this.checkAddUserInClass(this.state.checkSkills, newcheckLoc)
      }
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations?.includes(val)
    let checkLocations = this.state.checkLocations
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedLocations: [...state.selectedLocations, val] }));
        if(!this.state.propsLocations.includes(val)){
          //this.setState(state => ({ checkLocations: [...state.checkLocations, val]}));
          checkLocations.push(val)
        }
        this.checkAddUserInClass(this.state.checkSkills, checkLocations)
        this.checkRegionVisibility(region, [...this.state.selectedLocations, val])
      }
    }else{
      if(__FOUND){
        const { selectedLocations, checkLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val) 
        let newcheckLoc = checkLocations.filter((tag, index) => tag !== val) 
        this.setState({
          selectedLocations: newLocs,
          checkLocations: newcheckLoc       
        });
        this.checkAddUserInClass(this.state.checkSkills, newcheckLoc)
        this.checkRegionVisibility(region, newLocs)
      }
      
    }
  }

  selectManageRegions = (e, region) => {
    let val = e.target.value

    var __FOUND = this.state.managedRegions.find(function(region, index) {
      if(region == val)
        return true;
    });

    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.managedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ managedRegions: [...state.managedRegions, val] }));
        selected_locations = Array.prototype.push.apply(selected_locations, result)
      }
    }else{
      if(__FOUND){
        const { managedRegions } = this.state;
        this.setState({
          managedRegions: managedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          managedLocations: selected_locations
        })
      }
    }
  }

  selectManageLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.managedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ managedLocations: [...state.managedLocations, val] }));
        this.checkRegionVisibility2(region, [...this.state.managedLocations, val])
      }
    }else{
      if(__FOUND){
        const { managedLocations } = this.state;
        let newLocs = managedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          managedLocations: newLocs        
        });
        this.checkRegionVisibility2(region, newLocs)
      }      
    }
  }

  selectSkill = (e, skill) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedSkills?.includes(val)
    let checkSkills = this.state.checkSkills
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedSkills: [...state.selectedSkills, val] }));
        if(!this.state.propsSkills.includes(val)){
          //this.setState(state => ({checkSkills: [...state.checkSkills, val]}));
          checkSkills.push(val)
        }
        this.checkAddUserInClass(checkSkills, this.state.checkLocations)
      }
    }else{
      if(__FOUND){
        const { selectedSkills, checkSkills } = this.state;
        let newLocs = selectedSkills.filter((tag, index) => tag !== val)  
        let newcheckSkill =checkSkills.filter((tag, index) => tag !== val)
        this.setState({
          selectedSkills: newLocs,
          checkSkills: newcheckSkill       
        });
        this.checkAddUserInClass(newcheckSkill, this.state.checkLocations)
      }
      
    }
  }

  checkClubreadyAndFisikalIntegration = () => {
    const { business } = this.state
    return (business?.fisikal_integration || business?.clubready_integration)
  }

  handleSelectAllSkill = (e) => {
    let checkSkills = this.state.checkSkills
    let skills =  this.checkClubreadyAndFisikalIntegration() ? this.state.unSynckedSkills : this.state.skills
    
    let allSkillsIds = skills.map((item) => {return item?.id})
    
    if(e.target.checked){
      this.setState(state => ({ selectedSkills: allSkillsIds }));
      let checkSkillArr = allSkillsIds.filter((item) => !this.state.propsSkills.includes(item))
      if(checkSkillArr?.length > 0){
        checkSkills = [...checkSkills, ...checkSkillArr]
      }
      this.checkAddUserInClass(checkSkills, this.state.checkLocations)
    }
    else{
      const { selectedSkills, checkSkills } = this.state;
      if (this.checkClubreadyAndFisikalIntegration()) allSkillsIds = allSkillsIds.filter(item => !this.state.userSyncSkills.includes(item));
      let newLocs = selectedSkills.filter((tag, index) => !allSkillsIds?.includes(tag))  
      let newcheckSkill =checkSkills.filter((tag, index) => !allSkillsIds?.includes(tag))
      this.setState({
        selectedSkills: newLocs,
        checkSkills: newcheckSkill       
      });
      this.checkAddUserInClass(newcheckSkill, this.state.checkLocations)
    }
  }

  checkAllSkillsSelected = () => {
    const { skills, selectedSkills, unSynckedSkills } = this.state
    if(this.checkClubreadyAndFisikalIntegration()){
      return checkAllUnsyncedSkillsSelected(selectedSkills, unSynckedSkills)
    }
    return skills?.length === selectedSkills?.length
  }

  skillsName = (skill) => {
    const { skills } = this.state
    let skillName = skills.filter((item) => item?.id === skill)
    if(skillName?.length > 0){
      return skillName[0]?.name
    }else{
      return skill
    }
  }

  removeSelectedSkills = (skill) => {
    const { selectedSkills } = this.state
    let remainSkills = selectedSkills.filter(item =>  item !== skill)
    this.setState({selectedSkills: remainSkills})
  }

  checkRegionVisibility = (region, selectedLocations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});    
    const found = location_ids.some(r=> selectedLocations.includes(r))
    if(!found){
      this.setState({
        selectedRegions: this.state.selectedRegions.filter((tag, index) => tag !== region.region.name)          
      });
    }else{
      if(!this.state.selectedRegions.includes(region.region.name)){
        this.setState({
          selectedRegions: [...this.state.selectedRegions, region.region.name]         
        });
      }
    }
  }  

  checkRegionVisibility2 = (region, selectedLocations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});    
    const found = location_ids.some(r=> selectedLocations.includes(r))
    if(!found){
      this.setState({
        managedRegions: this.state.managedRegions.filter((tag, index) => tag !== region.region.name)          
      });
    }else{
      if(!this.state.managedRegions.includes(region.region.name)){
        this.setState({
          managedRegions: [...this.state.managedRegions, region.region.name]         
        });
      }
    }
  }

  handleDateChange = (e) => {
    var startDate = new Date(e);
    var day = 60 * 60 * 24 * 1000;
    let d = new Date(startDate.getTime() + day);
    this.setState({
      hire_date: d,
      show_date: e
    })
  }

  handleChange = e => {
    const { name, value } = e.target;
    const { user } = this.state;

    if(name === "home_club_id" && value !== ''){
      let value = parseInt(e.target.value)
      let selected_locations = this.state.selectedLocations
      let checkLocations = this.state.checkLocations
      if(!selected_locations?.includes(value) && !checkLocations.includes(value)){
        selected_locations.push(value)
        checkLocations.push(value)
      }
      this.checkAddUserInClass(this.state.checkSkills, checkLocations)
      let regionWithLocations = this.state.regionWithLocations
      let region = ""
      for(let i = 0; i < regionWithLocations?.length; i++){
        for(let j = 0; j < regionWithLocations[i].locations?.length; j++){
          if(regionWithLocations[i].locations[j]?.id === value){
            region = regionWithLocations[i].region?.name
          }
        }
      }
      if(!this.state.selectedRegions?.includes(region)){
        this.setState(state => ({selectedRegions: [...state.selectedRegions, region]}));
      }

     //this.checkRegionVisibility(region, selected_locations)
    }

    if(name === "role"){
      if(value === this.state.userPrevRole){
        this.setState({
          managedRegions: this.state.userPrevManReg,
          managedLocations: this.state.userPrevManLoc
        })
      }else{
        this.setState({
          managedRegions: [],
          managedLocations: []
        })
      }
    }

    if(name === "mbo_site_id"){
      let teachingRegionWithLocations = this.state.teachingRegionWithLocations
      this.showTeachingRegionLocationsWithSiteIds([value], teachingRegionWithLocations)
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
    }

    if(name === "external_id" || name === "marianatek_profile_id"){
      let val=value.replace(/\s+/g, '');
      this.setState({
        user: {
          ...user,
          [name]: val
        }
      });
    }if(name === "mbo_site_id") {
      this.setState({
        user: {
          ...user,
          [name]: value,
          mindbody_id: ''
        }
      });
    }else{
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const { first_name, last_name, email, mindbody_id, mbo_site_id, marianatek_profile_id, external_id, employee_id } = this.state.user;
    const { marianatek_integration, abc_integration, mbo_integration, crunch_integration, clubready_integration, fisikal_integration } = this.state.business;
    let business =  this.state.business
    let currentUser = this.state.currentUser
    let formType = this.state.formType
    let currentUserPermissions = this.state.currentUserPermissions
    this.setState({
      submitted: true
    });

    if(this.checkSubmitFormCommonCheck(first_name, last_name, formType, currentUserPermissions, currentUser, email, employee_id, abc_integration, crunch_integration, clubready_integration, fisikal_integration, external_id, marianatek_integration, marianatek_profile_id, mbo_integration, mindbody_id, mbo_site_id, business)){
      if(this.state.invalidFields.length == 0){
        this.submitForm()
      } 
      else {
        console.log("Error creating User");
      }
    }
  }    

  validateRoleWise = () => {
    const { managedRegions, managedLocations } = this.state
    const { role } = this.state?.user
    if(role === ''){
      return false
    }else if(role === 'regional_admin'){
      if(managedRegions?.length === 0){
        return false
      }else{
        return true
      }
    }else if(role === 'fitness_manager'){
      if(managedLocations?.length === 0){
        return false
      }else{
        return true
      }
    }else{
      return true
    }
  }

  submitForm = () => {
    let final_request = this.setRequest()
    if(this.state.formType === "edit"){
      this.props.editUser(final_request)
    }  
    else{
      this.props.addUser(final_request)
    }
  }

  setRequest = () => {    
    let { user } = this.state
    let { selectedRegions, regionWithLocations, managedRegions } = this.state    
    let business_region_ids = []
    let manage_region_ids = []
    for(var i=0; i< regionWithLocations.length; i++){
      if(selectedRegions.includes(regionWithLocations[i].region?.name)){
        if(regionWithLocations[i].region?.id !== ""){
          business_region_ids.push(regionWithLocations[i].region?.id)
        }
      }

      if(managedRegions.includes(regionWithLocations[i].region?.name)){
        if(regionWithLocations[i].region?.id !== ""){
          manage_region_ids.push(regionWithLocations[i].region?.id)
        }
      }

    }

    let saved_locs = user?.saved_locs || []
    let req = {
      id: user.id,
      role: user.role,
      business_skill_ids: this.state.selectedSkills,
      business_location_ids: [...this.state.selectedLocations, ...saved_locs],
      manager_location_ids: this.state.managedLocations,
      add_user_in_classes: this.state.add_user_in_classes,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      home_club_id: user.home_club_id,
      time_zone_string: user.time_zone_string,
      business_region_ids: business_region_ids,
      external_id: user.external_id,
      marianatek_profile_id: user.marianatek_profile_id,
      hire_date: this.state.hire_date,
      mindbody_id: user.mindbody_id,
      mbo_site_id: user.mbo_site_id,
      send_activation_email: this.state.sendActivationEmail,
      employee_id: user.employee_id,
      directory_phone_number: this.state?.phoneNumber || '',
      certification_ids: this.state?.selectedCertificationIds || [],
    }
    if(user.role === 'regional_admin'){
      req = {
        ...req,
        admin_region_ids: manage_region_ids,
      }
    }else{
      req = {
        ...req,
        manager_region_ids: manage_region_ids,
      }
    }  
    return req  
  }

  capitalizeFirstLetter = (strng) => {
    let string = strng?.split("_")
    let result = []
    for(let i = 0; i < string?.length; i++){
      let check = string[i].charAt(0).toUpperCase() + string[i].slice(1);
      result.push(check)
    }
    return result.join(" ");
  }

  checkAddUserInClass = (checkSkills, checkLocations) => {
    if(checkSkills?.length !== 0 || checkLocations?.length !== 0){
      this.setState({add_user_in_classes: true})
    }
    else{
      this.setState({add_user_in_classes: false})
    }
  }

  isDisabled = () => {
    if(this.state.business?.crunch_integration){
      if(this.state.formType === 'edit'){
        if(this.state.currentUser?.role_display === "Netgym Employee"){
        return false
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }else if(this.state.business?.clubready_integration){
      if(this.state.formType === 'edit'){
        if(this.state.currentUser?.role_display === "Netgym Employee"){
        return false
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }else{
      return false
    }
    
  }

  isDisabledRequireFields = () => {
    if(this.state.business?.fisikal_integration){
      if(this.state.formType === 'edit'){
        // if(this.state.currentUser?.role_display === "Netgym Employee"){
        // return false
        // }
        // else{
          return true
        // }
      }
      else{
        return false
      }
    }else if(this.state.business?.clubready_integration){
      if(this.state.formType === 'edit'){
        // if(this.state.currentUser?.role_display === "Netgym Employee"){
        // return false
        // }
        // else{
          return true
        // }
      }
      else{
        return false
      }
    }else{
      return false
    }
  }

  isDisabledSkillField = () => {
    if(this.state.business?.fisikal_integration || this.state.business?.clubready_integration){
      if(this.state.formType === 'edit'){
        if(this.state.currentUser?.role_display === "Netgym Employee"){
        return false
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }else{
      return false
    }
  }

  isDisabledHomeClubField = () => {
    if(this.state.business?.fisikal_integration){
      if(this.state.formType === 'edit'){
        // if(this.state.currentUser?.role_display === "Netgym Employee"){
        // return false
        // }
        // else{
          return true
        // }
      }
      else{
        return false
      }
    }else if(this.state.business?.crunch_integration){
      if(this.state.formType === 'edit'){
        if(this.state.currentUser?.role_display === "Netgym Employee"){
        return false
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }else if(this.state.business?.clubready_integration){
      if(this.state.formType === 'edit'){
        if(this.state.currentUser?.role_display === "Netgym Employee"){
        return false
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }else{
      return false
    }
  }

  isDisabledIntegratedIdField = () => {
    if(this.state.business?.fisikal_integration || this.state.business?.clubready_integration || this.state.business?.mbo_integration){
      if(this.state.formType === 'edit'){
        if(this.state.currentUser?.role_display === "Netgym Employee"){
        return false
        }
        else{
          return true
        }
      }
      else{
        return false
      }
    }else{
      return false
    }
  }

  removeSelectedRegions = (regionId) => {
    let managedRegions = this.state.managedRegions
    let remainRegions = managedRegions.filter(item =>  item !== regionId)
    this.setState({managedRegions: remainRegions})
  }

  checkAllMagedLocationSelect = () => {
    let all_regions = this.state.regionWithLocations.filter(item => item?.region?.name !== 'Others')
    return this.state.managedRegions?.length === all_regions?.length
  }

  handleSelectAllManagedReg = (e) => {
    let all_regions = this.state.regionWithLocations.filter(item => item?.region?.name !== 'Others')
    let result = []
    let selected_locations = this.state.managedLocations;

    if(e.target.checked){
      for (let i =0; i < all_regions?.length; i++){
        result = [...result, ...all_regions[i].locations.map(function(a) {return a.id;})]
      }
      let managedRegions = all_regions.map((item) => {return item?.region?.name})
      this.setState({ 
        managedRegions: managedRegions 
      });
      selected_locations = Array.prototype.push.apply(selected_locations, result)
    }else{
      this.setState({
        managedRegions:[],
        managedLocations: []          
      });
    }

  }

  checkSelectedAllManagedLocations = () => {
    const { locationsList, managedLocations } = this.state
    return locationsList?.length === managedLocations?.length
  }

  handleSelectAllManagedLoc = (e) => {
    let all_regions = this.state.regionWithLocations//.filter(item => item?.region?.name !== 'Others')
    let result = []
    let selected_locations = this.state.managedLocations;

    if(e.target.checked){
      for (let i =0; i < all_regions?.length; i++){
        result = [...result, ...all_regions[i].locations.map(function(a) {return a.id;})]
      }
      let managedRegions = all_regions.map((item) => {return item?.region?.name})
      this.setState({ 
        managedRegions: managedRegions, 
        managedLocations: result
      });
      // selected_locations = Array.prototype.push.apply(selected_locations, result)
    }else{
      this.setState({
        managedRegions:[],
        managedLocations: []          
      });
    }
  }

  managedLocationName = (loc) => {
    const { locationsList } = this.state
    let locationName = locationsList.filter((item) => item?.id === loc).map((item) => {return item.name})
    return locationName[0]
  }

  removeSelectedLocations = (locId) => {
    let managedLocations = this.state.managedLocations
    let remainLocs = managedLocations.filter(item =>  item !== locId)
    let allRegions = this.state.regionWithLocations
    for(let i = 0; i < allRegions?.length; i++){
      this.checkRegionVisibility2(allRegions[i], remainLocs)
    }
    this.setState({managedLocations: remainLocs})
  }

  handleSelectAllTeachingLocations = (e, allRegions) => {
    let all_locations = []
    let all_regions = allRegions.map((item) => {return item?.region?.name})
    for(let i = 0; i < allRegions?.length; i++){
      let locIds = allRegions[i].locations.map((items) => {return items.id})
      all_locations = [...all_locations,  ...locIds]
    }
    if(e.target.checked){
      this.setState(state => ({ 
        selectedLocations: all_locations,
        selectedRegions: all_regions
       }));
      let checkLocArr = all_locations.filter((item) => !this.state.propsLocations.includes(item))
      if(checkLocArr?.length > 0){
        checkLocations = [...checkLocations, ...checkLocArr]
      }
      this.checkAddUserInClass(this.state.checkSkills, checkLocations)
    }
    else{
      const { selectedLocations, checkLocations, selectedRegions } = this.state;
      let newLocs = selectedLocations.filter((tag, index) => !all_locations?.includes(tag)) 
      let newcheckLoc = checkLocations.filter((tag, index) => !all_locations?.includes(tag))
      let newcheckReg = selectedRegions.filter((tag) => !all_regions?.includes(tag))
      this.setState({
        selectedLocations: newLocs,
        checkLocations: newcheckLoc,
        selectedRegions: newcheckReg     
      });
      this.checkAddUserInClass(this.state.checkSkills, newcheckLoc)
    }
  }

  checkAllTeachingLocSelected = (allRegions) => {
    let all_locations = []
    const { selectedLocations } = this.state
    for(let i = 0; i < allRegions?.length; i++){
      let locIds = allRegions[i].locations.map((items) => {return items.id})
      all_locations = [...all_locations,  ...locIds]
    }
    return selectedLocations?.length === all_locations?.length
  }

  selectedLocName = (loc) => {
    let allRegions = this.state.teachingRegionWithLocations
    let all_locations = []
    for(let i = 0; i < allRegions?.length; i++){
      let locIds = allRegions[i].locations.map((items) => {return items})
      all_locations = [...all_locations,  ...locIds]
    }
    let locName = all_locations.filter((item) => item?.id == loc)
    if(locName?.length > 0){
      return locName[0]?.name
    }else{
      return loc
    }
  }

  removeTeachingSelectedLocs = (loc) => {
    const { selectedLocations, checkLocations } = this.state
    let allRegions = this.state.teachingRegionWithLocations
    let remainLocs = selectedLocations.filter(item =>  item !== loc)
    for(let i = 0; i < allRegions?.length; i++){
      this.checkRegionVisibility(allRegions[i], remainLocs)
    } 
    let newcheckLoc = checkLocations.filter((tag, index) => tag !== loc) 
    this.setState({
      selectedLocations: remainLocs,
      checkLocations: newcheckLoc       
    });
    this.checkAddUserInClass(this.state.checkSkills, newcheckLoc)
  }

  handleMTIDKeyDown = (e) => {
    if(e.key === " "){
      e.preventDefault();
    }
  }

  handleCheckEmployeeIdRequired = (employee_id) => {
    if(!this.state.business?.employee_id_optional){
      if(employee_id === null || employee_id === ""){
        return false
      }else{
        return true
      }
    }else{
      return true
    }
  }

  checkSubmitFormCommonCheck = (first_name, last_name, formType, currentUserPermissions, currentUser, email, employee_id, abc_integration, crunch_integration, clubready_integration, fisikal_integration, external_id, marianatek_integration, marianatek_profile_id, mbo_integration, mindbody_id, mbo_site_id, business) => {
    let is_true = (first_name !== "" && last_name !== "" && ((formType === 'edit' && (currentUserPermissions?.business_users?.edit || currentUser?.role_display === "Netgym Employee")) || (email !== "" && email.match("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?"))) && this.validateRoleWise() && this.handleCheckEmployeeIdRequired(employee_id) && this.state.validPhoneNumber)
    if(is_true){
      if(!marianatek_integration && !abc_integration && !mbo_integration && !crunch_integration && !clubready_integration && !fisikal_integration) return true 
      if((abc_integration || crunch_integration || clubready_integration || fisikal_integration) && external_id !== "") return true
      if(marianatek_integration && external_id !== "" && marianatek_profile_id && marianatek_profile_id !== "") return true
      if(mbo_integration && business?.multi_site_multi_staff_feature){
        return true
      } else {
        if(mbo_integration && mindbody_id !== "" && business?.single_mbo_id) return true
        if(mbo_integration && mindbody_id !== "" && mbo_site_id !== "" && !business?.single_mbo_id) return true
      }
      return false
    }else{
      return false
    }
  }

  validatePhoneNumber = (phoneNumber) => {
    return (phoneNumber == '' ? true : (parsePhoneNumber(phoneNumber) && this.state.countryList.includes(parsePhoneNumber(phoneNumber).country) && isValidPhoneNumber(phoneNumber.startsWith('+') ? phoneNumber : '+'+phoneNumber) === true))
  }

  handleSetPhoneNumber = (value) => {
    if (value && !(this.state.countryCodes.includes(value))) {
      let phoneNumber = parsePhoneNumber(value)
      if(phoneNumber && this.state.countryList.includes(phoneNumber.country) && isValidPhoneNumber(value) === true){
        this.setState({validPhoneNumber: true})
      }else{
        this.setState({validPhoneNumber: false})
      }
      this.setState({
        phoneNumber: value
      })
    }else{
      this.setState({
        validPhoneNumber: true,
        phoneNumber: ''
      })
    }
  }

  handleCountryChange = (value) => {
    this.setState({defaultCountry: value})
  }

  allCountryCodes = () => {
    return getCountriesAbbr().map(item => `+${getCountryCallingCode(item)}`)
  }

  checkAndShowMboSiteIdComponent = () => {
    if(this.state.formType === 'edit'){
      if(['Netgym Employee', 'Account Owner'].includes(this.state.currentUser?.role_display)){
        return false
      }
      else{
        return true
      }
    }
    else{
      return false
    }
  }

  checkAllCertsSelected = () => {
    const { selectedCertificationIds, certificationCategoriesIds } = this.state
    return certificationCategoriesIds.every(v => selectedCertificationIds.includes(v)) && certificationCategoriesIds?.length > 0
  }

  handleSelectAllCerts = (e) => {
    if (e.target.checked){
      const { certificationCategoriesIds } = this.state
      this.setState({
        selectedCertificationIds: certificationCategoriesIds
      })
    }else{
      this.setState({
        selectedCertificationIds: []
      })
    }
  }

  handleSelectCert = (e, cert) => {
    const { selectedCertificationIds } = this.state
    let newSelectedCertificationIds = []
    if (e.target.checked){
      newSelectedCertificationIds = [...selectedCertificationIds, parseInt(e.target.value)]
    }else{
      newSelectedCertificationIds = selectedCertificationIds.filter(item => item !== parseInt(e.target.value))
    }
    this.setState({
      selectedCertificationIds: newSelectedCertificationIds
    })
  }

  categoryName = (categoryId) => {
    const { certificationCategories } = this.state
    let category = certificationCategories.find((item) => item?.id === categoryId)
    if(category){
      return category?.name
    }else{
      return categoryId
    }
  }

  removeSelectedCertCategories = (categoryId) => {
    this.setState({
      selectedCertificationIds: this.state.selectedCertificationIds.filter(item => item !== parseInt(categoryId))
    })
  }

  checkAndShowCertificationSection = () => {
    const { formType, business, currentUserPermissions, currentUser } = this.state
    return (business?.certifications_feature && formType === 'edit' && (currentUser?.role_display == "Netgym Employee" || currentUserPermissions?.certifications?.write))
  }

  render() {
    const { showModal, submitted, formType, business, roles, regionWithLocations, time_zones, selectedRegions,selectedLocations, skills, selectedSkills, user, managedRegions, managedLocations, add_user_in_classes, hire_date, show_date, rolesName, checkSkills, checkLocations, showFormOther, teachingRegionWithLocations, showFormOtherTeaching, teachingRegionLocationsWithSiteIds, teachingLocationForCrunch, currentUser, currentUserPermissions, locationsList, sendActivationEmail, userSyncSkills, dataReady, phoneNumber, validPhoneNumber, defaultCountry, countryList, certificationCategories, selectedCertificationIds } = this.state
    const { closeAndReload, showAlert } = this.props;
    
    return (
      <Transition.Root show={this.props?.showModal} as={Fragment}>
				<Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={(e) => this.closeModal(e)}>
					<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>
	
						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-20 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6 min-h-96 mt-20">
								<div className="absolute top-0 right-0 pt-4 pr-4">
									<button
										type="button"
										className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
										onClick={(e) => this.closeModal(e)}
									>
										<span className="sr-only">Close</span>
										<XIcon className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
								<div className="sm:flex sm:items-start">
									<div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
										<Dialog.Title as="h4" className="text-lg leading-6 font-medium text-gray-900 border-b pb-3">
											{`${formType === 'edit' ? 'Edit' : 'New'} User Form`}
										</Dialog.Title>
										<div className="big-modal-scroll">
                      {!dataReady &&
                        <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
                          <img className = "w-9" src="/assets/business/loading.gif" />
                        </div>
                      }
                      {dataReady &&
                      <Fragment>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-4'>
												<label htmlFor="first-name" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">First Name*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<input type="text" name="first_name" value = {user.first_name} onChange={(e)=>this.handleChange(e)} placeholder="Enter First Name" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" />
                          {submitted && !user.first_name && (
                            <span className="error text-xs">*First Name is required</span>
                          )}
												</div>
											</div>
                      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="last-name" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Last Name*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<input type="text" name="last_name" value = {user.last_name} onChange={(e)=>this.handleChange(e)} placeholder="Enter Last Name" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" />
                          {submitted && !user.last_name && (
                            <span className="error text-xs">*Last Name is required</span>
                          )}
												</div>
											</div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="email" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Email*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
                          {(user.unconfirmed_email !== "" && user.unconfirmed_email !== null && user.status === "Yes") &&
                            <React.Fragment >
                              <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-4 w-4" viewBox="0 0 20 20" fill="currentColor" data-tip data-for={user.id.toString()+"email"}>
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                              <ReactTooltip id={user.id.toString()+"email"} place="top" effect="solid">Pending Verification</ReactTooltip>
                            </React.Fragment>
                          }
													<input type="text" name="email" value = {user.email} onChange={(e)=>this.handleChange(e)} placeholder="Enter Email" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10" />
                          {(submitted && !user.email && ((currentUser?.role_display == "Netgym Employee" || currentUserPermissions?.business_users?.edit) && formType !== 'edit')) && (
                            <span className="error text-xs">*Email is required</span>
                          )}
                          {submitted && user.email && !user.email.match("[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?") && (
                            <span className="error text-xs">*Invalid Email</span>
                          )}
												</div>
											</div>
                      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="email" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Phone Number</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
                          <div className="lg:w-80 rounded-md w-full text-sm shadow-sm">
                            <PhoneInput
                              className="border rounded-md pl-2 pr-2 py-1.5 w-full h-10"
                              value={phoneNumber}
                              onChange={this.handleSetPhoneNumber}
                              defaultCountry={defaultCountry}
                              international
                              withCountryCallingCode
                              countryCallingCodeEditable={false}
                              id="phone-input"
                              placeholder='+1 201-555-5555'
                              countries={countryList}
                              onCountryChange={this.handleCountryChange}
                            />
                            {submitted && !validPhoneNumber && (
                              <span className="error text-xs">*Please enter valid phone number.</span>
                            )}
                          </div>
												</div>
											</div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="role" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Role*</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
                          {rolesName.includes(user?.role) &&
                            <select className='form-select lg:w-80 bg-white mt-1 block w-full pl-2 pr-10 py-2 border border-gray-300 text-sm rounded-md h-10' value={user?.role} name="role" onChange={(e)=>this.handleChange(e)}>
                              {roles.map((role,i) => (
                                (role.label === 'Regional Admin' && !showFormOther) ? (user.role === "regional_admin") ? <option key={i} value={role.value}>{role.label}</option> : null : <option key={i} value={role.value}>{role.label}</option>
                              ))}
                            </select>
                          }
                          {!rolesName.includes(user?.role) &&
                            <select className='form-select lg:w-80 bg-white mt-1 block w-full pl-2 pr-10 py-2 border border-gray-300 text-sm rounded-md h-10' value={user?.role} name="role" disabled>
                              <option >{this.capitalizeFirstLetter(user?.role)}</option>
                            </select>
                          }
												</div>
											</div>
                      {user.role === 'regional_admin' &&
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                          <label htmlFor="regions-managed" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Region(s) Managed*</label>
                          <div className='mt-1 sm:mt-0 sm:col-span-2'>
                            {/* {regionWithLocations.map((region,i) =>
                              <React.Fragment key={i} >
                                {region?.region?.name !== 'Others' &&
                                  <div className="">
                                    <input type="checkbox" id={`business_region_admin_id_${i}`} name="admin_region_ids" value={region?.region?.name} onChange = {(e) => this.selectManageRegions(e, region)} checked={managedRegions?.includes(region.region.name)}/>
                                    <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_region_admin_id_${i}`}>{region?.region?.name}</label>                          
                                  </div>
                                }
                              </React.Fragment>
                            )} */}
                            <div className="relative filters_box w-full lg:w-80">
                              <Menu as="div" className="relative block text-left">
                                <div>
                                  <Menu.Button className="h-10 items-center flex justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 reminder_button" disabled={!rolesName.includes(user?.role)}>
                                    {/* {selectedLocations?.length === locations?.length && <p className = "text-gray-900">All Locations Selected</p>
                                    } 
                                    {(managedLocations?.length === 0  && managedLocations?.length !== locations?.length) && <p>Select location(s)</p>}*/}
                                    {managedRegions?.length === 0 ?
                                      <p>Select region(s)</p>
                                    :
                                    this.checkAllMagedLocationSelect() === true ?
                                      <p className='text-gray-900'>All regions managed</p>
                                    :
                                      <p className='text-gray-900'>{`${managedRegions?.length} region${managedRegions?.length > 1 ? 's' : ''} managed`}</p>
                                    } 
                                    <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  show={true}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95">
                                  <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden reminder_items filter_items">
                                    <div className="flex items-center p-2 border-b">
                                      <div className=" ml-auto">
                                        <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                          Select All
                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkAllMagedLocationSelect()} onChange = {(e) => this.handleSelectAllManagedReg(e)}/>
                                          </label>
                                      </div>
                                    </div>
                                    <div className="p-2 max-h-60 overflow-y-auto">
                                      {regionWithLocations.map((region, i) =>
                                        <div className="py-1" key = {i}>
                                          {region?.region?.name !== 'Others' &&
                                            <Menu.Item>
                                              {({ active }) => (
                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_region_admin_id_${i}`} name="admin_region_ids" value={region?.region?.name} onChange = {(e) => this.selectManageRegions(e, region)} checked={managedRegions?.includes(region.region.name)}/>
                                                  <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_region_admin_id_${i}`}>{region?.region?.name}</label> 
                                                </label>
                                                  
                                              )}
                                            </Menu.Item>
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                            {submitted && managedRegions?.length === 0 && (
                              <span className="error text-xs">*Region(s) Managed is required</span>
                            )}
                            {managedRegions?.length > 0 &&
                              <div className='flex items-center mt-3 gap-2 flex-wrap'>
                                {managedRegions.map((region, i) => 
                                  <div className='red-tag py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                    <div className='text-sm pr-1 font-medium'>{region}</div>
                                    {rolesName.includes(user?.role) &&
                                      <a onClick={() => this.removeSelectedRegions(region)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                      </a>
                                    }
                                  </div>
                                )}
                              </div>
                            }
                          </div>
                        </div>
                      }
                      {user.role === 'fitness_manager' &&
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                          <label htmlFor="regions-managed" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Location(s) Managed*</label>
                          <div className='mt-1 sm:mt-0 sm:col-span-2'>
                            {/* {regionWithLocations.map((region,i) =>
                              <div key={i} >
                                {showFormOther ?
                                  <React.Fragment>
                                    <input type="checkbox" id={`business_region_admin_id_${i}`} name={`business_region_id_${i}`} value={region?.region?.name} onChange = {(e) => this.selectManageRegions(e, region)} checked={managedRegions?.includes(region.region.name)}/>
                                    <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_region_admin_id_${i}`}>{region?.region?.name}</label>                          
                                  </React.Fragment>
                                  :
                                  <ul className="mb-1.5 flex">
                                    {region.locations.map((location,j) =>
                                      <li className="mr-3.5 flex items-center" key={j}>
                                        <input type="checkbox" id={`business_location_managed_id_${location.id}`} name="vehicle1" checked={managedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectManageLocation(e, region)} />
                                        <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_location_managed_id_${location.id}`}>{location?.name}</label>
                                      </li>
                                    )}
                                  </ul>
                                }
                                {(managedRegions.includes(region?.region?.name) && showFormOther) &&
                                  <ul className="pl-3.5 mt-1.5 flex">
                                    {region.locations.map((location,j) =>
                                      <li className="mr-3.5 flex items-center" key={j}>
                                        <input type="checkbox" id={`business_location_managed_id_${location.id}`} name="vehicle1" checked={managedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectManageLocation(e, region)} />
                                        <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_location_managed_id_${location.id}`}>{location?.name}</label>
                                      </li>
                                    )}
                                  </ul>
                                }
                              </div>
                            )} */}
                            <div className="relative filters_box w-full lg:w-80">
                              <Menu as="div" className="relative block text-left">
                                <div>
                                  <Menu.Button className="h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 reminder_button">
                                    {managedLocations?.length === 0 ?
                                      <p>Select location(s)</p>
                                    :
                                    this.checkSelectedAllManagedLocations() === true ?
                                      <p className='text-gray-900'>All locations managed</p>
                                    :
                                      <p className='text-gray-900'>{`${managedLocations?.length} location${managedLocations?.length > 1 ? 's' : ''} managed`}</p>
                                    } 
                                    <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  show={true}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95">
                                  <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden reminder_items filter_items">
                                    <div className="flex items-center p-2 border-b">
                                      <div className=" ml-auto">
                                        <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                          Select All
                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkSelectedAllManagedLocations()} onChange = {(e) => this.handleSelectAllManagedLoc(e)}/>
                                          </label>
                                      </div>
                                    </div>
                                    <div className="p-2 max-h-60 overflow-y-auto">
                                      {regionWithLocations.map((region, i) =>
                                        <div className="py-1" key = {i}>
                                          {showFormOther ?
                                            <Menu.Item>
                                              {({ active }) => (
                                                <label className="flex items-center text-gray-900 text-sm mb-2">
                                                  <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_region_admin_id_${i}`} name={`business_region_id_${i}`} value={region?.region?.name} onChange = {(e) => this.selectManageRegions(e, region)} checked={managedRegions?.includes(region.region.name)}/>
                                                  {region.region.name}
                                                </label>
                                                  
                                              )}
                                            </Menu.Item>
                                          :
                                            <div className="mb-1.5">
                                              {region.locations.map((location, j) =>
                                              <Menu.Item key = {j}>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_managed_id_${location.id}`} name="vehicle1" checked={managedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectManageLocation(e, region)}/>
                                                    {location.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                              )}
                                            </div>
                                          }
                                          {(showFormOther) &&
                                            <div className="ml-5">
                                              {region.locations.map((location, j) =>
                                              <Menu.Item key = {j}>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_managed_id_${location.id}`} name="vehicle1" checked={managedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectManageLocation(e, region)}/>
                                                    {location.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                              )}
                                            </div>
                                          }
                                        </div>
                                      )}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                            {submitted && managedLocations?.length === 0 && (
                              <span className="error text-xs">*Location(s) Managed is required</span>
                            )}
                            {managedLocations?.length > 0 &&
                              <div className='flex items-center mt-3 gap-2 flex-wrap'>
                                {managedLocations.map((loc, i) => 
                                  <div className='red-tag py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                    <div className='text-sm pr-1 font-medium'>{this.managedLocationName(loc)}</div>
                                    <a onClick={() => this.removeSelectedLocations(loc)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                    </a>
                                  </div>
                                )}
                              </div>
                            }
                          </div>
                        </div>
                      }
                      {!business?.employee_id_optional &&
                        <React.Fragment>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="cmployee-id" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Employee ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" defaultValue={user?.employee_id} name="employee_id" onChange={(e)=>this.handleChange(e)} placeholder="Enter Employee ID" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm h-10 shadow-sm`}/>
                              {submitted && !user?.employee_id && (
                                <span className="error text-xs">*Employee ID is required</span>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      {business.marianatek_integration &&
                        <React.Fragment>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="marianatek-id" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Marianatek ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" defaultValue={user?.external_id} name="external_id" onChange={(e)=>this.handleChange(e)} onKeyDown={(e) => this.handleMTIDKeyDown(e)} placeholder="Enter Marianatek ID" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm" />
                              {submitted && !user?.external_id && (
                                <span className="error text-xs">*Marianatek ID is required</span>
                              )}
                            </div>
                          </div>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="marianatek-profile-id" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Marianatek Profile ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" defaultValue={user?.marianatek_profile_id} name="marianatek_profile_id" onChange={(e)=>this.handleChange(e)} onKeyDown={(e) => this.handleMTIDKeyDown(e)} placeholder="Enter Marianatek ID" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm" />
                              {submitted && !user?.marianatek_profile_id && (
                                <span className="error text-xs">*Marianatek Profile ID is required</span>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      {business.abc_integration &&
                        <React.Fragment>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="abc-id" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">ABC ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" defaultValue={user?.external_id} name="external_id" onChange={(e)=>this.handleChange(e)} placeholder="Enter ABC ID" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-9" />
                              {submitted && !user?.external_id && (
                                <span className="error text-xs">*ABC ID is required</span>
                              )}
                            </div>
                          </div>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="start-date" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Start Date</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <div className="lg:w-80 relative messenger-box py-1.5">
                                <DatePicker
                                  value = {show_date}
                                  name = "hire_date"
                                  className=" border rounded-md px-2 w-full shadow-sm h-10 border-gray-300 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                                  autoFocus={false}
                                  format="M/d/yyyy"
                                  onChange={(e) => this.handleDateChange(e)}
                                  calendarType="US"
                                  clearIcon={null}
                                />
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      {business.mbo_integration && !business?.multi_site_multi_staff_feature &&
                        <React.Fragment>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="mindbody-id" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Mindbody ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" value={user?.mindbody_id} name="mindbody_id" onChange={(e)=>this.handleChange(e)} placeholder="Enter Mindbody ID" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm text-sm h-10 ${this.checkAndShowMboSiteIdComponent() ? 'cursor-not-allowed bg-gray-50' : ''}`} disabled={this.checkAndShowMboSiteIdComponent()}/>
                              {submitted && !user?.mindbody_id && (
                                <span className="error text-xs">*Mindbody ID is required</span>
                              )}
                            </div>
                          </div>
                          {(business.mbo_integration && !business?.single_mbo_id) &&
                            <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                              <label htmlFor="mbo-site-id" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">MBO Site*</label>
                              <div className='mt-1 sm:mt-0 sm:col-span-1'>
                                <select className={`form-select lg:w-80 bg-white mt-1 block w-full pl-3 h-10 pr-10 py-2 border border-gray-300 text-sm rounded-md ${this.checkAndShowMboSiteIdComponent() ? 'cursor-not-allowed bg-gray-50' : ''}`} defaultValue={user?.mbo_site_id} name="mbo_site_id" onChange={(e)=>this.handleChange(e)} disabled={this.checkAndShowMboSiteIdComponent()}>
                                  <option value = "">Select MBO Site</option>
                                  {currentUser?.assigned_mbo_sites.map((site,i) =>
                                    <option key={i} value={site.site_id}>{site.site_name}</option>
                                  )}
                                </select>
                                {submitted && !user?.mbo_site_id && (
                                  <span className="error text-xs">*Site ID is required</span>
                                )}
                              </div>
                            </div>
                          }
                        </React.Fragment>
                      }
                      {business.mbo_integration && business?.multi_site_multi_staff_feature &&
                        <React.Fragment>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="mindbody-id" className="flex flex-col text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">
                              Mindbody ID ({user?.mindbody_profiles.length})
                              <span className='text-xs'>
                                & MBO Site
                              </span>
                            </label>
                            <div className='mind-id sm:grid grid-cols-1 lg:grid-cols-2 sm:items-start py-1 gap-2 gap-y-4'>
                              {(user?.mindbody_profiles).map((profile, i) => (
                                <div className={`flex relative ${i%2 !== 0 ? 'border-b lg:border-b-0 lg:border-l border-gray-200 pb-4 lg:pb-0 lg:pl-4' : 'border-b lg:border-transparent border-gray-200 pb-4 lg:pb-0'}`}>
                                  <div className='flex flex-col'>
                                    <div className='lg:w-72 text-left py-1 w-full text-sm'>
                                      {profile?.staff_id}
                                    </div>
                                    <div className='lg:w-72 w-full text-sm pr-4'>
                                      {profile?.site_name}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          {(currentUser?.role_display === "Netgym Employee" && user?.active_status?.toLowerCase() !== "active") &&
                            <MergeMindbodyProfilesComponent userId={user?.id} closeAndReload={closeAndReload} showAlert={showAlert}/>
                          }
                        </React.Fragment>
                      }
                      {business.crunch_integration &&
                        <React.Fragment>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="crunch-id" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Crunch ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" defaultValue={user?.external_id} name="external_id" onChange={(e)=>this.handleChange(e)} placeholder="Enter Crunch ID" className="lg:w-80 border rounded-md px-2 py-1.5 w-full shadow-sm" disabled={this.isDisabled()}/>
                              {submitted && !user?.external_id && (
                                <span className="error text-xs">*Crunch ID is required</span>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      {business?.clubready_integration &&
                        <React.Fragment>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="clubready-id" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Clubready ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" defaultValue={user?.external_id} name="external_id" onChange={(e)=>this.handleChange(e)} placeholder="Enter Clubready ID" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm h-10 shadow-sm ${this.isDisabledIntegratedIdField() ? 'cursor-not-allowed' : ''}`} disabled={this.isDisabledIntegratedIdField()}/>
                              {submitted && !user?.external_id && (
                                <span className="error text-xs">*Clubready ID is required</span>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      {business?.fisikal_integration &&
                        <React.Fragment>
                          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                            <label htmlFor="Fisikal-id" className="block text-sm text-left font-medium text-gray-500 sm:mt-px sm:pt-2">Fisikal ID*</label>
                            <div className='mt-1 sm:mt-0 sm:col-span-1'>
                              <input type="text" defaultValue={user?.external_id} name="external_id" onChange={(e)=>this.handleChange(e)} placeholder="Enter Fisikal ID" className={`lg:w-80 border rounded-md px-2 py-1.5 w-full text-sm h-10 shadow-sm ${this.isDisabledIntegratedIdField() ? 'cursor-not-allowed' : ''}`} disabled={this.isDisabledIntegratedIdField()}/>
                              {submitted && !user?.external_id && (
                                <span className="error text-xs">*Fisikal ID is required</span>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      }
                      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                        <label htmlFor="home-club" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Home Club</label>
                        <div className='mt-1 sm:mt-0 sm:col-span-1'>
                          <select className={`form-select lg:w-80 mt-1 block w-full pl-2 h-10 pr-10 py-2 border border-gray-300 text-sm rounded-md ${this.isDisabledHomeClubField() ? 'cursor-not-allowed bg-gray-100' : 'bg-white'}`} value = {this.state.user.home_club_id} name="home_club_id" onChange={(e)=>this.handleChange(e)} disabled={this.isDisabledHomeClubField()}>
                            <option value = "">Select User Home Club</option>
                            {regionWithLocations.map((region,i) =>
                              <optgroup key={i} label={showFormOther ? region?.region?.name : ''}>
                                {region.locations.map((location,j) =>
                                  <option key={j} value={location.id}>{location.name}</option>
                                )}
                              </optgroup>
                            )}
                          </select>
                        </div>
                      </div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="time-zone" className="block text-left text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Time Zone</label>
												<div className='mt-1 sm:mt-0 sm:col-span-1'>
													<select className='form-select lg:w-80 bg-white mt-1 block w-full pl-3 pr-10 py-2 border border-gray-300 text-sm rounded-md h-10' defaultValue={user.time_zone_string} name="time_zone_string" onChange={(e)=>this.handleChange(e)} disabled={this.isDisabled()}>
                          <option value="">Select Time Zone</option>
                          {time_zones.map((zone,i) =>
                            <option key={i} value={zone.key}>{zone.value}</option>
                          )}
													</select>
												</div>
											</div>
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="skill" className="block text-left text-sm font-medium text-gray-500 sm:mt-px items-center flex">
                          Skill(s)
                          {(business?.fisikal_integration || business?.clubready_integration) &&
                            <React.Fragment >
                              <div className='skills-hover'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="cursor-pointer h-4 w-4 ml-2" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                                </svg>
                                <div className="skills-tooltip">
                                  {`Skills are automatically assigned based on the assigned ${business?.fisikal_integration ? 'services' : 'classes'} in ${business?.fisikal_integration ? 'Fisikal' : 'ClubReady'}`}
                                </div>
                              </div>
                            </React.Fragment>
                          }
                        </label>
												<div className='mt-1 sm:mt-0 sm:col-span-2'>
                          {/* {skills.length > 0 &&
                            <ul className='flex flex-wrap'>
                              {skills.map((skill,j) =>
                                <li className="mr-3.5 mb-1 flex items-center" key={j}>
                                  <input type="checkbox" id={skill.id} name="skills[]" onChange={(e) => this.selectSkill(e, skill)} value={skill.id} checked={selectedSkills?.includes(skill.id)} />
                                  <label className="ml-1.5 text-sm font-medium text-gray-500" htmlFor={skill.id}>{skill?.name}</label>
                                </li>
                              )}
                            </ul>
                          } */}
                          <div className="relative filters_box w-full lg:w-80">
                            <Menu as="div" className="relative block text-left">
                              <div>
                                <Menu.Button className={`h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 days_items_button`}>
                                  {selectedSkills?.length === 0 ?
                                    <p>Select skill(s)</p>
                                  :
                                  this.checkAllSkillsSelected() === true ?
                                    <p className='text-gray-900'>All skill selected</p>
                                  :
                                    <p className='text-gray-900'>{`${selectedSkills?.length} skill${selectedSkills?.length > 1 ? 's' : ''} selected`}</p>
                                  } 
                                  <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                show={true}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95">
                                <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden days_items filter_items">
                                  <div className="flex items-center p-2 border-b">
                                    <div className=" ml-auto">
                                      <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                        Select All
                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkAllSkillsSelected()} onChange = {(e) => this.handleSelectAllSkill(e)}/>
                                        </label>
                                    </div>
                                  </div>
                                  <div className="p-2 max-h-60 overflow-y-auto">
                                    {skills.map((skill, i) =>
                                      <div className="py-1" key = {i}>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <label className="flex items-center text-gray-900 text-sm mb-2">
                                              <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1 ${((business?.fisikal_integration || business?.clubready_integration) && (userSyncSkills.includes(skill.id))) ? 'cursor-not-allowed' : ''}`} id={skill.id} name="skills[]" onChange={(e) => this.selectSkill(e, skill)} value={skill.id} checked={selectedSkills?.includes(skill.id)} disabled={((business?.fisikal_integration || business?.clubready_integration) && userSyncSkills.includes(skill.id))}/>
                                              <label className={`ml-1.5 text-sm font-medium text-gray-500 ${((business?.fisikal_integration || business?.clubready_integration) && (userSyncSkills.includes(skill.id))) ? 'cursor-not-allowed' : ''}`} htmlFor={skill.id}>{skill?.name}</label> 
                                            </label>
                                              
                                          )}
                                        </Menu.Item>
                                      </div>
                                    )}
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                          {selectedSkills?.length > 0 &&
                            <div className='flex items-center mt-3 gap-2 flex-wrap'>
                              {selectedSkills.map((skill, i) => 
                                <div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                  <div className='text-sm pr-1 font-medium'>{this.skillsName(skill)}</div>
                                  {(!userSyncSkills.includes(skill)) &&
                                    <a onClick={() => this.removeSelectedSkills(skill)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-800  h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                    </a>
                                  }
                                </div>
                              )}
                            </div>
                          }
												</div>
											</div>
                      <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="location" className="block text-sm text-left font-medium text-gray-500 sm:mt-px">Teaching Location(s)</label>
												<div className='mt-1 sm:mt-0 sm:col-span-2'>
                          {(!business.mbo_integration && !business.crunch_integration) &&
                            <div>
                              {/* {teachingRegionWithLocations.map((region,i) =>
                                <div key={i}>
                                  {showFormOtherTeaching ?
                                    <React.Fragment>
                                      <input type="checkbox" id={`business_region_id_${i}`} name={`business_region_id_${i}`} onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                      <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_region_id_${i}`}>{region?.region?.name}</label>                          
                                    </React.Fragment>
                                  :
                                    <ul className = "mb-1.5 flex flex-wrap">
                                      {region.locations.map((location,j) =>
                                        <li className='mr-3 mb-3 flex items-center' key={j}>
                                          <input type="checkbox" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)} />
                                          <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_location_id_${location.id}`}>{location?.name}</label>
                                        </li>
                                      )}
                                    </ul>
                                  }
                                  {(selectedRegions.includes(region?.region?.name) && showFormOtherTeaching) &&
                                    <ul className = "pl-3.5 mt-1.5 flex flex-wrap">
                                      {region.locations.map((location,j) =>
                                        <li className='mr-3 mb-3 flex items-center' key={j}>
                                          <input type="checkbox" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)} />
                                          <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_location_id_${location.id}`}>{location?.name}</label>
                                        </li>
                                      )}
                                    </ul>
                                  }
                                </div>
                              )} */}
                              <div className="relative filters_box w-full lg:w-80">
                                <Menu as="div" className="relative block text-left">
                                  <div>
                                    <Menu.Button className={`h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 class_items_button ${this.isDisabledRequireFields() ? 'bg-gray-100 cursor-not-allowed' : 'bg-white'}`} disabled={this.isDisabledRequireFields()} >
                                      {selectedLocations?.length === 0 ?
                                        <p>Select location(s)</p>
                                      :
                                      this.checkAllTeachingLocSelected(teachingRegionWithLocations) === true ?
                                        <p className='text-gray-900'>All locations selected</p>
                                      :
                                        <p className='text-gray-900'>{`${selectedLocations?.length} location${selectedLocations?.length > 1 ? 's' : ''} selected`}</p>
                                      } 
                                      <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    show={true}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden class_items filter_items">
                                      <div className="flex items-center p-2 border-b">
                                        <div className=" ml-auto">
                                          <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                            Select All
                                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkAllTeachingLocSelected(teachingRegionWithLocations)} onChange = {(e) => this.handleSelectAllTeachingLocations(e, teachingRegionWithLocations)}/>
                                            </label>
                                        </div>
                                      </div>
                                      <div className="p-2 max-h-60 overflow-y-auto">
                                        {teachingRegionWithLocations.map((region, i) =>
                                          <div className="py-1" key = {i}>
                                            {showFormOtherTeaching ?
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_region_id_${i}`} name={`business_region_id_${i}`} onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                                    {region.region.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                            :
                                              <div className="mb-1.5">
                                                {region.locations.map((location, j) =>
                                                <Menu.Item key = {j}>
                                                  {({ active }) => (
                                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                      {location.name}
                                                    </label>
                                                      
                                                  )}
                                                </Menu.Item>
                                                )}
                                              </div>
                                            }
                                            {(showFormOtherTeaching) &&
                                              <div className="ml-5">
                                                {region.locations.map((location, j) =>
                                                <Menu.Item key = {j}>
                                                  {({ active }) => (
                                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                      {location.name}
                                                    </label>
                                                      
                                                  )}
                                                </Menu.Item>
                                                )}
                                              </div>
                                            }
                                          </div>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                              {selectedLocations?.length > 0 && teachingRegionWithLocations?.length > 0 &&
                                <div className='flex items-center mt-3 gap-2 flex-wrap'>
                                  {selectedLocations.map((loc, i) => 
                                    <div className='red-tag py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                      <div className='text-sm pr-1 font-medium'>{this.selectedLocName(loc)}</div>
                                      {!this.isDisabledRequireFields() &&
                                        <a onClick={() => this.removeTeachingSelectedLocs(loc)}>
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                          </svg>
                                        </a>
                                      }
                                    </div>
                                  )}
                                </div>
                              }
                            </div>
                          }
                          {(business.mbo_integration && currentUser?.assigned_single_site_id) &&
                            <div>
                              <div className="relative filters_box w-full lg:w-80">
                                <Menu as="div" className="relative block text-left">
                                  <div>
                                    <Menu.Button className="h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 class_items_button">
                                      {selectedLocations?.length === 0 ?
                                        <p>Select location(s)</p>
                                      :
                                      this.checkAllTeachingLocSelected(teachingRegionLocationsWithSiteIds) === true ?
                                        <p className='text-gray-900'>All locations selected</p>
                                      :
                                        <p className='text-gray-900'>{`${selectedLocations?.length} location${selectedLocations?.length > 1 ? 's' : ''} selected`}</p>
                                      } 
                                      <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    show={true}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden class_items filter_items">
                                      <div className="flex items-center p-2 border-b">
                                        <div className=" ml-auto">
                                          <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                            Select All
                                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkAllTeachingLocSelected(teachingRegionLocationsWithSiteIds)} onChange = {(e) => this.handleSelectAllTeachingLocations(e, teachingRegionLocationsWithSiteIds)}/>
                                            </label>
                                        </div>
                                      </div>
                                      <div className="p-2 max-h-60 overflow-y-auto">
                                        {teachingRegionLocationsWithSiteIds.map((region, i) =>
                                          <div className="py-1" key = {i}>
                                            {showFormOtherTeaching ?
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_region_id_${i}`} name={`business_region_id_${i}`} onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                                    {region.region.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                            :
                                              <div className="mb-1.5">
                                                {region.locations.map((location, j) =>
                                                <Menu.Item key = {j}>
                                                  {({ active }) => (
                                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                      {location.name}
                                                    </label>
                                                      
                                                  )}
                                                </Menu.Item>
                                                )}
                                              </div>
                                            }
                                            {(showFormOtherTeaching) &&
                                              <div className="ml-5">
                                                {region.locations.map((location, j) =>
                                                <Menu.Item key = {j}>
                                                  {({ active }) => (
                                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                      {location.name}
                                                    </label>
                                                      
                                                  )}
                                                </Menu.Item>
                                                )}
                                              </div>
                                            }
                                          </div>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                              {selectedLocations?.length > 0 && teachingRegionWithLocations?.length > 0 && 
                                <div className='flex items-center mt-3 gap-2 flex-wrap'>
                                  {selectedLocations.map((loc, i) => 
                                    <div className='red-tag py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                      <div className='text-sm pr-1 font-medium'>{this.selectedLocName(loc)}</div>
                                      <a onClick={() => this.removeTeachingSelectedLocs(loc)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              }
                            </div>
                          }
                          {(business.mbo_integration && !currentUser?.assigned_single_site_id) &&
                            <div>
                              {/* {teachingRegionLocationsWithSiteIds.map((region,i) =>
                                <div key={i}>
                                  {showFormOtherTeaching ?
                                    <React.Fragment>
                                      <input type="checkbox" id={`business_region_id_${i}`} name={`business_region_id_${i}`} onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                      <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_region_id_${i}`}>{region?.region?.name}</label>                          
                                    </React.Fragment>
                                  :
                                    <ul className = "mb-1.5 flex flex-wrap">
                                      {region.locations.map((location,j) =>
                                        <li className='mr-3 mb-3 flex items-center' key={j}>
                                          <input type="checkbox" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)} />
                                          <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_location_id_${location.id}`}>{location?.name}</label>
                                        </li>
                                      )}
                                    </ul>
                                  }
                                  {(selectedRegions.includes(region?.region?.name) && showFormOtherTeaching) &&
                                    <ul className = "pl-3.5 mt-1.5 flex flex-wrap">
                                      {region.locations.map((location,j) =>
                                        <li className='mr-3 mb-3 flex items-center' key={j}>
                                          <input type="checkbox" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)} />
                                          <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_location_id_${location.id}`}>{location?.name}</label>
                                        </li>
                                      )}
                                    </ul>
                                  }
                                </div>
                              )} */}
                              {((user?.mbo_site_id && !business?.multi_site_multi_staff_feature) || (user?.mindbody_profile_site_ids && business?.multi_site_multi_staff_feature)) &&
                                <React.Fragment>
                                  <div className="relative filters_box w-full lg:w-80">
                                    <Menu as="div" className="relative block text-left">
                                      <div>
                                        <Menu.Button className="h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 class_items_button">
                                          {selectedLocations?.length === 0 ?
                                            <p>Select location(s)</p>
                                          :
                                          this.checkAllTeachingLocSelected(teachingRegionLocationsWithSiteIds) === true ?
                                            <p className='text-gray-900'>All locations selected</p>
                                          :
                                            <p className='text-gray-900'>{`${selectedLocations?.length} location${selectedLocations?.length > 1 ? 's' : ''} selected`}</p>
                                          } 
                                          <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                        </Menu.Button>
                                      </div>

                                      <Transition
                                        as={Fragment}
                                        show={true}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95">
                                        <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden class_items filter_items">
                                          <div className="flex items-center p-2 border-b">
                                            <div className=" ml-auto">
                                              <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                                Select All
                                                <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkAllTeachingLocSelected(teachingRegionLocationsWithSiteIds)} onChange = {(e) => this.handleSelectAllTeachingLocations(e, teachingRegionLocationsWithSiteIds)}/>
                                                </label>
                                            </div>
                                          </div>
                                          <div className="p-2 max-h-60 overflow-y-auto">
                                            {teachingRegionLocationsWithSiteIds.map((region, i) =>
                                              <div className="py-1" key = {i}>
                                                {showFormOtherTeaching ?
                                                  <Menu.Item>
                                                    {({ active }) => (
                                                      <label className="flex items-center text-gray-900 text-sm mb-2">
                                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_region_id_${i}`} name={`business_region_id_${i}`} onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                                        {region.region.name}
                                                      </label>
                                                        
                                                    )}
                                                  </Menu.Item>
                                                :
                                                  <div className="mb-1.5">
                                                    {region.locations.map((location, j) =>
                                                    <Menu.Item key = {j}>
                                                      {({ active }) => (
                                                        <label className="flex items-center text-gray-900 text-sm mb-2">
                                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                          {location.name}
                                                        </label>
                                                          
                                                      )}
                                                    </Menu.Item>
                                                    )}
                                                  </div>
                                                }
                                                {(showFormOtherTeaching) &&
                                                  <div className="ml-5">
                                                    {region.locations.map((location, j) =>
                                                    <Menu.Item key = {j}>
                                                      {({ active }) => (
                                                        <label className="flex items-center text-gray-900 text-sm mb-2">
                                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                          {location.name}
                                                        </label>
                                                          
                                                      )}
                                                    </Menu.Item>
                                                    )}
                                                  </div>
                                                }
                                              </div>
                                            )}
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  </div>
                                
                                  {selectedLocations?.length > 0 && teachingRegionWithLocations?.length > 0 && 
                                    <div className='flex items-center mt-3 gap-2 flex-wrap'>
                                      {selectedLocations.map((loc, i) => 
                                        <div className='red-tag py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                          <div className='text-sm pr-1 font-medium'>{this.selectedLocName(loc)}</div>
                                          <a onClick={() => this.removeTeachingSelectedLocs(loc)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                            </svg>
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                  }
                                </React.Fragment>
                              }
                              {(!user?.mbo_site_id && !business?.multi_site_multi_staff_feature) &&
                                <span className="error text-xs">*To populate the location(s), please select a MBO Site</span>
                              }
                            </div>
                          }
                          {business.crunch_integration &&
                            <div>
                              {/* {teachingLocationForCrunch.map((region,i) =>
                                <div key={i}>
                                  {showFormOtherTeaching ?
                                    <React.Fragment>
                                      <input type="checkbox" id={`business_region_id_${i}`} name={`business_region_id_${i}`} onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                      <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_region_id_${i}`}>{region?.region?.name}</label>                          
                                    </React.Fragment>
                                  :
                                    <ul className = "mb-1.5 flex flex-wrap">
                                      {region.locations.map((location,j) =>
                                        <li className='mr-3 mb-3 flex items-center' key={j}>
                                          <input type="checkbox" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)} />
                                          <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_location_id_${location.id}`}>{location?.name}</label>
                                        </li>
                                      )}
                                    </ul>
                                  }
                                  {(selectedRegions.includes(region?.region?.name) && showFormOtherTeaching) &&
                                    <ul className = "pl-3.5 mt-1.5 flex flex-wrap">
                                      {region.locations.map((location,j) =>
                                        <li className='mr-3 mb-3 flex items-center' key={j}>
                                          <input type="checkbox" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)} />
                                          <label className='ml-1.5 text-sm font-medium text-gray-500' htmlFor={`business_location_id_${location.id}`}>{location?.name}</label>
                                        </li>
                                      )}
                                    </ul>
                                  }
                                </div>
                              )} */}
                              <div className="relative filters_box w-full lg:w-80">
                                <Menu as="div" className="relative block text-left">
                                  <div>
                                    <Menu.Button className="h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 class_items_button">
                                      {selectedLocations?.length === 0 ?
                                        <p>Select location(s)</p>
                                      :
                                      this.checkAllTeachingLocSelected(teachingLocationForCrunch) === true ?
                                        <p className='text-gray-900'>All locations selected</p>
                                      :
                                        <p className='text-gray-900'>{`${selectedLocations?.length} location${selectedLocations?.length > 1 ? 's' : ''} selected`}</p>
                                      } 
                                      <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    show={true}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden class_items filter_items">
                                      <div className="flex items-center p-2 border-b">
                                        <div className=" ml-auto">
                                          <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                            Select All
                                            <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkAllTeachingLocSelected(teachingLocationForCrunch)} onChange = {(e) => this.handleSelectAllTeachingLocations(e, teachingLocationForCrunch)}/>
                                            </label>
                                        </div>
                                      </div>
                                      <div className="p-2 max-h-60 overflow-y-auto">
                                        {teachingLocationForCrunch.map((region, i) =>
                                          <div className="py-1" key = {i}>
                                            {showFormOtherTeaching ?
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_region_id_${i}`} name={`business_region_id_${i}`} onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                                    {region.region.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                            :
                                              <div className="mb-1.5">
                                                {region.locations.map((location, j) =>
                                                <Menu.Item key = {j}>
                                                  {({ active }) => (
                                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                      {location.name}
                                                    </label>
                                                      
                                                  )}
                                                </Menu.Item>
                                                )}
                                              </div>
                                            }
                                            {(showFormOtherTeaching) &&
                                              <div className="ml-5">
                                                {region.locations.map((location, j) =>
                                                <Menu.Item key = {j}>
                                                  {({ active }) => (
                                                    <label className="flex items-center text-gray-900 text-sm mb-2">
                                                      <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" id={`business_location_id_${location.id}`} name="vehicle1" checked={selectedLocations?.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                      {location.name}
                                                    </label>
                                                      
                                                  )}
                                                </Menu.Item>
                                                )}
                                              </div>
                                            }
                                          </div>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                              {selectedLocations?.length > 0 && teachingRegionWithLocations?.length > 0 &&
                                <div className='flex items-center mt-3 gap-2 flex-wrap'>
                                  {selectedLocations.map((loc, i) => 
                                    <div className='red-tag py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                      <div className='text-sm pr-1 font-medium'>{this.selectedLocName(loc)}</div>
                                      <a onClick={() => this.removeTeachingSelectedLocs(loc)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="text-red-400 h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>
                                      </a>
                                    </div>
                                  )}
                                </div>
                              }
                            </div>
                          }
												</div>
											</div>
                      {(this.checkAndShowCertificationSection()) &&
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                          <label htmlFor="location" className="block text-sm text-left font-medium text-gray-500 sm:mt-px">Certifications</label>
                          <div className='mt-1 sm:mt-0 sm:col-span-2'>
                            <div className="relative filters_box w-full lg:w-80">
                              <Menu as="div" className="relative block text-left">
                                <div>
                                  <Menu.Button className={`h-10 flex items-center justify-center w-full rounded-md border border-gray-300 shadow-sm pl-4 pr-2 py-2 text-sm text-gray-400 focus:outline-nonefocus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500`}>
                                    {selectedCertificationIds?.length === 0 ?
                                      <p>Select certification(s)</p>
                                    :
                                    (this.checkAllCertsSelected()) ?
                                      <p className='text-gray-900'>All certifications selected</p>
                                    :
                                      <p className='text-gray-900'>{`${selectedCertificationIds?.length} certification${selectedCertificationIds?.length > 1 ? 's' : ''} selected`}</p>
                                    } 
                                    <ChevronDownIcon className="ml-auto text-gray-400 w-4 mr-0.5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95">
                                  <Menu.Items className="origin-top-right z-10 relative right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="flex items-center p-2 border-b">
                                      <div className=" ml-auto">
                                        <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                          Select All
                                          <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.checkAllCertsSelected()} onChange = {(e) => this.handleSelectAllCerts(e)}/>
                                          </label>
                                      </div>
                                    </div>
                                    <div className="p-2 max-h-60 overflow-y-auto">
                                      {certificationCategories.map((cert, i) =>
                                        <div className="py-1" key = {i}>
                                          <label className="flex items-center text-gray-900 text-sm mb-2">
                                            <input type="checkbox" className={`w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1`} id={cert.id} name="certifications[]" onChange={(e) => this.handleSelectCert(e, cert)} value={cert.id} checked={selectedCertificationIds?.includes(cert.id)}/>
                                            <label className={`ml-1.5 text-sm font-medium text-gray-500`} htmlFor={cert.id}>{cert?.name}</label> 
                                          </label>
                                        </div>
                                      )}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </div>
                            {selectedCertificationIds?.length > 0 &&
                              <div className='flex items-center mt-3 gap-2 flex-wrap'>
                                {selectedCertificationIds.map((categoryId, i) => 
                                  <div className='bg-purple-100 text-purple-800 py-0.5 px-2.5 flex items-center rounded-xl' key={i}>
                                    <div className='text-sm pr-1 font-medium'>{this.categoryName(categoryId)}</div>
                                    <a onClick={() => this.removeSelectedCertCategories(categoryId)}>
                                      <svg xmlns="http://www.w3.org/2000/svg" className="text-violet-800  h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                      </svg>
                                    </a>
                                  </div>
                                )}
                              </div>
                            }
                          </div>
                        </div>
                      }
											<div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
												<label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2"></label>
												<div className='mt-1 sm:mt-0 sm:col-span-2'>
                          <div className='flex justify-start'>
                            <input type="checkbox" id='add_instructor' className='mt-0.5' checked={add_user_in_classes} name="add_user_in_classes" onChange={(e) => this.setState({add_user_in_classes: e.target.checked})} />
                            <label className='ml-1.5 text-sm' htmlFor='add_instructor'>Invite instructor to all open and pending sub requests</label>
                          </div>
												</div>
											</div>
                      {(!integration_enabled(business)) && (formType == 'add' || user?.active_status == "Inactive") &&
                        <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start border-t border-gray-200 py-4'>
                          <label htmlFor="first-name" className="block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2">Send activation email?</label>
                          <div className='mt-1 sm:mt-0 sm:col-span-2 sm:pt-3'>
                            <div className='flex justify-start'>
                              <input type="checkbox" id='sendActivationEmail' className='mt-0.5' checked={sendActivationEmail} name="sendActivationEmail" onChange={(e) => this.setState({sendActivationEmail: e.target.checked})} />
                            </div>
                          </div>
                        </div>
                      }
											
											<div className='flex mt-4 gap-3 justify-end'>
												<a className='bg-white text-xs in line-flex items-center sm:text-base rounded-md hover:bg-gray-100 text-gray-700 font-medium border border-gray-200 px-6 py-3' onClick={(e) => this.closeModal(e)}>Cancel</a>
												<button className='flex text-xs sm:text-base font-medium hover:bg-gray-800 rounded-md text-white bg-dark-blue px-6 py-3 gap-2' onClick={(e) => this.handleSubmit(e)}>{formType === 'add' ? 'Add New' : 'Update'} User</button>
											</div>
                      </Fragment>
                      }
										</div>
									</div>
								</div>
								
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
    )
  }
}
