import React from 'react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition, Switch } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import Cookies from 'js-cookie';
//import { getSudomain, setTitle, timeZones } from './../../../helpers'
import { getDaysIndex, getSudomain, saveAvailabilityNote, savePermanentAdditionalInfo, getPreferredLocations, snakeCase, getName } from './helpers.js'
import {
  Link
} from "react-router-dom";
import { setTitle } from './../../helpers.js'
import { SearchIcon, ChevronDownIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default class Availability extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      day_index: "",
      alert_message: "",
      alert_type: "",
      show: false,
      editNote: false,
      note: "",
      extra_note: "",
      loaded: false,
      showAvailability: 'subbing',
      editPermanentAdditionInfo: false,
      permanentPreferredLocations: [],
      permanentPreferredWeeklyClasses: "0",
      permanentNote: '',
      permanentData: {},
      subbingData:{},
      permanentAddiInfoData: {},
      preferredResionsLocations: [],
      allLocations: [],
      selectedLocations: [],
      selectedRegions: [],
      showFilterOther: true,
      selectedRegLocToShow: [],
      showOthers: true,
      permanentPreferredWeeklyClassesArray: Array.apply(null, {length: 26}).map(Number.call, Number),
      disabledSaveAdditionalInfo: false
    }
  }

  componentDidMount = () => {
    this.checkAvailability()
    if(this.props.pageLoaded){
      this.getRegionLocations()
    }
    // this.getDays()
    setTitle('availability')
    this.props.setCurrent('Availability')
  }

  checkAvailability = () => { 
    const { business, user } = this.props   
    if (Object.keys(business).length != 0) {
      if(business.availability_feature === false){
        let role = snakeCase(user?.role)        
        if(role){
          let url = `/my-requests`
          window.location.href = url
        }        
      }
    }
  }

  componentWillUnmount() {
    window.addEventListener("beforeunload", function (event) {
      localStorage.removeItem("addiInfoData");
    })
    
    this.setState = (state,callback)=>{
      return;
    };
  }

  getDays = () => {
    let prevLoc = localStorage.getItem("startingURL");
    if(prevLoc && prevLoc !== null && prevLoc !== ''){
      this.setState({
        showAvailability: prevLoc
      })
      localStorage.removeItem("startingURL");
    }
    getDaysIndex(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          day_index: this.state.showAvailability === 'permanent' ? result.permanent_data : result.data,
          extra_note: result.note,
          note: result.note,
          permanentData: result.permanent_data,
          subbingData: result.data,
          permanentPreferredLocations: result.permanent_notes.preferred_locations,
          permanentPreferredWeeklyClasses: result.permanent_notes.preferred_classes,
          permanentNote: result.permanent_notes.permanent_notes,
          permanentAddiInfoData: result.permanent_notes,
          selectedLocations: result.permanent_notes.preferred_locations,
          loaded: true
        })
        this.showRegionLocation(result.permanent_notes.preferred_locations)
        localStorage.setItem("addiInfoData", JSON.stringify(result.permanent_notes));
        this.setSelectedLocations(this.state.preferredResionsLocations, result.permanent_notes.preferred_locations)
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })


    // let prevLoc = localStorage.getItem("startingURL");
    // if(prevLoc && prevLoc !== null && prevLoc !== ''){
    //   this.setState({
    //     showAvailability: prevLoc
    //   })
    //   localStorage.removeItem("startingURL");
    // }
  }

  getRegionLocations =() => {
    getPreferredLocations(Cookies.get(`currentUser.${getSudomain()}`), getSudomain()).then(response => response.json()
    ).then(result => {
      if(result.status === 200){
        this.setState({
          preferredResionsLocations: result.regions_and_locations,
          showFilterOther: result.show_others
        })
        this.getDays()
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
        this.getDays()
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })
  }

  dayName = (day) => {
    let name = ""
    switch(day){
      case 'monday':
        name = "Monday"
        break;
      case 'tuesday':
        name = 'Tuesday'
        break;
      case 'wednesday':
        name = 'Wednesday'
        break;
      case 'thursday':
        name = 'Thursday'
        break;
      case 'friday':
        name = 'Friday'
        break;
      case 'saturday':
        name = 'Saturday'
        break;
      case 'sunday':
        name = 'Sunday'
        break;
    }
    return name
  }

  getTimeFrom = (time) =>{
    let changeTime = time 
    return changeTime.time_from
  }

  getTimeTo = (time) => {
    let changeTime = time
    return changeTime.time_to
  }

  handleEditNotes = (e) => {
    this.setState({editNote: true})
  }

  handleCancelNotes = (e) => {
    this.setState({
      editNote: false,
      note: this.state.extra_note
    })
  }

  handleChangeText = (e) => {
    this.setState({note: e.target.value})
  }

  saveNotes = (e) => {
    saveAvailabilityNote(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), this.state.note).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Successfully Edited',
          alert_type: 'success',
          editNote: false,
          show: true,
          loaded: false
        })
        this.getDays()
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })
  }

  handleChangeShowAvailability = (e, type) => {
    if(type === 'subbing'){
      this.setState({day_index: this.state.subbingData})
    }else if(type === 'permanent'){
      this.setState({day_index: this.state.permanentData})
    }
    this.setState({showAvailability: type})
  }

  handlePermanentAdditionInfo = () => {
    this.setState({editPermanentAdditionInfo: true})
  }

  handleCancelPermanentAdditionInfo = () => {
    let permanent_notes = JSON.parse(localStorage.getItem("addiInfoData"));// this.state.permanentAddiInfoData
    this.setState({
      editPermanentAdditionInfo: false,
      permanentPreferredLocations: permanent_notes.preferred_locations,
      permanentPreferredWeeklyClasses: permanent_notes.preferred_classes,
      permanentNote: permanent_notes.permanent_notes,
      selectedLocations: permanent_notes.preferred_locations
    })
    this.setSelectedLocations(this.state.preferredResionsLocations, permanent_notes.preferred_locations)
  }

  handleChangePermanentAdditionalInfo = (e) => {
    let name = e.target.name
    let value = e.target.value
    this.setState({
      [name]: value
    })
  }

  handleKeyDownPermanentPreferredWeeklyClasses = (e) => {
    e.preventDefault();
  }

  performSavePermanentAdditionalInfo = (e) => {
    this.setState({disabledSaveAdditionalInfo: true})
    let data = {
      preferred_classes: this.state.permanentPreferredWeeklyClasses,
      preferred_locations: this.state.selectedLocations,
      permanent_class_notes: this.state.permanentNote
    }

    savePermanentAdditionalInfo(Cookies.get(`currentUser.${getSudomain()}`), getSudomain(), data).then(response => response.json()
    )
    .then(result => {
      if(result.status === 200){
        this.setState({
          alert_message: 'Successfully Edited',
          alert_type: 'success',
          show: true,
          loaded: false,
          editPermanentAdditionInfo: false,
          disabledSaveAdditionalInfo: false
        })
        this.getDays()
      }else{
        this.setState({
          alert_message: result.error,
          alert_type: 'danger',
          show: true,
          disabledSaveAdditionalInfo: false
        })
      }
      this.setState({show:true},()=>{
        window.setTimeout(()=>{
          this.setState({show:false})
        },5000)
      });
    })

  }

  setSelectedLocations = (regions, locations) => {
    let selectLocations = locations
    let all_locations = []
    for(var i=0; i<regions.length;i++){     
      let selected_locations = [] 
      for(var j=0; j<regions[i]['locations'].length; j++){
        //if(regions[i]['locations'][j]['checked'] === true){
          all_locations.push({id: regions[i]['locations'][j]['id'], name: regions[i]['locations'][j]['name']})
        //}
        if(selectLocations.includes(regions[i].locations[j].id)){
          selected_locations.push(regions[i].locations[j].id)
        }
      }
      // if(selected_locations?.length > 0){
        this.checkRegionVisibility(regions[i], selected_locations)
      // }
    }
    this.setState({
      allLocations: all_locations,
    })
  }

  locationName = () => {
    let locations = this.state.allLocations
    let selected_locations = this.state.selectedLocations
    let locationName = []
    let ctr = 0
    for(let i = 0; i < locations?.length; i++){
      if(selected_locations.includes(locations[i].id)){
        if(ctr < 2){
          locationName.push(locations[i].name)
          ctr++
        }
        else{
          break;
        }
      }
    }
    return locationName.join(", ")
  }

  selectRegions = (e, region) => {
    let val = e.target.value
    let regions = this.state.selectedRegions
   
    var __FOUND = regions.indexOf(val) > -1;
    var result = region.locations.map(function(a) {return a.id;});
    let selected_locations = this.state.selectedLocations;
    
    if(e.target.checked){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }));
        let final_result = []
        for(let i = 0; i < result?.length; i++){
          if(!selected_locations.includes(result[i])){
            final_result.push(result[i])
          }
        }
        selected_locations = Array.prototype.push.apply(selected_locations, final_result)
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
        selected_locations = selected_locations.filter(item => !result.includes(item));
        this.setState({
          selectedLocations: selected_locations
        })
      }
    }
  }

  selectLocation = (e, region) => {
    let val = parseInt(e.target.value)
    var __FOUND = this.state.selectedLocations.includes(val)
    if(e.target.checked){
      if(!__FOUND){
        let newlocs = [...this.state.selectedLocations, val]
        this.setState(state => ({ selectedLocations: newlocs }));
        this.checkRegionVisibility(region, newlocs)
      }
    }else{
      if(__FOUND){
        const { selectedLocations } = this.state;
        let newLocs = selectedLocations.filter((tag, index) => tag !== val)  
        this.setState({
          selectedLocations: newLocs        
        });
        this.checkRegionVisibility(region, newLocs)  
      }      
    }
  }

  checkRegionVisibility = (region, locations) =>{
    let location_ids = region.locations.map(function(a) {return a.id;});
    let checker = (arr, target) => target.every(v => arr.includes(v));
    let equal = checker(locations, location_ids)
    let val = region.region?.name

    let regions = this.state.selectedRegions
    var __FOUND = regions.indexOf(val) > -1;
    if(equal){
      if(!__FOUND){
        this.setState(state => ({ selectedRegions: [...state.selectedRegions, val] }))
      }
    }else{
      if(__FOUND){
        
        const { selectedRegions } = this.state;
        this.setState({
          selectedRegions: selectedRegions.filter((tag, index) => tag !== val)          
        });
      }
    }
  }

  selectedAllRegLoc = () => {
    let check = this.state.selectedRegions?.length === this.state.preferredResionsLocations?.length
    return check
  }

  selectedAllRegionsLocations = (e) => {
    if(e.target.checked){
      let all = this.state.preferredResionsLocations
      let selected_regions = []
      let selected_locations = []
      for(var i=0; i<all.length;i++){     
        selected_regions.push(all[i]['region']['name'])
        for(var j=0; j<all[i]['locations'].length; j++){
          selected_locations.push(all[i]['locations'][j]['id'])
        }
      }
      this.setState({
        selectedLocations: selected_locations,
        selectedRegions: selected_regions
      })
    }else{
      this.setState({
        selectedRegions: [],
        selectedLocations: []
      })
    }
  }

  showRegionLocation = (locations) => {
    let all_preferredResionsLocations = this.state.preferredResionsLocations
    let selectedLocations = locations
    let finalArr = []
    if(locations?.length > 0){
      let selected_regions = []
      
      for(let i = 0; i < all_preferredResionsLocations?.length; i++){
        let string = ''
        let select_reg = ''
        let selected_locations = []
        let region = all_preferredResionsLocations[i]
        let location_ids = region.locations.map(function(a) {return a.id;});    
        const found = location_ids.some(r=> selectedLocations.includes(r))
        if(found){
          select_reg = region.region.name
        }
        region.locations.map(function(a) {
          if(selectedLocations.includes(a.id)){
            selected_locations.push(a.name)
          }
        });

        string = select_reg+': '+selected_locations.join(' ')
        selected_regions.push(string)
        if(selected_locations?.length > 0){
          finalArr.push({region: select_reg, locations: selected_locations})
        }
      }
    }
    let show_others = true
    if(finalArr?.length === 1 && finalArr[0].region === "Others"){
      show_others = false
    }else{
      show_others = true
    }
    this.setState({
      selectedRegLocToShow: finalArr,
      showOthers: show_others
    })
  }

  render(){
    const { day_index, editNote, note, alert_message, alert_type, show, loaded, extra_note, showAvailability, editPermanentAdditionInfo, permanentPreferredLocations, permanentPreferredWeeklyClasses, permanentNote, preferredResionsLocations, selectedLocations, selectedRegions, allLocations, showFilterOther, selectedRegLocToShow, showOthers, permanentPreferredWeeklyClassesArray, disabledSaveAdditionalInfo } = this.state
    const { user, business, loadingMessage } = this.props
    
    return (  
      <React.Fragment> 
        {show &&
          <React.Fragment>
            {alert_type === 'success' &&
              <div className="w-3/5 flex items-center bg-green-50 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50 left-0 right-0 mx-auto">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-400 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-green-800 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }
            {alert_type === 'danger' &&
              <div className="w-3/5 flex items-center bg-red-100 rounded-md px-4 py-3.5 mb-1 fixed top-1 z-50">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-red-300 mr-3.5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
                <div className="text-sm text-red-700 font-medium">
                  {alert_message}
                </div>
                <a href="" className="ml-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </a>
              </div>
            }  
          </React.Fragment>
        } 
        {!loaded &&
          <div className="msg-loading flex flex-col items-center w-full justify-center h-screen">
            <p className="mb-1">{loadingMessage}</p>
            <img className = "w-9" src="/assets/business/loading.gif" />
          </div>
        }
        {loaded &&
          <div className="w-full min-h-screen bg-gray-50">
            <div className="pt-20 md:pt-8 pb-8">
              <header>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <h1 className="text-2xl font-semibold leading-tight text-gray-900">Availability</h1>
                </div>
              </header>
              <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                  <div className="px-4 pt-4 sm:px-0">
                    <div className='inline-flex bg-white shadow-md mb-4 rounded-lg'>
                      <a className={`text-sm py-3.5 w-36 sm:w-52 text-center font-semibold border-b-2 rounded-bl-lg ${showAvailability === 'subbing' ? 'text-gray-900 border-dark-blue' : 'text-gray-300 border-transparent cursor-pointer '}`} onClick={(e) => this.handleChangeShowAvailability(e, 'subbing')}>Subbing</a>
                      <a className={`text-sm py-3.5 w-36 sm:w-52 text-center font-semibold border-b-2 rounded-br-lg ${showAvailability === 'permanent' ? 'text-gray-900 border-dark-blue' : 'text-gray-300 border-transparent cursor-pointer'}`} onClick={(e) => this.handleChangeShowAvailability(e, 'permanent')}>Permanent Classes</a>
                    </div>
                    <div className="bg-white shadow-md rounded-lg px-6 pt-7">
                      <div className="flex items-center mb-4 pb-1">
                        <div className="text-lg font-medium flex-auto">{showAvailability === 'permanent' ? 'Permanent Classes Weekly Hours' : 'Subbing Weekly Hours'}</div>
                        <Link className="flex bg-dark-blue text-sm text-white py-2 px-4 rounded-md h-10" to={`/availability/edit/${showAvailability}`}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                          </svg>
                          Edit
                        </Link>
                      </div>
                      {day_index?.length > 0 &&
                        <React.Fragment>
                          {day_index?.map((day, i) => 
                            <div key = {i} className="grid grid-cols-1 border-t border-gray-200 py-8 md:py-10">
                              <div className="flex flex-wrap relative">
                                <div className="w-1/2 sm:w-1/6 md:w-1/6 font-medium text-sm text-gray-900">{this.dayName(day.day)}
                                </div>
                                {day.available &&
                                  <React.Fragment>
                                    <div className="w-full sm:1/4 md:w-4/6 pt-6 md:pt-0 font-normal text-sm text-gray-700">
                                      {day.availability_slots_attributes?.map((hours,j) =>
                                        ((showAvailability === 'permanent' && !business?.block_permanent_availability) || (showAvailability === 'subbing' && !business?.block_subbing_availability) ?
                                          <div key = {j} className = "mb-2">From {this.getTimeFrom(hours)} to {this.getTimeTo(hours)}</div>
                                          :
                                          ((showAvailability === 'permanent' && business?.block_permanent_availability) || (showAvailability === 'subbing' && business?.block_subbing_availability)) && hours?.accepted &&
                                            <div key = {j} className = "mb-2">From {this.getTimeFrom(hours)} to {this.getTimeTo(hours)}</div>
                                        )
                                      )}
                                    </div>
                                    <div className="absolute top-1 md:top-0 right-0 sm:w-1/6 md:w-1/6 font-normal text-xs text-green-500 text-right">Available
                                    </div>
                                  </React.Fragment>
                                }
                                {!day.available &&
                                  <React.Fragment>
                                    
                                    <div className="absolute top-1 md:top-0 right-0 sm:w-1/6 font-normal text-xs text-red-500 text-right">Not available
                                    </div>
                                  </React.Fragment>
                                }
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      }
                    </div> 
                    {/* {showAvailability === 'subbing' &&
                      <div className="bg-white shadow-md rounded-lg p-6 mt-4">
                        <div className="flex items-center border-b pb-4">
                          <div className="text-lg font-medium flex-auto">
                            Additional Information
                          </div>
                          {!editNote &&
                            <button className="flex text-sm bg-dark-blue text-white py-2 px-4 rounded-md h-10" onClick = {(e) => this.handleEditNotes(e)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                                Edit
                            </button>
                          }
                        </div>
                        
                        {!editNote &&
                          <div className="flex flex-wrap pt-5 pb-6">
                            <div className="w-1/4 text-sm text-gray-500 mb-1 md:mb-0">Note</div>
                            <div className="w-full md:w-3/4 text-sm text-gray-900">
                              <p className="break-all">{note}</p>
                            </div>
                          </div>
                        }
                        {editNote &&
                          <React.Fragment>
                          <div className="flex flex-wrap pt-5">
                          <div className="w-1/4 text-sm text-gray-500 mb-1 md:mb-0">Note</div>
                            <div className="w-full md:w-3/4 text-sm text-gray-900">
                              <textarea className="w-full text-sm border focus:outline-none rounded-md text-gray-500 shadow-sm resize-none py-2 px-3.5" rows="3" defaultValue = {note} placeholder = "Enter note" onChange = {(e) => this.handleChangeText(e)}>
                              </textarea>
                            </div>
                            </div>
                            <div className="flex mt-4 gap-3 md:justify-end">
                              <button href = "/availability" className="text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" onClick = {(e) => this.handleCancelNotes(e)}>Cancel
                              </button>
                              <button className="flex text-base font-medium rounded-md text-white bg-dark-blue px-4 py-3 gap-2" onClick = {(e) => this.saveNotes(e)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                </svg>
                                Save
                              </button>
                            </div>
                          </React.Fragment>
                        }
                      </div>
                    } */}
                    {showAvailability === 'permanent' &&
                      <div className="bg-white shadow-md rounded-lg p-6 mt-4">
                        <div className='flex items-center border-b border-gray-200 pb-4'>
                          <div className='text-lg font-medium flex-auto'>
                            Additional information
                          </div>
                          {!editPermanentAdditionInfo &&
                            <button className="flex text-sm bg-dark-blue text-white py-2 px-4 rounded-md h-10" onClick = {(e) => this.handlePermanentAdditionInfo(e)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1.5" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                                Edit
                            </button>
                          }
                        </div>
                        {/* Location where wants to teach */}
                        <div className="flex flex-wrap pt-5 md:pb-0 pb-5 md:border-b-0 border-b border-gray-200">
                          <div className='md:w-1/4 text-sm text-gray-900 mb-1 md:mb-0'>
                            Preferred Locations
                          </div>
                          {!editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                              {selectedRegLocToShow?.length > 0 &&
                                <React.Fragment>
                                  {selectedRegLocToShow.map((reg, i) =>
                                    <div className='flex' key = {i}> 
                                      {showOthers && <div>{reg.region}:</div>} <div className={`${showOthers ? 'ml-2' : ''}`}>{reg.locations.join(', ')}</div>
                                    </div>
                                  )}
                                </React.Fragment>
                              }
                              </div>
                            </div>
                          }
                          {editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              {/* <div className="relative">
                                <select className='form-select text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none'>
                                    <option>Select location(s)</option>
                                </select>
                                <p className='text-gray-500 text-sm mt-1'>Where do you want to teach?</p>
                              </div> */}
                              <div className="relative filters_box">
                                <Menu as="div" className="relative block text-left">

                                  <div>
                                    <Menu.Button className="inline-flex justify-center w-full rounded border border-gray-200 shadow-sm pl-4 pr-2 py-2 bg-white text-sm text-gray-400 focus:outline-none focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 location_items_button">
                                      {selectedLocations?.length === allLocations?.length && <p className = "text-gray-900">All Locations Selected</p>
                                      }
                                      {selectedLocations?.length < allLocations?.length && 
                                        <React.Fragment>
                                          {(selectedLocations?.length <= 2 && selectedLocations?.length !== 0) && 
                                            <p className = "text-gray-900">{this.locationName()}</p>
                                          }
                                          {(selectedLocations?.length > 2 && selectedLocations?.length < allLocations?.length) && 
                                            <p className = "text-gray-900">{this.locationName()} +{selectedLocations?.length-2}</p>
                                          }
                                        </React.Fragment>
                                      }
                                      {(selectedLocations?.length === 0  && selectedLocations?.length !== allLocations?.length) && <p>Select location</p>} 
                                      <ChevronDownIcon className="ml-auto h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    show={true}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95">
                                    <Menu.Items className="origin-top-right z-10 absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none hidden location_items filter_items">
                                      <div className="flex items-center p-2 border-b">
                                    {/*<div className="relative">
                                      <div className="bg-gray-50 rounded-lg h-8">
                                        <input type="text" className="bg-gray-50 px-2 py-1.5 w-full focus:outline-none text-sm text-gray-900 placeholder-gray-500"  placeholder="Search" />
                                        <button className="absolute top-1.5 right-1.5">
                                          <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>*/}
                                    <div className=" ml-auto">
                                      <label className="inline-flex items-center text-gray-400 text-sm mb-2">
                                        Select All
                                        <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 ml-1" checked = {this.selectedAllRegLoc()} onChange = {(e) => this.selectedAllRegionsLocations(e)}/>
                                          
                                        </label>
                                    </div>
                                  </div>
                                      <div className="p-2 max-h-60 overflow-y-auto">
                                        {preferredResionsLocations.map((region, i) =>
                                          <div className="py-1" key = {i}>
                                            {showFilterOther &&
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" onChange={(e) => this.selectRegions(e, region)} value={region?.region?.name} checked={selectedRegions.includes(region.region.name)}/>
                                                    {region.region.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                            }
                                            <div className="ml-5">
                                              {region.locations.map((location, j) =>
                                              <Menu.Item key = {j}>
                                                {({ active }) => (
                                                  <label className="flex items-center text-gray-900 text-sm mb-2">
                                                    <input type="checkbox" className="w-4 h-4 form-checkbox text-dark-blue border rounded border-gray-300 focus:outline-none focus:border-gray-300 mr-1" checked={selectedLocations.includes(location?.id)} value={location.id} onChange={(e) => this.selectLocation(e, region)}/>
                                                    {location.name}
                                                  </label>
                                                    
                                                )}
                                              </Menu.Item>
                                              )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div>
                          }
                        </div>
                        {/* No of classes to teach */}
                        <div className="flex flex-wrap pt-5 md:pb-0 pb-5 md:border-b-0 border-b border-gray-200">
                          <div className='md:w-1/4 text-sm text-gray-900 mb-1 md:mb-0'>
                            Preferred # Weekly Classes
                          </div>
                          {!editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                <p>{permanentPreferredWeeklyClasses} classes per week</p>
                              </div>
                            </div>
                          }
                          {editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                {/* <input 
                                  id="permanent-PreferredWeeklyClasses"
                                  name="permanentPreferredWeeklyClasses"
                                  type='number'
                                  min="0"
                                  max="20" 
                                  onKeyDown={(e) => this.handleKeyDownPermanentPreferredWeeklyClasses(e)}
                                  className='text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none' 
                                  value={permanentPreferredWeeklyClasses}
                                  onChange={(e) => this.handleChangePermanentAdditionalInfo(e)}
                                  /> */}
                                  <select 
                                    id="permanent-PreferredWeeklyClasses" name="permanentPreferredWeeklyClasses" className='form-select text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none' value={permanentPreferredWeeklyClasses}
                                    onChange={(e) => this.handleChangePermanentAdditionalInfo(e)}>
                                      {permanentPreferredWeeklyClassesArray.map((num, i) => 
                                        <option key={i} value={num}>{num}</option>
                                      )}
                                  </select>
                                <p className='text-gray-500 text-sm mt-1'>How many classes per week do you want to teach? Pick between 1 - 25 classes</p>
                              </div>
                            </div>
                          }
                        </div>
                        {/* Note */}
                        <div className="flex flex-wrap pt-5">
                          <div className='md:w-1/4 text-sm text-gray-900 mb-1 md:mb-0'>
                            Notes
                          </div>
                          {!editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                <p>{permanentNote}</p>
                              </div>
                            </div>
                          }
                          {editPermanentAdditionInfo &&
                            <div className='w-full md:w-1/3 text-sm text-gray-900 md:pl-5'>
                              <div className="relative">
                                <textarea 
                                  id= "permanent-Note"
                                  name="permanentNote"
                                  value={permanentNote}
                                  className='text-sm p-2 bg-white text-gray-700 rounded-md border shadow-sm w-full focus:outline-none' 
                                  onChange={(e) => this.handleChangePermanentAdditionalInfo(e)}
                                >
                                </textarea>
                              </div>
                            </div>
                          }
                        </div>
                        {editPermanentAdditionInfo &&
                          <div className="flex mt-4 gap-3 md:justify-end">
                            <a className="cursor-pointer text-base rounded-md text-gray-700 font-medium border border-gray-200 px-6 py-3" onClick={() => this.handleCancelPermanentAdditionInfo()}>Cancel</a>
                            <button className={`flex text-base font-medium rounded-md text-white px-4 py-3 gap-2 ${disabledSaveAdditionalInfo ? 'button-disabled bg-gray-500' : 'bg-dark-blue'}`} onClick={(e) => this.performSavePermanentAdditionalInfo(e)} disabled={disabledSaveAdditionalInfo}><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>Save</button>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>
              </main>
            </div>
          </div>
        }
      </React.Fragment>
    )
  }
}