import React, { Fragment } from "react";
import BreadCrumbs from './submitCertification/breadCrumbs';
import TopButtons from './submitCertification/topButtons';

export default function CertificationHeader({path}) {
  return (
    <Fragment>
      {path == 'submit-certification' ?
        <Fragment>
          <BreadCrumbs />
          <TopButtons />
        </Fragment>
      :
        <header>
          <h1 className="text-2xl font-semibold mb-5 leading-tight text-gray-900">Certifications</h1>
        </header>
      }
    </Fragment>
  );
}