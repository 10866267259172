import React, { Fragment } from "react";

export default function CancelActionComponent({handleCancelMassAction}) {
  return (
    <Fragment>
      <div className={`flex items-center`}>
        <button className={`text-red-600 font-semibold text-sm`} onClick={() => handleCancelMassAction()}>Cancel</button>
      </div>
    </Fragment>
  )
}