import React, { Fragment } from "react";
import LoadingIcon from '../../../subRequests/assets/loadingIcon';
import CustomPopupContainerComponent from '../../../subRequestDetails/components/customPopupContainerComponent';

export default function RemoveFileConfirmation(props) {
  const { showConfirmation, handleClose, processRemove, disabledRemove } = props

  return (
    <Fragment>
      <CustomPopupContainerComponent show={showConfirmation} close={handleClose} title={'Remove File'} customWidth={'max-w-lg'}>
        <div className="pt-4 border-t border-gray-200 mx-7 mt-4 mb-2">
          <div className="flex text-black text-left mb-4">
            Are you sure you want to remove your file?
          </div>
          <div className="mt-5 sm:mt-4 gap-2 flex flex-row-reverse items-center flex-wrap">
            <button
              type="button"
              className={`flex bg-dark-blue text-white inline-block px-6 py-2 border border-gray-900 shadow rounded-lg relative ${disabledRemove ? 'cursor-not-allowed opacity-70' : 'cursor-pointer hover:bg-gray-800'}`}
              onClick={(e) => processRemove(e)}
              disabled={disabledRemove}
            >
              {disabledRemove &&
                <div className="absolute top-0 bottom-0 left-1.5 flex items-center justify-center">
                  <LoadingIcon classNames={'animate-spin h-4 w-4 text-white'}/>
                </div>
              }
              Yes, remove file
            </button>
            <button
              type="button"
              className={`flex bg-gray-50 text-gray-900 px-6 py-2 border shadow rounded-lg ${disabledRemove ? 'cursor-not-allowed opacity-70' : 'cursor-pointer hover:bg-gray-100'}`}
              onClick={(e) => handleClose(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomPopupContainerComponent>
    </Fragment>
  )
}