import React from 'react';
import Cookies from 'js-cookie';
import { getSudomain } from '../../helpers'

export function getHeaders(token, subdomain){  
  if (token !== undefined) {
    let obj = {
      'Accept': 'application/vnd.netgym.v3',
      'Content-Type': 'application/json',
      'Authorization': eval(`${token}`),
      'subdomain': localStorage.getItem("businessID") || subdomain
    }
    return obj    
  }  
}

export function userToken(){
  return `${Cookies.get(`currentUser.${getSudomain()}`)}`
}

export const axiosGetRequest = async(url) => {
  return await fetch(url, {
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const getPopupHeadingText = () => {
  window.setTimeout(()=> {
    let a = document.getElementsByClassName("fc-popover-header")
    if(a.length > 0 && a[0] !== null){
      let text = a[0].textContent
      if(text !== undefined){
        let text_split = text.replace(',', '').split(' ')
        let divHeading = document.createElement('div')
        divHeading.classList.add('heading-div-content-1')
        let label1 = document.createElement('label')
        $(label1).addClass('popover-label1-class')
        $(label1).text(text_split[0])
        let label2 = document.createElement('label')
        $(label2).addClass('popover-label2-class')
        $(label2).text(text_split[2])
        let label3 = document.createElement('label')
        $(label3).addClass('popover-label3-class')
        $(label3).text(text_split[1])
        $(divHeading).append(label1)
        $(divHeading).append(label2)
        $(divHeading).append(label3)
        $(a).prepend(divHeading)
      }
    }
  }, 10)
}

export const hideItems = (cls) => {
  $('.filter_items').each(function(i, obj) {
    if($(obj).hasClass(`${cls}_items`)){
      $(`.${cls}_items`).toggleClass("hidden")
      if($(`.${cls}_items_button`).hasClass('need-border')){
        $(`.${cls}_items_button`).toggleClass("filter-dynamic-border")
      }
    }else{
      $(obj).addClass("hidden")
      $('.need-border').each(function(i, obj1) {
        if(!$(obj1).hasClass(`${cls}_items_button`)){
          $(obj1).removeClass("filter-dynamic-border")
        }
      })
    }
  });
}

export const chekLocationNameCount = (name) => {
  let shortName = []
  for(let i = 0; i < name?.length; i++){
    if(i < 19){
      shortName.push(name[i])
    }else{
      shortName.push('...')
      break
    }
  }

  return shortName.join('')
}

export const seprateOutLocations = (regions) => {
  let locations = []
  for (let i = 0; i < regions.length; i++) {
    locations = [...locations, ...regions[i].locations]
  }
  return locations
}

export const axiosRequest = async(url, method, params, headerType) => {
  return await fetch(url, {
    method: method,
    body: headerType == 'content' ? params : JSON.stringify(params),
    headers: headerType == 'content' ? {
      'Accept': 'application/vnd.netgym.v3',
      'Authorization': eval(`${userToken()}`),
      'subdomain': localStorage.getItem("businessID") || `${getSudomain()}`
    } : getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const axiosPostRequestWithoutData = async(url) => {
  return await fetch(url, {
    method: 'POST',
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const saveComment = async(params) => {
  let url = '/api/v2/business_comments'
  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const axiosGetDownloadRequest = async(url) => {
  return await fetch(url, {
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.blob()
  )
}

export const handleStartDate = (currentDate) => {
  let monthFirstDate = (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
  let firstDayOfCalendar = new Date(monthFirstDate.getFullYear(), monthFirstDate.getMonth(), monthFirstDate.getDate() - monthFirstDate.getDay());
  return firstDayOfCalendar
}

export const handleEndDate = (currentDate) => {
  let monthLastDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
  let lastDayOfCalendar = new Date(monthLastDate.getFullYear(), monthLastDate.getMonth(), monthLastDate.getDate() + (6 - monthLastDate.getDay()));
  return lastDayOfCalendar
}

export const handleSetEventsData = (events, pageType) => {
  let backPathNeeded = ['all-requests'].includes(pageType)
  return events?.map((event) => ({
    ...event,
    start: event?.start.substring(0, 19),
    end: event?.end.substring(0, 19),
    redirect_url: backPathNeeded ? event?.url : event?.url?.replace('?backpath=schedule_path', '') || '',
    url: backPathNeeded ? event?.url : event?.url?.replace('?backpath=schedule_path', '') || ''
  })) || []
}

export const performMGRAction = async(url, params) => {
  return await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(params),
    headers: getHeaders(`${userToken()}`, `${getSudomain()}`)
  }).then(      
    response => response.json()
  )
}

export const handleCheckCurrentDate = () => {
  let storedDate = localStorage.getItem("calendarDate")
  if(storedDate && storedDate !== ''){
    return new Date(storedDate)
  }else{
    return new Date()
  }
}

export const integratedBusiness = (business) => {
  return (business?.mbo_integration || business?.abc_integration || business?.marianatek_integration || business?.clubready_integration || business?.crunch_integration || business?.fisikal_integration)
}

export const calendarTypeList = [
  {
    label: 'Day',
    value: 'day'
  },
  {
    label: 'Week',
    value: 'week'
  },
  {
    label: 'Month',
    value: 'month'
  },
  {
    label: 'Year',
    value: 'year'
  }
] 

export const handleStartDateOfweek = (date) => {
  const dayOfWeek = date.getDay();
  const sundayDate = new Date(date);
  sundayDate.setDate(date.getDate() - dayOfWeek);
  return new Date(sundayDate);
}

export const handleEndDateOfWeek = (date) => {
  const dayOfWeek = date.getDay();
  const diff = 6 - dayOfWeek;
  const saturdayDate = new Date(date);
  saturdayDate.setDate(date.getDate() + diff);
  return new Date(saturdayDate);
}

export const oldControllerAxiosGetRequest = async(url) => {
  return await fetch(url, {
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=UTF-8',
      'Cookie': document.cookie,
      'Host': window.location.host
    }
  }).then(      
    response => response.json()
  )
}

export const snakeCase = (string) => {
  return string?.replace(/\d+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_');
}

export const getName = (role) => {
  let role_name = snakeCase(role)
  if (role_name === 'account_owner') role_name = 'business_admin'
  return (['business_admin', 'fitness_instructor'].includes(role_name) ? role_name?.split('_')[1] : role_name)
}

export const showCancelButton = (eventData, user) => {
  return(eventData?.is_more_action && (["Business Admin", 'business_admin', 'account_owner', 'Account Owner'].includes(user?.role) || eventData?.admins.includes(user?.id) || eventData?.gfms.includes(user?.id) || eventData?.requesting_instructor?.id == user?.id || eventData?.original_instructor?.id == user.id) && !eventData?.cancelled && !eventData?.no_show)
}

export const oldControllerAxiosRequest = async(url, method, params) => {
  return await fetch(url, {
    method: method,
    body: JSON.stringify(params),
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=UTF-8',
      'Cookie': document.cookie,
      'Host': window.location.host
    }
  }).then(      
    response => response.json()
  )
}

export const simplifyStringAndTags = (text) => {
  return text.replace(/@\[(.*?)\]\(email:.*?\)/g, '@$1');
}

export const simplifyUserTags = (text) => {
  let splitedText = text?.split('@')
  let userTagsIds = []
  if(splitedText?.length > 1){
    let arr = []
    for (let i = 1; i < splitedText?.length; i++){
      let a = ''
      if(splitedText[i]?.includes('](')){
        let b = splitedText[i]?.split('](')
        let c = b[1]?.split(')')[0]
        a = b[0]+']('+c
      }
      arr.push(a+')')
    }
    
    if(arr?.length > 0){
      for(let i = 0; i < arr?.length; i++){
        let str1 = arr[i]?.split(':')[1]
        let str = str1?.split(')')[0]
        if(!isNaN(str)){
          userTagsIds.push(parseInt(str))
        }
      }
    }
  }
  return userTagsIds
}

export const showReopenButton = (eventData) => {
  return(!eventData?.mrg_reopened && !eventData?.past && !eventData?.cancelled && Object.keys(eventData?.approved_user).length > 0 && eventData?.check_reopen)
}

export const showResendButton = (eventData, user) => {
  return((['business_admin', 'Business Admin', 'account_owner', 'Account Owner'].includes(user?.role) || eventData?.admins?.includes(user?.id) || eventData?.gfms?.includes(user?.id) || eventData?.requesting_instructor?.id == user?.id || eventData?.original_instructor?.id == user.id) && (eventData?.canInvite || eventData?.is_more_action) && Object.keys(eventData?.approved_user).length == 0  && !eventData?.past && !eventData?.cancelled)
}

export const eventDetailUrl = (event, path) => {
  return (`/sub-request-details/${event?.id}?backpath=${['my-requests'].includes(path) ? 'my_schedule_path' : 'schedule_path'}`)
}

export const currentMonthFirstDate = (currentDate) => {
  let monthFirstDate = (new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))
  return monthFirstDate
}

export const currentMonthLastDate = (currentDate) => {
  let monthLastDate = (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
  return monthLastDate
}

export const currentYearFirstDate = (currentDate) => {
  let yearFirstDate = (new Date(currentDate.getFullYear(), 0, 1))
  return yearFirstDate
}

export const currentYearLastDate = (currentDate) => {
  let yearLastDate = (new Date(currentDate.getFullYear(), 11, 31))
  return yearLastDate
}

export const currentYearFirstDateByCalendar = (currentDate) => {
  let monthFirstDate = currentYearFirstDate(currentDate)
  let firstDayOfCalendar = new Date(monthFirstDate.getFullYear(), monthFirstDate.getMonth(), monthFirstDate.getDate() - monthFirstDate.getDay());
  return firstDayOfCalendar
}

export const currentYearLastDateByCalendar = (currentDate) => {
  let monthLastDate = currentYearLastDate(currentDate)
  let lastDayOfCalendar = new Date(monthLastDate.getFullYear(), monthLastDate.getMonth(), monthLastDate.getDate() + (6 - monthLastDate.getDay()));
  return lastDayOfCalendar
}

export const dateTimeString = (start_date) => {
  let start_time = start_date?.substring(0, 19)
  let formattedDate = new Date(start_time).toLocaleString('en-US', { 
    weekday: 'short', 
    day: '2-digit', 
    month: '2-digit', 
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  return formattedDate.replace(/,/g, '');
}

export const veryFirstSetStartDate = (currentDate, type, page) => {
  if(type == 'month'){
    if(page == 'list'){
      return (currentMonthFirstDate(currentDate).toLocaleDateString('en-GB'))
    }else{
      return (handleStartDate(currentDate).toLocaleDateString('en-GB'))
    }
  }else if(type == 'week'){
    return (handleStartDateOfweek(currentDate).toLocaleDateString('en-GB'))
  }else if(type == 'day'){
    return (currentDate.toLocaleDateString('en-GB'))
  }else if(type == 'year'){
    if(page == 'list'){
      return (currentYearFirstDate(currentDate).toLocaleDateString('en-GB'))
    }else{
      return (currentYearFirstDateByCalendar(currentDate).toLocaleDateString('en-GB'))
    }
  }
}

export const veryFirstSetEndDate = (currentDate, type, page) => {
  if(type == 'month'){
    if(page == 'list'){
      return (currentMonthLastDate(currentDate).toLocaleDateString('en-GB'))
    }else{
      return (handleEndDate(currentDate).toLocaleDateString('en-GB'))
    }
  }else if(type == 'week'){
    return (handleEndDateOfWeek(currentDate).toLocaleDateString('en-GB'))
  }else if(type == 'day'){
    return (currentDate.toLocaleDateString('en-GB'))
  }else if(type == 'year'){
    if(page == 'list'){
      return (currentYearLastDate(currentDate).toLocaleDateString('en-GB'))
    }else{
      return (currentYearLastDateByCalendar(currentDate).toLocaleDateString('en-GB'))
    }
  }
}

export const filterEventsAsPerFilters = (business, statusFilter, locations, instructors, classes, approvedEvents, cancelledEvents, managerEvents, noShowEvents, openEvents, pendingEvents, path, allLocationsData, allInstructorsData, allClassesData, allStatuses, user_id) => {
  let events = []
  if(!checkPerformFilter(allLocationsData, allInstructorsData, allClassesData, allStatuses, locations, instructors, classes, statusFilter)){
    events = [...approvedEvents, ...cancelledEvents, ...noShowEvents, ...openEvents, ...pendingEvents]
    if (business?.manager_request_gate) events = [...events, ...managerEvents]

    return(events)
  }
  if(statusFilter !== undefined && statusFilter?.length > 0){
    if(statusFilter.includes("approved")){
      events = [...events, ...approvedEvents]
    }
    if(statusFilter.includes("cancelled")){
      events = [...events, ...cancelledEvents]
    }
    if(statusFilter.includes("mgr") && business?.manager_request_gate){
      events = [...events, ...managerEvents]
    }
    if(statusFilter.includes("no_show")){
      events = [...events, ...noShowEvents]
    }
    if(statusFilter.includes("open")){
      events = [...events, ...openEvents]
    }
    if(statusFilter.includes("pending")){
      events = [...events, ...pendingEvents]
    }
  }
  if(path == 'all-requests') {
    let instructor_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user_id, 'instructor'))
    let class_status = ['all_selected', 'no_selected'].includes(checkFiltersState(user_id, 'class'))
    if(locations.length > 0 && instructor_status && class_status){
      events = events.filter((event) =>  locations.includes(event?.location_id))
    }else if(locations.length > 0 && !instructor_status && class_status){
      events = events.filter((event) =>  locations.includes(event?.location_id) && instructors.includes(event?.requesting_instructor_id))
    }else if(locations.length > 0 && instructor_status && !class_status){
      events = events.filter((event) =>  locations.includes(event?.location_id) && classes.includes(event?.business_class_id))
    }else if(locations.length > 0 && !instructor_status && !class_status){
      events = events.filter((event) =>  locations.includes(event?.location_id) && instructors.includes(event?.requesting_instructor_id) && classes.includes(event?.business_class_id))
    }else{
      events = []
    }
  }else {
    if(locations.length > 0){
      events = events.filter((event) =>  locations.includes(event?.location_id))
    }else{
      events = []
    }
  }
  
  return(events)
}

export const updateEventsList = (events, data) => {
  return(events.map((event) => {
    if(event?.id == data?.id){
      return data
    }else{
      return event
    }
  }))
}

export const removeDeletedEvent = (events, data) => {
  return(events.filter((event) =>  event?.id !== data?.id))
}

export const checkPerformFilter = (locations, instructorIds, classIds, allStatuses, selectedLocations, selectedInstructors, selectedClasses, statusFilter) => {
  return (selectedLocations.length !== locations?.length || selectedInstructors.length !== instructorIds?.length || selectedClasses.length !== classIds?.length || statusFilter.length !== allStatuses?.length)
}

export const checkFilterSaved = (userId) => {
  const lastFilters = JSON.parse(localStorage.getItem('lastFilters'));
  if(lastFilters && lastFilters?.uid && lastFilters?.uid == userId){
    return true
  }else{
    return false
  }
}

export const checkSavedAndComingLocations = (comingLocations, savedLocations) => {
  return (savedLocations.every(v => comingLocations.includes(v)))
}

export const seprateOutApprovalErrorEvents = (approvedEvents, cancelledEvents, managerEvents, noShowEvents, openEvents, pendingEvents) => {
  let events = [...approvedEvents, ...cancelledEvents, ...managerEvents, ...noShowEvents, ...openEvents, ...pendingEvents]
  events = events.filter((event) => event?.api_sync_error_message !== '')
  return events
}

export const eventsOfTheDay = (arg, dayDate) => {
  let eventsForDay = arg.view.calendar.getEvents().filter(event => {
    return event.start.toDateString() === dayDate.toDateString();
  })
  return eventsForDay
}

export const countEventsWithError = (eventsForDay) => {
  const numEventsWithError = eventsForDay.filter(event => {
    return event.extendedProps.api_sync_error_message !== '';
  }).length;
  return numEventsWithError
}

export const formatDateToFindDiv = (argDate) => {
  let dateInToDateStringFormat = argDate.toDateString();
  let parts = dateInToDateStringFormat.split(' ');
  let year = parts[3];
  let month = ('0' + (new Date(Date.parse(parts[1] + " 1, 2000")).getMonth() + 1)).slice(-2);
  let day = ('0' + parts[2]).slice(-2);
  return `${year}-${month}-${day}`
}

export const appendApprovalErrorSpan = (formattedDate, numEventsWithError) => {
  $(document).ready(function() {
    const moreLinkDay = $('.fc-day[data-date="' + formattedDate + '"]');
    const hasMoreLink = moreLinkDay.find('.fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-day-bottom a.fc-daygrid-more-link');
    if(hasMoreLink.length > 0){
      if(moreLinkDay.find('.fc-daygrid-day-frame .fc-daygrid-day-events .fc-daygrid-day-bottom a.fc-daygrid-more-link .approval-error-span').length == 0){
        let newSpan = $(`<span class="approval-error-span text-xs text-red-500 lowercase"> (<svg xmlns="http://www.w3.org/2000/svg" class="text-red-600 h-3.5 w-3.5 inline-block" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" /></svg>` + ` ${numEventsWithError}` + ' approval errors)' + '</span>')
        hasMoreLink.append(newSpan);
      }
    }
  });
}

export const hourlySortedEvents = (events) => {
  let newHash = events.reduce((acc, event) => {
    const hour = new Date(event.start).getHours();
    acc[hour] = acc[hour] || [];
    acc[hour].push(event);
    acc[hour] = acc[hour].sort((a, b) => new Date(a.start) - new Date(b.start));
    return acc;
  }, {});
  return newHash
}

export const modifyDayData = (events) => {
  let data = hourlySortedEvents(events)
  for (let hour in data) {
    if(data[hour].length > 3){
      let length = data[hour].length
      let approvalErrorEventsCount = getApprovalErrorEventsCount(data[hour]) || 0
      data[hour] = data[hour].slice(0, 3);
      let new_hash = modifyEventsDataComponent(data[hour], length, '', hour, approvalErrorEventsCount)
      data[hour].push(new_hash);
    }
  }
  return data
}

const modifyEventsDataComponent = (events, length, dateKey, hour, approvalErrorEventsCount) => {
  let time_array = events.map((event) => event.start)
  let latestTime = new Date(Math.max(...time_array.map(time => new Date(time))));
  let nextMinute = new Date(latestTime.getTime() + 60000);
  let fullYear = nextMinute.getFullYear();
  let monthInDigit = (nextMinute.getMonth() + 1).toString().padStart(2, '0');
  let dateOfMonth = (nextMinute.getDate()).toString().padStart(2, '0');
  let timeHour = (nextMinute.getHours()).toString().padStart(2, '0');
  let minute = (nextMinute.getMinutes()).toString().padStart(2, '0');
  let second = (nextMinute.getSeconds()).toString().padStart(2, '0');
  let start_date = `${fullYear}-${monthInDigit}-${dateOfMonth}T${timeHour}:${minute}:${second}`
  let endTimeMinute = new Date(nextMinute.getTime() + (2 * 60000));
  let endTimeHour = (endTimeMinute.getHours()).toString().padStart(2, '0');
  let endMinute = (endTimeMinute.getMinutes()).toString().padStart(2, '0');
  let endSecond = (endTimeMinute.getSeconds()).toString().padStart(2, '0');
  let end_date = `${fullYear}-${monthInDigit}-${dateOfMonth}T${endTimeHour}:${endMinute}:${endSecond}`
  return {
    title: `+${length - 3} More`,
    start: start_date,
    end: end_date,
    id: '',
    more_button: true,
    more_count: (length - 3),
    more_count_text: `+${length - 3} More`,
    varKey: hour,
    varDateKey: dateKey,
    approvalErrorEventsCount: approvalErrorEventsCount
  } 
}

const allEventsHours = (events) => {
  let hours = events.reduce((acc, event) => {
    const hour = new Date(event.start).getHours();
    if (!acc.includes(hour)) {
      acc.push(hour);
    }
    return acc;
  }, []).sort((a, b) => a - b);
  return hours
}

export const getEventsByDate = (events) => {
  let hours = allEventsHours(events)
  let eventsByDate = events.reduce((acc, event) => {
    const date = event.start.split('T')[0];
    const hour = new Date(event.start).getHours();
    const hourBlock = hours.find(h => h === hour);
    
    acc[date] = acc[date] || {};
    acc[date][hourBlock] = acc[date][hourBlock] || [];
    acc[date][hourBlock].push(event);
    acc[date][hourBlock] = acc[date][hourBlock].sort((a, b) => new Date(a.start) - new Date(b.start));
    
    return acc;
  }, {});
  return eventsByDate
}

const getApprovalErrorEventsCount = (events) => {
  return events.filter((event) => event?.api_sync_error_message !== '').length
}

export const modifyWeekData = (hashData) => {
  let hourlyEvents = []
  for (const date in hashData) {
    for (const hour in hashData[date]) {
      if(hashData[date][hour].length > 3){
        let singleHourEvents = []
        let length = hashData[date][hour].length
        let approvalErrorEventsCount = getApprovalErrorEventsCount(hashData[date][hour]) || 0
        singleHourEvents = hashData[date][hour].slice(0, 3);
        let new_hash = modifyEventsDataComponent(singleHourEvents, length, date, hour, approvalErrorEventsCount)
        singleHourEvents.push(new_hash);
        hourlyEvents = [...hourlyEvents, ...singleHourEvents]
      }else{
        hourlyEvents = [...hourlyEvents, ...hashData[date][hour]]
      }
    }
  }
  return hourlyEvents
}

export const checkAllInstructors = (oldSavedInstructorIds, newInstructorIds) => {
  return ((newInstructorIds.every(v => oldSavedInstructorIds.includes(v))) && oldSavedInstructorIds?.length == newInstructorIds)
}

export const checkAllClasses = (oldSavedClassIds, newClassIds) => {
  return ((newClassIds.every(v => oldSavedClassIds.includes(v))) && oldSavedClassIds?.length == newClassIds)
}

export const checkLocations = (comingLocationIds, comingRegions, savedLocations, savedRegions, regionLocations, user_id) => {
  if(savedLocations.length == 0){
    let data = {
      locations: comingLocationIds,
      regions: comingRegions
    }
    handleSaveFilters(data, (comingLocationIds?.length > 0 ? 'all_selected' : 'no_selected'), 'location', user_id)
    return [comingLocationIds, comingRegions]
  }else{
    let newLocations = []
    let newRegion = []
    newLocations = savedLocations.filter((id) => comingLocationIds.includes(id))
    if(newLocations.length == 0){
      let data = {
        locations: comingLocationIds,
        regions: comingRegions
      }
      handleSaveFilters(data, (comingLocationIds?.length > 0 ? 'all_selected' : 'no_selected'), 'location', user_id)
      newLocations = comingLocationIds
      newRegion = comingRegions
    }else{
      newRegion = checkSelctedRegions(regionLocations, newLocations)
      let data = {
        locations: newLocations,
        regions: newRegion
      }
      handleSaveFilters(data, (comingLocationIds?.length == newLocations?.length ? 'all_selected' : newLocations?.length > 0 ? 'some_selected' : 'no_selected'), 'location', user_id)
    }
    return [newLocations, newRegion]
  }
}

export const checkSelctedRegions = (regionLocations, locations) => {
  let selectedRegions = []
  for (let i = 0; i < regionLocations?.length; i++) {
    selectedRegions = checkRegionVisibility(regionLocations[i], locations, selectedRegions)
  }
  
  return selectedRegions
}

const checkRegionVisibility = (region, locations, selectedRegions) =>{
  let location_ids = region.locations.map(function(a) {return a.id;});
  let checker = (arr, target) => target.every(v => arr.includes(v));
  let equal = checker(locations, location_ids)
  let val = region.region?.name
  let regions = selectedRegions
  var __FOUND = regions.indexOf(val) > -1;
  if(equal){
    if(!__FOUND){
      return ([...selectedRegions, val])
    }else{
      return selectedRegions
    }
  }else{
    if(__FOUND){
      return(selectedRegions.filter((tag, index) => tag !== val))
    }else{
      return selectedRegions
    }
  }
}

export const checkInstructors = (comingInstructorIds, savedInstructors, user_id) => {
  if(savedInstructors.length == 0){
    handleSaveFilters(comingInstructorIds, (comingInstructorIds?.length > 0 ? 'all_selected' : 'no_selected'), 'instructor', user_id)
    return comingInstructorIds
  }else{
    let newInstructor = []
    newInstructor = savedInstructors.filter((id) => comingInstructorIds.includes(id))
    if(newInstructor.length == 0){
      handleSaveFilters(comingInstructorIds, (comingInstructorIds?.length > 0 ? 'all_selected' : 'no_selected'), 'instructor', user_id)
      newInstructor = comingInstructorIds
    }else{
      handleSaveFilters(newInstructor, (comingInstructorIds?.length == newInstructor?.length ? 'all_selected' : newInstructor?.length > 0 ? 'some_selected' : 'no_selected'), 'instructor', user_id)
    }
    return newInstructor
  }
}

export const checkClasses = (comingClassIds, savedClasses, user_id) => {
  if(savedClasses.length == 0){
    handleSaveFilters(comingClassIds, (comingClassIds?.length > 0 ? 'all_selected' : 'no_selected'), 'class', user_id)
    return comingClassIds
  }else{
    let newClass = []
    newClass = savedClasses.filter((id) => comingClassIds.includes(id))
    if(newClass.length == 0){
      newClass = comingClassIds
      handleSaveFilters(comingClassIds, (comingClassIds?.length > 0 ? 'all_selected' : 'no_selected'), 'class', user_id)
    }else{
      handleSaveFilters(newClass, (comingClassIds?.length == newClass?.length ? 'all_selected' : newClass?.length > 0 ? 'some_selected' : 'no_selected'), 'class', user_id)
    }
    return newClass
  }
}

export const handleCheckShowAll = () => {
  let showAllEvents = localStorage.getItem("showAllEvents")
  if(showAllEvents && showAllEvents !== ''){
    return showAllEvents
  }else{
    return false
  }
}

export const getSubBoardPath = (role) => {
  let roleName = getName(role)
  return `/my-requests`
}

export const handleRemoveCurrentDate = () => {
  let storedDate = localStorage.getItem("calendarDate")
  if(storedDate && storedDate !== ''){
    localStorage.removeItem("calendarDate");
  }
}

export const handleClickOnLink = (dateToSave) => {
  if(dateToSave !== '') localStorage.setItem("calendarDate", dateToSave);
}

export const checkShowRegions = (regionLocations) => {
  return !(regionLocations?.length == 1 && regionLocations[0]?.region?.id == 0)
}

export const savePageViewInLocalStorage = (viewType, pagePath) => {
  localStorage.setItem(`${pagePath == 'all-requests' ? 'allRequestsPageView' : "myRequestsPageView"}`, viewType)
}

export const dayWeekViewTimeGridTime = (date) => {
  const min = date.getMinutes();
  if (min == 15 || min == 45){
    return ''
  }else{
    const options = {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    };
    return date.toLocaleTimeString('en-US', options)
  }
}

export const  updateCalendarEvent = (events, newEventData) => {
  const eventIndex = events.findIndex(event => event.id === newEventData?.id);
  if (eventIndex !== -1) {
    const updatedEvents = [...events];
    updatedEvents[eventIndex] = { ...updatedEvents[eventIndex], ...newEventData };
    return updatedEvents;
  }
  return events;
}

export const removeCalendarEvent = (events, id) => {
  return events.filter((event) =>  event?.id !== id)
}

export const checkHidePastEvents = () => {
  let hidePastEvents = localStorage.getItem("hidePastEvents")
  if(hidePastEvents !== null && hidePastEvents !== undefined && hidePastEvents !== ''){
    return hidePastEvents
  }else{
    return false
  }
}

export const handleAddExtraPadding = (index, eventsCount, data) => {
  let div = $('.special-container-class')
  if(div.length > 0){
    if(eventsCount > 4){
      if(index === (eventsCount -1)){
        if(data?.length > 0){
          if(div.hasClass('pb-40')){
            div.removeClass('pb-40')
          }else{
            div.addClass('pb-40')
          }
        }
      }else if(index === (eventsCount -2)){
        if(data?.length > 0){
          if(div.hasClass('pb-24')){
            div.removeClass('pb-24')
          }else{
            div.addClass('pb-24')
          }
        }
      }else if(index === (eventsCount -3)){
        if(data?.length > 0){
          if(div.hasClass('pb-8')){
            div.removeClass('pb-8')
          }else{
            div.addClass('pb-8')
          }
        }
      }
    }else if(eventsCount === 4){
      if(index === (eventsCount -1)){
        if(data?.length > 2){
          if(div.hasClass('pb-40')){
            div.removeClass('pb-40')
          }else{
            div.addClass('pb-40')
          }
        }
      }
    }
  }
}

export const handleRemoveExtraPadding = () => {
  let div = $('.special-container-class')
  if(div.length > 0){
    if(div.hasClass('pb-40')){
      div.removeClass('pb-40')
    }else if(div.hasClass('pb-20')){
      div.removeClass('pb-20')
    }else if(div.hasClass('pb-24')){
      div.removeClass('pb-24')
    }else if(div.hasClass('pb-8')){
      div.removeClass('pb-8')
    }
  }
}

export const handleSaveFilters = (data, state, filterName, userId) => {
  let lastFilters = {
    statuses: [],
    statusesState: '',
    locations: [],
    regions: [],
    locationsState: '',
    instructors: [],
    instructorsState: '',
    classes: [],
    classesState: '',
    calendarViewType: 'month',
    uid: userId
  }
  if(checkFilterSaved(userId)){
    lastFilters = JSON.parse(localStorage.getItem('lastFilters'));
  }
  if(filterName == 'status'){
    lastFilters.statuses = data
    lastFilters.statusesState = state
  }else if(filterName == 'location'){
    lastFilters.locations = data?.locations
    lastFilters.regions = data?.regions
    lastFilters.locationsState = state
  }else if(filterName == 'instructor'){
    lastFilters.instructors = data
    lastFilters.instructorsState = state
  }else if(filterName == 'class'){
    lastFilters.classes = data
    lastFilters.classesState = state
  }else if(filterName == 'calendarViewType'){
    lastFilters.calendarViewType = data
  }
  localStorage.setItem('lastFilters', JSON.stringify(lastFilters));
}

export const checkFiltersState = (userId, filterName) => {
  if(checkFilterSaved(userId)){
    let lastFilters = JSON.parse(localStorage.getItem('lastFilters'));
    if(filterName == 'status'){
      return ((lastFilters?.statusesState && lastFilters?.statusesState !== '' ) ? lastFilters?.statusesState : 'all_selected' )
    }else if(filterName == 'location'){
      return ((lastFilters?.locationsState && lastFilters?.locationsState !== '' ) ? lastFilters?.locationsState : 'all_selected' )
    }else if(filterName == 'instructor'){
      return ((lastFilters?.instructorsState && lastFilters?.instructorsState !== '' ) ? lastFilters?.instructorsState : 'all_selected' )
    }else if(filterName == 'class'){
      return ((lastFilters?.classesState && lastFilters?.classesState !== '' ) ? lastFilters?.classesState : 'all_selected' )
    }
  }else{
    return 'all_selected'
  }
}

export const dayWeekViewEventTimeFormat = (eventTime) => {
  let timeString = eventTime.split(' - ')
  let startTime = timeString[0].replace(' ', '')
  return `${startTime}`.toLowerCase()
}

export const checkMyRequestFilterSaved = (userId, storageName) => {
  const lastFilters = JSON.parse(localStorage.getItem(storageName));
  if(lastFilters && lastFilters?.uid && lastFilters?.uid == userId){
    return true
  }else{
    return false
  }
}

export const handleSaveMyRequestFilters = (data, filters, userId, filterType) => {
  let storageName = filterType == 'sent' ? 'myRequestSentFilters' : filterType == 'available' ? 'myRequestAvailableFilters' : 'filterType'
  let lastFilters = {
    statuses: [],
    statusesState: '',
    locations: [],
    regions: [],
    locationsState: '',
    calendarViewType: 'month',
    uid: userId
  }
  if(checkMyRequestFilterSaved(userId, storageName)){
    lastFilters = JSON.parse(localStorage.getItem(storageName));
  }
  if(filters){
    if(Object.keys(data).includes('selectedStatus')) lastFilters.statuses = data?.selectedStatus
    if(Object.keys(data).includes('statusState')) lastFilters.statusesState = data?.statusState
    if(Object.keys(data).includes('locations')) lastFilters.locations = data?.locations
    if(Object.keys(data).includes('regions')) lastFilters.regions = data?.regions
    if(Object.keys(data).includes('locationsState')) lastFilters.locationsState = data?.locationsState
  }else{
    lastFilters.calendarViewType = data
  }
  localStorage.setItem(storageName, JSON.stringify(lastFilters));
}

export const checkMyRequestFiltersState = (userId, filterName, filterType) => {
  let storageName = filterType == 'sent' ? 'myRequestSentFilters' : filterType == 'available' ? 'myRequestAvailableFilters' : 'filterType'
  if(checkMyRequestFilterSaved(userId, storageName)){
    let lastFilters = JSON.parse(localStorage.getItem(storageName));
    if(filterName == 'status'){
      return ((lastFilters?.statusesState && lastFilters?.statusesState !== '' ) ? lastFilters?.statusesState : 'all_selected' )
    }else if(filterName == 'location'){
      return ((lastFilters?.locationsState && lastFilters?.locationsState !== '' ) ? lastFilters?.locationsState : 'no_selected' )
    }
  }
  return filterName == 'location' ? 'no_selected' : 'all_selected'
}

export const checkMyRequestLocations = (comingLocationIds, comingRegions, savedLocations, savedRegions, regionLocations, user_id, filterType) => {
  if(savedLocations.length == 0){
    let data = {
      locations: comingLocationIds,
      regions: comingRegions,
      locationsState: (comingLocationIds?.length > 0 ? 'all_selected' : 'no_selected')
    }
    handleSaveMyRequestFilters(data, true, user_id, filterType)
    return [comingLocationIds, comingRegions]
  }else{
    let newLocations = []
    let newRegion = []
    newLocations = savedLocations.filter((id) => comingLocationIds.includes(id))
    if(newLocations.length == 0){
      let data = {
        locations: comingLocationIds,
        regions: comingRegions,
        locationsState: (comingLocationIds?.length > 0 ? 'all_selected' : 'no_selected')
      }
      handleSaveMyRequestFilters(data, true, user_id, filterType)
      newLocations = comingLocationIds
      newRegion = comingRegions
    }else{
      newRegion = checkSelctedRegions(regionLocations, newLocations)
      let data = {
        locations: newLocations,
        regions: newRegion,
        locationsState: (comingLocationIds?.length == newLocations?.length ? 'all_selected' : newLocations?.length > 0 ? 'some_selected' : 'no_selected')
      }
      handleSaveMyRequestFilters(data, true, user_id, filterType)
    }
    return [newLocations, newRegion]
  }
}

export function availableHideDeclinedFilters(usr){
  let data = {
    selectedStatus: [],
    statusState: 'no_selected'
  }
  if(usr?.hide_declined_requests){
    data = {
      selectedStatus: ['pending', 'accept'],
      statusState: 'some_selected'
    }
    handleSaveMyRequestFilters(data, true, usr?.id, 'available')
  }
  return data
}
